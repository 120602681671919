define('shared/components/schema/input-string/component', ['exports', 'shared/components/schema/input-string/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    layout: _template.default,
    classNames: ['form-control'],
    type: 'text'
  });
});