define('global-admin/accounts/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    hasRefreshProviderAccess: false,

    model: function model() {
      return Ember.get(this, 'globalStore').findAll('user');
    },
    afterModel: function afterModel() {
      var _this = this;

      return this.globalStore.rawRequest({
        url: 'users',
        method: 'GET'
      }).then(function (users) {
        if (Ember.get(users, 'body.actions.refreshauthprovideraccess')) {
          Ember.set(_this, 'hasRefreshProviderAccess', true);
        }

        return;
      });
    },
    setupController: function setupController(controller, model) {
      if (Ember.get(this, 'hasRefreshProviderAccess')) {
        controller.set('hasRefreshProviderAccess', true);
      }

      this._super(controller, model);
    }
  });
});