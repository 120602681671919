define('shared/utils/evaluate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.evaluate = evaluate;
  exports.stringifyAnswer = stringifyAnswer;


  function getVariables(showIf, operator) {
    if (showIf.indexOf(operator) > -1) {
      var array = showIf.split(operator);

      if (array.length === 2) {
        return {
          left: array[0],
          right: array[1]
        };
      } else {
        return null;
      }
    }

    return null;
  }

  function getAnswer(variable, questions) {
    var found = questions.find(function (q) {
      return q.variable === variable;
    });

    if (found) {
      return found.answer;
    } else {
      return variable;
    }
  }

  function isEqual(showIf, allQuestions) {
    showIf = showIf.trim();
    var variables = getVariables(showIf, '=');

    if (variables) {
      var left = stringifyAnswer(getAnswer(variables.left, allQuestions));
      var right = stringifyAnswer(variables.right);

      return left === right;
    }

    return false;
  }

  function evaluate(question, allQuestions) {
    if (!question.showIf) {
      return true;
    }

    var and = question.showIf.split('&&');
    var or = question.showIf.split('||');

    var result = void 0;

    if (Ember.get(or, 'length') > 1) {
      result = or.some(function (showIf) {
        return isEqual(showIf, allQuestions);
      });
    } else {
      result = and.every(function (showIf) {
        return isEqual(showIf, allQuestions);
      });
    }

    return result;
  }

  function stringifyAnswer(answer) {
    if (answer === undefined || answer === null) {
      return '';
    } else if (typeof answer === 'string') {
      return answer;
    } else {
      return '' + answer;
    }
  }
});