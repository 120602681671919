define('global-admin/components/form-runasuser-policy/component', ['exports', 'global-admin/components/form-runasuser-policy/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,

    classNames: ['accordion-wrapper'],

    model: null,
    readOnly: false,

    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model.runAsUser', this.get('model.runAsUser') || this.get('globalStore').createRecord({
        type: 'runAsUserStrategyOptions',
        rule: 'RunAsAny'
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },


    actions: {
      add: function add() {
        this.get('model.runAsUser.ranges').pushObject(this.get('globalStore').createRecord({
          type: 'idRange',
          min: 1,
          max: 6
        }));
      },
      remove: function remove(obj) {
        this.get('model.runAsUser.ranges').removeObject(obj);
      }
    },

    ruleDidChange: function () {
      var rule = this.get('model.runAsUser.rule');

      if (rule === 'MustRunAs') {
        this.set('model.runAsUser.ranges', []);
        this.send('add');
      } else {
        this.set('model.runAsUser.ranges', null);
      }
    }.observes('model.runAsUser.rule')

  });
});