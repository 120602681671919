define('global-admin/settings/index/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    cookies: Ember.inject.service(),
    projectId: Ember.computed.alias('cookies.' + _constants.default.COOKIE.PROJECT)
  });
});