define('shared/grafana/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GRAFANA_LINKS = [{
    id: 'etcd',
    title: 'Etcd'
  }, {
    id: 'scheduler',
    title: 'Kubernetes Components'
  }, {
    id: 'controller',
    title: 'Kubernetes Components'
  }, {
    id: 'nodes',
    title: 'Nodes'
  }, {
    id: 'k8s',
    title: 'Kubernetes Components'
  }, {
    id: 'rancher',
    title: 'Rancher Components'
  }];

  exports.default = Ember.Service.extend({
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    grafanaLinks: GRAFANA_LINKS,
    dashboards: null,

    updateLinks: function updateLinks() {
      var _this = this;

      (Ember.get(this, 'grafanaLinks') || []).forEach(function (link) {
        var dashboards = Ember.get(_this, 'dashboards') || [];
        var target = dashboards.findBy('title', Ember.get(link, 'title'));

        if (target) {
          var grafanaUrl = '' + Ember.get(_this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint') + Ember.get(target, 'url');

          Ember.set(_this, Ember.get(link, 'id') + 'Url', grafanaUrl);
        } else {
          Ember.set(_this, Ember.get(link, 'id') + 'Url', null);
        }
      });
    },


    monitoringStatusDidChange: Ember.on('init', Ember.observer('scope.currentCluster.id', 'scope.currentProject.id', 'scope.currentCluster.isMonitoringReady', 'scope.currentProject.isMonitoringReady', function () {
      var _this2 = this;

      Ember.set(this, 'dashboards', []);
      this.updateLinks();
      var isProjectReady = Ember.get(this, 'scope.currentProject.isMonitoringReady');
      var systemProject = Ember.get(this, 'globalStore').all('project').findBy('isSystemProject', true);

      if (isProjectReady) {
        var rootUrl = Ember.get(this, 'scope.currentProject.monitoringStatus.grafanaEndpoint');

        Ember.get(this, 'globalStore').rawRequest({
          url: rootUrl + 'api/search',
          method: 'GET'
        }).then(function (xhr) {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          var dashboards = xhr.body || [];

          Ember.set(_this2, 'dashboards', dashboards);
        });
      } else if (systemProject) {
        var isClusterReady = Ember.get(this, 'scope.currentCluster.isMonitoringReady');

        if (isClusterReady) {
          var _rootUrl = Ember.get(this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint');

          Ember.get(this, 'globalStore').rawRequest({
            url: _rootUrl + 'api/search',
            method: 'GET'
          }).then(function (xhr) {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }

            var dashboards = xhr.body || [];

            Ember.set(_this2, 'dashboards', dashboards);
            _this2.updateLinks();
          });
        } else {
          Ember.set(this, 'dashboards', []);
          this.updateLinks();
        }
      }
    }))
  });
});