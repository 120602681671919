define('shared/components/schema/input-int/component', ['exports', 'shared/components/schema/input-int/template', 'shared/components/input-integer/component'], function (exports, _template, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,

    classNames: ['form-control'],

    max: Ember.computed.alias('field.max'),
    min: Ember.computed.alias('field.min')
  });
});