define('global-admin/components/role-rule-row/component', ['exports', 'ui/utils/constants', 'global-admin/components/role-rule-row/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var verbs = _constants.default.RULE_VERBS;

  exports.default = Ember.Component.extend({
    layout: _template.default,
    rule: null,
    rules: null,
    resource: null,
    readOnly: null,

    tagName: 'TR',
    classNames: 'main-row',

    init: function init() {
      this._super.apply(this, arguments);
      var rule = Ember.get(this, 'rule');
      var currentVerbs = Ember.get(rule, 'verbs');

      Ember.set(this, 'verbs', verbs.map(function (verb) {
        return {
          key: verb,
          value: currentVerbs.indexOf('*') > -1 || currentVerbs.indexOf(verb) > -1
        };
      }));
      var rules = _constants.default.ROLE_RULES.sort();

      Ember.set(this, 'rules', rules.map(function (rule) {
        return {
          label: rule,
          value: rule.toLowerCase()
        };
      }));
      if ((Ember.get(rule, 'resources') || []).get('length') > 0) {
        Ember.set(this, 'resource', Ember.get(rule, 'resources').join(','));
      }
    },


    actions: {
      remove: function remove() {
        this.sendAction('remove', Ember.get(this, 'rule'));
      }
    },

    verbChanged: Ember.observer('verbs.@each.{key,value}', function () {
      var verbs = Ember.get(this, 'verbs');
      var selectedVerbs = verbs.filter(function (verb) {
        return verb.value;
      }).map(function (verb) {
        return verb.key;
      });

      var rule = Ember.get(this, 'rule');

      Ember.set(rule, 'verbs', selectedVerbs);
    }),

    selectedResourceChanged: Ember.observer('resource', function () {
      var rule = Ember.get(this, 'rule');

      Ember.set(rule, 'resources', [Ember.get(this, 'resource')]);
    })
  });
});