define('shared/settings/service', ['exports', 'shared/utils/constants', 'shared/utils/parse-version', 'async'], function (exports, _constants, _parseVersion, _async) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeName = normalizeName;
  exports.denormalizeName = denormalizeName;
  function normalizeName(str) {
    return str; // .replace(/\./g, C.SETTING.DOT_CHAR).toLowerCase();
  }

  function denormalizeName(str) {
    return str; // .replace(new RegExp('['+C.SETTING.DOT_CHAR+']','g'),'.').toLowerCase();
  }

  exports.default = Ember.Service.extend(Ember.Evented, {
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),

    all: null,
    promiseCount: 0,

    init: function init() {
      this._super();
      Ember.set(this, 'all', Ember.get(this, 'globalStore').all('setting'));
    },
    unknownProperty: function unknownProperty(key) {
      var obj = this.findByName(key);

      if (obj) {
        var val = obj.get('value');

        if (val === 'false') {
          return false;
        } else if (val === 'true') {
          return true;
        } else {
          return val;
        }
      }

      return null;
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      var _this = this;

      if (key !== 'app') {
        var obj = this.findByName(key);

        if (value === undefined) {
          // Delete by set to undefined is not needed for settings
          throw new Error('Deleting settings is not supported');
        }

        if (obj) {
          obj = obj.clone();
        } else {
          obj = Ember.get(this, 'globalStore').createRecord({
            type: 'setting',
            name: denormalizeName(key)
          });
        }

        this.incrementProperty('promiseCount');

        obj.set('value', '' + value); // Values are all strings in settings.
        obj.save().then(function () {
          _this.notifyPropertyChange(normalizeName(key));
        }).catch(function (err) {
          Ember.get(_this, 'growl').fromError(err);
        }).finally(function () {
          _this.decrementProperty('promiseCount');
        });
      }

      return value;
    },


    promiseCountObserver: Ember.observer('promiseCount', function () {
      if (Ember.get(this, 'promiseCount') <= 0) {
        this.trigger('settingsPromisesResolved');
      }
    }),

    findByName: function findByName(name) {
      return Ember.get(this, 'asMap')[normalizeName(name)];
    },
    loadAll: function loadAll() {
      return Ember.get(this, 'globalStore').findAll('setting');
    },
    load: function load(names) {
      if (!Ember.isArray(names)) {
        names = [names];
      }

      var globalStore = Ember.get(this, 'globalStore');

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _async.eachLimit)(names, 3, function (name, cb) {
          globalStore.find('setting', denormalizeName(name)).then(function () {
            cb();
          }).catch(function (err) {
            cb(err);
          });
        }, function (err) {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });

      return promise;
    },


    cliVersion: Ember.computed.alias('asMap.' + _constants.default.SETTING.VERSION_CLI + '.value'),
    dockerMachineVersion: Ember.computed.alias('asMap.' + _constants.default.SETTING.VERSION_MACHINE + '.value'),
    helmVersion: Ember.computed.alias('asMap.' + _constants.default.SETTING.VERSION_HELM + '.value'),
    minDockerVersion: Ember.computed.alias('asMap.' + _constants.default.SETTING.MIN_DOCKER + '.value'),
    rancherImage: Ember.computed.alias('asMap.' + _constants.default.SETTING.IMAGE_RANCHER + '.value'),
    rancherVersion: Ember.computed.alias('asMap.' + _constants.default.SETTING.VERSION_RANCHER + '.value'),
    serverUrl: Ember.computed.alias('asMap.' + _constants.default.SETTING.SERVER_URL + '.value'),

    asMap: Ember.computed('all.@each.{name,value,customized}', function () {
      var out = {};

      (Ember.get(this, 'all') || []).forEach(function (setting) {
        var name = normalizeName(setting.get('name'));

        out[name] = setting;
      });

      return out;
    }),

    uiVersion: Ember.computed('app.version', function () {
      return 'v' + Ember.get(this, 'app.version');
    }),

    issueUrl: Ember.computed('app.currentRouteName', 'access.{provider,admin}', 'rancherVersion', 'uiVersion', function () {
      var str = '*Describe your issue here*\n\n\n---\n| Useful | Info |\n| :-- | :-- |\n' + ('|Versions|Rancher `' + (Ember.get(this, 'rancherVersion') || '-') + '` ') + ('UI: `' + (Ember.get(this, 'uiVersion') || '--') + '` |\n');

      str += '|Route|`' + Ember.get(this, 'app.currentRouteName') + '`|\n';

      var url = _constants.default.EXT_REFERENCES.GITHUB + '/issues/new?body=' + encodeURIComponent(str);

      return url;
    }),

    serverUrlIsEmpty: Ember.computed('serverUrl', function () {
      return Ember.isEmpty(Ember.get(this, 'serverUrl'));
    }),

    isRancher: Ember.computed(_constants.default.SETTING.PL, function () {
      var out = (Ember.get(this, _constants.default.SETTING.PL) || '').toUpperCase() === _constants.default.SETTING.PL_RANCHER_VALUE.toUpperCase();

      return out;
    }),

    isEnterprise: Ember.computed('rancherImage', function () {
      return Ember.get(this, 'rancherImage') === 'rancher/enterprise';
    }),

    appName: Ember.computed('isRancher', _constants.default.SETTING.PL, function () {
      if (Ember.get(this, 'isRancher')) {
        return Ember.get(this, 'app.appName');
      }

      return Ember.get(this, _constants.default.SETTING.PL) || '';
    }),

    minorVersion: Ember.computed('rancherVersion', function () {
      var version = Ember.get(this, 'rancherVersion');

      if (!version) {
        return null;
      }

      return (0, _parseVersion.minorVersion)(version);
    }),

    docsBase: Ember.computed('intl.locale', 'minorVersion', function () {
      var full = Ember.get(this, 'rancherVersion');
      var version = void 0;

      if (!full || (0, _parseVersion.isDevBuild)(full)) {
        version = (0, _parseVersion.minorVersion)(Ember.get(this, 'uiVersion'));
      } else {
        version = (0, _parseVersion.minorVersion)(full);
      }

      version = version.replace(/\.\d+$/, '.x');

      var lang = ((Ember.get(this, 'intl.locale') || [])[0] || '').replace(/-.*$/, '');

      if (!lang || lang === 'none' || _constants.default.LANGUAGE.DOCS.indexOf(lang) === -1) {
        lang = 'en';
      }

      return _constants.default.EXT_REFERENCES.DOCS + '/' + version + '/' + lang;
    })
  });
});