define('global-admin/application/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),

    model: function model() {
      return Ember.get(this, 'scope').startSwitchToGlobal(false);
    },
    setupController: function setupController() /* controller, model*/{
      this._super.apply(this, arguments);
      Ember.get(this, 'scope').finishSwitchToGlobal();
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('error', null);
      }
    }
  });
});