define('shared/utils/intl/missing-message', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = missingMessage;
  var logger = Ember.Logger;
  function missingMessage(key, locales) {
    if (key) {
      locales = locales || ['unknown'];
      if (locales[0] !== 'none') {
        logger.warn('Translation not found: locale=\'' + locales.join(', ') + '\', key=\'' + key + '\'.');
      }

      return '*%' + key + '%*';
    } else {
      return '';
    }
  }
});