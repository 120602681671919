enifed('@ember/map/lib/utils', ['exports', '@ember/deprecated-features'], function (exports, _deprecatedFeatures) {
  'use strict';

  exports.copyNull = exports.copyMap = undefined;

  var copyNull = void 0,
      copyMap = void 0;

  if (_deprecatedFeatures.MAP || _deprecatedFeatures.ORDERED_SET) {
    exports.copyNull = copyNull = function (obj) {
      var output = Object.create(null);

      for (var prop in obj) {
        // hasOwnPropery is not needed because obj is Object.create(null);
        output[prop] = obj[prop];
      }

      return output;
    };

    exports.copyMap = copyMap = function (original, newObject) {
      var keys = original._keys.copy();
      var values = copyNull(original._values);

      newObject._keys = keys;
      newObject._values = values;
      newObject.size = original.size;

      return newObject;
    };
  }

  exports.copyMap = copyMap;
  exports.copyNull = copyNull;
});