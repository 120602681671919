define('global-admin/clusters/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    shortcuts: { 'g': 'toggleGrouping' },
    actions: {
      toggleGrouping: function toggleGrouping() {
        var _this = this;

        var choices = ['list', 'grouped'];
        var cur = this.get('controller.mode');
        var neu = choices[(choices.indexOf(cur) + 1) % choices.length];

        Ember.run.next(function () {
          _this.set('controller.mode', neu);
        });
      }
    }

  });
});