enifed('@ember/map/lib/ordered-set', ['exports', 'ember-babel', '@ember/debug', 'ember-utils', '@ember/map/lib/utils', '@ember/deprecated-features'], function (exports, _emberBabel, _debug, _emberUtils, _utils, _deprecatedFeatures) {
  'use strict';

  exports.__OrderedSet__ = undefined;

  /**
    This class is used internally by Ember and Ember Data.
    Please do not use it at this time. We plan to clean it up
    and add many tests soon.
  
    @class OrderedSet
    @namespace Ember
    @constructor
    @private
    @deprecated
  */
  var __OrderedSet__ = void 0,
      OrderedSet = void 0;
  /**
   * This is exported so it can be used by the OrderedSet library.
   * This is private do not use it.
   @private
   */

  if (_deprecatedFeatures.ORDERED_SET) {
    exports.__OrderedSet__ = __OrderedSet__ = function () {
      function __OrderedSet__() {

        this.clear();
      }
      /**
      @method create
      @static
      @return {Ember.OrderedSet}
      @private
      */

      __OrderedSet__.create = function () {
        var Constructor = this;
        return new Constructor();
      };

      /**
      @method clear
      @private
      */

      __OrderedSet__.prototype.clear = function () {
        this.presenceSet = Object.create(null);
        this.list = [];
        this.size = 0;
      };

      /**
      @method add
      @param obj
      @param guid (optional, and for internal use)
      @return {Ember.OrderedSet}
      @private
      */

      __OrderedSet__.prototype.add = function (obj, _guid) {
        var guid = _guid || (0, _emberUtils.guidFor)(obj);
        var presenceSet = this.presenceSet;
        var list = this.list;

        if (presenceSet[guid] !== true) {
          presenceSet[guid] = true;
          this.size = list.push(obj);
        }

        return this;
      };

      /**
      @since 1.8.0
      @method delete
      @param obj
      @param _guid (optional and for internal use only)
      @return {Boolean}
      @private
      */

      __OrderedSet__.prototype.delete = function (obj, _guid) {
        var guid = _guid || (0, _emberUtils.guidFor)(obj),
            index;
        var presenceSet = this.presenceSet;
        var list = this.list;

        if (presenceSet[guid] === true) {
          delete presenceSet[guid];
          index = list.indexOf(obj);

          if (index > -1) {
            list.splice(index, 1);
          }
          this.size = list.length;
          return true;
        } else {
          return false;
        }
      };

      /**
      @method isEmpty
      @return {Boolean}
      @private
      */

      __OrderedSet__.prototype.isEmpty = function () {
        return this.size === 0;
      };

      /**
      @method has
      @param obj
      @return {Boolean}
      @private
      */

      __OrderedSet__.prototype.has = function (obj) {
        if (this.size === 0) {
          return false;
        }

        var guid = (0, _emberUtils.guidFor)(obj);
        var presenceSet = this.presenceSet;

        return presenceSet[guid] === true;
      };

      /**
      @method forEach
      @param {Function} fn
      @param self
      @private
      */

      __OrderedSet__.prototype.forEach = function (fn /*, ...thisArg*/) {
        false && !(typeof fn === 'function') && (0, _debug.assert)(Object.prototype.toString.call(fn) + ' is not a function', typeof fn === 'function');

        if (this.size === 0) {
          return;
        }

        var list = this.list,
            i,
            _i;

        if (arguments.length === 2) {
          for (i = 0; i < list.length; i++) {
            fn.call(arguments[1], list[i]);
          }
        } else {
          for (_i = 0; _i < list.length; _i++) {
            fn(list[_i]);
          }
        }
      };

      /**
      @method toArray
      @return {Array}
      @private
      */

      __OrderedSet__.prototype.toArray = function () {
        return this.list.slice();
      };

      /**
      @method copy
      @return {Ember.OrderedSet}
      @private
      */

      __OrderedSet__.prototype.copy = function () {
        var Constructor = this.constructor;
        var set = new Constructor();

        set.presenceSet = (0, _utils.copyNull)(this.presenceSet);
        set.list = this.toArray();
        set.size = this.size;

        return set;
      };

      return __OrderedSet__;
    }();

    OrderedSet = function (_OrderedSet__) {
      (0, _emberBabel.inherits)(OrderedSet, _OrderedSet__);

      function OrderedSet() {

        var _this = (0, _emberBabel.possibleConstructorReturn)(this, _OrderedSet__.call(this));

        false && !false && (0, _debug.deprecate)('Use of @ember/OrderedSet is deprecated. Please use native `Map` instead', false, {
          id: 'ember-map-deprecation',
          until: '3.5.0'
        });
        return _this;
      }

      return OrderedSet;
    }(__OrderedSet__);
  }

  exports.__OrderedSet__ = __OrderedSet__;
  exports.default = OrderedSet;
});