define('shared/catalog/service', ['exports', 'shared/utils/util', 'ui/utils/parse-externalid'], function (exports, _util, _parseExternalid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var RANCHER_VERSION = 'rancherVersion';
  var SYSTEM_CATALOG = 'system-library';

  exports.default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    scope: Ember.inject.service(),
    app: Ember.inject.service(),

    templateCache: null,
    catalogs: null,

    _allCatalogs: Ember.computed.union('globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),
    _refreshMap: null,

    globalCatalogs: null,
    clusterCatalogs: null,
    projectCatalogs: null,

    init: function init() {
      this._super.apply(this, arguments);
      var store = Ember.get(this, 'globalStore');

      Ember.setProperties(this, {
        globalCatalogs: store.all('catalog'),
        clusterCatalogs: store.all('clustercatalog'),
        projectCatalogs: store.all('projectcatalog'),
        '_refreshMap': {}
      });
    },


    catalogsDidChange: Ember.observer('_allCatalogs.@each.state', '_refreshMap', function () {
      if (Ember.get(this, 'templateCache') !== null) {
        var oldRefreshMap = Ember.get(this, '_refreshMap');
        var newRefreshMap = {};

        (Ember.get(this, '_allCatalogs') || []).forEach(function (c) {
          newRefreshMap[Ember.get(c, 'id')] = Ember.get(c, 'lastRefreshTimestamp');
        });
        var needRefresh = false;

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = new Set([].concat(_toConsumableArray(Object.keys(newRefreshMap)), _toConsumableArray(Object.keys(oldRefreshMap))))[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var k = _step.value;

            if (!oldRefreshMap.hasOwnProperty(k) || !newRefreshMap.hasOwnProperty(k) || oldRefreshMap[k] !== newRefreshMap[k]) {
              needRefresh = true;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Ember.set(this, 'needRefresh', needRefresh);
      }
    }),

    reset: function reset() {
      this.setProperties({
        templateCache: null,
        catalogs: null
      });
    },
    refresh: function refresh() {
      var _this = this;

      var store = Ember.get(this, 'globalStore');

      return this.fetchUnScopedCatalogs().then(function () {
        Ember.set(_this, 'templateCache', null);

        return Ember.RSVP.hash({
          projectCatalogs: store.request({
            method: 'POST',
            url: Ember.get(_this, 'app.apiEndpoint') + '/projectcatalogs?action=refresh'
          }),
          clusterCatalogs: store.request({
            method: 'POST',
            url: Ember.get(_this, 'app.apiEndpoint') + '/clustercatalogs?action=refresh'
          }),
          globalCatalogs: store.request({
            method: 'POST',
            url: Ember.get(_this, 'app.apiEndpoint') + '/catalogs?action=refresh'
          })
        });
      });
    },
    fetchAppTemplates: function fetchAppTemplates(apps) {
      var _this2 = this;

      var deps = [];

      apps.forEach(function (app) {
        var extInfo = (0, _parseExternalid.parseHelmExternalId)(app.get('externalId'));

        if (extInfo && extInfo.templateId) {
          deps.push(_this2.fetchTemplate(extInfo.templateId, false));
        }
      });

      return Ember.RSVP.allSettled(deps);
    },
    fetchMultiClusterAppTemplates: function fetchMultiClusterAppTemplates(apps) {
      var _this3 = this;

      var deps = [];

      apps.forEach(function (app) {
        var extInfo = Ember.get(app, 'externalIdInfo');

        if (extInfo && extInfo.templateId) {
          deps.push(_this3.fetchTemplate(extInfo.templateId, false));
        }
      });

      return Ember.RSVP.allSettled(deps);
    },
    fetchUnScopedCatalogs: function fetchUnScopedCatalogs() {
      return Ember.RSVP.hash({
        projectCatalogs: this.fetchCatalogs('projectCatalog'),
        clusterCatalogs: this.fetchCatalogs('clusterCatalog'),
        globalCatalogs: this.fetchCatalogs('catalog')
      });
    },
    fetchCatalogs: function fetchCatalogs() {
      var catalogs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'catalog';
      var opts = arguments[1];

      return Ember.get(this, 'globalStore').findAll(catalogs, opts);
    },
    getTemplateFromCache: function getTemplateFromCache(id) {
      return Ember.get(this, 'globalStore').getById('template', id);
    },
    getVersionFromCache: function getVersionFromCache(id) {
      return Ember.get(this, 'globalStore').getById('templateversion', id);
    },
    fetchTemplate: function fetchTemplate(id) {
      var upgrade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var type = void 0,
          cached = void 0;

      if (upgrade === true) {
        type = 'templateversions';
        cached = this.getVersionFromCache(id);
      } else {
        type = 'templates';
        cached = this.getTemplateFromCache(id);
      }

      if (cached) {
        return Ember.RSVP.resolve(cached);
      }

      var url = this._addLimits(Ember.get(this, 'app.apiEndpoint') + '/' + type + '/' + id);

      return Ember.get(this, 'globalStore').request({ url: url });
    },
    fetchTemplates: function fetchTemplates(params) {
      var _this4 = this;

      params = params || {};

      var cache = Ember.get(this, 'templateCache');
      var catalogId = null;
      var qp = { 'category_ne': 'system' };
      var project = params.project ? params.project : null;
      var currentProjectId = project ? project.id.split(':')[1] : null;
      var currentClusterId = project ? project.clusterId : null;

      if (params.catalogId) {
        catalogId = params.catalogId;

        if (catalogId && catalogId !== 'all') {
          qp['catalogId'] = catalogId;
        }
      } else if (params.clusterCatalogId) {
        catalogId = params.clusterCatalogId;

        if (catalogId && catalogId !== 'all') {
          qp['clusterCatalogId'] = catalogId;
        }
      } else if (params.projectCatalogId) {
        catalogId = params.projectCatalogId;

        if (catalogId && catalogId !== 'all') {
          qp['projectCatalogId'] = catalogId;
        }
      }

      // If the catalogIds dont match we need to go get the other catalog from the store since we do not cache all catalogs
      if (cache && cache.catalogId === catalogId && !Ember.get(this, 'needRefresh')) {
        return Ember.RSVP.resolve(this.filter(cache, params.category));
      }

      var catalogs = Ember.get(this, '_allCatalogs');
      var refreshMap = {};

      catalogs.forEach(function (c) {
        refreshMap[Ember.get(c, 'id')] = Ember.get(c, 'lastRefreshTimestamp');
      });
      Ember.set(this, '_refreshMap', refreshMap);

      var url = this._addLimits(Ember.get(this, 'app.apiEndpoint') + '/templates', qp);

      return Ember.get(this, 'globalStore').request({ url: url }).then(function (res) {
        res.catalogId = catalogId;

        if (catalogId === 'all' || !catalogId) {
          Ember.set(_this4, 'templateCache', res.filter(function (t) {
            _this4;
            if (t.clusterId && currentClusterId) {
              if (t.clusterId === currentClusterId) {
                return t;
              }
            } else if (t.projectId && currentProjectId) {
              if (t.projectId === currentProjectId) {
                return t;
              }
            } else {
              return t;
            }
          }));
        } else {
          Ember.set(_this4, 'templateCache', res);
        }

        return _this4.filter(res, params.category);
      }).catch(function (err) {
        if (params.allowFailure) {
          return _this4.filter([], params.category);
        } else {
          return Ember.RSVP.reject(err);
        }
      });
    },
    cleanVersionsArray: function cleanVersionsArray(template) {
      return Object.keys(template.versionLinks).filter(function (key) {
        // Filter out empty values for rancher/rancher#5494
        return !!template.versionLinks[key];
      }).map(function (key) {
        return {
          version: key,
          sortVersion: key,
          link: template.versionLinks[key]
        };
      });
    },
    fetchByUrl: function fetchByUrl(url) {
      return Ember.get(this, 'store').request({ url: url });
    },
    filter: function filter(data, category) {
      category = (category || '').toLowerCase();

      var categories = [];

      data.forEach(function (obj) {
        if (Ember.get(obj, 'catalogId') !== SYSTEM_CATALOG) {
          categories.pushObjects(obj.get('categoryArray'));
        }
      });
      categories = (0, _util.uniqKeys)(categories);
      categories.unshift('');

      data = data.filter(function (tpl) {
        if (category !== '' && !tpl.get('categoryLowerArray').includes(category)) {
          return false;
        }

        return Ember.get(tpl, 'catalogId') !== SYSTEM_CATALOG;
      });

      data = data.sortBy('name');

      return Ember.Object.create({
        categories: categories,
        catalog: data
      });
    },
    _addLimits: function _addLimits(url, qp) {
      var version = Ember.get(this, 'settings.rancherVersion');

      qp = qp || {};

      if (version) {
        qp[RANCHER_VERSION] = version;
      }

      url = (0, _util.addQueryParams)(url, qp);

      return url;
    }
  });
});