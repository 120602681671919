define('shared/components/storage-class/provisioner-scaleio/component', ['exports', 'shared/components/storage-class/provisioner-scaleio/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['gateway', 'system', 'protectionDomain', 'storagePool', {
    id: 'storageMode',
    options: [{
      value: 'ThinProvisioned',
      translationKey: 'cruStorageClass.scaleio.storageMode.ThinProvisioned'
    }, {
      value: 'ThickProvisioned',
      translationKey: 'cruStorageClass.scaleio.storageMode.ThickProvisioned'
    }]
  }, 'secretRef', {
    id: 'readOnly',
    options: [{
      value: 'false',
      translationKey: 'generic.no'
    }, {
      value: 'true',
      translationKey: 'generic.yes'
    }]
  }, 'fsType'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'scaleio',
    fields: FIELDS
  });
});