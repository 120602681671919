enifed('@ember/deprecated-features/index', ['exports'], function (exports) {
  'use strict';

  exports.SEND_ACTION = !!'3.4.0';
  exports.PROPERTY_BASED_DESCRIPTORS = !!'3.2.0';
  exports.EMBER_EXTEND_PROTOTYPES = !!'3.2.0-beta.5';
  exports.DEPRECATE_OPTIONS_MISSING = !!'2.1.0-beta.1';
  exports.DEPRECATE_ID_MISSING = !!'2.1.0-beta.1';
  exports.DEPRECATE_UNTIL_MISSING = !!'2.1.0-beta.1';
  exports.RUN_SYNC = !!'3.0.0-beta.4';
  exports.REGISTRY_RESOLVER_AS_FUNCTION = !!'2.3.0-beta.3';
  exports.LOGGER = !!'3.2.0-beta.1';
  exports.POSITIONAL_PARAM_CONFLICT = !!'3.1.0-beta.1';
  exports.DID_INIT_ATTRS = !!'2.6.0-beta.1';
  exports.PROPERTY_WILL_CHANGE = !!'3.1.0-beta.1';
  exports.PROPERTY_DID_CHANGE = !!'3.1.0-beta.1';
  exports.ROUTER_ROUTER = !!'3.2.0-beta.1';
  exports.ORPHAN_OUTLET_RENDER = !!'2.11.0-beta.1';
  exports.ARRAY_AT_EACH = !!'3.1.0-beta.1';
  exports.TARGET_OBJECT = !!'2.18.0-beta.1';
  exports.RENDER_HELPER = !!'2.11.0-beta.1';
  exports.BINDING_SUPPORT = !!'2.7.0-beta.1';
  exports.MAP = !!'3.3.0-beta.1';
  exports.ORDERED_SET = !!'3.3.0-beta.1';
});