define('shared/pipeline-github/service', ['exports', 'ui/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    redirect: null,
    // Set by app/services/access
    hostname: null,
    scheme: null,
    clientId: null,

    generateState: function generateState() {
      var state = '' + Math.random();

      Ember.get(this, 'session').set('githubState', state);

      return state;
    },


    redirectURL: function () {
      return window.location.origin + '/verify-auth';
    }.property(),

    getAuthorizeUrl: function getAuthorizeUrl(githubAuthUrl) {
      var redirect = Ember.get(this, 'redirectURL');

      redirect = redirect.split('#')[0];
      var url = _util.default.addQueryParams(githubAuthUrl, {
        state: this.generateState(),
        redirect_uri: redirect
      });

      Ember.set(this, 'redirect', redirect);

      return url;
    },
    authorizeTest: function authorizeTest(githubAuthUrl, cb) {
      var neverReject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var responded = false;

      window.onAuthTest = function (err, code) {
        if (!responded) {
          responded = true;
          cb(err, code);
        }
      };

      var popup = window.open(this.getAuthorizeUrl(githubAuthUrl), 'rancherAuth', _util.default.popupWindowOptions());
      var timer = setInterval(function () {
        if (!popup || popup.closed) {
          clearInterval(timer);
          if (!responded) {
            responded = true;
            if (neverReject) {
              cb();
            } else {
              cb({
                type: 'error',
                message: 'Access was not authorized'
              });
            }
          }
        }
      }, 500);
    }
  });
});