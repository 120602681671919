define('global-admin/clusters/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      var store = this.get('globalStore');

      return store.findAll('cluster').then(function () /* resp */{
        var clusters = store.all('cluster');

        if (clusters.length > 0) {
          clusters.forEach(function (cluster) {
            cluster.store.findAll('etcdbackup');
          });
        }

        return { clusters: clusters };
      });
    }
  });
});