define('@rancher/ember-api-store/mixins/type', ['exports', '@rancher/ember-api-store/mixins/serializable', '@rancher/ember-api-store/utils/normalize', '@rancher/ember-api-store/utils/apply-headers', '@rancher/ember-api-store/utils/url-options'], function (exports, _serializable, _normalize, _applyHeaders, _urlOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Type = Ember.Mixin.create(_serializable.default, {
    id: null,
    type: null,
    links: null,

    toString: function toString() {
      return '(generic store type mixin)';
    },

    // unionArrays=true will append the new values to the existing ones instead of overwriting.
    merge: function merge(newData) {
      var unionArrays = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      var self = this;

      newData.eachKeys(function (v, k) {
        if (newData.hasOwnProperty(k)) {
          var curVal = self.get(k);
          if (unionArrays && Ember.isArray(curVal) && Ember.isArray(v)) {
            curVal.addObjects(v);
          } else {
            self.set(k, v);
          }
        }
      });

      return self;
    },

    replaceWith: function replaceWith(newData) {
      var self = this;
      // Add/replace values that are in newData
      newData.eachKeys(function (v, k) {
        self.set(k, v);
      });

      // Remove values that are in current but not new.
      var newKeys = newData.allKeys();
      this.eachKeys(function (v, k) {
        // If the key is a valid link name and
        if (newKeys.indexOf(k) === -1 && !this.hasLink(k)) {
          self.set(k, undefined);
        }
      });

      return self;
    },

    clone: function clone() {
      var store = Ember.get(this, 'store');
      var output = store.createRecord(JSON.parse(JSON.stringify(this.serialize())), { updateStore: false });
      //output.set('store', get(this, 'store'));
      return output;
    },

    linkFor: function linkFor(name) {
      var url = Ember.get(this, 'links.' + name);
      return url;
    },

    pageFor: function pageFor(which) {
      return Ember.get(this, 'pagination.' + which);
    },

    hasLink: function hasLink(name) {
      return !!this.linkFor(name);
    },

    headers: null,
    request: function request(opt) {
      if (!opt.headers) {
        opt.headers = {};
      }

      (0, _applyHeaders.copyHeaders)(this.constructor.headers, opt.headers);
      (0, _applyHeaders.copyHeaders)(Ember.get(this, 'headers'), opt.headers);

      return Ember.get(this, 'store').request(opt);
    },

    followPagination: function followPagination(which, opt) {
      var url = this.pageFor(which);

      if (!url) {
        throw new Error('Unknown link');
      }

      opt = opt || {};
      opt.url = url;
      opt.depaginate = false;

      return this.request(opt);
    },

    followLink: function followLink(name, opt) {
      var url = this.linkFor(name);
      opt = opt || {};

      if (!url) {
        throw new Error('Unknown link');
      }

      opt.url = (0, _urlOptions.urlOptions)(url, opt);

      return this.request(opt);
    },

    hasAction: function hasAction(name) {
      var url = Ember.get(this, 'actionLinks.' + name);
      return !!url;
    },

    computedHasAction: function computedHasAction(name) {
      return Ember.computed('actionLinks.' + name, function () {
        return this.hasAction(name);
      });
    },

    doAction: function doAction(name, data, opt) {
      var url = Ember.get(this, 'actionLinks.' + name);
      if (!url) {
        return Ember.RSVP.reject(new Error('Unknown action: ' + name));
      }

      opt = opt || {};
      opt.method = 'POST';
      opt.url = opt.url || url;
      if (data) {
        opt.data = data;
      }

      // Note: The response object may or may not be this same object, depending on what the action returns.
      return this.request(opt);
    },

    save: function save(opt) {
      var self = this;
      var store = Ember.get(this, 'store');
      opt = opt || {};

      var id = Ember.get(this, 'id');
      var type = (0, _normalize.normalizeType)(Ember.get(this, 'type'));
      if (id) {
        // Update
        opt.method = opt.method || 'PUT';
        opt.url = opt.url || this.linkFor('self');
      } else {
        // Create
        if (!type) {
          return Ember.RSVP.reject(new Error('Cannot create record without a type'));
        }

        opt.method = opt.method || 'POST';
        opt.url = opt.url || type;
      }

      if (opt.qp) {
        for (var k in opt.qp) {
          opt.url += (opt.url.indexOf('?') >= 0 ? '&' : '?') + encodeURIComponent(k) + '=' + encodeURIComponent(opt.qp[k]);
        }
      }

      var json = this.serialize();

      delete json['links'];
      delete json['actions'];
      delete json['actionLinks'];

      if (typeof opt.data === 'undefined') {
        opt.data = json;
      }

      return this.request(opt).then(function (newData) {
        if (!newData || !Type.detect(newData)) {
          return newData;
        }

        var newId = newData.get('id');
        var newType = (0, _normalize.normalizeType)(newData.get('type'));
        if (!id && newId && type === newType) {
          Ember.beginPropertyChanges();

          // A new record was created.  Typeify will have put it into the store,
          // but it's not the same instance as this object.  So we need to fix that.
          self.merge(newData);
          var existing = store.getById(type, newId);
          if (existing) {
            store._remove(type, existing);
          }
          store._add(type, self);

          // And also for the base type
          var baseType = self.get('baseType');
          if (baseType) {
            baseType = (0, _normalize.normalizeType)(baseType);
            if (baseType !== type) {
              existing = store.getById(baseType, newId);
              if (existing) {
                store._remove(baseType, existing);
              }
              store._add(baseType, self);
            }
          }

          Ember.endPropertyChanges();
        }

        return self;
      });
    },

    delete: function _delete(opt) {
      var self = this;
      var store = Ember.get(this, 'store');
      var type = Ember.get(this, 'type');

      opt = opt || {};
      opt.method = 'DELETE';
      opt.url = opt.url || this.linkFor('self');

      return this.request(opt).then(function (newData) {
        if (store.get('removeAfterDelete') || opt.forceRemove || opt.responseStatus === 204) {
          store._remove(type, self);
        }
        return newData;
      });
    },

    reload: function reload(opt) {
      if (!this.hasLink('self')) {
        return Ember.RSVP.reject('Resource has no self link');
      }

      var url = this.linkFor('self');

      opt = opt || {};
      if (typeof opt.method === 'undefined') {
        opt.method = 'GET';
      }

      if (typeof opt.url === 'undefined') {
        opt.url = url;
      }

      var self = this;
      return this.request(opt).then(function () /*newData*/{
        return self;
      });
    },

    isInStore: function isInStore() {
      var store = Ember.get(this, 'store');
      return store && Ember.get(this, 'id') && Ember.get(this, 'type') && store.hasRecord(this);
    }
  });

  exports.default = Type;
});