define('shared/components/cluster-driver/driver-rke/component', ['exports', 'shared/mixins/cluster-driver', 'shared/utils/parse-version', 'shared/utils/util', '@rancher/ember-api-store/utils/validate', 'shared/utils/constants', 'yamljs', 'json2yaml', 'shared/components/cluster-driver/driver-rke/template', 'ui/components/input-text-file/component', 'shared/components/cru-cloud-provider/cloud-provider-info', 'moment'], function (exports, _clusterDriver, _parseVersion, _util, _validate, _constants, _yamljs, _json2yaml, _template, _component, _cloudProviderInfo, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var EXCLUDED_KEYS = ['extra_args'];

  function camelToUnderline(str) {
    var split = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    str = str || '';
    if (str.indexOf('-') > -1 || str.endsWith('CloudProvider')) {
      return str;
    } else if (split) {
      return (str || '').dasherize().split('-').join('_');
    } else {
      return (str || '').dasherize();
    }
  }

  var NETWORKCHOICES = [{
    label: 'clusterNew.rke.network.flannel',
    value: 'flannel'
  }, {
    label: 'clusterNew.rke.network.calico',
    value: 'calico'
  }, {
    label: 'clusterNew.rke.network.canal',
    value: 'canal'
  }, {
    label: 'clusterNew.rke.network.weave',
    value: 'weave'
  }];

  var FLANNEL = 'flannel';
  var CANAL = 'canal';
  var WEAVE = 'weave';
  var HOST_GW = 'host-gw';
  var DEFAULT_BACKEND_TYPE = 'vxlan';

  var EXCLUED_CLUSTER_OPTIONS = ['annotations', 'labels'];
  var AUTHCHOICES = [{
    label: 'clusterNew.rke.auth.x509',
    value: 'x509'
  }];

  var INGRESSCHOICES = [{
    label: 'clusterNew.rke.ingress.nginx',
    value: 'nginx'
  }, {
    label: 'clusterNew.rke.ingress.none',
    value: 'none'
  }];

  var AVAILABLE_STRATEGIES = ['local', 's3'];

  exports.default = _component.default.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    networkContent: NETWORKCHOICES,
    authChoices: AUTHCHOICES,
    ingressChoices: INGRESSCHOICES,
    availableStrategies: AVAILABLE_STRATEGIES,

    configField: 'rancherKubernetesEngineConfig',
    registry: 'default',
    accept: '.yml, .yaml',
    backupStrategy: 'local',
    loading: false,
    pasteOrUpload: false,
    model: null,
    initialVersion: null,
    registryUrl: null,
    registryUser: null,
    registryPass: null,
    clusterOptErrors: null,
    nodeNameErrors: null,

    existingNodes: null,
    initialNodeCounts: null,
    step: 1,
    token: null,
    labels: null,
    etcd: false,
    controlplane: false,
    worker: true,
    defaultDockerRootDir: null,

    windowsEnable: false,
    isLinux: true,
    windowsSupport: false,
    weaveCustomPassword: false,
    isNew: Ember.computed.equal('mode', 'new'),
    isEdit: Ember.computed.equal('mode', 'edit'),
    notView: Ember.computed.or('isNew', 'isEdit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),

    // Custom stuff
    isCustom: Ember.computed.equal('nodeWhich', 'custom'),

    init: function init() {
      var _this = this;

      this._super();

      this.initNodeCounts();

      if (Ember.get(this, 'isNew')) {
        this.createRkeConfigWithDefaults();
      } else {
        this.initPrivateRegistries();

        this.initBackupConfigs();
      }

      this.setFlannelLables();

      Ember.run.scheduleOnce('afterRender', function () {
        _this.initRootDockerDirectory();

        if (Ember.get(_this, 'isEdit') && Ember.get(_this, 'config.network.options.flannel_backend_type') === HOST_GW) {
          Ember.set(_this, 'windowsSupport', true);
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isEdit')) {
        this.loadToken();
      }
    },


    actions: {
      setCpFields: function setCpFields(configName, cloudProviderConfig) {
        Ember.set(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.' + configName, cloudProviderConfig);
      },
      setNodePoolErrors: function setNodePoolErrors(errors) {
        Ember.set(this, 'nodePoolErrors', errors);
      },
      cancel: function cancel() {
        Ember.set(this, 'pasteOrUpload', false);
      },
      showPaste: function showPaste() {
        Ember.set(this, 'pasteOrUpload', true);
      },
      addRegistry: function addRegistry(pr) {
        var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

        if ((config.privateRegistries || []).length <= 0) {
          Ember.set(config, 'privateRegistries', [pr]);
        } else {
          config.privateRegistries.pushObject(pr);
        }
      },
      removeRegistry: function removeRegistry(pr) {
        Ember.get(this, 'cluster.rancherKubernetesEngineConfig.privateRegistries').removeObject(pr);
      }
    },

    networkPluginDidChange: Ember.observer('cluster.rancherKubernetesEngineConfig.network.plugin', function () {
      var plugin = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.network.plugin');

      if (plugin) {
        if (plugin !== CANAL) {
          Ember.set(this, 'cluster.enableNetworkPolicy', false);
        }

        if (plugin !== FLANNEL) {
          Ember.set(this, 'windowsSupport', false);
        }

        if (plugin === WEAVE) {
          Ember.set(this, 'cluster.rancherKubernetesEngineConfig.network.weaveNetworkProvider', this.globalStore.createRecord({
            type: 'weaveNetworkProvider',
            password: (0, _util.randomStr)(16, 16, 'password')
          }));
        } else if (plugin !== WEAVE && Ember.get(this, 'cluster.rancherKubernetesEngineConfig.network.weaveNetworkProvider.password')) {
          Ember.set(this, 'cluster.rancherKubernetesEngineConfig.network.weaveNetworkProvider', null);
        }
      }
    }),

    driverDidChange: Ember.observer('nodeWhich', function () {
      this.createRkeConfigWithDefaults();
      this.setFlannelLables();
    }),

    windowsSupportChange: Ember.observer('windowsSupport', function () {
      var windowsSupport = Ember.get(this, 'windowsSupport');
      var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

      if (windowsSupport) {
        Ember.set(config, 'network.options.flannel_backend_type', HOST_GW);
      } else {
        Ember.set(config, 'network.options.flannel_backend_type', DEFAULT_BACKEND_TYPE);
      }
    }),

    isLinuxChanged: Ember.observer('isLinux', function () {
      if (Ember.get(this, 'nodeWhich') !== 'custom') {
        return;
      }

      var isLinux = Ember.get(this, 'isLinux');

      if (!isLinux) {
        Ember.setProperties(this, {
          controlplane: false,
          etcd: false,
          worker: true
        });
      }
    }),

    strategyChanged: Ember.observer('backupStrategy', function () {
      var backupStrategy = this.backupStrategy,
          globalStore = this.globalStore;

      var services = this.cluster.rancherKubernetesEngineConfig.services.clone();

      switch (backupStrategy) {
        case 'local':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: null,
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }
          break;
        case 's3':
          if (services) {
            Ember.setProperties(services.etcd, {
              backupConfig: globalStore.createRecord({
                type: 'backupConfig',
                s3BackupConfig: globalStore.createRecord({ type: 's3BackupConfig' }),
                enabled: Ember.get(services, 'etcd.backupConfig.enabled') || true
              })
            });
          }
          break;
        default:
          break;
      }

      Ember.set(this, 'cluster.rancherKubernetesEngineConfig.services', services);
    }),

    kubeApiPodSecurityPolicy: Ember.computed('config.services.kubeApi.podSecurityPolicy', {
      get: function get() {
        var pspConfig = Ember.get(this, 'config.services.kubeApi');

        if (typeof pspConfig === 'undefined') {
          return false;
        }

        return Ember.get(pspConfig, 'podSecurityPolicy');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.services') === 'undefined') {
          Ember.set(this, 'config.services', Ember.get(this, 'globalStore').createRecord({
            type: 'rkeConfigServices',
            kubeApi: Ember.get(this, 'globalStore').createRecord({
              type: 'kubeAPIService',
              podSecurityPolicy: value
            })
          }));
        } else {
          Ember.set(this, 'config.services', { kubeApi: { podSecurityPolicy: value } });
        }

        return value;
      }
    }),

    monitoringProvider: Ember.computed('config.monitoring', {
      get: function get() {
        var monitoringConfig = Ember.get(this, 'config.monitoring');

        if (typeof monitoringConfig === 'undefined') {
          return null;
        }

        return Ember.get(monitoringConfig, 'provider');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.monitoring') === 'undefined') {
          Ember.set(this, 'config.monitoring', Ember.get(this, 'globalStore').createRecord({
            type: 'monitoringConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.monitoring', { provider: value });
        }

        return value;
      }
    }),

    nginxIngressProvider: Ember.computed('config.ingress', {
      get: function get() {
        var ingressConfig = Ember.get(this, 'config.ingress');

        if (typeof ingressConfig === 'undefined') {
          return null;
        }

        return Ember.get(ingressConfig, 'provider');
      },
      set: function set(key, value) {
        if (typeof Ember.get(this, 'config.ingress') === 'undefined') {
          Ember.set(this, 'config.ingress', Ember.get(this, 'globalStore').createRecord({
            type: 'ingressConfig',
            provider: value
          }));
        } else {
          Ember.set(this, 'config.ingress', { provider: value });
        }

        return value;
      }
    }),

    versionChoices: Ember.computed('settings.' + _constants.default.SETTING.VERSIONS_K8S, function () {
      var out = JSON.parse(Ember.get(this, 'settings.' + _constants.default.SETTING.VERSIONS_K8S) || '{}');

      return Object.keys(out);
    }),

    isNodeNameValid: Ember.computed('nodeName', function () {
      var nodeName = (Ember.get(this, 'nodeName') || '').toLowerCase();

      if (Ember.get(nodeName, 'length') === 0) {
        return true;
      } else {
        var errors = (0, _validate.validateHostname)(nodeName, 'Node Name', Ember.get(this, 'intl'), { restricted: true });

        Ember.set(this, 'nodeNameErrors', errors);

        return errors.length === 0;
      }
    }),

    isAddressValid: Ember.computed('address', function () {
      return Ember.get(this, 'address') === undefined || Ember.get(this, 'address.length') === 0 || (0, _util.validateEndpoint)(Ember.get(this, 'address'));
    }),

    isInternalAddressValid: Ember.computed('internalAddress', function () {
      return Ember.get(this, 'internalAddress') === undefined || Ember.get(this, 'internalAddress.length') === 0 || (0, _util.validateEndpoint)(Ember.get(this, 'internalAddress'));
    }),

    newNodeCount: Ember.computed('initialNodeCounts', 'cluster.id', 'existingNodes.@each.clusterId', function () {
      var clusterId = Ember.get(this, 'cluster.id');
      var orig = Ember.get(this, 'initialNodeCounts')[clusterId] || 0;
      var cur = Ember.get(this, 'existingNodes').filterBy('clusterId', clusterId).length;

      if (cur < orig) {
        orig = cur;
        Ember.set(Ember.get(this, 'initialNodeCounts'), clusterId, cur);
      }

      return cur - orig;
    }),

    command: Ember.computed('labels', 'token.nodeCommand', 'token.windowsNodeCommand', 'etcd', 'controlplane', 'worker', 'address', 'internalAddress', 'nodeName', 'isLinux', function () {
      var out = Ember.get(this, 'token.nodeCommand');

      if (!out) {
        return;
      }

      var address = Ember.get(this, 'address');
      var nodeName = Ember.get(this, 'nodeName');
      var internalAddress = Ember.get(this, 'internalAddress');
      var roles = ['etcd', 'controlplane', 'worker'];
      var labels = Ember.get(this, 'labels') || {};

      if (nodeName) {
        out += ' --node-name ' + nodeName.toLowerCase();
      }

      if (address) {
        out += ' --address ' + address;
      }

      if (internalAddress) {
        out += ' --internal-address ' + internalAddress;
      }

      for (var i = 0, k; i < roles.length; i++) {
        k = roles[i];

        if (Ember.get(this, k)) {
          out += ' --' + k;
        }
      }

      Object.keys(labels).forEach(function (key) {
        out += ' --label ' + key + '=' + labels[key];
      });

      var windowsSelected = !Ember.get(this, 'isLinux');

      if (windowsSelected) {
        out = Ember.get(this, 'token.windowsNodeCommand') || '';
        out = out.replace('--isolation hyperv ', '');
        var addressCmd = '';

        if (address) {
          addressCmd += ' -address ' + address;
        }

        if (internalAddress) {
          addressCmd += ' -internalAddress ' + internalAddress;
        }

        if (addressCmd) {
          out = out.replace('; if($?)', addressCmd + '; if($?)');
        }
      }

      return out;
    }),

    yamlValue: Ember.computed('pasteOrUpload', {
      get: function get() {
        var intl = Ember.get(this, 'intl');

        var config = this.getSupportedFields(Ember.get(this, 'cluster.rancherKubernetesEngineConfig'), 'rancherKubernetesEngineConfig');

        if (!config) {
          return '';
        }

        var cluster = this.getSupportedFields(Ember.get(this, 'cluster'), 'cluster', EXCLUED_CLUSTER_OPTIONS);
        var cpConfig = JSON.parse(JSON.stringify(Ember.get(cluster, 'rancher_kubernetes_engine_config.cloud_provider')));

        delete cluster.rancher_kubernetes_engine_config;

        Object.assign(config, cluster);

        config = (0, _util.removeEmpty)(config, EXCLUDED_KEYS);

        while (JSON.stringify(config) !== JSON.stringify((0, _util.removeEmpty)(config, EXCLUDED_KEYS))) {
          config = (0, _util.removeEmpty)(config, EXCLUDED_KEYS);
        }

        if (config.cloud_provider && Ember.get(this, 'nodeWhich') === 'azure') {
          // this is a quick and dirty fix for azure only because it is currently the only cp that works
          // this whole process will be recieving updates shortly so this is a temp fix
          // client_id, secret, & subscription_id are all required so ensure they are there on NEW when a user has entered them but switched to yaml or edit
          // removeEmpty works great except for these fields and adding nested paths doesn't work in removeEmpty
          Object.assign(config.cloud_provider.azureCloudProvider, {
            'aad_client_cert_password': cpConfig.azureCloudProvider.aad_client_cert_password || '',
            'aad_client_id': cpConfig.azureCloudProvider.aad_client_id || '',
            'aad_client_secret': cpConfig.azureCloudProvider.aad_client_secret || '',
            'tenant_id': cpConfig.azureCloudProvider.tenant_id || '',
            'subscription_id': cpConfig.azureCloudProvider.subscription_id || ''
          });
        }

        var yaml = _json2yaml.default.stringify(config);
        var lines = yaml.split('\n');

        lines.shift();

        var out = '';

        lines.forEach(function (line) {
          if (line.trim()) {
            var key = 'rkeConfigComment.' + line.split(':')[0].trim();

            if (intl.exists(key)) {
              var commentLines = intl.t(key).split('\n');

              commentLines.forEach(function (commentLine) {
                out += '# ' + commentLine.slice(1, commentLine.length - 1) + '\n';
              });
            }

            out += line.slice(2) + '\n';
          }
        });

        return out;
      },
      set: function set(key, value) {
        var _this2 = this;

        var configs = void 0;

        try {
          configs = _yamljs.default.parse(value);
        } catch (err) {
          Ember.set(this, 'clusterOptErrors', ['Cluster Options Parse Error: ' + err.snippet + ' - ' + err.message]);

          return value;
        }

        Ember.set(this, 'clusterOptErrors', []);

        var validFields = this.getResourceFields('rancherKubernetesEngineConfig');
        var clusterFields = this.getResourceFields('cluster');
        var clusterKeys = [];

        Object.keys(clusterFields).filter(function (key) {
          return clusterFields[key].update && key !== 'rancher_kubernetes_engine_config';
        }).forEach(function (key) {
          clusterKeys.push(camelToUnderline(key));
        });

        Object.keys(configs || {}).forEach(function (key) {
          if (validFields[(0, _util.underlineToCamel)(key)] || clusterFields[(0, _util.underlineToCamel)(key)]) {
            if (configs[key] === null) {
              return;
            }
            var obj = (0, _util.keysToCamel)(configs[key]);

            if (clusterKeys.includes(key)) {
              Ember.set(_this2, 'cluster.' + (0, _util.underlineToCamel)(key), obj);

              return;
            }

            if (key === 'services' && obj['kube-api']) {
              Ember.set(obj, 'kubeApi', obj['kube-api']);
              delete obj['kube-api'];
            }

            if (key === 'services' && obj['kube-controller']) {
              Ember.set(obj, 'kubeController', obj['kube-controller']);
              delete obj['kube-controller'];
            }

            Ember.set(_this2, 'cluster.rancherKubernetesEngineConfig.' + (0, _util.underlineToCamel)(key), obj);
          }
        });

        return value;
      }
    }),

    validate: function validate() {
      var _this3 = this;

      this._super.apply(this, arguments);

      var errors = [];
      var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

      if (!Ember.get(this, 'isCustom')) {
        errors.pushObjects(Ember.get(this, 'nodePoolErrors'));
      }

      if (Ember.get(config, 'services.kubeApi.podSecurityPolicy') && !Ember.get(this, 'cluster.defaultPodSecurityPolicyTemplateId')) {
        errors.push(Ember.get(this, 'intl').t('clusterNew.psp.required'));
      }

      if (Ember.get(this, 'cluster.rancherKubernetesEngineConfig.services.etcd.snapshot')) {
        errors = this.validateEtcdService(errors);
      }

      if (Ember.get(this, 'cluster.localClusterAuthEndpoint.enabled')) {
        errors = this.validateAuthorizedClusterEndpoint(errors);
      }

      var clusterOptErrors = Ember.get(this, 'clusterOptErrors') || [];

      if (Ember.get(config, 'cloudProvider.name') === 'azure') {
        var intl = Ember.get(this, 'intl');

        Object.keys(_cloudProviderInfo.azure).forEach(function (key) {
          if (Ember.get(_cloudProviderInfo.azure, key + '.required') && !Ember.get(config, 'cloudProvider.azureCloudProvider.' + key)) {
            if (_this3.isNew || _this3.isEdit && key !== 'aadClientSecret') {
              errors.push(intl.t('validation.required', { key: key }));
            }
          }
        });
      }

      Ember.set(this, 'errors', errors);

      return errors.length === 0 && clusterOptErrors.length === 0;
    },
    validateAuthorizedClusterEndpoint: function validateAuthorizedClusterEndpoint(errors) {
      var _EmberGet = Ember.get(this, 'cluster'),
          localClusterAuthEndpoint = _EmberGet.localClusterAuthEndpoint;

      var caCerts = localClusterAuthEndpoint.caCerts,
          fqdn = localClusterAuthEndpoint.fqdn;


      if (caCerts) {
        if (!(0, _util.validateCertWeakly)(caCerts)) {
          errors.push(this.intl.t('newCertificate.errors.cert.invalidFormat'));
        }
      }

      if (fqdn) {
        errors = (0, _validate.validateHostname)(fqdn, 'FQDN', Ember.get(this, 'intl'), { restricted: true }, errors);
      }

      return errors;
    },
    validateEtcdService: function validateEtcdService(errors) {
      var etcdService = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.services.etcd') || {};
      var creation = etcdService.creation,
          retention = etcdService.retention;

      var that = this;

      function checkDurationIsValid(duration, type) {
        // exact matching on these inputs
        // patternList = 12h12m12s || 12h12m || 12m12s  || 12h12s || 12h || 12m || 12s
        var patternList = [/^(\d+)(h)(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(m)$/, /^(\d+)(m)(\d+)(s)$/, /^(\d+)(h)(\d+)(s)$/, /^(\d+)(h)$/, /^(\d+)(m)$/, /^(\d+)(s)$/];
        var match = patternList.filter(function (p) {
          return p.test(duration);
        });

        if (match.length === 0) {
          durationError(duration, type);
        }

        return;
      }

      function durationError(entry, type) {
        return errors.push(Ember.get(that, 'intl').t('clusterNew.rke.etcd.error', {
          type: type,
          entry: entry
        }));
      }

      checkDurationIsValid(creation, 'Creation');
      checkDurationIsValid(retention, 'Reteintion');

      return errors;
    },
    doneSaving: function doneSaving() {
      if (Ember.get(this, 'isCustom')) {
        if (Ember.get(this, 'isEdit')) {
          this.sendAction('close');
        } else {
          this.loadToken();
        }
      } else {
        this.sendAction('close');
      }

      return Ember.RSVP.resolve();
    },
    loadToken: function loadToken() {
      var _this4 = this;

      var cluster = Ember.get(this, 'cluster');

      Ember.setProperties(this, {
        step: 2,
        loading: true
      });

      return cluster.getOrCreateToken().then(function (token) {
        if (_this4.isDestroyed || _this4.isDestroying) {
          return;
        }

        Ember.setProperties(_this4, {
          token: token,
          loading: false
        });
      }).catch(function (err) {
        if (_this4.isDestroyed || _this4.isDestroying) {
          return;
        }

        Ember.get(_this4, 'growl').fromError('Error getting command', err);

        Ember.set(_this4, 'loading', false);
      });
    },
    findExcludedKeys: function findExcludedKeys(resourceFields) {
      var _this5 = this;

      Object.keys(resourceFields).forEach(function (key) {
        var type = resourceFields[key].type;

        if (type.startsWith('map[')) {
          var t = type.slice(4, type.length - 1);
          var s = Ember.get(_this5, 'globalStore').getById('schema', t.toLowerCase());

          if (s) {
            var underlineKey = camelToUnderline(key);

            if (EXCLUDED_KEYS.indexOf(underlineKey) === -1) {
              EXCLUDED_KEYS.push(underlineKey);
            }
          }
        }
      });
    },
    getResourceFields: function getResourceFields(type) {
      var schema = Ember.get(this, 'globalStore').getById('schema', type.toLowerCase());
      var resourceFields = null;

      if (schema) {
        resourceFields = Ember.get(schema, 'resourceFields');
        this.findExcludedKeys(resourceFields);
      }

      return resourceFields;
    },
    getFieldValue: function getFieldValue(field, type) {
      var _this6 = this;

      var resourceFields = void 0;
      var out = {};

      if (type.startsWith('map[')) {
        type = type.slice(4, type.length - 1);

        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          if (field) {
            Object.keys(field).forEach(function (key) {
              out[camelToUnderline(key)] = _this6.getFieldValue(field[key], type);
            });

            return out;
          } else {
            return null;
          }
        } else {
          if (field) {
            Object.keys(field).forEach(function (key) {
              out[camelToUnderline(key)] = field[key];
            });

            return out;
          } else {
            return null;
          }
        }
      } else if (type.startsWith('array[')) {
        type = type.slice(6, type.length - 1);

        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          return field ? field.map(function (item) {
            return _this6.getFieldValue(item, type);
          }) : null;
        } else {
          return field ? field.map(function (item) {
            return item;
          }) : null;
        }
      } else {
        resourceFields = this.getResourceFields(type);

        if (resourceFields) {
          Object.keys(resourceFields).forEach(function (key) {
            if (!Ember.isEmpty(field) && ((typeof field === 'undefined' ? 'undefined' : _typeof(field)) !== 'object' || Object.keys(field).length)) {
              out[camelToUnderline(key, type !== 'rkeConfigServices')] = _this6.getFieldValue(field[key], resourceFields[key].type);
            }
          });

          return out;
        } else {
          return field;
        }
      }
    },
    getSupportedFields: function getSupportedFields(source, tragetField) {
      var _this7 = this;

      var excludeFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      var out = {};
      var resourceFields = this.getResourceFields(tragetField);

      Object.keys(resourceFields).filter(function (key) {
        return resourceFields[key].update && excludeFields.indexOf(key) === -1;
      }).forEach(function (key) {
        var field = Ember.get(source, key);
        var type = resourceFields[key].type;
        var value = _this7.getFieldValue(field, type);

        out[camelToUnderline(key)] = value;
      });

      return out;
    },
    initNodeCounts: function initNodeCounts() {
      var _this8 = this;

      var counts = {};

      Ember.set(this, 'existingNodes', this.globalStore.all('node'));

      this.globalStore.findAll('node').then(function (all) {
        all.forEach(function (node) {
          var id = Ember.get(node, 'clusterId');

          counts[id] = (counts[id] || 0) + 1;
        });

        _this8.notifyPropertyChange('initialNodeCounts');
      });

      Ember.setProperties(this, {
        initialNodeCounts: counts,
        initialVersion: Ember.get(this, 'cluster.rancherKubernetesEngineConfig.kubernetesVersion')
      });
    },
    initPrivateRegistries: function initPrivateRegistries() {
      var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

      if (Ember.get(config, 'privateRegistries.length') > 0) {
        var registry = Ember.get(config, 'privateRegistries.firstObject');

        Ember.setProperties(this, {
          registry: 'custom',
          registryUrl: Ember.get(registry, 'url'),
          registryUser: Ember.get(registry, 'user'),
          registryPass: Ember.get(registry, 'password')
        });
      }
    },
    createRkeConfigWithDefaults: function createRkeConfigWithDefaults() {
      var _this9 = this;

      var globalStore = this.globalStore,
          versionChoices = this.versionChoices;

      var defaultVersion = Ember.get(this, 'settings.' + _constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE);
      var satisfying = (0, _parseVersion.maxSatisfying)(versionChoices, defaultVersion);
      var out = {};

      var rkeConfig = globalStore.createRecord({
        type: 'rancherKubernetesEngineConfig',
        ignoreDockerVersion: true,
        kubernetesVersion: satisfying,
        authentication: globalStore.createRecord({
          type: 'authnConfig',
          strategy: 'x509'
        }),
        network: globalStore.createRecord({
          type: 'networkConfig',
          plugin: 'canal',
          options: { flannel_backend_type: DEFAULT_BACKEND_TYPE }
        }),
        ingress: globalStore.createRecord({
          type: 'ingressConfig',
          provider: 'nginx'
        }),
        monitoring: globalStore.createRecord({
          type: 'monitoringConfig',
          provider: 'metrics-server'
        }),
        services: globalStore.createRecord({
          type: 'rkeConfigServices',
          kubeApi: globalStore.createRecord({
            type: 'kubeAPIService',
            podSecurityPolicy: false,
            serviceNodePortRange: '30000-32767'
          }),
          etcd: globalStore.createRecord({
            type: 'etcdService',
            snapshot: false,
            backupConfig: globalStore.createRecord({
              type: 'backupConfig',
              enabled: true
            }),
            extraArgs: {
              'heartbeat-interval': 500,
              'election-timeout': 5000
            }
          })
        })
      });

      Ember.setProperties(out, {
        rancherKubernetesEngineConfig: rkeConfig,
        enableNetworkPolicy: false
      });

      if (this.isNew) {
        Ember.set(out, 'localClusterAuthEndpoint', globalStore.createRecord({
          type: 'localClusterAuthEndpoint',
          enabled: true
        }));
      }

      Ember.run.scheduleOnce('afterRender', function () {
        return Ember.setProperties(_this9.cluster, out);
      });
    },
    setFlannelLables: function setFlannelLables() {
      var flannel = this.networkContent.findBy('value', 'flannel');

      if (Ember.get(this, 'isCustom')) {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannelCustom');
      } else {
        Ember.set(flannel, 'label', 'clusterNew.rke.network.flannel');
      }
    },
    migrateLegacyEtcdSnapshotSettings: function migrateLegacyEtcdSnapshotSettings() {
      var cluster = this.cluster;
      var _cluster$rancherKuber = cluster.rancherKubernetesEngineConfig.services.etcd,
          retention = _cluster$rancherKuber.retention,
          creation = _cluster$rancherKuber.creation,
          backupConfig = _cluster$rancherKuber.backupConfig;


      var creationMatch = creation.match(/^((\d+)h)?((\d+)m)?((\d+)s)?$/);
      var momentReady = [creationMatch[2], creationMatch[4], creationMatch[6]];

      if (momentReady[1] && momentReady[1] < 30) {
        // round min down since new settting is in intval hours
        momentReady[1] = 0;
      } else if (momentReady[1] && momentReady[1] >= 30) {
        // round up to the nearest hour
        momentReady[0] = parseInt(momentReady[0], 10) + 1;
        momentReady[1] = 0;
      }

      if ((!momentReady[0] || momentReady[0] === 0) && momentReady[1] === 0) {
        // if both hours and min are zero set hour to 1;
        momentReady[0] = 1;
      }

      var toMoment = {
        hours: momentReady[0] ? momentReady[0] : 0,
        minutes: momentReady[1] ? momentReady[1] : 0,
        seconds: momentReady[2] ? momentReady[2] : 0
      };

      var parsedDurationAsHours = _moment.default.duration(toMoment).hours();

      Ember.setProperties(this, {
        legacyRetention: retention,
        hasLegacySnapshotSettings: true
      });

      if (backupConfig) {
        Ember.setProperties(cluster.rancherKubernetesEngineConfig.services.etcd, {
          'backupConfig.enabled': true,
          'backupConfig.intervalHours': parsedDurationAsHours,
          snapshot: false
        });
      } else {
        backupConfig = this.globalStore.createRecord({
          type: 'backupConfig',
          intervalHours: parsedDurationAsHours,
          enabled: true
        });

        Ember.setProperties(cluster.rancherKubernetesEngineConfig.services.etcd, {
          backupConfig: backupConfig,
          snapshot: false
        });
      }
    },
    initRootDockerDirectory: function initRootDockerDirectory() {
      Ember.set(this, 'defaultDockerRootDir', Ember.get(this.globalStore.getById('schema', 'cluster').getCreateDefaults(), 'dockerRootDir'));
    },
    initBackupConfigs: function initBackupConfigs() {
      var etcd = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.services.etcd');

      if (etcd) {
        if (etcd.snapshot) {
          this.migrateLegacyEtcdSnapshotSettings();
        } else if (etcd.backupConfig && etcd.backupConfig.s3BackupConfig) {
          Ember.set(this, 'backupStrategy', 's3');
        } else if (!etcd.snapshot && !etcd.backupConfig) {
          var backupConfig = Ember.get(this, 'globalStore').createRecord({
            enabled: false,
            type: 'backupConfig'
          });

          Ember.set(etcd, 'backupConfig', backupConfig);
        }
      }
    }
  });
});