define('shared/helpers/uc-first', ['exports', 'ui/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ucFirst = ucFirst;
  function ucFirst(params) {
    return _util.default.ucFirst(params[0]);
  }

  exports.default = Ember.Helper.helper(ucFirst);
});