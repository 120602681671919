enifed('@ember/debug/lib/warn', ['exports', 'ember-environment', '@ember/debug/index', '@ember/debug/lib/deprecate', '@ember/debug/lib/handlers'], function (exports) {
    'use strict';

    exports.missingOptionsDeprecation = exports.missingOptionsIdDeprecation = exports.registerHandler = undefined;

    /**
    @module @ember/debug
    */
    exports.default = function () {};
    exports.registerHandler = function () {};
    exports.missingOptionsIdDeprecation = void 0;
    exports.missingOptionsDeprecation = void 0;
});