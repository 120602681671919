define('shared/components/schema/input-dns-record/component', ['exports', 'shared/components/schema/input-dns-record/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    allDnsRecords: Ember.inject.service(),

    layout: _template.default,
    selected: null, // Selected dnsRecord ID
    selectClass: 'form-control',
    exclude: null, // ID or array of IDs to exclude from list
    selectedNamespace: null,
    culsterIpNotNull: false,
    disabled: false,

    value: null, // namespace/dnsRecordName string output

    // For other abuses
    obj: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'obj')) {
        Ember.set(this, 'selected', Ember.get(this, 'obj.id'));
      }
    },
    didRender: function didRender() {
      this.selectedChanged();
    },


    selectedChanged: Ember.observer('selected', function () {
      var id = Ember.get(this, 'selected');
      var str = null;
      var dnsRecord = null;

      if (id) {
        dnsRecord = Ember.get(this, 'allDnsRecords').byId(id);
        if (dnsRecord) {
          str = Ember.get(dnsRecord, 'namespace') + '/' + Ember.get(dnsRecord, 'name');
        }
      }

      Ember.set(this, 'value', str);
      Ember.set(this, 'obj', dnsRecord);
    }),
    grouped: Ember.computed('allDnsRecords.list.[]', 'selectedNamespace', function () {
      var _this = this;

      var list = Ember.get(this, 'allDnsRecords.list');

      var exclude = Ember.get(this, 'exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        list = list.filter(function (x) {
          return !exclude.includes(x.id);
        });
      }

      if (Ember.get(this, 'culsterIpNotNull')) {
        list = list.filter(function (x) {
          return x.clusterIp !== null;
        });
      }

      if (this.get('selectedNamespace')) {
        list = list.filter(function (x) {
          return x.namespace === _this.get('selectedNamespace.id');
        });
      }

      var out = Ember.get(this, 'allDnsRecords').group(list);
      var selected = Ember.get(this, 'allDnsRecords').byId(Ember.get(this, 'selected'));

      if (selected && !list.findBy('id', Ember.get(selected, 'id'))) {
        out['(Selected)'] = [{
          id: Ember.get(selected, 'id'),
          name: Ember.get(selected, 'displayName'),
          kind: Ember.get(selected, 'type'),
          obj: selected
        }];
      }

      return out;
    }),

    readableService: Ember.computed('selected', function () {
      var selected = this.selected,
          selectedNamespace = this.selectedNamespace;

      var service = Ember.get(selectedNamespace, 'services').findBy('id', selected);

      var out = 'N/A';

      if (service) {
        out = Ember.get(service, 'displayName');
      }

      return out;
    })
  });
});