define('global-admin/security/authentication/localauth/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),

    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,

    adminName: '',
    adminPublicValue: '',
    adminSecretValue: '',
    adminSecretValue2: '',

    actions: {
      test: function test() {
        var _this = this;

        if (!Ember.get(this, 'adminPublicValue')) {
          return void this.send('showError', 'Login username is required');
        }

        if (!Ember.get(this, 'adminSecretValue')) {
          return void this.send('showError', 'Password is required');
        }

        if (Ember.get(this, 'adminSecretValue') !== Ember.get(this, 'adminSecretValue2')) {
          return void this.send('showError', 'Passwords do not match');
        }

        this.send('clearError');
        Ember.set(this, 'testing', true);

        var model = Ember.get(this, 'model');
        var am = Ember.get(model, 'accessMode') || 'unrestricted';

        Ember.setProperties(model, {
          name: Ember.get(this, 'adminName'),
          accessMode: am,
          username: Ember.get(this, 'adminPublicValue'),
          password: Ember.get(this, 'adminSecretValue'),
          enabled: false
        });

        model.save().then(function () {
          // Wait a bit for the new config to take effect...
          setTimeout(function () {
            _this.send('authenticate');
          }, 1000);
        }).catch(function (err) {
          _this.send('gotError', err);
        });
      },
      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');
        var code = Ember.get(this, 'adminPublicValue') + ':' + Ember.get(this, 'adminSecretValue');

        Ember.get(this, 'access').login(code).then(function (res) {
          _this2.send('authenticationSucceeded', res.body);
        }).catch(function (err) {
          _this2.send('gotError', err);
        });
      },
      authenticationSucceeded: function authenticationSucceeded() /* auth*/{
        var _this3 = this;

        this.send('clearError');

        // Set this to true so the token will be sent with the request
        Ember.set(this, 'access.enabled', true);

        var model = Ember.get(this, 'model');

        Ember.setProperties(model, { enabled: true });

        model.save().then(function () {
          _this3.send('waitAndRefresh');
        }).catch(function (err) {
          Ember.set(_this3, 'access.enabled', false);
          _this3.send('gotError', err);
        });
      },
      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line
        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },
      promptDisable: function promptDisable() {
        Ember.set(this, 'confirmDisable', true);
        Ember.run.later(this, function () {
          Ember.set(this, 'confirmDisable', false);
        }, 10000);
      },
      gotError: function gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? '(' + err.detail + ')' : ''));
        } else {
          this.send('showError', 'Error (' + err.status + ' - ' + err.code + ')');
        }

        Ember.set(this, 'testing', false);
        Ember.set(this, 'saving', false);
      },
      showError: function showError(msg) {
        Ember.set(this, 'errors', [msg]);
        window.scrollY = 0;
      },
      clearError: function clearError() {
        Ember.set(this, 'errors', null);
      },
      disable: function disable() {
        var _this4 = this;

        this.send('clearError');

        var model = Ember.get(this, 'model');

        Ember.setProperties(model, {
          enabled: false,
          username: '',
          password: ''
        });

        model.save().then(function () {
          _this4.send('waitAndRefresh');
        }).catch(function (err) {
          _this4.send('gotError', err);
        }).finally(function () {
          Ember.set(_this4, 'confirmDisable', false);
        });
      }
    },
    createDisabled: Ember.computed('adminPublicValue', 'adminSecretValue', 'adminSecretValue2', function () {
      var ok = Ember.get(this, 'adminPublicValue.length') && Ember.get(this, 'adminSecretValue.length') && Ember.get(this, 'adminSecretValue') === Ember.get(this, 'adminSecretValue2');

      return !ok;
    }),

    validateDescription: Ember.computed(function () {
      return Ember.get(this, 'settings').get(_constants.default.SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    })

  });
});