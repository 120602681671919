enifed('ember-routing/lib/ext/controller', ['exports', 'ember-metal', '@ember/controller/lib/controller_mixin', 'ember-routing/lib/utils'], function (exports, _emberMetal, _controller_mixin, _utils) {
  'use strict';

  /**
  @module ember
  */

  _controller_mixin.default.reopen({
    concatenatedProperties: ['queryParams'],

    /**
      Defines which query parameters the controller accepts.
      If you give the names `['category','page']` it will bind
      the values of these query parameters to the variables
      `this.category` and `this.page`.
      By default, Ember coerces query parameter values using `toggleProperty`.
      This behavior may lead to unexpected results.
      To explicitly configure a query parameter property so it coerces as expected, you must define a type property:
      ```javascript
        queryParams: [{
          category: {
            type: 'boolean'
          }
        }]
      ```
      @for Ember.ControllerMixin
      @property queryParams
      @public
    */
    queryParams: null,

    /**
     This property is updated to various different callback functions depending on
     the current "state" of the backing route. It is used by
     `Controller.prototype._qpChanged`.
      The methods backing each state can be found in the `Route.prototype._qp` computed
     property return value (the `.states` property). The current values are listed here for
     the sanity of future travelers:
      * `inactive` - This state is used when this controller instance is not part of the active
       route hierarchy. Set in `Route.prototype._reset` (a `router.js` microlib hook) and
       `Route.prototype.actions.finalizeQueryParamChange`.
     * `active` - This state is used when this controller instance is part of the active
       route hierarchy. Set in `Route.prototype.actions.finalizeQueryParamChange`.
     * `allowOverrides` - This state is used in `Route.prototype.setup` (`route.js` microlib hook).
       @method _qpDelegate
      @private
    */
    _qpDelegate: null, _qpChanged: function (controller, _prop) {
      var prop = _prop.substr(0, _prop.length - 3);

      var delegate = controller._qpDelegate;
      var value = (0, _emberMetal.get)(controller, prop);
      delegate(prop, value);
    },
    transitionToRoute: function () {
      // target may be either another controller or a router
      var target = (0, _emberMetal.get)(this, 'target'),
          _len,
          args,
          _key;
      var method = target.transitionToRoute || target.transitionTo;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return method.apply(target, (0, _utils.prefixRouteNameArg)(this, args));
    },
    replaceRoute: function () {
      // target may be either another controller or a router
      var target = (0, _emberMetal.get)(this, 'target'),
          _len2,
          args,
          _key2;
      var method = target.replaceRoute || target.replaceWith;

      for (_len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return method.apply(target, (0, _utils.prefixRouteNameArg)(this, args));
    }
  });

  exports.default = _controller_mixin.default;
});