define('shared/components/outside-click/component', ['exports', 'shared/components/outside-click/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['outside-click'],

    onOutsideClick: null,

    layout: _template.default,
    isOutside: false,

    init: function init() {
      this._super.apply(this, arguments);
      this._boundHandleDown = this.handleDown.bind(this);
      this._boundHandleUp = this.handleUp.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      document.addEventListener('mousedown', this._boundHandleDown, true);
      document.addEventListener('mouseup', this._boundHandleUp, true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('mousedown', this._boundHandleDown, true);
      document.removeEventListener('mouseup', this._boundHandleUp, true);
    },
    handleDown: function handleDown(e) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!this.element.contains(e.target)) {
        this.set('isOutside', true);
      }
    },
    handleUp: function handleUp(e) {
      if (this.get('isOutside')) {
        this.sendAction('onOutsideClick', e);
      }
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.set('isOutside', false);
    }
  });
});