define('shared/components/form-ssl-termination/component', ['exports', 'shared/components/form-ssl-termination/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),

    layout: _template.default,
    editing: null,
    ingress: null,
    namespacedCertificates: null,
    namespace: null,
    certificates: null,
    certs: null,
    errors: null,
    statusClass: null,
    status: null,

    init: function init() {
      this._super.apply(this, arguments);
      var certs = Ember.get(this, 'ingress.tls') || [];

      Ember.set(this, 'certs', certs);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },


    actions: {
      removeCert: function removeCert(cert) {
        Ember.get(this, 'certs').removeObject(cert);
      },
      addCert: function addCert() {
        Ember.get(this, 'certs').pushObject({
          certificateId: '',
          hosts: []
        });
      }
    },

    inputDidChange: Ember.observer('certs.@each.{certificateId,hosts}', function () {
      var _this = this;

      var errors = [];
      var intl = Ember.get(this, 'intl');
      var certs = Ember.get(this, 'certs').map(function (cert) {
        var id = Ember.get(cert, 'certificateId');

        if (!id && Ember.get(cert, 'mode') === 'custom') {
          errors.push(intl.t('formSslTermination.custom.required'));
        }

        if (!id && Ember.get(cert, 'mode') !== 'custom' && Ember.get(cert, 'hosts.length') === 0) {
          errors.push(intl.t('formSslTermination.default.noHosts'));
        }

        if (_this.isWildcardCert(id) && Ember.get(cert, 'hosts.length') === 0) {
          var c = Ember.get(_this, 'allCertificates').findBy('id', id);

          errors.push(intl.t('formSslTermination.wildcardCert', { name: Ember.get(c, 'name') }));
        }

        return {
          certificateId: cert.certificateId || null,
          hosts: cert.hosts
        };
      });

      Ember.setProperties(this, {
        errors: errors.uniq(),
        'ingress.tls': certs
      });
    }),

    allCertificates: Ember.computed('namespacedCertificates.[]', 'certificates.[]', 'namespace', function () {
      var _this2 = this;

      var out = [];
      var namespacedCertificates = (Ember.get(this, 'namespacedCertificates') || []).filter(function (c) {
        var selectedNamespace = Ember.get(_this2, 'namespace.id');

        return selectedNamespace === c.namespaceId;
      });

      out.pushObjects(namespacedCertificates.toArray());
      out.pushObjects((Ember.get(this, 'certificates') || []).toArray());

      return out;
    }),

    isWildcardCert: function isWildcardCert(certId) {
      var cert = Ember.get(this, 'allCertificates').findBy('id', certId);

      if (cert) {
        return (Ember.get(cert, 'cn') || '').startsWith('*');
      }

      return false;
    }
  });
});