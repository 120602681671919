define('shared/components/form-global-resource-roles/component', ['exports', 'shared/components/form-global-resource-roles/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['row'],

    multiClusterApp: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var roles = this.multiClusterApp.roles;


      if (!roles || roles.length === 0) {
        Ember.run.next(function () {
          _this.addRole('project-member', '');
        });
      }
    },


    role: Ember.computed('multiClusterApp.roles.[]', {
      get: function get() {
        var _multiClusterApp$role = this.multiClusterApp.roles,
            roles = _multiClusterApp$role === undefined ? [] : _multiClusterApp$role;


        return roles.find(function (role) {
          return role === 'project-member' || role === 'cluster-owner';
        });
      },
      set: function set(key, value) {
        this.addRole(value, this.role);

        return value;
      }
    }),

    otherRoles: Ember.computed('multiClusterApp.roles.[]', function () {
      var _multiClusterApp$role2 = this.multiClusterApp.roles,
          roles = _multiClusterApp$role2 === undefined ? [] : _multiClusterApp$role2;


      return roles.filter(function (role) {
        return role !== 'cluster-owner' && role !== 'project-member';
      });
    })
  });
});