define('shared/mixins/display-image', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    displayImages: Ember.computed('containers.@each.image', function () {
      return (Ember.get(this, 'containers') || []).map(function (container) {
        return Ember.get(container, 'image');
      });
    }),

    displayImage: Ember.computed('containers.@each.image', function () {
      var containers = Ember.get(this, 'containers') || [];
      var count = Ember.get(containers, 'length');

      if (count > 1) {
        return Ember.get(this, 'intl').t('podPage.displayImage', {
          image: Ember.get(containers, 'firstObject.image'),
          sidecar: count - 1
        });
      } else if (count) {
        return Ember.get(containers, 'firstObject.image');
      } else {
        return Ember.get(this, 'intl').t('generic.unknown');
      }
    })
  });
});