define('shared/helpers/date-from-now', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateFromNow = dateFromNow;
  function dateFromNow(params) {
    var d = (0, _moment.default)(params[0]);
    var str = '<span title="' + d.format('llll') + '">' + d.fromNow() + '</span>';

    return Ember.String.htmlSafe(str);
  }

  exports.default = Ember.Helper.helper(dateFromNow);
});