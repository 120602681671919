define('ember-engines/components/link-to-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);

      (false && !(owner.mountPoint !== undefined) && Ember.assert('You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.', owner.mountPoint !== undefined));


      if (owner.mountPoint) {
        // Prepend engine mount point to targetRouteName
        this._prefixProperty(owner.mountPoint, 'targetRouteName');

        // Prepend engine mount point to current-when if set
        if (Ember.get(this, 'current-when') !== null) {
          this._prefixProperty(owner.mountPoint, 'current-when');
        }
      }
    },
    _prefixProperty: function _prefixProperty(prefix, prop) {
      var _this = this;

      var propValue = Ember.get(this, prop);

      // Sometimes `targetRouteName` will be a class
      if (Ember.typeOf(propValue) !== 'string') {
        return;
      }

      var namespacedPropValue = void 0;
      if (prop === 'current-when') {
        // `current-when` is a space-separated list of routes
        namespacedPropValue = propValue.split(' ');
        namespacedPropValue = namespacedPropValue.map(function (propValue) {
          return _this._namespacePropertyValue(prefix, propValue);
        });
        namespacedPropValue = namespacedPropValue.join(' ');
      } else {
        namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
      }

      Ember.set(this, prop, namespacedPropValue);
    },
    _namespacePropertyValue: function _namespacePropertyValue(prefix, propValue) {
      if (propValue === 'application') {
        return prefix;
      } else {
        return prefix + '.' + propValue;
      }
    }
  });
});