define('shared/mixins/promise-to-cb', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    toCb: function toCb(name_or_fn) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return function (results, cb) {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        var promise = void 0;

        if (typeof name_or_fn === 'string') {
          promise = _this[name_or_fn].apply(_this, _toConsumableArray(args).concat([results]));
        } else {
          promise = name_or_fn.apply(undefined, _toConsumableArray(args).concat([results]));
        }

        promise.then(function (res) {
          cb(null, res);
        }).catch(function (err) {
          cb(err, null);
        });
      };
    }
  });
});