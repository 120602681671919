define('shared/helpers/str-replace', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.strReplace = strReplace;
  function strReplace(params, options) {
    return ('' + params[0]).replace(options.match, options.with);
  }

  exports.default = Ember.Helper.helper(strReplace);
});