define('shared/components/empty-table/component', ['exports', 'shared/components/empty-table/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    app: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['row border-dash'],
    showNew: true,
    disabled: false,

    ctx: 'projectId' // or clusterId
  });
});