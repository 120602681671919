define('shared/components/cluster-driver/driver-tencenttke/component', ['exports', 'shared/mixins/cluster-driver', 'shared/components/cluster-driver/driver-tencenttke/template'], function (exports, _clusterDriver, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ENDPOINT = 'tencentcloudapi.com/';
  var CCS_ENDPOINT = 'api.qcloud.com/v2/index.php';

  var OS = [{
    label: 'Ubuntu Server 16.04.1 LTS 64bit',
    value: 'ubuntu16.04.1 LTSx86_64'
  }, {
    label: 'CentOS 7.2 64bit',
    value: 'centos7.2x86_64'
  }];

  var REGIONS = [{
    label: 'ap-guangzhou',
    value: 'ap-guangzhou'
  }, {
    label: 'ap-shanghai',
    value: 'ap-shanghai'
  }, {
    label: 'ap-beijing',
    value: 'ap-beijing'
  }, {
    label: 'ap-hongkong',
    value: 'ap-hongkong'
  }, {
    label: 'ap-singapore',
    value: 'ap-singapore'
  }, {
    label: 'ap-chengdu',
    value: 'ap-chengdu'
  }, {
    label: 'ap-mumbai',
    value: 'ap-mumbai'
  }, {
    label: 'ap-tokyo',
    value: 'ap-tokyo'
  }, {
    label: 'ap-bangkok',
    value: 'ap-bangkok'
  }, {
    label: 'na-siliconvalley',
    value: 'na-siliconvalley'
  }, {
    label: 'na-ashburn',
    value: 'na-ashburn'
  }, {
    label: 'eu-moscow',
    value: 'eu-moscow'
  }, {
    label: 'eu-frankfurt',
    value: 'eu-frankfurt'
  }];

  var VERSIONS = [{
    label: '1.10.5',
    value: '1.10.5'
  }];

  var ROOT_DISK = [{
    label: 'clusterNew.tencenttke.disk.basic',
    value: 'LOCAL_BASIC'
  }, {
    label: 'clusterNew.tencenttke.disk.ssd',
    value: 'LOCAL_SSD'
  }, {
    label: 'clusterNew.tencenttke.disk.cloudBasic',
    value: 'CLOUD_BASIC'
  }, {
    label: 'clusterNew.tencenttke.disk.cloudSsd',
    value: 'CLOUD_SSD'
  }];

  var STORAGE_DISK = [{
    label: 'clusterNew.tencenttke.disk.basic',
    value: 'LOCAL_BASIC'
  }, {
    label: 'clusterNew.tencenttke.disk.ssd',
    value: 'LOCAL_SSD'
  }, {
    label: 'clusterNew.tencenttke.disk.cloudBasic',
    value: 'CLOUD_BASIC'
  }, {
    label: 'clusterNew.tencenttke.disk.cloudPremium',
    value: 'CLOUD_PREMIUM'
  }, {
    label: 'clusterNew.tencenttke.disk.cloudSsd',
    value: 'CLOUD_SSD'
  }];

  var BAND_WIDTH = [{
    label: 'clusterNew.tencenttke.bandwidthType.hour',
    value: 'PayByHour'
  }, {
    label: 'clusterNew.tencenttke.bandwidthType.traffic',
    value: 'PayByTraffic'
  }];

  exports.default = Ember.Component.extend(_clusterDriver.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'tencentEngineConfig',

    step: 1,
    regionChoices: REGIONS,
    versionChoices: VERSIONS,
    osChoices: OS,
    rootDiskChoices: ROOT_DISK,
    storageDiskChoices: STORAGE_DISK,
    bandWidthChoices: BAND_WIDTH,
    zoneChoices: null,
    vpcChoices: null,
    sgChoices: null,
    keyChoices: null,
    allSubnets: null,
    allInstances: null,

    isNew: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),

    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.tencentEngineConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          type: 'tencentEngineConfig',
          clusterCidr: '172.16.0.0/16',
          clusterVersion: '1.10.5',
          region: 'ap-guangzhou',
          secretId: null,
          secretKey: null,
          zoneId: null,
          vpcId: null,
          subnetId: null,
          instanceType: 'S2.MEDIUM4',
          osName: 'ubuntu16.04.1 LTSx86_64',
          sgId: null,
          rootType: 'CLOUD_BASIC',
          storageType: 'CLOUD_BASIC',
          rootSize: 25,
          storageSize: 20,
          cvmType: 'PayByHour',
          wanIp: 1,
          isVpcGateway: 0,
          emptyCluster: false,
          bandwidthType: 'PayByHour',
          bandwidth: 10,
          keyId: null,
          nodeCount: 1
        });

        Ember.set(this, 'cluster.tencentEngineConfig', config);
      }
    },


    actions: {
      tencentLogin: function tencentLogin(cb) {
        var _this = this;

        Ember.setProperties(this, {
          'errors': null,
          'config.secretId': (Ember.get(this, 'config.secretId') || '').trim(),
          'config.secretKey': (Ember.get(this, 'config.secretKey') || '').trim()
        });

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var secretId = Ember.get(this, 'config.secretId');
        var secretKey = Ember.get(this, 'config.secretKey');

        if (!secretId) {
          errors.push(intl.t('clusterNew.tencenttke.secretId.required'));
        }

        if (!secretKey) {
          errors.push(intl.t('clusterNew.tencenttke.secretKey.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        return Ember.RSVP.all([this.fetchVpcs(), this.fetchSubnets()]).then(function () {
          Ember.set(_this, 'step', 2);
          cb(true);
        }).catch(function () {
          cb(false);
        });
      },
      loadNodeConfig: function loadNodeConfig(cb) {
        var _this2 = this;

        Ember.setProperties(this, { 'errors': null });

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var _EmberGet = Ember.get(this, 'config'),
            clusterCidr = _EmberGet.clusterCidr,
            vpcId = _EmberGet.vpcId,
            nodeCount = _EmberGet.nodeCount;

        if (!clusterCidr) {
          errors.push(intl.t('clusterNew.tencenttke.cidr.required'));
        }

        if (!vpcId) {
          errors.push(intl.t('clusterNew.tencenttke.vpc.required'));
        }

        if (!nodeCount) {
          errors.push(intl.t('clusterNew.tencenttke.nodeCount.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        return this.checkCidr().then(function (res) {
          if (Ember.get(res, 'code') === 0) {
            Ember.RSVP.all([_this2.fetchZones(), _this2.fetchNodeTypes()]).then(function () {
              Ember.set(_this2, 'step', 3);
              cb(true);
            }).catch(function () {
              cb(false);
            });
          } else {
            var error = decodeURIComponent(Ember.get(res, 'message'));

            if (error) {
              Ember.set(_this2, 'errors', [error]);
            }
            cb(false);
          }
        }).catch(function (error) {
          Ember.set(_this2, 'errors', [error]);

          cb(false);
        });
      },
      loadInstanceConfig: function loadInstanceConfig(cb) {
        var _this3 = this;

        Ember.setProperties(this, { 'errors': null });

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var _EmberGet2 = Ember.get(this, 'config'),
            zoneId = _EmberGet2.zoneId,
            subnetId = _EmberGet2.subnetId;

        if (!zoneId) {
          errors.push(intl.t('clusterNew.tencenttke.zone.required'));
        }

        if (!subnetId) {
          errors.push(intl.t('clusterNew.tencenttke.subnet.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        return Ember.RSVP.all([this.fetchSecurityGroups(), this.fetchKeyPairs()]).then(function () {
          Ember.set(_this3, 'step', 4);
          cb(true);
        }).catch(function () {
          cb(false);
        });
      },
      save: function save(cb) {
        Ember.setProperties(this, { 'errors': null });

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var _EmberGet3 = Ember.get(this, 'config'),
            sgId = _EmberGet3.sgId,
            keyId = _EmberGet3.keyId;

        if (!sgId) {
          errors.push(intl.t('clusterNew.tencenttke.securityGroup.required'));
        }

        if (!keyId) {
          errors.push(intl.t('clusterNew.tencenttke.keyPair.required'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        this.send('driverSave', cb);
      }
    },

    clusterNameDidChange: Ember.observer('cluster.name', function () {
      Ember.set(this, 'config.clusterName', Ember.get(this, 'cluster.name'));
    }),

    subnetChoices: Ember.computed('selectedZone', 'allSubnets', function () {
      var _this4 = this;

      if (!Ember.get(this, 'selectedZone') || !Ember.get(this, 'allSubnets')) {
        return;
      }
      var subnets = Ember.get(this, 'allSubnets').filter(function (subnet) {
        return Ember.get(subnet, 'vpcId') === Ember.get(_this4, 'config.vpcId') && Ember.get(subnet, 'zone') === Ember.get(_this4, 'selectedZone.label');
      });

      var subnetId = Ember.get(this, 'config.subnetId');

      if (!subnetId && Ember.get(subnets, 'length')) {
        Ember.set(this, 'config.subnetId', Ember.get(subnets, 'firstObject.value'));
      } else {
        var found = subnets.findBy('value', subnetId);

        if (!found) {
          Ember.set(this, 'config.subnetId', null);
        }
      }

      return subnets;
    }),

    instanceChoices: Ember.computed('selectedZone', 'allInstances', function () {
      if (!Ember.get(this, 'selectedZone') || !Ember.get(this, 'allInstances')) {
        return;
      }
      var instances = Ember.get(this, 'allInstances').filterBy('zone', Ember.get(this, 'selectedZone.label'));

      var instanceType = Ember.get(this, 'config.instanceType');

      var found = instances.findBy('value', instanceType);

      if (!found) {
        Ember.set(this, 'config.instanceType', null);
      }

      return instances;
    }),

    selectedZone: Ember.computed('config.zoneId', 'zoneChoices', function () {
      var zoneChoices = Ember.get(this, 'zoneChoices') || [];

      return zoneChoices.findBy('value', Ember.get(this, 'config.zoneId'));
    }),

    checkCidr: function checkCidr() {
      if (Ember.get(this, 'isNew')) {
        return this.queryFromTencent('ccs', 'CheckClusterCIDR', CCS_ENDPOINT, {
          clusterCIDR: Ember.get(this, 'config.clusterCidr'),
          vpcId: Ember.get(this, 'config.vpcId')
        });
      } else {
        return Ember.RSVP.resolve({ code: 0 });
      }
    },
    queryFromTencent: function queryFromTencent(product, action) {
      var _this5 = this;

      var endpoint = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ENDPOINT;
      var extraParams = arguments[3];

      var data = {
        Action: action,
        Nonce: Math.round(Math.random() * 65535),
        Region: Ember.get(this, 'config.region'),
        SecretId: Ember.get(this, 'config.secretId'),
        SignatureMethod: 'HmacSHA1',
        Timestamp: Math.round(Date.now() / 1000),
        Version: '2017-03-12'
      };

      var url = product + '.' + endpoint + '?';
      var params = [];

      Object.keys(data).forEach(function (key) {
        params.push(key + '=' + data[key]);
      });

      if (extraParams) {
        Object.keys(extraParams).forEach(function (key) {
          params.push(key + '=' + extraParams[key]);
        });
      }

      url += params.join('&');

      url += '&Signature=' + encodeURIComponent(AWS.util.crypto.hmac(Ember.get(this, 'config.secretKey'), 'GET' + url, 'base64', 'sha1'));

      return Ember.get(this, 'globalStore').rawRequest({
        url: '/meta/proxy/https:/' + url,
        method: 'GET'
      }).then(function (xhr) {
        var error = Ember.get(xhr, 'body.Response.Error.Message');

        if (error) {
          Ember.set(_this5, 'errors', [error]);

          return Ember.RSVP.reject();
        }

        return Ember.get(xhr, 'body.Response') || JSON.parse(Ember.get(xhr, 'body'));
      }).catch(function (xhr) {
        var error = Ember.get(xhr, 'body.Response.Error.Message') || JSON.stringify(xhr);

        Ember.set(_this5, 'errors', [error]);

        return Ember.RSVP.reject();
      });
    },
    fetchVpcs: function fetchVpcs() {
      var _this6 = this;

      return this.queryFromTencent('vpc', 'DescribeVpcs').then(function (res) {
        Ember.set(_this6, 'vpcChoices', Ember.get(res, 'VpcSet').map(function (vpc) {
          return {
            label: Ember.get(vpc, 'VpcName'),
            value: Ember.get(vpc, 'VpcId')
          };
        }));

        if (!Ember.get(_this6, 'config.vpcId') && Ember.get(_this6, 'vpcChoices.length')) {
          Ember.set(_this6, 'config.vpcId', Ember.get(_this6, 'vpcChoices.firstObject.value'));
        }
      });
    },
    fetchSubnets: function fetchSubnets() {
      var _this7 = this;

      return this.queryFromTencent('vpc', 'DescribeSubnets').then(function (res) {
        Ember.set(_this7, 'allSubnets', Ember.get(res, 'SubnetSet').map(function (subnet) {
          return {
            label: Ember.get(subnet, 'SubnetName'),
            value: Ember.get(subnet, 'SubnetId'),
            vpcId: Ember.get(subnet, 'VpcId'),
            zone: Ember.get(subnet, 'Zone')
          };
        }));
      });
    },
    fetchNodeTypes: function fetchNodeTypes() {
      var _this8 = this;

      return this.queryFromTencent('cvm', 'DescribeInstanceTypeConfigs').then(function (res) {
        Ember.set(_this8, 'allInstances', Ember.get(res, 'InstanceTypeConfigSet').map(function (instance) {
          return {
            value: Ember.get(instance, 'InstanceType'),
            label: Ember.get(instance, 'InstanceType') + ' (CPU ' + Ember.get(instance, 'CPU') + ' Memory ' + Ember.get(instance, 'Memory') + ' GiB)',
            group: Ember.get(instance, 'InstanceFamily'),
            zone: Ember.get(instance, 'Zone')
          };
        }));
      });
    },
    fetchSecurityGroups: function fetchSecurityGroups() {
      var _this9 = this;

      return this.queryFromTencent('vpc', 'DescribeSecurityGroups').then(function (res) {
        Ember.set(_this9, 'sgChoices', Ember.get(res, 'SecurityGroupSet').map(function (zone) {
          return {
            label: Ember.get(zone, 'SecurityGroupName'),
            value: Ember.get(zone, 'SecurityGroupId')
          };
        }));

        if (!Ember.get(_this9, 'config.sgId') && Ember.get(_this9, 'sgChoices.length')) {
          Ember.set(_this9, 'config.sgId', Ember.get(_this9, 'sgChoices.firstObject.value'));
        }
      });
    },
    fetchKeyPairs: function fetchKeyPairs() {
      var _this10 = this;

      return this.queryFromTencent('cvm', 'DescribeKeyPairs').then(function (res) {
        Ember.set(_this10, 'keyChoices', Ember.get(res, 'KeyPairSet').map(function (key) {
          return {
            label: Ember.get(key, 'KeyName'),
            value: Ember.get(key, 'KeyId')
          };
        }));

        if (!Ember.get(_this10, 'config.keyId') && Ember.get(_this10, 'keyChoices.length')) {
          Ember.set(_this10, 'config.keyId', Ember.get(_this10, 'keyChoices.firstObject.value'));
        }
      });
    },
    fetchZones: function fetchZones() {
      var _this11 = this;

      return this.queryFromTencent('cvm', 'DescribeZones').then(function (res) {
        Ember.set(_this11, 'zoneChoices', Ember.get(res, 'ZoneSet').filterBy('ZoneState', 'AVAILABLE').map(function (zone) {
          return {
            label: Ember.get(zone, 'Zone'),
            value: Ember.get(zone, 'ZoneId')
          };
        }));

        if (!Ember.get(_this11, 'config.zoneId') && Ember.get(_this11, 'zoneChoices.length')) {
          Ember.set(_this11, 'config.zoneId', Ember.get(_this11, 'zoneChoices.firstObject.value'));
        }
      });
    }
  });
});