define('shared/modal/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    modalType: 'generic-modal',
    modalOpts: null,
    modalVisible: false,
    lastScroll: null,
    closeWithOutsideClick: Ember.computed.alias('modalOpts.closeWithOutsideClick'),
    app: Ember.inject.service(),
    toggleModal: function toggleModal() {
      var _this = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (opts) {
        this.set('modalOpts', opts);
      }

      this.set('modalType', type);

      if (this.get('modalVisible')) {
        this.set('modalVisible', false);
        this.set('modalOpts', null);
        Ember.run.next(function () {
          window.scrollTo(0, _this.get('lastScroll'));
        });
      } else {
        this.set('lastScroll', window.scrollY);
        this.set('modalVisible', true);
        Ember.run.next(function () {
          window.scrollTo(0, 0);
        });
      }
    }
  });
});