define('shared/helpers/date-recent', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateCalendar = dateCalendar;
  function dateCalendar(params) {
    var date = (0, _moment.default)(params[0]);
    var now = (0, _moment.default)();
    var diff = now.diff(date);

    if (Math.abs(diff) > 44 * 60 * 1000) {
      return date.calendar();
    } else if (diff > 0) {
      return date.fromNow();
    } else {
      return date.toNow();
    }
  }

  exports.default = Ember.Helper.helper(dateCalendar);
});