define('shared/components/copy-inline/component', ['exports', 'ui/utils/platform', 'shared/components/copy-inline/template'], function (exports, _platform, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DELAY = 1000;
  var DEFAULT_TEXT = 'copyToClipboard.tooltip';

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',

    model: null,

    /* Component Params*/
    buttonText: null,
    tooltipText: null,
    status: null,
    size: null,
    target: null,
    clipboardText: null,
    textChangedEvent: null,
    defaultText: DEFAULT_TEXT,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', new Object({ tooltipText: this.get('defaultText') }));
    },


    actions: {
      alertSuccess: function alertSuccess() {
        var _this = this;

        var orig = this.get('model.tooltipText');

        this.setProperties({
          status: 'success',
          model: { tooltipText: 'copyToClipboard.copied' }
        });

        Ember.run.later(function () {
          _this.setProperties({
            status: null,
            model: { tooltipText: orig }
          });
        }, DELAY);
      }
    },
    isSupported: function () {
      return this.get('clipboardText.length') && (!_platform.isSafari || document.queryCommandSupported('copy'));
    }.property('clipboardText'),

    mouseEnter: function mouseEnter() {
      this.set('model', new Object({ tooltipText: this.get('defaultText') }));
    }
  });
});