define('shared/components/pretty-json/component', ['exports', 'shared/components/pretty-json/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    value: null,

    json: function () {
      var value = '' + (this.get('value') || '');

      if (value === '{}' || value === '[]') {
        return false;
      }

      if (['[', '{'].indexOf(value.substr(0, 1)) >= 0) {
        try {
          var pretty = JSON.stringify(JSON.parse(value), null, 2);

          return pretty;
        } catch (e) {}
      }

      return null;
    }.property('value')
  });
});