define('shared/utils/convert-yaml', ['exports', 'json2yaml', 'dot-object'], function (exports, _json2yaml, _dotObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = convertDotAnswersToYaml;
  function convertDotAnswersToYaml(answers) {
    var obj = {};

    Object.keys(answers).forEach(function (key) {
      var value = answers[key];

      key = key.replace(/\]\[/g, '.').replace(/\]\./g, '.').replace(/\[/g, '.');

      if (key.startsWith('.')) {
        key = key.substr(1, key.length);
      }

      if (key.endsWith(']')) {
        key = key.substr(0, key.length - 1);
      }
      _dotObject.default.str(key, value, obj);
    });

    return _json2yaml.default.stringify(obj);
  }
});