define('nodes/custom-drivers/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      if (transition.targetName === 'nodes.custom-drivers.index') {
        this.replaceWith('custom-drivers.cluster-drivers');
      }
    }
  });
});