define('shared/components/input-or-display/component', ['exports', 'shared/mixins/safe-style', 'shared/components/input-or-display/template'], function (exports, _safeStyle, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_safeStyle.default, {
    layout: _template.default,

    tagName: 'span',
    value: null,
    editable: true,
    classesForInput: 'form-control',
    classesForDisplay: '',
    obfuscate: false,

    obfuscatedValue: Ember.computed('value', function () {
      var val = this.get('value') || '';
      var count = val.length;
      var obChar = '*';

      return new Array(count + 1).join(obChar);
    }),

    hasValue: Ember.computed('value', function () {
      return !Ember.isEmpty(this.value);
    })
  });
});