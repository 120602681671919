define('shared/components/hover-dropdown/component', ['exports', 'shared/components/hover-dropdown/template', 'shared/utils/calculate-position'], function (exports, _template, _calculatePosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    delay: 200,
    openDelay: Ember.computed.oneWay('delay'),
    closeDelay: Ember.computed.oneWay('delay'),

    actions: {
      open: function open(forContent, dropdown) {
        var _this = this;

        if (Ember.get(this, 'closeTimer')) {
          Ember.run.cancel(Ember.get(this, 'closeTimer'));
          Ember.set(this, 'closeTimer', null);
        } else {
          var openFn = function openFn() {
            Ember.set(_this, 'openTimer', null);

            if (forContent) {
              _this.sendAction('onBeforeOpen');
            }

            dropdown.actions.open();

            if (forContent) {
              _this.sendAction('onOpen');
            }
          };

          var openDelay = Ember.get(this, 'openDelay');

          if (openDelay) {
            Ember.set(this, 'openTimer', Ember.run.later(openFn, openDelay));
          } else {
            openFn();
          }
        }
      },
      close: function close(forContent, dropdown) {
        var _this2 = this;

        if (this.openTimer) {
          Ember.run.cancel(this.openTimer);
          Ember.set(this, 'openTimer', null);
        } else {
          var closeFn = function closeFn() {
            Ember.set(_this2, 'closeTimer', null);
            // signature - event, skipfocus

            if (forContent) {
              _this2.sendAction('onBeforeClose');
            }

            dropdown.actions.close(null, true);

            if (forContent) {
              _this2.sendAction('onClose');
            }
          };

          var closeDelay = Ember.get(this, 'closeDelay');

          if (closeDelay) {
            Ember.set(this, 'closeTimer', Ember.run.later(closeFn, closeDelay));
          } else {
            closeFn();
          }
        }
      },
      prevent: function prevent() {
        return false;
      },


      calculatePosition: _calculatePosition.default
    }
  });
});