define('global-admin/components/policy-row/component', ['exports', 'global-admin/components/policy-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    policies: null,

    tagName: 'TR',
    classNames: 'main-row',

    actions: {
      remove: function remove() {
        this.sendAction('remove', this.get('model'));
      }
    }
  });
});