define('shared/components/schema/input-multiline/component', ['exports', 'shared/components/schema/input-multiline/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextArea.extend({
    layout: _template.default,
    classNames: ['form-control', 'text-mono'],
    rows: 3
  });
});