define('ember-engines/-private/router-ext', [], function () {
  'use strict';

  var defaultSerialize = Ember.Route.proto().serialize;
  function hasDefaultSerialize(handler) {
    return handler.serialize === defaultSerialize;
  }

  // NOTE:
  // This needed because we need to infer the setup of the router.js
  // Prior to https://github.com/emberjs/ember.js/pull/16974 we used to
  // call `_getHandlerFunction` to get the closed over function to resolve
  // a name to a handler. PR#16974 removed this private method.
  var newSetup = true;

  Ember.Router.reopen({
    init: function init() {
      this._super.apply(this, arguments);
      this._enginePromises = Object.create(null);
      this._seenHandlers = Object.create(null);

      // We lookup the asset loader service instead of injecting it so that we
      // don't blow up unit tests for consumers
      this._assetLoader = Ember.getOwner(this).lookup('service:asset-loader');
    },
    _getQPMeta: function _getQPMeta(handlerInfo) {
      var routeName = handlerInfo.name;
      var isWithinEngine = this._engineInfoByRoute[routeName];
      var hasBeenLoaded = this._seenHandlers[routeName];
      if (isWithinEngine && !hasBeenLoaded) {
        return undefined;
      }

      return this._super.apply(this, arguments);
    },
    _getHandlerFunction: function _getHandlerFunction() {
      newSetup = false;
      return this._handlerResolver();
    },
    setupRouter: function setupRouter() {
      var isSetup = this._super.apply(this, arguments);
      if (newSetup) {
        // This method used to be called `getHandler` and it is going to be called `getRoute`.
        if (this._routerMicrolib.getRoute !== undefined) {
          this._routerMicrolib.getRoute = this._handlerResolver();
        } else if (this._routerMicrolib.getHandler !== undefined) {
          this._routerMicrolib.getHandler = this._handlerResolver();
        }
      }
      return isSetup;
    },
    _handlerResolver: function _handlerResolver() {
      var _this = this;

      var seen = this._seenHandlers;
      var owner = Ember.getOwner(this);
      return function (name) {
        var engineInfo = _this._engineInfoByRoute[name];
        if (engineInfo) {
          var engineInstance = _this._getEngineInstance(engineInfo);
          if (engineInstance) {
            return _this._getHandlerForEngine(seen, name, engineInfo.localFullName, engineInstance);
          } else {
            return _this._loadEngineInstance(engineInfo).then(function (instance) {
              return _this._getHandlerForEngine(seen, name, engineInfo.localFullName, instance);
            });
          }
        }

        // If we don't cross into an Engine, then the routeName and localRouteName
        // are the same.
        return _this._internalGetHandler(seen, name, name, owner);
      };
    },
    _getHandlerForEngine: function _getHandlerForEngine(seen, routeName, localRouteName, engineInstance) {
      var handler = this._internalGetHandler(seen, routeName, localRouteName, engineInstance);

      if (!hasDefaultSerialize(handler)) {
        throw new Error('Defining a custom serialize method on an Engine route is not supported.');
      }

      return handler;
    },
    _internalGetHandler: function _internalGetHandler(seen, routeName, localRouteName, routeOwner) {
      var fullRouteName = 'route:' + localRouteName;
      var handler = routeOwner.lookup(fullRouteName);

      if (seen[routeName] && handler) {
        return handler;
      }

      seen[routeName] = true;

      if (!handler) {
        var DefaultRoute = routeOwner.factoryFor ? routeOwner.factoryFor('route:basic').class : routeOwner._lookupFactory('route:basic');

        routeOwner.register(fullRouteName, DefaultRoute.extend());
        handler = routeOwner.lookup(fullRouteName);

        if (Ember.get(this, 'namespace.LOG_ACTIVE_GENERATION')) {
          // eslint-disable-next-line no-console
          console.info('generated -> ' + fullRouteName, { fullName: fullRouteName });
        }
      }

      handler._setRouteName(localRouteName);
      if (handler._populateQPMeta) {
        handler._populateQPMeta();
      }

      return handler;
    },
    _engineIsLoaded: function _engineIsLoaded(name) {
      var owner = Ember.getOwner(this);
      return owner.hasRegistration('engine:' + name);
    },
    _registerEngine: function _registerEngine(name) {
      var owner = Ember.getOwner(this);
      if (!owner.hasRegistration('engine:' + name)) {
        owner.register('engine:' + name, window.require(name + '/engine').default);
      }
    },
    _getEngineInstance: function _getEngineInstance(_ref) {
      var name = _ref.name,
          instanceId = _ref.instanceId;

      var engineInstances = this._engineInstances;
      return engineInstances[name] && engineInstances[name][instanceId];
    },
    _loadEngineInstance: function _loadEngineInstance(_ref2) {
      var _this2 = this;

      var name = _ref2.name,
          instanceId = _ref2.instanceId,
          mountPoint = _ref2.mountPoint;

      var enginePromises = this._enginePromises;

      if (!enginePromises[name]) {
        enginePromises[name] = Object.create(null);
      }

      var enginePromise = enginePromises[name][instanceId];

      // We already have a Promise for this engine instance
      if (enginePromise) {
        return enginePromise;
      }

      if (this._engineIsLoaded(name)) {
        // The Engine is loaded, but has no Promise
        enginePromise = Ember.RSVP.resolve();
      } else {
        // The Engine is not loaded and has no Promise
        enginePromise = this._assetLoader.loadBundle(name).then(function () {
          return _this2._registerEngine(name);
        }, function (error) {
          enginePromises[name][instanceId] = undefined;
          throw error;
        });
      }

      return enginePromises[name][instanceId] = enginePromise.then(function () {
        return _this2._constructEngineInstance({ name: name, instanceId: instanceId, mountPoint: mountPoint });
      });
    },
    _constructEngineInstance: function _constructEngineInstance(_ref3) {
      var name = _ref3.name,
          instanceId = _ref3.instanceId,
          mountPoint = _ref3.mountPoint;

      var owner = Ember.getOwner(this);

      (false && !(owner.hasRegistration('engine:' + name)) && Ember.assert("You attempted to mount the engine '" + name + "' in your router map, but the engine cannot be found.", owner.hasRegistration('engine:' + name)));


      var engineInstances = this._engineInstances;

      if (!engineInstances[name]) {
        engineInstances[name] = Object.create(null);
      }

      var engineInstance = owner.buildChildEngineInstance(name, {
        routable: true,
        mountPoint: mountPoint
      });

      engineInstances[name][instanceId] = engineInstance;

      return engineInstance.boot().then(function () {
        return engineInstance;
      });
    }
  });
});