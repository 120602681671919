define('nodes/custom-drivers/cluster-drivers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({ drivers: this.get('globalStore').findAll('kontainerDriver') });
    }
  });
});