define('global-admin/settings/registration/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    settings: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('settings').load([_constants.default.SETTING.API_HOST]);
    },
    model: function model() {
      var settings = this.get('settings');

      return this.get('globalStore').find('setting').then(function () {
        return Ember.Object.create({ host: settings.get(_constants.default.SETTING.API_HOST) });
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('error', null);
      }
    }
  });
});