define("nodes/cldrs/sv", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{ "locale": "sv-se", "parentLocale": "sv" }, { "locale": "sv", "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          v0 = !s[1],
          t0 = Number(s[0]) == n,
          n10 = t0 && s[0].slice(-1),
          n100 = t0 && s[0].slice(-2);if (ord) return (n10 == 1 || n10 == 2) && n100 != 11 && n100 != 12 ? "one" : "other";return n == 1 && v0 ? "one" : "other";
    }, "fields": { "year": { "displayName": "år", "relative": { "0": "i år", "1": "nästa år", "-1": "i fjol" }, "relativeTime": { "future": { "one": "om {0} år", "other": "om {0} år" }, "past": { "one": "för {0} år sedan", "other": "för {0} år sedan" } } }, "month": { "displayName": "månad", "relative": { "0": "denna månad", "1": "nästa månad", "-1": "förra månaden" }, "relativeTime": { "future": { "one": "om {0} månad", "other": "om {0} månader" }, "past": { "one": "för {0} månad sedan", "other": "för {0} månader sedan" } } }, "day": { "displayName": "dag", "relative": { "0": "i dag", "1": "i morgon", "2": "i övermorgon", "-2": "i förrgår", "-1": "i går" }, "relativeTime": { "future": { "one": "om {0} dag", "other": "om {0} dagar" }, "past": { "one": "för {0} dag sedan", "other": "för {0} dagar sedan" } } }, "hour": { "displayName": "timme", "relativeTime": { "future": { "one": "om {0} timme", "other": "om {0} timmar" }, "past": { "one": "för {0} timme sedan", "other": "för {0} timmar sedan" } } }, "minute": { "displayName": "minut", "relativeTime": { "future": { "one": "om {0} minut", "other": "om {0} minuter" }, "past": { "one": "för {0} minut sedan", "other": "för {0} minuter sedan" } } }, "second": { "displayName": "sekund", "relative": { "0": "nu" }, "relativeTime": { "future": { "one": "om {0} sekund", "other": "om {0} sekunder" }, "past": { "one": "för {0} sekund sedan", "other": "för {0} sekunder sedan" } } } } }];
});