enifed('@glimmer/compiler', ['exports', 'ember-babel', 'node-module', '@glimmer/util', '@glimmer/wire-format', '@glimmer/syntax'], function (exports, _emberBabel, _nodeModule, _util, _wireFormat, _syntax) {
    'use strict';

    exports.TemplateVisitor = exports.TemplateCompiler = exports.precompile = exports.defaultId = undefined;

    var SymbolTable = function () {
        function SymbolTable() {}

        SymbolTable.top = function () {
            return new ProgramSymbolTable();
        };

        SymbolTable.prototype.child = function (locals) {
            var _this = this;

            var symbols = locals.map(function (name) {
                return _this.allocate(name);
            });
            return new BlockSymbolTable(this, locals, symbols);
        };

        return SymbolTable;
    }();

    var ProgramSymbolTable = function (_SymbolTable) {
        (0, _emberBabel.inherits)(ProgramSymbolTable, _SymbolTable);

        function ProgramSymbolTable() {

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _SymbolTable.apply(this, arguments));

            _this2.symbols = [];
            _this2.size = 1;
            _this2.named = (0, _util.dict)();
            _this2.blocks = (0, _util.dict)();
            return _this2;
        }

        ProgramSymbolTable.prototype.has = function () {
            return false;
        };

        ProgramSymbolTable.prototype.get = function () {
            throw (0, _util.unreachable)();
        };

        ProgramSymbolTable.prototype.getLocalsMap = function () {
            return {};
        };

        ProgramSymbolTable.prototype.getEvalInfo = function () {
            return [];
        };

        ProgramSymbolTable.prototype.allocateNamed = function (name) {
            var named = this.named[name];
            if (!named) {
                named = this.named[name] = this.allocate(name);
            }
            return named;
        };

        ProgramSymbolTable.prototype.allocateBlock = function (name) {
            var block = this.blocks[name];
            if (!block) {
                block = this.blocks[name] = this.allocate('&' + name);
            }
            return block;
        };

        ProgramSymbolTable.prototype.allocate = function (identifier) {
            this.symbols.push(identifier);
            return this.size++;
        };

        return ProgramSymbolTable;
    }(SymbolTable);

    var BlockSymbolTable = function (_SymbolTable2) {
        (0, _emberBabel.inherits)(BlockSymbolTable, _SymbolTable2);

        function BlockSymbolTable(parent, symbols, slots) {

            var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _SymbolTable2.call(this));

            _this3.parent = parent;
            _this3.symbols = symbols;
            _this3.slots = slots;
            return _this3;
        }

        BlockSymbolTable.prototype.has = function (name) {
            return this.symbols.indexOf(name) !== -1 || this.parent.has(name);
        };

        BlockSymbolTable.prototype.get = function (name) {
            var slot = this.symbols.indexOf(name);
            return slot === -1 ? this.parent.get(name) : this.slots[slot];
        };

        BlockSymbolTable.prototype.getLocalsMap = function () {
            var _this4 = this;

            var dict$$1 = this.parent.getLocalsMap();
            this.symbols.forEach(function (symbol) {
                return dict$$1[symbol] = _this4.get(symbol);
            });
            return dict$$1;
        };

        BlockSymbolTable.prototype.getEvalInfo = function () {
            var locals = this.getLocalsMap();
            return Object.keys(locals).map(function (symbol) {
                return locals[symbol];
            });
        };

        BlockSymbolTable.prototype.allocateNamed = function (name) {
            return this.parent.allocateNamed(name);
        };

        BlockSymbolTable.prototype.allocateBlock = function (name) {
            return this.parent.allocateBlock(name);
        };

        BlockSymbolTable.prototype.allocate = function (identifier) {
            return this.parent.allocate(identifier);
        };

        return BlockSymbolTable;
    }(SymbolTable);

    var Frame = function () {

        this.parentNode = null;
        this.children = null;
        this.childIndex = null;
        this.childCount = null;
        this.childTemplateCount = 0;
        this.mustacheCount = 0;
        this.actions = [];
        this.blankChildTextNodes = null;
        this.symbols = null;
    };

    var TemplateVisitor = function () {
        function TemplateVisitor() {

            this.frameStack = [];
            this.actions = [];
            this.programDepth = -1;
        }

        TemplateVisitor.prototype.visit = function (node) {
            this[node.type](node);
        };

        TemplateVisitor.prototype.Program = function (program) {
            var _actions, i;

            this.programDepth++;
            var parentFrame = this.getCurrentFrame();
            var programFrame = this.pushFrame();
            if (!parentFrame) {
                program['symbols'] = SymbolTable.top();
            } else {
                program['symbols'] = parentFrame.symbols.child(program.blockParams);
            }
            var startType = void 0,
                endType = void 0;
            if (this.programDepth === 0) {
                startType = 'startProgram';
                endType = 'endProgram';
            } else {
                startType = 'startBlock';
                endType = 'endBlock';
            }
            programFrame.parentNode = program;
            programFrame.children = program.body;
            programFrame.childCount = program.body.length;
            programFrame.blankChildTextNodes = [];
            programFrame.actions.push([endType, [program, this.programDepth]]);
            programFrame.symbols = program['symbols'];
            for (i = program.body.length - 1; i >= 0; i--) {
                programFrame.childIndex = i;
                this.visit(program.body[i]);
            }
            programFrame.actions.push([startType, [program, programFrame.childTemplateCount, programFrame.blankChildTextNodes.reverse()]]);
            this.popFrame();
            this.programDepth--;
            // Push the completed template into the global actions list
            if (parentFrame) {
                parentFrame.childTemplateCount++;
            }
            (_actions = this.actions).push.apply(_actions, programFrame.actions.reverse());
        };

        TemplateVisitor.prototype.ElementNode = function (element) {
            var _parentFrame$actions, i, _i;

            var parentFrame = this.currentFrame;
            var elementFrame = this.pushFrame();
            elementFrame.parentNode = element;
            elementFrame.children = element.children;
            elementFrame.childCount = element.children.length;
            elementFrame.mustacheCount += element.modifiers.length;
            elementFrame.blankChildTextNodes = [];
            elementFrame.symbols = element['symbols'] = parentFrame.symbols.child(element.blockParams);
            var actionArgs = [element, parentFrame.childIndex, parentFrame.childCount];
            elementFrame.actions.push(['closeElement', actionArgs]);
            for (i = element.attributes.length - 1; i >= 0; i--) {
                this.visit(element.attributes[i]);
            }
            for (_i = element.children.length - 1; _i >= 0; _i--) {
                elementFrame.childIndex = _i;
                this.visit(element.children[_i]);
            }
            var open = ['openElement', [].concat(actionArgs, [elementFrame.mustacheCount, elementFrame.blankChildTextNodes.reverse()])];
            elementFrame.actions.push(open);
            this.popFrame();
            // Propagate the element's frame state to the parent frame
            if (elementFrame.mustacheCount > 0) {
                parentFrame.mustacheCount++;
            }
            parentFrame.childTemplateCount += elementFrame.childTemplateCount;
            (_parentFrame$actions = parentFrame.actions).push.apply(_parentFrame$actions, elementFrame.actions);
        };

        TemplateVisitor.prototype.AttrNode = function (attr) {
            if (attr.value.type !== 'TextNode') {
                this.currentFrame.mustacheCount++;
            }
        };

        TemplateVisitor.prototype.TextNode = function (text) {
            var frame = this.currentFrame;
            if (text.chars === '') {
                frame.blankChildTextNodes.push(domIndexOf(frame.children, text));
            }
            frame.actions.push(['text', [text, frame.childIndex, frame.childCount]]);
        };

        TemplateVisitor.prototype.BlockStatement = function (node) {
            var frame = this.currentFrame;
            frame.mustacheCount++;
            frame.actions.push(['block', [node, frame.childIndex, frame.childCount]]);
            if (node.inverse) {
                this.visit(node.inverse);
            }
            if (node.program) {
                this.visit(node.program);
            }
        };

        TemplateVisitor.prototype.PartialStatement = function (node) {
            var frame = this.currentFrame;
            frame.mustacheCount++;
            frame.actions.push(['mustache', [node, frame.childIndex, frame.childCount]]);
        };

        TemplateVisitor.prototype.CommentStatement = function (text) {
            var frame = this.currentFrame;
            frame.actions.push(['comment', [text, frame.childIndex, frame.childCount]]);
        };

        TemplateVisitor.prototype.MustacheCommentStatement = function () {
            // Intentional empty: Handlebars comments should not affect output.
        };

        TemplateVisitor.prototype.MustacheStatement = function (mustache) {
            var frame = this.currentFrame;
            frame.mustacheCount++;
            frame.actions.push(['mustache', [mustache, frame.childIndex, frame.childCount]]);
        };

        TemplateVisitor.prototype.getCurrentFrame = function () {
            return this.frameStack[this.frameStack.length - 1];
        };

        TemplateVisitor.prototype.pushFrame = function () {
            var frame = new Frame();
            this.frameStack.push(frame);
            return frame;
        };

        TemplateVisitor.prototype.popFrame = function () {
            return this.frameStack.pop();
        };

        (0, _emberBabel.createClass)(TemplateVisitor, [{
            key: 'currentFrame',
            get: function () {
                return this.getCurrentFrame();
            }
        }]);
        return TemplateVisitor;
    }();

    // Returns the index of `domNode` in the `nodes` array, skipping
    // over any nodes which do not represent DOM nodes.
    function domIndexOf(nodes, domNode) {
        var index = -1,
            i,
            node;
        for (i = 0; i < nodes.length; i++) {
            node = nodes[i];

            if (node.type !== 'TextNode' && node.type !== 'ElementNode') {
                continue;
            } else {
                index++;
            }
            if (node === domNode) {
                return index;
            }
        }
        return -1;
    }

    var Block = function () {
        function Block() {

            this.statements = [];
        }

        Block.prototype.push = function (statement) {
            this.statements.push(statement);
        };

        return Block;
    }();

    var InlineBlock = function (_Block) {
        (0, _emberBabel.inherits)(InlineBlock, _Block);

        function InlineBlock(table) {

            var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _Block.call(this));

            _this5.table = table;
            return _this5;
        }

        InlineBlock.prototype.toJSON = function () {
            return {
                statements: this.statements,
                parameters: this.table.slots
            };
        };

        return InlineBlock;
    }(Block);

    var TemplateBlock = function (_Block2) {
        (0, _emberBabel.inherits)(TemplateBlock, _Block2);

        function TemplateBlock(symbolTable) {

            var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _Block2.call(this));

            _this6.symbolTable = symbolTable;
            _this6.type = 'template';
            _this6.yields = new _util.DictSet();
            _this6.named = new _util.DictSet();
            _this6.blocks = [];
            _this6.hasEval = false;
            return _this6;
        }

        TemplateBlock.prototype.push = function (statement) {
            this.statements.push(statement);
        };

        TemplateBlock.prototype.toJSON = function () {
            return {
                symbols: this.symbolTable.symbols,
                statements: this.statements,
                hasEval: this.hasEval
            };
        };

        return TemplateBlock;
    }(Block);

    var ComponentBlock = function (_Block3) {
        (0, _emberBabel.inherits)(ComponentBlock, _Block3);

        function ComponentBlock(tag, table, selfClosing) {

            var _this7 = (0, _emberBabel.possibleConstructorReturn)(this, _Block3.call(this));

            _this7.tag = tag;
            _this7.table = table;
            _this7.selfClosing = selfClosing;
            _this7.attributes = [];
            _this7.arguments = [];
            _this7.inParams = true;
            _this7.positionals = [];
            return _this7;
        }

        ComponentBlock.prototype.push = function (statement) {
            if (this.inParams) {
                if ((0, _wireFormat.isModifier)(statement)) {
                    throw new Error('Compile Error: Element modifiers are not allowed in components');
                } else if ((0, _wireFormat.isFlushElement)(statement)) {
                    this.inParams = false;
                } else if ((0, _wireFormat.isArgument)(statement)) {
                    this.arguments.push(statement);
                } else if ((0, _wireFormat.isAttribute)(statement)) {
                    this.attributes.push(statement);
                } else if ((0, _wireFormat.isAttrSplat)(statement)) {
                    this.attributes.push(statement);
                } else {
                    throw new Error('Compile Error: only parameters allowed before flush-element');
                }
            } else {
                this.statements.push(statement);
            }
        };

        ComponentBlock.prototype.toJSON = function () {
            var args = this.arguments;
            var keys = args.map(function (arg) {
                return arg[1];
            });
            var values = args.map(function (arg) {
                return arg[2];
            });
            var block = this.selfClosing ? null : {
                statements: this.statements,
                parameters: this.table.slots
            };
            return [this.tag, this.attributes, [keys, values], block];
        };

        return ComponentBlock;
    }(Block);

    var Template = function () {
        function Template(symbols) {

            this.block = new TemplateBlock(symbols);
        }

        Template.prototype.toJSON = function () {
            return this.block.toJSON();
        };

        return Template;
    }();

    var JavaScriptCompiler = function () {
        function JavaScriptCompiler(opcodes, symbols, options) {

            this.blocks = new _util.Stack();
            this.values = [];
            this.opcodes = opcodes;
            this.template = new Template(symbols);
            this.options = options;
        }

        JavaScriptCompiler.process = function (opcodes, symbols, options) {
            var compiler = new JavaScriptCompiler(opcodes, symbols, options);
            return compiler.process();
        };

        JavaScriptCompiler.prototype.process = function () {
            var _this8 = this;

            this.opcodes.forEach(function (op) {
                var opcode = op[0];
                var arg = op[1];
                if (!_this8[opcode]) {
                    throw new Error('unimplemented ' + opcode + ' on JavaScriptCompiler');
                }
                _this8[opcode](arg);
            });
            return this.template;
        };

        JavaScriptCompiler.prototype.startBlock = function (program) {
            var block = new InlineBlock(program['symbols']);
            this.blocks.push(block);
        };

        JavaScriptCompiler.prototype.endBlock = function () {
            var template = this.template,
                blocks = this.blocks;

            var block = blocks.pop();
            template.block.blocks.push(block.toJSON());
        };

        JavaScriptCompiler.prototype.startProgram = function () {
            this.blocks.push(this.template.block);
        };

        JavaScriptCompiler.prototype.endProgram = function () {};

        JavaScriptCompiler.prototype.text = function (content) {
            this.push([_wireFormat.Ops.Text, content]);
        };

        JavaScriptCompiler.prototype.append = function (trusted) {
            this.push([_wireFormat.Ops.Append, this.popValue(), trusted]);
        };

        JavaScriptCompiler.prototype.comment = function (value) {
            this.push([_wireFormat.Ops.Comment, value]);
        };

        JavaScriptCompiler.prototype.modifier = function (name) {
            var params = this.popValue();
            var hash = this.popValue();
            this.push([_wireFormat.Ops.Modifier, name, params, hash]);
        };

        JavaScriptCompiler.prototype.block = function (_ref) {
            var name = _ref[0],
                template = _ref[1],
                inverse = _ref[2];

            var params = this.popValue();
            var hash = this.popValue();
            var blocks = this.template.block.blocks;

            this.push([_wireFormat.Ops.Block, name, params, hash, blocks[template], blocks[inverse]]);
        };

        JavaScriptCompiler.prototype.openComponent = function (element) {
            var tag = this.options && this.options.customizeComponentName ? this.options.customizeComponentName(element.tag) : element.tag;
            var component = new ComponentBlock(tag, element['symbols'], element.selfClosing);
            this.blocks.push(component);
        };

        JavaScriptCompiler.prototype.openSplattedElement = function (element) {
            var tag = element.tag;
            if (element.blockParams.length > 0) {
                throw new Error('Compile Error: <' + element.tag + '> is not a component and doesn\'t support block parameters');
            } else {
                this.push([_wireFormat.Ops.OpenSplattedElement, tag]);
            }
        };

        JavaScriptCompiler.prototype.openElement = function (element) {
            var tag = element.tag;
            if (element.blockParams.length > 0) {
                throw new Error('Compile Error: <' + element.tag + '> is not a component and doesn\'t support block parameters');
            } else {
                this.push([_wireFormat.Ops.OpenElement, tag]);
            }
        };

        JavaScriptCompiler.prototype.flushElement = function () {
            this.push([_wireFormat.Ops.FlushElement]);
        };

        JavaScriptCompiler.prototype.closeComponent = function () {
            var _endComponent = this.endComponent(),
                tag = _endComponent[0],
                attrs = _endComponent[1],
                args = _endComponent[2],
                block = _endComponent[3];

            this.push([_wireFormat.Ops.Component, tag, attrs, args, block]);
        };

        JavaScriptCompiler.prototype.closeDynamicComponent = function () {
            var _endComponent2 = this.endComponent(),
                attrs = _endComponent2[1],
                args = _endComponent2[2],
                block = _endComponent2[3];

            this.push([_wireFormat.Ops.DynamicComponent, this.popValue(), attrs, args, block]);
        };

        JavaScriptCompiler.prototype.closeElement = function () {
            this.push([_wireFormat.Ops.CloseElement]);
        };

        JavaScriptCompiler.prototype.staticAttr = function (_ref2) {
            var name = _ref2[0],
                namespace = _ref2[1];

            var value = this.popValue();
            this.push([_wireFormat.Ops.StaticAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.dynamicAttr = function (_ref3) {
            var name = _ref3[0],
                namespace = _ref3[1];

            var value = this.popValue();
            this.push([_wireFormat.Ops.DynamicAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.trustingAttr = function (_ref4) {
            var name = _ref4[0],
                namespace = _ref4[1];

            var value = this.popValue();
            this.push([_wireFormat.Ops.TrustingAttr, name, value, namespace]);
        };

        JavaScriptCompiler.prototype.staticArg = function (name) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.StaticArg, name, value]);
        };

        JavaScriptCompiler.prototype.dynamicArg = function (name) {
            var value = this.popValue();
            this.push([_wireFormat.Ops.DynamicArg, name, value]);
        };

        JavaScriptCompiler.prototype.yield = function (to) {
            var params = this.popValue();
            this.push([_wireFormat.Ops.Yield, to, params]);
        };

        JavaScriptCompiler.prototype.attrSplat = function (to) {
            // consume (and disregard) the value pushed for the
            // ...attributes attribute
            this.popValue();
            this.push([_wireFormat.Ops.AttrSplat, to]);
        };

        JavaScriptCompiler.prototype.debugger = function (evalInfo) {
            this.push([_wireFormat.Ops.Debugger, evalInfo]);
            this.template.block.hasEval = true;
        };

        JavaScriptCompiler.prototype.hasBlock = function (name) {
            this.pushValue([_wireFormat.Ops.HasBlock, name]);
        };

        JavaScriptCompiler.prototype.hasBlockParams = function (name) {
            this.pushValue([_wireFormat.Ops.HasBlockParams, name]);
        };

        JavaScriptCompiler.prototype.partial = function (evalInfo) {
            var params = this.popValue();
            this.push([_wireFormat.Ops.Partial, params[0], evalInfo]);
            this.template.block.hasEval = true;
        };

        JavaScriptCompiler.prototype.literal = function (value) {
            if (value === undefined) {
                this.pushValue([_wireFormat.Ops.Undefined]);
            } else {
                this.pushValue(value);
            }
        };

        JavaScriptCompiler.prototype.unknown = function (name) {
            this.pushValue([_wireFormat.Ops.Unknown, name]);
        };

        JavaScriptCompiler.prototype.get = function (_ref5) {
            var head = _ref5[0],
                path = _ref5[1];

            this.pushValue([_wireFormat.Ops.Get, head, path]);
        };

        JavaScriptCompiler.prototype.maybeLocal = function (path) {
            this.pushValue([_wireFormat.Ops.MaybeLocal, path]);
        };

        JavaScriptCompiler.prototype.concat = function () {
            this.pushValue([_wireFormat.Ops.Concat, this.popValue()]);
        };

        JavaScriptCompiler.prototype.helper = function (name) {
            var params = this.popValue();
            var hash = this.popValue();
            this.pushValue([_wireFormat.Ops.Helper, name, params, hash]);
        };

        JavaScriptCompiler.prototype.prepareArray = function (size) {
            var values = [],
                i;
            for (i = 0; i < size; i++) {
                values.push(this.popValue());
            }
            this.pushValue(values);
        };

        JavaScriptCompiler.prototype.prepareObject = function (size) {

            var keys = new Array(size),
                i;
            var values = new Array(size);
            for (i = 0; i < size; i++) {
                keys[i] = this.popValue();
                values[i] = this.popValue();
            }
            this.pushValue([keys, values]);
        };

        JavaScriptCompiler.prototype.endComponent = function () {
            var component = this.blocks.pop();

            return component.toJSON();
        };

        JavaScriptCompiler.prototype.push = function (args) {
            while (args[args.length - 1] === null) {
                args.pop();
            }
            this.currentBlock.push(args);
        };

        JavaScriptCompiler.prototype.pushValue = function (val) {
            this.values.push(val);
        };

        JavaScriptCompiler.prototype.popValue = function () {

            return this.values.pop();
        };

        (0, _emberBabel.createClass)(JavaScriptCompiler, [{
            key: 'currentBlock',
            get: function () {
                return this.blocks.current;
            }
        }]);
        return JavaScriptCompiler;
    }();

    // There is a small whitelist of namespaced attributes specially
    // enumerated in
    // https://www.w3.org/TR/html/syntax.html#attributes-0
    //
    // > When a foreign element has one of the namespaced attributes given by
    // > the local name and namespace of the first and second cells of a row
    // > from the following table, it must be written using the name given by
    // > the third cell from the same row.
    //
    // In all other cases, colons are interpreted as a regular character
    // with no special meaning:
    //
    // > No other namespaced attribute can be expressed in the HTML syntax.
    var XLINK = 'http://www.w3.org/1999/xlink';
    var XML = 'http://www.w3.org/XML/1998/namespace';
    var XMLNS = 'http://www.w3.org/2000/xmlns/';
    var WHITELIST = {
        'xlink:actuate': XLINK,
        'xlink:arcrole': XLINK,
        'xlink:href': XLINK,
        'xlink:role': XLINK,
        'xlink:show': XLINK,
        'xlink:title': XLINK,
        'xlink:type': XLINK,
        'xml:base': XML,
        'xml:lang': XML,
        'xml:space': XML,
        xmlns: XMLNS,
        'xmlns:xlink': XMLNS
    };
    function getAttrNamespace(attrName) {
        return WHITELIST[attrName] || null;
    }

    var SymbolAllocator = function () {
        function SymbolAllocator(ops) {

            this.ops = ops;
            this.symbolStack = new _util.Stack();
        }

        SymbolAllocator.prototype.process = function () {
            var out = [],
                i,
                op,
                result;
            var ops = this.ops;

            for (i = 0; i < ops.length; i++) {
                op = ops[i];
                result = this.dispatch(op);

                if (result === undefined) {
                    out.push(op);
                } else {
                    out.push(result);
                }
            }
            return out;
        };

        SymbolAllocator.prototype.dispatch = function (op) {
            var name = op[0];
            var operand = op[1];
            return this[name](operand);
        };

        SymbolAllocator.prototype.startProgram = function (op) {
            this.symbolStack.push(op['symbols']);
        };

        SymbolAllocator.prototype.endProgram = function () {
            this.symbolStack.pop();
        };

        SymbolAllocator.prototype.startBlock = function (op) {
            this.symbolStack.push(op['symbols']);
        };

        SymbolAllocator.prototype.endBlock = function () {
            this.symbolStack.pop();
        };

        SymbolAllocator.prototype.flushElement = function (op) {
            this.symbolStack.push(op['symbols']);
        };

        SymbolAllocator.prototype.closeElement = function () {
            this.symbolStack.pop();
        };

        SymbolAllocator.prototype.closeComponent = function () {
            this.symbolStack.pop();
        };

        SymbolAllocator.prototype.closeDynamicComponent = function () {
            this.symbolStack.pop();
        };

        SymbolAllocator.prototype.attrSplat = function () {
            return ['attrSplat', this.symbols.allocateBlock('attrs')];
        };

        SymbolAllocator.prototype.get = function (op) {
            var name = op[0],
                rest = op[1],
                head,
                _head;

            if (name === 0) {
                return ['get', [0, rest]];
            }
            if (isLocal(name, this.symbols)) {
                head = this.symbols.get(name);

                return ['get', [head, rest]];
            } else if (name[0] === '@') {
                _head = this.symbols.allocateNamed(name);

                return ['get', [_head, rest]];
            } else {
                return ['maybeLocal', [name].concat(rest)];
            }
        };

        SymbolAllocator.prototype.maybeGet = function (op) {
            var name = op[0],
                rest = op[1],
                head,
                _head2;

            if (name === 0) {
                return ['get', [0, rest]];
            }
            if (isLocal(name, this.symbols)) {
                head = this.symbols.get(name);

                return ['get', [head, rest]];
            } else if (name[0] === '@') {
                _head2 = this.symbols.allocateNamed(name);

                return ['get', [_head2, rest]];
            } else if (rest.length === 0) {
                return ['unknown', name];
            } else {
                return ['maybeLocal', [name].concat(rest)];
            }
        };

        SymbolAllocator.prototype.yield = function (op) {
            if (op === 0) {
                throw new Error('Cannot yield to this');
            }
            return ['yield', this.symbols.allocateBlock(op)];
        };

        SymbolAllocator.prototype.debugger = function () {
            return ['debugger', this.symbols.getEvalInfo()];
        };

        SymbolAllocator.prototype.hasBlock = function (op) {
            if (op === 0) {
                throw new Error('Cannot hasBlock this');
            }
            return ['hasBlock', this.symbols.allocateBlock(op)];
        };

        SymbolAllocator.prototype.hasBlockParams = function (op) {
            if (op === 0) {
                throw new Error('Cannot hasBlockParams this');
            }
            return ['hasBlockParams', this.symbols.allocateBlock(op)];
        };

        SymbolAllocator.prototype.partial = function () {
            return ['partial', this.symbols.getEvalInfo()];
        };

        SymbolAllocator.prototype.text = function () {};

        SymbolAllocator.prototype.comment = function () {};

        SymbolAllocator.prototype.openComponent = function () {};

        SymbolAllocator.prototype.openElement = function () {};

        SymbolAllocator.prototype.openSplattedElement = function () {};

        SymbolAllocator.prototype.staticArg = function () {};

        SymbolAllocator.prototype.dynamicArg = function () {};

        SymbolAllocator.prototype.staticAttr = function () {};

        SymbolAllocator.prototype.trustingAttr = function () {};

        SymbolAllocator.prototype.dynamicAttr = function () {};

        SymbolAllocator.prototype.modifier = function () {};

        SymbolAllocator.prototype.append = function () {};

        SymbolAllocator.prototype.block = function () {};

        SymbolAllocator.prototype.literal = function () {};

        SymbolAllocator.prototype.helper = function () {};

        SymbolAllocator.prototype.unknown = function () {};

        SymbolAllocator.prototype.maybeLocal = function () {};

        SymbolAllocator.prototype.prepareArray = function () {};

        SymbolAllocator.prototype.prepareObject = function () {};

        SymbolAllocator.prototype.concat = function () {};

        (0, _emberBabel.createClass)(SymbolAllocator, [{
            key: 'symbols',
            get: function () {
                return this.symbolStack.current;
            }
        }]);
        return SymbolAllocator;
    }();

    function isLocal(name, symbols) {
        return symbols && symbols.has(name);
    }

    function isTrustedValue(value) {
        return value.escaped !== undefined && !value.escaped;
    }

    var TemplateCompiler = function () {
        function TemplateCompiler() {

            this.templateId = 0;
            this.templateIds = [];
            this.opcodes = [];
            this.includeMeta = false;
        }

        TemplateCompiler.compile = function (ast, options) {
            var templateVisitor = new TemplateVisitor();
            templateVisitor.visit(ast);
            var compiler = new TemplateCompiler();
            var opcodes = compiler.process(templateVisitor.actions);
            var symbols = new SymbolAllocator(opcodes).process();
            return JavaScriptCompiler.process(symbols, ast['symbols'], options);
        };

        TemplateCompiler.prototype.process = function (actions) {
            var _this9 = this;

            actions.forEach(function (_ref6) {
                var name = _ref6[0],
                    args = _ref6.slice(1);

                if (!_this9[name]) {
                    throw new Error('Unimplemented ' + name + ' on TemplateCompiler');
                }
                _this9[name].apply(_this9, args);
            });
            return this.opcodes;
        };

        TemplateCompiler.prototype.startProgram = function (_ref7) {
            var program = _ref7[0];

            this.opcode(['startProgram', program], program);
        };

        TemplateCompiler.prototype.endProgram = function () {
            this.opcode(['endProgram', null], null);
        };

        TemplateCompiler.prototype.startBlock = function (_ref8) {
            var program = _ref8[0];

            this.templateId++;
            this.opcode(['startBlock', program], program);
        };

        TemplateCompiler.prototype.endBlock = function () {
            this.templateIds.push(this.templateId - 1);
            this.opcode(['endBlock', null], null);
        };

        TemplateCompiler.prototype.text = function (_ref9) {
            var action = _ref9[0];

            this.opcode(['text', action.chars], action);
        };

        TemplateCompiler.prototype.comment = function (_ref10) {
            var action = _ref10[0];

            this.opcode(['comment', action.value], action);
        };

        TemplateCompiler.prototype.openElement = function (_ref11) {
            var action = _ref11[0],
                i,
                attr,
                head,
                rest,
                _action$tag$split,
                _i2,
                _i3;

            var attributes = action.attributes;
            var hasSplat = void 0;
            for (i = 0; i < attributes.length; i++) {
                attr = attributes[i];

                if (attr.name === '...attributes') {
                    hasSplat = attr;
                    break;
                }
            }
            if (isDynamicComponent(action)) {
                head = void 0, rest = void 0;
                _action$tag$split = action.tag.split('.');


                head = _action$tag$split[0];
                rest = _action$tag$split.slice(1);

                if (head === 'this') {
                    head = 0;
                }
                this.opcode(['get', [head, rest]]);
                this.opcode(['openComponent', action], action);
            } else if (isComponent(action)) {
                this.opcode(['openComponent', action], action);
            } else if (hasSplat) {
                this.opcode(['openSplattedElement', action], action);
            } else {
                this.opcode(['openElement', action], action);
            }
            var typeAttr = null;
            var attrs = action.attributes;
            for (_i2 = 0; _i2 < attrs.length; _i2++) {
                if (attrs[_i2].name === 'type') {
                    typeAttr = attrs[_i2];
                    continue;
                }
                this.attribute([attrs[_i2]]);
            }
            if (typeAttr) {
                this.attribute([typeAttr]);
            }
            for (_i3 = 0; _i3 < action.modifiers.length; _i3++) {
                this.modifier([action.modifiers[_i3]]);
            }
            this.opcode(['flushElement', action], null);
        };

        TemplateCompiler.prototype.closeElement = function (_ref12) {
            var action = _ref12[0];

            if (isDynamicComponent(action)) {
                this.opcode(['closeDynamicComponent', action], action);
            } else if (isComponent(action)) {
                this.opcode(['closeComponent', action], action);
            } else {
                this.opcode(['closeElement', action], action);
            }
        };

        TemplateCompiler.prototype.attribute = function (_ref13) {
            var action = _ref13[0],
                isTrusting;
            var name = action.name,
                value = action.value;

            var namespace = getAttrNamespace(name);
            var isStatic = this.prepareAttributeValue(value);
            if (name.charAt(0) === '@') {
                // Arguments
                if (isStatic) {
                    this.opcode(['staticArg', name], action);
                } else if (action.value.type === 'MustacheStatement') {
                    this.opcode(['dynamicArg', name], action);
                } else {
                    this.opcode(['dynamicArg', name], action);
                }
            } else {
                isTrusting = isTrustedValue(value);

                if (isStatic && name === '...attributes') {
                    this.opcode(['attrSplat', null], action);
                } else if (isStatic) {
                    this.opcode(['staticAttr', [name, namespace]], action);
                } else if (isTrusting) {
                    this.opcode(['trustingAttr', [name, namespace]], action);
                } else if (action.value.type === 'MustacheStatement') {
                    this.opcode(['dynamicAttr', [name, null]], action);
                } else {
                    this.opcode(['dynamicAttr', [name, namespace]], action);
                }
            }
        };

        TemplateCompiler.prototype.modifier = function (_ref14) {
            var action = _ref14[0];

            assertIsSimplePath(action.path, action.loc, 'modifier');
            var parts = action.path.parts;

            this.prepareHelper(action);
            this.opcode(['modifier', parts[0]], action);
        };

        TemplateCompiler.prototype.mustache = function (_ref15) {
            var action = _ref15[0],
                to,
                params;
            var path = action.path;

            if ((0, _syntax.isLiteral)(path)) {
                this.mustacheExpression(action);
                this.opcode(['append', !action.escaped], action);
            } else if (isYield(path)) {
                to = assertValidYield(action);

                this.yield(to, action);
            } else if (isPartial(path)) {
                params = assertValidPartial(action);

                this.partial(params, action);
            } else if (isDebugger(path)) {
                assertValidDebuggerUsage(action);
                this.debugger('debugger', action);
            } else {
                this.mustacheExpression(action);
                this.opcode(['append', !action.escaped], action);
            }
        };

        TemplateCompiler.prototype.block = function (_ref16) {
            var action /*, index, count*/ = _ref16[0];

            this.prepareHelper(action);
            var templateId = this.templateIds.pop();
            var inverseId = action.inverse === null ? null : this.templateIds.pop();
            this.opcode(['block', [action.path.parts[0], templateId, inverseId]], action);
        };

        TemplateCompiler.prototype.arg = function (_ref17) {
            var path = _ref17[0];

            var _path$parts = path.parts,
                head = _path$parts[0],
                rest = _path$parts.slice(1);

            this.opcode(['get', ['@' + head, rest]], path);
        };

        TemplateCompiler.prototype.mustacheExpression = function (expr) {
            var path = expr.path,
                _path$parts2,
                head,
                parts;

            if ((0, _syntax.isLiteral)(path)) {
                this.opcode(['literal', path.value], expr);
            } else if (isBuiltInHelper(path)) {
                this.builtInHelper(expr);
            } else if (isArg(path)) {
                this.arg([path]);
            } else if (isHelperInvocation(expr)) {
                this.prepareHelper(expr);
                this.opcode(['helper', path.parts[0]], expr);
            } else if (path.this) {
                this.opcode(['get', [0, path.parts]], expr);
            } else {
                _path$parts2 = path.parts, head = _path$parts2[0], parts = _path$parts2.slice(1);


                this.opcode(['maybeGet', [head, parts]], expr);
            }
            // } else if (isLocal(path, this.symbols)) {
            //   let [head, ...parts] = path.parts;
            //   this.opcode(['get', [head, parts]], expr);
            // } else if (isSimplePath(path)) {
            //   this.opcode(['unknown', path.parts[0]], expr);
            // } else {
            //   this.opcode(['maybeLocal', path.parts], expr);
            // }
        };

        TemplateCompiler.prototype.yield = function (to, action) {
            this.prepareParams(action.params);
            this.opcode(['yield', to], action);
        };

        TemplateCompiler.prototype.debugger = function (_name, action) {
            this.opcode(['debugger', null], action);
        };

        TemplateCompiler.prototype.hasBlock = function (name, action) {
            this.opcode(['hasBlock', name], action);
        };

        TemplateCompiler.prototype.hasBlockParams = function (name, action) {
            this.opcode(['hasBlockParams', name], action);
        };

        TemplateCompiler.prototype.partial = function (_params, action) {
            this.prepareParams(action.params);
            this.opcode(['partial', null], action);
        };

        TemplateCompiler.prototype.builtInHelper = function (expr) {
            var path = expr.path,
                name,
                _name2;

            if (isHasBlock(path)) {
                name = assertValidHasBlockUsage(expr.path.original, expr);

                this.hasBlock(name, expr);
            } else if (isHasBlockParams(path)) {
                _name2 = assertValidHasBlockUsage(expr.path.original, expr);

                this.hasBlockParams(_name2, expr);
            }
        };

        TemplateCompiler.prototype.SubExpression = function (expr) {
            if (isBuiltInHelper(expr.path)) {
                this.builtInHelper(expr);
            } else {
                this.prepareHelper(expr);
                this.opcode(['helper', expr.path.parts[0]], expr);
            }
        };

        TemplateCompiler.prototype.PathExpression = function (expr) {
            var _expr$parts, head, rest;

            if (expr.data) {
                this.arg([expr]);
            } else {
                _expr$parts = expr.parts, head = _expr$parts[0], rest = _expr$parts.slice(1);


                if (expr.this) {
                    this.opcode(['get', [0, expr.parts]], expr);
                } else {
                    this.opcode(['get', [head, rest]], expr);
                }
            }
        };

        TemplateCompiler.prototype.StringLiteral = function (action) {
            this.opcode(['literal', action.value], action);
        };

        TemplateCompiler.prototype.BooleanLiteral = function (action) {
            this.opcode(['literal', action.value], action);
        };

        TemplateCompiler.prototype.NumberLiteral = function (action) {
            this.opcode(['literal', action.value], action);
        };

        TemplateCompiler.prototype.NullLiteral = function (action) {
            this.opcode(['literal', action.value], action);
        };

        TemplateCompiler.prototype.UndefinedLiteral = function (action) {
            this.opcode(['literal', action.value], action);
        };

        TemplateCompiler.prototype.opcode = function (_opcode) {
            var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

            // TODO: This doesn't really work
            if (this.includeMeta && action) {
                _opcode.push(this.meta(action));
            }
            this.opcodes.push(_opcode);
        };

        TemplateCompiler.prototype.prepareHelper = function (expr) {
            assertIsSimplePath(expr.path, expr.loc, 'helper');
            var params = expr.params,
                hash = expr.hash;

            this.prepareHash(hash);
            this.prepareParams(params);
        };

        TemplateCompiler.prototype.prepareParams = function (params) {
            var i, param;

            if (!params.length) {
                this.opcode(['literal', null], null);
                return;
            }
            for (i = params.length - 1; i >= 0; i--) {
                param = params[i];


                this[param.type](param);
            }
            this.opcode(['prepareArray', params.length], null);
        };

        TemplateCompiler.prototype.prepareHash = function (hash) {
            var pairs = hash.pairs,
                i,
                _pairs$i,
                key,
                value;
            if (!pairs.length) {
                this.opcode(['literal', null], null);
                return;
            }
            for (i = pairs.length - 1; i >= 0; i--) {
                _pairs$i = pairs[i], key = _pairs$i.key, value = _pairs$i.value;


                this[value.type](value);
                this.opcode(['literal', key], null);
            }
            this.opcode(['prepareObject', pairs.length], null);
        };

        TemplateCompiler.prototype.prepareAttributeValue = function (value) {
            // returns the static value if the value is static
            switch (value.type) {
                case 'TextNode':
                    this.opcode(['literal', value.chars], value);
                    return true;
                case 'MustacheStatement':
                    this.attributeMustache([value]);
                    return false;
                case 'ConcatStatement':
                    this.prepareConcatParts(value.parts);
                    this.opcode(['concat', null], value);
                    return false;
            }
        };

        TemplateCompiler.prototype.prepareConcatParts = function (parts) {
            var i, part;

            for (i = parts.length - 1; i >= 0; i--) {
                part = parts[i];

                if (part.type === 'MustacheStatement') {
                    this.attributeMustache([part]);
                } else if (part.type === 'TextNode') {
                    this.opcode(['literal', part.chars], null);
                }
            }
            this.opcode(['prepareArray', parts.length], null);
        };

        TemplateCompiler.prototype.attributeMustache = function (_ref18) {
            var action = _ref18[0];

            this.mustacheExpression(action);
        };

        TemplateCompiler.prototype.meta = function (node) {
            var loc = node.loc;
            if (!loc) {
                return [];
            }
            var source = loc.source,
                start = loc.start,
                end = loc.end;

            return ['loc', [source || null, [start.line, start.column], [end.line, end.column]]];
        };

        return TemplateCompiler;
    }();

    function isHelperInvocation(mustache) {
        return mustache.params && mustache.params.length > 0 || mustache.hash && mustache.hash.pairs.length > 0;
    }
    function isSimplePath(_ref19) {
        var parts = _ref19.parts;

        return parts.length === 1;
    }
    function isYield(path) {
        return path.original === 'yield';
    }
    function isPartial(path) {
        return path.original === 'partial';
    }
    function isDebugger(path) {
        return path.original === 'debugger';
    }
    function isHasBlock(path) {
        return path.original === 'has-block';
    }
    function isHasBlockParams(path) {
        return path.original === 'has-block-params';
    }
    function isBuiltInHelper(path) {
        return isHasBlock(path) || isHasBlockParams(path);
    }
    function isArg(path) {
        return !!path['data'];
    }
    function isDynamicComponent(element) {
        var open = element.tag.charAt(0);

        var _element$tag$split = element.tag.split('.'),
            maybeLocal = _element$tag$split[0];

        var isLocal = element['symbols'].has(maybeLocal);
        var isThisPath = element.tag.indexOf('this.') === 0;
        return isLocal || open === '@' || isThisPath;
    }
    function isComponent(element) {
        var open = element.tag.charAt(0);
        var isPath = element.tag.indexOf('.') > -1;
        var isUpperCase = open === open.toUpperCase() && open !== open.toLowerCase();
        return isUpperCase && !isPath || isDynamicComponent(element);
    }
    function assertIsSimplePath(path, loc, context) {
        if (!isSimplePath(path)) {
            throw new _syntax.SyntaxError('`' + path.original + '` is not a valid name for a ' + context + ' on line ' + loc.start.line + '.', path.loc);
        }
    }
    function assertValidYield(statement) {
        var pairs = statement.hash.pairs;

        if (pairs.length === 1 && pairs[0].key !== 'to' || pairs.length > 1) {
            throw new _syntax.SyntaxError('yield only takes a single named argument: \'to\'', statement.loc);
        } else if (pairs.length === 1 && pairs[0].value.type !== 'StringLiteral') {
            throw new _syntax.SyntaxError('you can only yield to a literal value', statement.loc);
        } else if (pairs.length === 0) {
            return 'default';
        } else {
            return pairs[0].value.value;
        }
    }
    function assertValidPartial(statement) {
        var params = statement.params,
            hash = statement.hash,
            escaped = statement.escaped,
            loc = statement.loc;

        if (params && params.length !== 1) {
            throw new _syntax.SyntaxError('Partial found with no arguments. You must specify a template name. (on line ' + loc.start.line + ')', statement.loc);
        } else if (hash && hash.pairs.length > 0) {
            throw new _syntax.SyntaxError('partial does not take any named arguments (on line ' + loc.start.line + ')', statement.loc);
        } else if (!escaped) {
            throw new _syntax.SyntaxError('{{{partial ...}}} is not supported, please use {{partial ...}} instead (on line ' + loc.start.line + ')', statement.loc);
        }
        return params;
    }
    function assertValidHasBlockUsage(type, call) {
        var params = call.params,
            hash = call.hash,
            loc = call.loc,
            param;

        if (hash && hash.pairs.length > 0) {
            throw new _syntax.SyntaxError(type + ' does not take any named arguments', call.loc);
        }
        if (params.length === 0) {
            return 'default';
        } else if (params.length === 1) {
            param = params[0];

            if (param.type === 'StringLiteral') {
                return param.value;
            } else {
                throw new _syntax.SyntaxError('you can only yield to a literal value (on line ' + loc.start.line + ')', call.loc);
            }
        } else {
            throw new _syntax.SyntaxError(type + ' only takes a single positional argument (on line ' + loc.start.line + ')', call.loc);
        }
    }
    function assertValidDebuggerUsage(statement) {
        var params = statement.params,
            hash = statement.hash;

        if (hash && hash.pairs.length > 0) {
            throw new _syntax.SyntaxError('debugger does not take any named arguments', statement.loc);
        }
        if (params.length === 0) {
            return 'default';
        } else {
            throw new _syntax.SyntaxError('debugger does not take any positional arguments', statement.loc);
        }
    }

    var defaultId = function () {
        var crypto, idFn;

        if (typeof _nodeModule.require === 'function') {
            try {
                /* tslint:disable:no-require-imports */
                crypto = (0, _nodeModule.require)('crypto');
                /* tslint:enable:no-require-imports */

                idFn = function (src) {
                    var hash = crypto.createHash('sha1');
                    hash.update(src, 'utf8');
                    // trim to 6 bytes of data (2^48 - 1)
                    return hash.digest('base64').substring(0, 8);
                };

                idFn('test');
                return idFn;
            } catch (e) {}
        }
        return function () {
            return null;
        };
    }();
    var defaultOptions = {
        id: defaultId,
        meta: {}
    };


    exports.defaultId = defaultId;
    exports.precompile = function (string) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultOptions;

        var ast = (0, _syntax.preprocess)(string, options);
        var meta = options.meta;

        var _TemplateCompiler$com = TemplateCompiler.compile(ast, options),
            block = _TemplateCompiler$com.block;

        var idFn = options.id || defaultId;
        var blockJSON = JSON.stringify(block.toJSON());
        var templateJSONObject = {
            id: idFn(JSON.stringify(meta) + blockJSON),
            block: blockJSON,
            meta: meta
        };
        // JSON is javascript
        return JSON.stringify(templateJSONObject);
    };
    exports.TemplateCompiler = TemplateCompiler;
    exports.TemplateVisitor = TemplateVisitor;
});