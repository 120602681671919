define('ember-math-helpers/helpers/pow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pow = pow;


  /**
   * Takes two or more numbers, using the first as the base number,
   * then using each subsequent number as an exponent to the previous value
   *
   * ```hbs
   * {{pow a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.pow`
   * @return {number} The base raised to all exponents
   */
  function pow(numbers) {
    return numbers.reduce(function (base, exponent) {
      return Math.pow(base, exponent);
    });
  }

  exports.default = Ember.Helper.helper(pow);
});