define('shared/components/form-members/component', ['exports', 'shared/components/form-members/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    access: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    editing: false,
    errors: null,
    memberArray: null,
    memberConfig: null,
    model: null,
    primaryResource: null,
    roles: null,
    type: null,
    users: null,
    creator: null,
    showCreator: true,
    toAddCustom: null,
    _bindings: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.buildUpdateList(Ember.get(this, 'primaryResource'));
      this.sendAction('registerHook', this.saveMembers.bind(this), 'saveMembers');
    },


    actions: {
      cancel: function cancel() {
        this.goBack();
      },
      addMember: function addMember() /* kind */{
        var out = {
          principalId: null,
          bindings: []
        };

        Ember.get(this, 'memberArray').pushObject(out);
      },
      removeMember: function removeMember(obj) {
        Ember.set(obj, 'pendingDelete', true);
        Ember.set(obj, 'bindings', []);
      }
    },

    defaultRoles: Ember.computed('roles.[]', function () {
      return Ember.get(this, 'roles').filterBy(Ember.get(this, 'type') + 'CreatorDefault');
    }),

    filteredRoles: Ember.computed('roles.[]', function () {
      var pt = Ember.get(this, 'type');

      return Ember.get(this, 'roles').filterBy('hidden', false).filter(function (r) {
        return Ember.get(r, 'context') === pt || Ember.get(r, 'context') === '' || !Ember.get(r, 'context');
      }).sortBy('displayName');
    }),

    filteredUsers: Ember.computed('users.@each.{id,state}', function () {
      return Ember.get(this, 'users').sortBy('displayName');
    }),

    buildUpdateList: function buildUpdateList(resource) {
      var bindings = Ember.set(this, 'currentBindings', Ember.get(resource, 'roleTemplateBindings'));

      if (Ember.get(bindings, 'length') >= 1) {
        // editing
        var mOut = [];

        Ember.set(this, 'memberArray', bindings.filter(function (b) {
          return !Ember.get(b, 'serviceAccount');
        }).forEach(function (b) {
          var match = mOut.findBy('principalId', Ember.get(b, 'principalIdReference'));

          if (match) {
            match.bindings.push(Ember.get(b, 'roleTemplateId'));
            match.preEditBindings.push({
              roleTemplateId: Ember.get(b, 'roleTemplateId'),
              id: Ember.get(b, 'id')
            });
          } else {
            match = {
              principalId: Ember.get(b, 'principalIdReference'),
              bindings: [Ember.get(b, 'roleTemplateId')],
              preEditBindings: [{
                roleTemplateId: Ember.get(b, 'roleTemplateId'),
                id: Ember.get(b, 'id')
              }],
              isCustom: Ember.get(b, 'isCustom')
            };

            mOut.push(match);
          }
        }));

        Ember.set(this, 'memberArray', mOut);
      } else {
        Ember.set(this, 'memberArray', []);
      }
    },
    createRole: function createRole() {
      var inConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var config = Ember.$().extend(true, {}, Ember.get(this, 'memberConfig'), inConfig);
      var type = Ember.get(this, 'primaryResource.type');

      // set(config, 'subjectKind', 'User');
      Ember.set(config, type + 'Id', Ember.get(this, 'primaryResource.id'));

      return Ember.get(this, 'globalStore').createRecord(config);
    },
    saveMembers: function saveMembers() {
      var _this = this;

      var memberArray = Ember.get(this, 'memberArray');
      var currentBindings = Ember.get(this, 'currentBindings');

      var add = [];
      var remove = [];
      var update = [];

      memberArray.forEach(function (member) {
        var bindings = Ember.get(member, 'bindings');
        var preEditBindings = Ember.get(member, 'preEditBindings') || [];

        if (bindings.length > 0) {
          // update
          if (bindings.length === 1 && Ember.get(member, 'preEditBindings.length') === 1) {
            var toUpdate = Ember.get(preEditBindings, 'firstObject');
            var neuRT = Ember.get(bindings, 'firstObject');
            var match = Ember.get(_this, 'currentBindings').findBy('id', Ember.get(toUpdate, 'id'));

            if (match && Ember.get(match, 'roleTemplateId') !== neuRT) {
              Ember.set(match, 'roleTemplateId', neuRT);
              update.push(match);
            }
          } else {
            // bindings to add
            bindings.forEach(function (memberBinding) {
              if (!preEditBindings.findBy('roleTemplateId', memberBinding)) {
                if (Ember.get(member, 'principalId')) {
                  var principalId = Ember.get(member, 'principalId');
                  var type = Ember.get(member, 'memberType');

                  if (type === 'group') {
                    add.push(_this.createRole({
                      groupPrincipalId: principalId,
                      roleTemplateId: memberBinding,
                      subjectKind: 'Group'
                    }));
                  } else {
                    add.push(_this.createRole({
                      userPrincipalId: principalId,
                      roleTemplateId: memberBinding,
                      subjectKind: 'User'
                    }));
                  }
                }
              }
            });

            preEditBindings.forEach(function (peBinding) {
              if (!bindings.includes(Ember.get(peBinding, 'roleTemplateId'))) {
                remove.push(currentBindings.findBy('id', Ember.get(peBinding, 'id')));
              }
            });
          }
        } else {
          // remove
          preEditBindings.forEach(function (peBinding) {
            remove.push(currentBindings.findBy('id', Ember.get(peBinding, 'id')));
          });
        }
      });

      if (Ember.get(this, 'isNew') || this.useCustomizedOwner() || this.hasOwner()) {
        return Ember.RSVP.all(add.map(function (x) {
          return x.save();
        })).then(function () {
          return Ember.RSVP.all(update.map(function (x) {
            return x.save();
          })).then(function () {
            return Ember.RSVP.all(remove.map(function (x) {
              return x.delete();
            })).then(function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }

              Ember.set(_this, 'memberArray', []);

              return Ember.get(_this, 'primaryResource');
            });
          });
        });
      } else {
        this.set('errors', [Ember.get(this, 'intl').t('formMembers.members.errors.ownerReq')]);

        return Ember.RSVP.reject();
      }
    },
    useCustomizedOwner: function useCustomizedOwner() {
      var roles = Ember.get(this, 'roles') || [];
      var context = Ember.get(this, 'primaryResource.type');
      var ownerRole = roles.findBy('id', context + '-owner');
      var defaultRoles = roles.filterBy(context + 'CreatorDefault', true);

      if (ownerRole && !Ember.get(ownerRole, 'locked') && Ember.get(ownerRole, context + 'CreatorDefault') && Ember.get(defaultRoles, 'length') === 1) {
        return false;
      } else {
        return true;
      }
    },
    hasOwner: function hasOwner() {
      var _this2 = this;

      var memberArray = Ember.get(this, 'memberArray');
      var matches = [];
      var okay = true;

      // find the matching bindings in the memebrsArray.bindings property, these will be the active bindings. when i binding is removed its also dropped out fo this array
      matches = memberArray.filter(function (member) {
        return Ember.get(member, 'bindings').includes(Ember.get(_this2, 'primaryResource.type') + '-owner');
      });

      if (matches.length <= 0) {
        okay = false;
      }

      return okay;
    }
  });
});