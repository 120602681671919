define('shared/components/form-members-global-access/component', ['exports', 'shared/components/form-members-global-access/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MEMBERS_HEADERS = [{
    translationKey: 'newMultiClusterApp.members.table.name',
    name: 'name',
    sort: ['userPrincipalId', 'groupPrincipalId']
  }, {
    translationKey: 'newMultiClusterApp.members.table.type',
    name: 'type',
    sort: ['displayType']
  }, {
    translationKey: 'newMultiClusterApp.members.table.accessType',
    name: 'accessType',
    sort: ['accessType']
  }];

  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),

    layout: _template.default,

    membersHeaders: MEMBERS_HEADERS,
    sortBy: '',
    descending: false,
    ownerOnly: false,
    resource: null,
    gotError: null,
    removeMember: null,
    optionsForAccessType: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.ownerOnly) {
        this.initOptionsForMembersAccessType();
      }
    },


    actions: {
      addPrincipal: function addPrincipal(principal) {
        if (principal) {
          var principalType = principal.principalType,
              id = principal.id;


          var nue = {
            type: 'member',
            accessType: null,
            displayType: Ember.get(principal, 'displayType') || principalType,
            displayName: Ember.get(principal, 'displayName') || Ember.get(principal, 'loginName') || Ember.get(principalType, 'id')
          };

          if (principalType === 'group') {
            Ember.set(nue, 'groupPrincipalId', id);
          } else if (principalType === 'user') {
            Ember.set(nue, 'userPrincipalId', id);
          }

          this.addAuthorizedPrincipal(nue);
        }
      }
    },

    addAuthorizedPrincipal: function addAuthorizedPrincipal() {
      throw new Error('add principal handler must be provided!!');
    },
    initOptionsForMembersAccessType: function initOptionsForMembersAccessType() {
      Ember.set(this, 'optionsForAccessType', this.globalStore.getById('schema', 'member').optionsFor('accessType') || []);

      return;
    }
  });
});