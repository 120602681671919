define('shared/components/cluster-driver/driver-import/component', ['exports', 'shared/mixins/cluster-driver', 'shared/components/cluster-driver/driver-import/template'], function (exports, _clusterDriver, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CLUSTER_ADMIN = 'kubectl create clusterrolebinding cluster-admin-binding --clusterrole cluster-admin --user [USER_ACCOUNT]';

  exports.default = Ember.Component.extend(_clusterDriver.default, {
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),

    layout: _template.default,
    configField: 'importedConfig',
    step: 1,
    loading: false,
    clusterAdmin: CLUSTER_ADMIN,

    isEdit: Ember.computed.equal('mode', 'edit'),
    clusterState: Ember.computed.alias('model.originalCluster.state'),

    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isEdit') && Ember.get(this, 'clusterState') === 'pending') {
        this.loadToken();
      }
    },


    clusterChanged: Ember.observer('originalCluster.state', function () {
      if (Ember.get(this, 'step') >= 2) {
        var state = Ember.get(this, 'originalCluster.state');

        if (!['pending', 'initializing', 'active'].includes(state)) {
          this.sendAction('close');
        }
      }
    }),

    doneSaving: function doneSaving() {
      if (Ember.get(this, 'isEdit')) {
        this.sendAction('close');
      } else {
        return this.loadToken();
      }
    },
    loadToken: function loadToken() {
      var _this = this;

      var cluster = Ember.get(this, 'cluster');

      Ember.set(this, 'step', 2);
      Ember.set(this, 'loading', true);

      return cluster.getOrCreateToken().then(function (token) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        Ember.set(_this, 'token', token);
        Ember.set(_this, 'loading', false);
      }).catch(function (err) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        Ember.get(_this, 'growl').fromError('Error getting command', err);
        Ember.set(_this, 'loading', false);
      });
    }
  });
});