define('global-admin/catalog/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    catalog: Ember.inject.service(),

    togglingHelmIncubator: false,
    togglingHelmStable: false,
    togglingLibrary: false,

    actions: {
      disableLibrary: function disableLibrary() {
        var _this = this;

        if (Ember.get(this, 'togglingLibrary')) {
          return;
        }

        Ember.set(this, 'togglingLibrary', true);
        Ember.get(this, 'library').delete().catch(function (err) {
          Ember.get(_this, 'growl').fromError('Error removing Library', err);
        }).finally(function () {
          Ember.set(_this, 'togglingLibrary', false);
          _this.send('refresh');
        });
      },
      enableLibrary: function enableLibrary() {
        var _this2 = this;

        if (Ember.get(this, 'togglingLibrary')) {
          return;
        }

        Ember.set(this, 'togglingLibrary', true);
        Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          name: _constants.default.CATALOG.LIBRARY_KEY,
          url: _constants.default.CATALOG.LIBRARY_VALUE,
          branch: _constants.default.CATALOG.DEFAULT_BRANCH,
          kind: 'helm'
        }).save().catch(function (err) {
          Ember.get(_this2, 'growl').fromError('Error saving Library', err);
        }).finally(function () {
          Ember.set(_this2, 'togglingLibrary', false);
          _this2.send('refresh');
        });
      },
      disableHelmIncubator: function disableHelmIncubator() {
        var _this3 = this;

        if (Ember.get(this, 'togglingHelmIncubator')) {
          return;
        }

        Ember.set(this, 'togglingHelmIncubator', true);
        Ember.get(this, 'helmIncubator').delete().catch(function (err) {
          Ember.get(_this3, 'growl').fromError('Error removing Incubator', err);
        }).finally(function () {
          Ember.set(_this3, 'togglingHelmIncubator', false);
          _this3.send('refresh');
        });
      },
      enableHelmIncubator: function enableHelmIncubator() {
        var _this4 = this;

        if (Ember.get(this, 'togglingHelmIncubator')) {
          return;
        }

        Ember.set(this, 'togglingHelmIncubator', true);
        Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          name: _constants.default.CATALOG.HELM_INCUBATOR_KEY,
          url: _constants.default.CATALOG.HELM_INCUBATOR_VALUE,
          branch: _constants.default.CATALOG.DEFAULT_BRANCH,
          kind: 'helm'
        }).save().catch(function (err) {
          Ember.get(_this4, 'growl').fromError('Error saving Incubator', err);
        }).finally(function () {
          Ember.set(_this4, 'togglingHelmIncubator', false);
          _this4.send('refresh');
        });
      },
      disableHelmStable: function disableHelmStable() {
        var _this5 = this;

        if (Ember.get(this, 'togglingHelmStable')) {
          return;
        }

        Ember.set(this, 'togglingHelmStable', true);
        Ember.get(this, 'helmStable').delete().catch(function (err) {
          Ember.get(_this5, 'growl').fromError('Error removing Stable', err);
        }).finally(function () {
          Ember.set(_this5, 'togglingHelmStable', false);
          _this5.send('refresh');
        });
      },
      enableHelmStable: function enableHelmStable() {
        var _this6 = this;

        if (Ember.get(this, 'togglingHelmStable')) {
          return;
        }

        Ember.set(this, 'togglingHelmStable', true);
        Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          name: _constants.default.CATALOG.HELM_STABLE_KEY,
          url: _constants.default.CATALOG.HELM_STABLE_VALUE,
          branch: _constants.default.CATALOG.DEFAULT_BRANCH,
          kind: 'helm'
        }).save().catch(function (err) {
          Ember.get(_this6, 'growl').fromError('Error saving Stable', err);
        }).finally(function () {
          Ember.set(_this6, 'togglingHelmStable', false);
          _this6.send('refresh');
        });
      },
      enableCommunity: function enableCommunity() {
        var _this7 = this;

        Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          name: _constants.default.CATALOG.COMMUNITY_KEY,
          url: _constants.default.CATALOG.COMMUNITY_VALUE,
          branch: _constants.default.CATALOG.COMMUNITY_BRANCH
        }).save().catch(function (err) {
          Ember.get(_this7, 'growl').fromError('Error saving Community', err);
        }).finally(function () {
          _this7.send('refresh', _this7);
        });
      },
      disableCommunity: function disableCommunity() {
        var _this8 = this;

        Ember.get(this, 'stdCommunity').delete().catch(function (err) {
          Ember.get(_this8, 'growl').fromError('Error removing Community', err);
        }).finally(function () {
          _this8.send('refresh', _this8);
        });
      },
      add: function add() {
        var record = Ember.get(this, 'globalStore').createRecord({
          type: 'catalog',
          kind: 'helm',
          branch: 'master'
        });

        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: record,
          scope: 'global'
        });
      }
    },

    library: Ember.computed('model.@each.{name,url,branch}', function () {
      return this.findMatch(_constants.default.CATALOG.LIBRARY_KEY, _constants.default.CATALOG.LIBRARY_VALUE);
    }),

    helmStable: Ember.computed('model.@each.{name,url,branch}', function () {
      return this.findMatch(_constants.default.CATALOG.HELM_STABLE_KEY, _constants.default.CATALOG.HELM_STABLE_VALUE);
    }),

    helmIncubator: Ember.computed('model.@each.{name,url,branch}', function () {
      return this.findMatch(_constants.default.CATALOG.HELM_INCUBATOR_KEY, _constants.default.CATALOG.HELM_INCUBATOR_VALUE);
    }),

    custom: Ember.computed('library', 'helmStable', 'helmIncubator', function () {
      var hide = [Ember.get(this, 'library'), Ember.get(this, 'helmStable'), Ember.get(this, 'helmIncubator')];

      return Ember.get(this, 'model').filter(function (x) {
        return !hide.includes(x);
      });
    }),

    customLibrary: Ember.computed('custom.@each.{name}', function () {
      return Ember.get(this, 'custom').findBy('name', 'library');
    }),

    customHelmStable: Ember.computed('custom.@each.{name}', function () {
      return Ember.get(this, 'custom').findBy('name', 'helm');
    }),

    customHelmIncubator: Ember.computed('custom.@each.{name}', function () {
      return Ember.get(this, 'custom').findBy('name', 'helm-incubator');
    }),

    findMatch: function findMatch(name, url, branch) {
      var entry = Ember.get(this, 'model').findBy('name', name);

      if (!entry) {
        return null;
      }

      if (Ember.get(entry, 'url') === url && (!branch || Ember.get(entry, 'branch') === branch)) {
        return entry;
      }
    }
  });
});