define('global-admin/security/roles/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    roleTemplateService: Ember.inject.service('roleTemplate'),
    globalStore: Ember.inject.service(),

    model: function model() /* params */{
      return Ember.RSVP.hash({
        roleTemplates: Ember.get(this, 'roleTemplateService.allVisibleRoleTemplates'),
        globalRoles: Ember.get(this, 'globalStore').find('globalRole')
      });
    },


    queryParams: { context: { refreshModel: true } }
  });
});