enifed('ember-runtime/lib/system/array_proxy', ['exports', 'ember-babel', 'ember-metal', 'ember-runtime/lib/system/object', 'ember-runtime/lib/mixins/array', '@ember/debug'], function (exports, _emberBabel, _emberMetal, _object, _array, _debug) {
  'use strict';

  /**
  @module @ember/array
  */

  var ARRAY_OBSERVER_MAPPING = {
    willChange: '_arrangedContentArrayWillChange',
    didChange: '_arrangedContentArrayDidChange'
  };

  /**
    An ArrayProxy wraps any other object that implements `Array` and/or
    `MutableArray,` forwarding all requests. This makes it very useful for
    a number of binding use cases or other cases where being able to swap
    out the underlying array is useful.
  
    A simple example of usage:
  
    ```javascript
    import { A } from '@ember/array';
    import ArrayProxy from '@ember/array/proxy';
  
    let pets = ['dog', 'cat', 'fish'];
    let ap = ArrayProxy.create({ content: A(pets) });
  
    ap.get('firstObject');                        // 'dog'
    ap.set('content', ['amoeba', 'paramecium']);
    ap.get('firstObject');                        // 'amoeba'
    ```
  
    This class can also be useful as a layer to transform the contents of
    an array, as they are accessed. This can be done by overriding
    `objectAtContent`:
  
    ```javascript
    import { A } from '@ember/array';
    import ArrayProxy from '@ember/array/proxy';
  
    let pets = ['dog', 'cat', 'fish'];
    let ap = ArrayProxy.create({
        content: A(pets),
        objectAtContent: function(idx) {
            return this.get('content').objectAt(idx).toUpperCase();
        }
    });
  
    ap.get('firstObject'); // . 'DOG'
    ```
  
    When overriding this class, it is important to place the call to
    `_super` *after* setting `content` so the internal observers have
    a chance to fire properly:
  
    ```javascript
    import { A } from '@ember/array';
    import ArrayProxy from '@ember/array/proxy';
  
    export default ArrayProxy.extend({
      init() {
        this.set('content', A(['dog', 'cat', 'fish']));
        this._super(...arguments);
      }
    });
    ```
  
    @class ArrayProxy
    @extends EmberObject
    @uses MutableArray
    @public
  */

  var ArrayProxy = function (_EmberObject) {
    (0, _emberBabel.inherits)(ArrayProxy, _EmberObject);

    function ArrayProxy() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _EmberObject.apply(this, arguments));
    }

    ArrayProxy.prototype.init = function () {
      var _EmberObject$prototyp;

      (_EmberObject$prototyp = _EmberObject.prototype.init).call.apply(_EmberObject$prototyp, [this].concat(Array.prototype.slice.call(arguments)));

      /*
        `this._objectsDirtyIndex` determines which indexes in the `this._objects`
        cache are dirty.
         If `this._objectsDirtyIndex === -1` then no indexes are dirty.
        Otherwise, an index `i` is dirty if `i >= this._objectsDirtyIndex`.
         Calling `objectAt` with a dirty index will cause the `this._objects`
        cache to be recomputed.
      */
      this._objectsDirtyIndex = 0;
      this._objects = null;

      this._lengthDirty = true;
      this._length = 0;

      this._arrangedContent = null;
      this._addArrangedContentArrayObsever();
    };

    ArrayProxy.prototype.willDestroy = function () {
      this._removeArrangedContentArrayObsever();
    };

    ArrayProxy.prototype.objectAtContent = function (idx) {
      return (0, _emberMetal.objectAt)((0, _emberMetal.get)(this, 'arrangedContent'), idx);
    };

    ArrayProxy.prototype.replace = function (idx, amt, objects) {
      false && !((0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content')) && (0, _debug.assert)('Mutating an arranged ArrayProxy is not allowed', (0, _emberMetal.get)(this, 'arrangedContent') === (0, _emberMetal.get)(this, 'content'));

      this.replaceContent(idx, amt, objects);
    };

    ArrayProxy.prototype.replaceContent = function (idx, amt, objects) {
      (0, _emberMetal.get)(this, 'content').replace(idx, amt, objects);
    };

    ArrayProxy.prototype.objectAt = function (idx) {
      var arrangedContent, length, i;

      if (this._objects === null) {
        this._objects = [];
      }

      if (this._objectsDirtyIndex !== -1 && idx >= this._objectsDirtyIndex) {
        arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');

        if (arrangedContent) {
          length = this._objects.length = (0, _emberMetal.get)(arrangedContent, 'length');


          for (i = this._objectsDirtyIndex; i < length; i++) {
            this._objects[i] = this.objectAtContent(i);
          }
        } else {
          this._objects.length = 0;
        }
        this._objectsDirtyIndex = -1;
      }

      return this._objects[idx];
    };

    ArrayProxy.prototype[_emberMetal.PROPERTY_DID_CHANGE] = function (key) {
      var oldLength, arrangedContent, newLength;

      if (key === 'arrangedContent') {
        oldLength = this._objects === null ? 0 : this._objects.length;
        arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
        newLength = arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;


        this._removeArrangedContentArrayObsever();
        this.arrayContentWillChange(0, oldLength, newLength);

        this._invalidate();

        this.arrayContentDidChange(0, oldLength, newLength);
        this._addArrangedContentArrayObsever();
      } else if (key === 'content') {
        this._invalidate();
      }
    };

    ArrayProxy.prototype._addArrangedContentArrayObsever = function () {
      var arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');
      if (arrangedContent) {
        false && !(arrangedContent !== this) && (0, _debug.assert)("Can't set ArrayProxy's content to itself", arrangedContent !== this);
        false && !((0, _array.isArray)(arrangedContent) || arrangedContent.isDestroyed) && (0, _debug.assert)('ArrayProxy expects an Array or ArrayProxy, but you passed ' + typeof arrangedContent, (0, _array.isArray)(arrangedContent) || arrangedContent.isDestroyed);

        (0, _emberMetal.addArrayObserver)(arrangedContent, this, ARRAY_OBSERVER_MAPPING);

        this._arrangedContent = arrangedContent;
      }
    };

    ArrayProxy.prototype._removeArrangedContentArrayObsever = function () {
      if (this._arrangedContent) {
        (0, _emberMetal.removeArrayObserver)(this._arrangedContent, this, ARRAY_OBSERVER_MAPPING);
      }
    };

    ArrayProxy.prototype._arrangedContentArrayWillChange = function () {};

    ArrayProxy.prototype._arrangedContentArrayDidChange = function (proxy, idx, removedCnt, addedCnt) {
      this.arrayContentWillChange(idx, removedCnt, addedCnt);

      var dirtyIndex = idx,
          length;
      if (dirtyIndex < 0) {
        length = (0, _emberMetal.get)(this._arrangedContent, 'length');

        dirtyIndex += length + removedCnt - addedCnt;
      }

      if (this._objectsDirtyIndex === -1) {
        this._objectsDirtyIndex = dirtyIndex;
      } else {
        if (this._objectsDirtyIndex > dirtyIndex) {
          this._objectsDirtyIndex = dirtyIndex;
        }
      }

      this._lengthDirty = true;

      this.arrayContentDidChange(idx, removedCnt, addedCnt);
    };

    ArrayProxy.prototype._invalidate = function () {
      this._objectsDirtyIndex = 0;
      this._lengthDirty = true;
    };

    (0, _emberBabel.createClass)(ArrayProxy, [{
      key: 'length',
      get: function () {
        var arrangedContent;

        if (this._lengthDirty) {
          arrangedContent = (0, _emberMetal.get)(this, 'arrangedContent');

          this._length = arrangedContent ? (0, _emberMetal.get)(arrangedContent, 'length') : 0;
          this._lengthDirty = false;
        }

        return this._length;
      },
      set: function (value) {
        var length = this.length;
        var removedCount = length - value;
        var added = void 0;

        if (removedCount === 0) {
          return;
        } else if (removedCount < 0) {
          added = new Array(-removedCount);
          removedCount = 0;
        }

        var content = (0, _emberMetal.get)(this, 'content');
        if (content) {
          (0, _emberMetal.replace)(content, value, removedCount, added);

          this._invalidate();
        }
      }
    }]);
    return ArrayProxy;
  }(_object.default);

  exports.default = ArrayProxy;

  ArrayProxy.reopen(_array.MutableArray, {
    /**
      The array that the proxy pretends to be. In the default `ArrayProxy`
      implementation, this and `content` are the same. Subclasses of `ArrayProxy`
      can override this property to provide things like sorting and filtering.
       @property arrangedContent
      @public
    */
    arrangedContent: (0, _emberMetal.alias)('content')
  });
});