define('shared/helpers/get-card-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getCardClass = getCardClass;


  // this list matchse the card types that stripe uses
  var CARDS = {
    'Visa': 'card-visa',
    'American Express': 'card-amex',
    'MasterCard': 'card-mastercard',
    'Discover': 'card-discover',
    'Diners Club': 'card-diners',
    'JCB': 'card-jcb'
  };

  function getCardClass(key /* , hash*/) {
    return CARDS[key];
  }

  exports.default = Ember.Helper.helper(getCardClass);
});