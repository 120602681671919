define('shared/github/service', ['exports', 'shared/utils/util', 'shared/utils/constants'], function (exports, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    session: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    app: Ember.inject.service(),
    intl: Ember.inject.service(),

    generateState: function generateState() {
      return Ember.set(this, 'session.githubState', '' + Math.random());
    },
    stateMatches: function stateMatches(actual) {
      return actual && Ember.get(this, 'session.githubState') === actual;
    },
    testConfig: function testConfig(config) {
      return config.doAction('configureTest', config);
    },
    saveConfig: function saveConfig(config, opt) {
      return config.doAction('testAndApply', opt);
    },
    authorize: function authorize(auth, state) {
      var url = (0, _util.addQueryParams)(Ember.get(auth, 'redirectUrl'), {
        scope: 'read:org',
        redirect_uri: window.location.origin + '/verify-auth',
        authProvider: 'github',
        state: state
      });

      return window.location.href = url;
    },
    login: function login(forwardUrl) {
      var provider = Ember.get(this, 'access.providers').findBy('id', 'github');
      var authRedirect = Ember.get(provider, 'redirectUrl');
      var redirect = (0, _util.addQueryParams)(window.location.origin + '/verify-auth', {
        login: true,
        state: this.generateState()
      });

      if (forwardUrl) {
        redirect = (0, _util.addQueryParam)(redirect, 'forward', forwardUrl);
      }

      var url = (0, _util.addQueryParams)(authRedirect, {
        scope: 'read:org',
        redirect_uri: redirect
      });

      window.location.href = url;
    },
    test: function test(config, cb) {
      var _this = this;

      var responded = false;

      window.onAuthTest = function (err, code) {
        if (!responded) {
          var ghConfig = config;

          responded = true;

          _this.finishTest(ghConfig, code, cb);
        }
      };

      Ember.set(this, 'state', this.generateState());

      var url = (0, _util.addQueryParams)(window.location.origin + '/verify-auth', { config: 'github' });

      var popup = window.open(url, 'rancherAuth', (0, _util.popupWindowOptions)());
      var intl = Ember.get(this, 'intl');

      var timer = setInterval(function () {
        if (popup && popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;
            cb({
              type: 'error',
              message: intl.t('authPage.github.testAuth.authError')
            });
          }
        } else if (popup === null || typeof popup === 'undefined') {
          clearInterval(timer);

          if (!responded) {
            responded = true;

            cb({
              type: 'error',
              message: intl.t('authPage.github.testAuth.popupError')
            });
          }
        }
      }, 500);
    },
    finishTest: function finishTest(config, code, cb) {
      var _this2 = this;

      var ghConfig = config;

      Ember.set(ghConfig, 'enabled', true);

      var out = {
        code: code,
        enabled: true,
        githubConfig: ghConfig,
        description: _constants.default.SESSION.DESCRIPTION,
        ttl: _constants.default.SESSION.TTL
      };

      var allowedPrincipalIds = Ember.get(config, 'allowedPrincipalIds') || [];

      return this.saveConfig(config, out).then(function () {
        var found = false;
        var myPIds = Ember.get(_this2, 'access.me.principalIds');

        myPIds.forEach(function (id) {
          if (allowedPrincipalIds.indexOf(id) >= 0) {
            found = true;
          }
        });

        if (!found && !allowedPrincipalIds.length) {
          allowedPrincipalIds.pushObject(Ember.get(_this2, 'access.principal.id'));
        }

        return ghConfig.save().then(function () {
          window.location.href = window.location.href;
        });
      }).catch(function (err) {
        cb(err);
      });
    }
  });
});