enifed('ember-template-compiler/lib/system/compile', ['exports', 'require', 'ember-template-compiler/lib/system/precompile'], function (exports, _require2, _precompile) {
    'use strict';

    exports.default = compile;
    /**
    @module ember
    */
    var template = void 0;
    /**
      Uses HTMLBars `compile` function to process a string into a compiled template.
    
      This is not present in production builds.
    
      @private
      @method compile
      @param {String} templateString This is the string to be compiled by HTMLBars.
      @param {Object} options This is an options hash to augment the compiler options.
    */
    function compile(templateString, options) {
        if (!template && (0, _require2.has)('ember-glimmer')) {
            // tslint:disable-next-line:no-require-imports
            template = (0, _require2.default)('ember-glimmer').template;
        }
        if (!template) {
            throw new Error('Cannot call `compile` with only the template compiler loaded. Please load `ember.debug.js` or `ember.prod.js` prior to calling `compile`.');
        }
        var precompiledTemplateString = (0, _precompile.default)(templateString, options);
        var templateJS = new Function('return ' + precompiledTemplateString)();
        return template(templateJS);
    }
});