enifed("ember-routing/lib/system/query_params", ["exports"], function (exports) {
  "use strict";

  exports.default = function () {
    var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;


    this.values = values;
    this.isQueryParams = true;
  };
});