define('shared/helpers/upper-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.upperCase = upperCase;
  function upperCase(params) {
    return (params[0] || '').toUpperCase();
  }

  exports.default = Ember.Helper.helper(upperCase);
});