enifed("@ember/polyfills/lib/assign", ["exports"], function (exports) {
    "use strict";

    exports.assign = assign;
    /**
     @module @ember/polyfills
    */
    /**
      Copy properties from a source object to a target object.
    
      ```javascript
      import { assign } from '@ember/polyfills';
    
      var a = { first: 'Yehuda' };
      var b = { last: 'Katz' };
      var c = { company: 'Tilde Inc.' };
      assign(a, b, c); // a === { first: 'Yehuda', last: 'Katz', company: 'Tilde Inc.' }, b === { last: 'Katz' }, c === { company: 'Tilde Inc.' }
      ```
    
      @method assign
      @for @ember/polyfills
      @param {Object} target The object to assign into
      @param {Object} ...args The objects to copy properties from
      @return {Object}
      @public
      @static
    */
    function assign(target) {
        var i, arg, updates, _i, prop;

        for (i = 1; i < arguments.length; i++) {
            arg = arguments[i];

            if (!arg) {
                continue;
            }
            updates = Object.keys(arg);

            for (_i = 0; _i < updates.length; _i++) {
                prop = updates[_i];

                target[prop] = arg[prop];
            }
        }
        return target;
    }
    // Note: We use the bracket notation so
    //       that the babel plugin does not
    //       transform it.
    // https://www.npmjs.com/package/babel-plugin-transform-object-assign
    var _assign = Object.assign;
    exports.default = _assign || assign;
});