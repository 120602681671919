define('shared/mixins/view-new-edit', ['exports', 'shared/mixins/new-or-edit'], function (exports, _newOrEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EDIT = exports.NEW = exports.VIEW = undefined;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var VIEW = exports.VIEW = 'view';
  var NEW = exports.NEW = 'new';
  var EDIT = exports.EDIT = 'edit';

  exports.default = Ember.Mixin.create(_newOrEdit.default, {
    intl: Ember.inject.service(),

    titleKey: null,
    inlineEdit: false,

    isView: Ember.computed.equal('mode', VIEW),
    isNew: Ember.computed.equal('mode', NEW),
    isEdit: Ember.computed.equal('mode', EDIT),
    notView: Ember.computed.or('isNew', 'isEdit'),
    editing: Ember.computed.alias('notView'),

    actions: {
      inlineEdit: function inlineEdit() {
        Ember.set(this, 'mode', EDIT);
        Ember.set(this, 'inlineEdit', true);
      },
      viewEditCancel: function viewEditCancel() {
        if (Ember.get(this, 'inlineEdit')) {
          Ember.set(this, 'inlineEdit', false);
          Ember.set(this, 'mode', VIEW);
        } else {
          this.sendAction('cancel');
        }
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    isClone: Ember.computed('mode', 'primaryResource.{id,name}', function () {
      var pr = Ember.get(this, 'primaryResource');
      var created = pr.hasOwnProperty('created');
      var tpof = _typeof(pr.created);

      var isClone = false;

      if (created && (tpof !== null || tpof !== undefined)) {
        isClone = true;
      }

      return isClone;
    }),

    title: Ember.computed('mode', 'primaryResource.displayName', 'titleKey', function () {
      var prefix = Ember.get(this, 'titleKey');
      var mode = Ember.get(this, 'mode');
      var intl = Ember.get(this, 'intl');

      var name = Ember.get(this, 'originalModel.displayName') || Ember.get(this, 'primaryResource.displayName') || '';

      return intl.t(prefix + '.' + mode, { name: name });
    }),

    doneSaving: function doneSaving() {
      this.sendAction('done');

      return this._super.apply(this, arguments);
    }
  });
});