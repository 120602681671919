define('shared/components/metrics-graph/component', ['exports', 'shared/components/metrics-graph/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),

    layout: _template.default,

    rows: null,
    graphs: null,
    loading: null,
    noGraphs: null,
    noDataLabel: 'metricsAction.noData',
    currentGraphs: null,

    graphsDidChange: Ember.observer('graphs', function () {
      var out = [];
      var graphs = Ember.get(this, 'graphs') || [];
      var newGrahps = graphs.map(function (graph) {
        return Ember.get(graph, 'graph.title') || '';
      }).join(',');
      var changed = newGrahps !== Ember.get(this, 'currentGraphs');

      Ember.set(this, 'currentGraphs', newGrahps);

      if (changed) {
        graphs.forEach(function (graph, index) {
          if (index % 3 === 0) {
            out.pushObject([graph]);
          } else {
            Ember.get(out, 'lastObject').pushObject(graph);
          }
        });
        Ember.set(this, 'rows', out);
      } else {
        var rowIndex = -1;
        var currentRows = Ember.get(this, 'rows') || [];

        graphs.forEach(function (graph, index) {
          var colIndex = index % 3;

          if (colIndex === 0) {
            rowIndex++;
          }
          var row = currentRows.objectAt(rowIndex) || [];

          Ember.set(row.objectAt(colIndex), 'series', Ember.get(graph, 'series'));
        });
      }
    })
  });
});