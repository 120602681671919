enifed('@ember/debug/index', ['exports', '@ember/debug/lib/warn', '@ember/debug/lib/deprecate', '@ember/debug/lib/testing', '@ember/error', 'ember-browser-environment'], function (exports, _warn2, _deprecate2, _testing) {
    'use strict';

    exports._warnIfUsingStrippedFeatureFlags = exports.getDebugFunction = exports.setDebugFunction = exports.deprecateFunc = exports.runInDebug = exports.debugFreeze = exports.debugSeal = exports.deprecate = exports.debug = exports.warn = exports.info = exports.assert = exports.setTesting = exports.isTesting = exports.registerDeprecationHandler = exports.registerWarnHandler = undefined;
    Object.defineProperty(exports, 'registerWarnHandler', {
        enumerable: true,
        get: function () {
            return _warn2.registerHandler;
        }
    });
    Object.defineProperty(exports, 'registerDeprecationHandler', {
        enumerable: true,
        get: function () {
            return _deprecate2.registerHandler;
        }
    });
    Object.defineProperty(exports, 'isTesting', {
        enumerable: true,
        get: function () {
            return _testing.isTesting;
        }
    });
    Object.defineProperty(exports, 'setTesting', {
        enumerable: true,
        get: function () {
            return _testing.setTesting;
        }
    });

    // These are the default production build versions:
    var noop = function () {};

    exports.assert = noop;
    exports.info = noop;
    exports.warn = noop;
    exports.debug = noop;
    exports.deprecate = noop;
    exports.debugSeal = noop;
    exports.debugFreeze = noop;
    exports.runInDebug = noop;
    exports.deprecateFunc = function () {
        return arguments[arguments.length - 1];
    };
    exports.setDebugFunction = noop;
    exports.getDebugFunction = noop;
    exports._warnIfUsingStrippedFeatureFlags = void 0;
});