define('shared/components/field-required/component', ['exports', 'shared/components/field-required/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    classNames: ['field-required'],
    classNameBindings: ['hide'],

    editing: null,

    hide: Ember.computed('editing', function () {
      return Ember.get(this, 'editing') === false;
    })
  });
});