define('shared/components/graph-area/component', ['exports', 'shared/mixins/throttled-resize', 'shared/utils/util', 'shared/components/graph-area/theme', 'shared/components/graph-area/template'], function (exports, _throttledResize, _util, _theme, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getFormatter(unit) {
    var full = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    switch (unit) {
      case 'seconds':
        return _util.formatSecond;
      case 'pps':
        return function (value) {
          return (0, _util.roundValue)(value) + ' ' + (full ? 'Packets Per Second' : 'Pps');
        };
      case 'ops':
        return function (value) {
          return (0, _util.roundValue)(value) + ' ' + (full ? 'Operation Per Second' : 'Ops');
        };
      case 'ms':
        return function (value) {
          return (0, _util.roundValue)(value) + ' ms';
        };
      case 'mcpu':
        return function (value) {
          return (0, _util.roundValue)(value) + ' mCPU';
        };
      case 'percent':
        return _util.formatPercent;
      case 'bps':
      case 'kbps':
        return _util.formatKbps;
      case 'byte':
        return _util.formatMib;
      default:
        return _util.roundValue;
    }
  }

  function getConverter(unit) {
    switch (unit) {
      case 'percent':
        return function (value) {
          return value * 100;
        };
      case 'mcpu':
        return function (value) {
          return value * 1000;
        };
      case 'bps':
        return function (value) {
          return value / 1024;
        };
      case 'byte':
        return function (value) {
          return value / 1048576;
        };
      default:
        return function (value) {
          return value;
        };
    }
  }

  var LOADING_PARAMS = {
    text: '',
    color: '#3d3d3d',
    textColor: '#3d3d3d',
    maskColor: 'rgba(255, 255, 255, 0.8)',
    zlevel: 0
  };

  var ECharts = null;

  exports.default = Ember.Component.extend(_throttledResize.default, {
    intl: Ember.inject.service(),
    layout: _template.default,

    tagName: 'div',
    classNames: ['graph-area'],

    model: null,
    fields: null,
    chart: null,

    formatter: 'value',
    needRefresh: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!ECharts) {
        Ember.RSVP.all([emberAutoImportDynamic('echarts/lib/echarts'), emberAutoImportDynamic('echarts/lib/chart/line'), emberAutoImportDynamic('echarts/lib/component/tooltip')]).then(function (modules) {
          ECharts = modules[0].default;
          ECharts.registerTheme('walden', _theme.theme);
          _this.didRender();
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      var chart = Ember.get(this, 'chart');

      if (chart) {
        chart.clear();
        chart.dispose();
      }
    },
    didRender: function didRender() {
      var _this2 = this;

      if (ECharts && !Ember.get(this, 'chart')) {
        this.create();
        setTimeout(function () {
          var chart = Ember.get(_this2, 'chart');

          if (chart) {
            chart.resize();
          }
        }, 200);
      }
    },


    loadingDidChange: Ember.observer('loading', function () {
      var chart = Ember.get(this, 'chart');

      if (chart && Ember.get(this, 'loading')) {
        chart.showLoading(LOADING_PARAMS);
      } else if (chart) {
        chart.hideLoading();
      }
    }),

    dataDidChange: Ember.observer('series', function () {
      if (Ember.get(this, 'chart')) {
        this.draw();
      }
    }),

    onResize: function onResize() {
      if (!ECharts || this.isDestroyed || this.isDestroying) {
        return;
      }

      if (Ember.get(this, 'chart')) {
        Ember.get(this, 'chart').resize();
      }
    },
    create: function create() {
      var chart = ECharts.init(this.$('.content')[0], 'walden');

      Ember.set(this, 'chart', chart);
      chart.showLoading(LOADING_PARAMS);
      this.draw();
    },
    draw: function draw() {
      var _this3 = this;

      var chart = Ember.get(this, 'chart');

      if (!chart) {
        return;
      }

      var minMax = Ember.get(this, 'formatter') === 'percent' ? 100 : null;
      var setMax = true;
      var series = [];
      var fields = (Ember.get(this, 'series') || []).filter(function (serie) {
        return Ember.get(serie, 'points.length') > 1;
      }).map(function (serie) {
        return {
          id: Ember.get(serie, 'name'),
          data: (Ember.get(serie, 'points') || []).map(function (p) {
            return [p[1], getConverter(Ember.get(_this3, 'formatter'))(p[0])];
          })
        };
      });

      fields.forEach(function (field) {
        var serie = field.data || [];
        var data = [];

        serie.forEach(function (d) {
          if (minMax && setMax && d[1] > minMax) {
            setMax = false;
          }
          data.push(d);
        });

        series.push({
          name: field.id,
          type: 'line',
          showSymbol: false,
          animation: false,
          data: data,
          itemStyle: { normal: { lineStyle: { width: 1 } } }
        });
      });

      var _formatter = getFormatter(Ember.get(this, 'formatter'), true);
      var option = {
        tooltip: {
          trigger: 'axis',
          position: function position(pos, params, dom, rect, size) {
            var obj = { top: 60 };

            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;

            return obj;
          },
          formatter: function formatter(params) {
            var html = '';

            params.forEach(function (p, i) {
              if (i === 0) {
                html = '<div class="text-left">' + p.axisValueLabel;
              }

              var value = (0, _util.escapeHtml)(_formatter(p.data[1]));
              var label = (0, _util.escapeHtml)(p.seriesName);

              html += '<br><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + p.color + ';"></span> ' + label + ' : ' + value;
            });

            html += '</div>';

            return html.htmlSafe();
          }
        },
        grid: {
          top: '10px',
          left: '30px',
          right: '30px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          axisLabel: { formatter: getFormatter(Ember.get(this, 'formatter')) },
          splitArea: { show: true }
        },
        series: series
      };

      if (setMax && minMax) {
        option.yAxis.max = minMax;
      }

      chart.clear();
      chart.setOption(option, true);

      chart.hideLoading();
    }
  });
});