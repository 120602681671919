define('shared/helpers/nl-to-br', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.nlToBr = nlToBr;
  function nlToBr(params) {
    var val = Ember.Handlebars.Utils.escapeExpression(params[0] || '');

    return new Ember.String.htmlSafe(val.replace(/\n/g, '<br/>\n'));
  }

  exports.default = Ember.Helper.helper(nlToBr);
});