define('shared/components/input-url/component', ['exports', 'shared/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    intl: Ember.inject.service(),

    type: 'url',
    classNameBindings: ['invalid:input-error'],
    invalid: false,
    stripScheme: true,
    isInvalid: null,
    urlWarning: null,
    urlError: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', function () {
        var val = null;

        if (Ember.get(_this, 'stripScheme')) {
          val = (0, _util.stripScheme)(Ember.get(_this, 'element.value') || '');
        } else {
          val = Ember.get(_this, 'element.value') || '';
        }

        Ember.set(_this, 'value', _this.validateInput(val));
      });
    },
    _elementValueDidChange: function _elementValueDidChange() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var val = null;

      if (Ember.get(this, 'stripScheme')) {
        val = (0, _util.stripScheme)(Ember.get(this, 'element.value') || '');
      } else {
        val = Ember.get(this, 'element.value') || '';
      }

      Ember.run.debounce(this, 'validateInput', val, 250);

      Ember.set(this, 'value', val);
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var val = null;

      if (Ember.get(this, 'stripScheme')) {
        val = (0, _util.stripScheme)(Ember.get(this, 'element.value') || '');
      } else {
        val = Ember.get(this, 'element.value') || '';
      }

      Ember.set(this, 'value', this.validateInput(val));
    },
    validateInput: function validateInput(val) {
      var warnings = [];
      var errors = [];

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (val && val.length > 0 && (0, _util.validateEndpoint)(val) && (0, _util.isPrivate)(val)) {
        warnings.addObject({
          type: 'warning',
          msg: 'hostSettings.notPublic.alert'
        });
      }

      if ((0, _util.isBadTld)(val)) {
        errors.addObject({
          type: 'error',
          msg: 'hostSettings.badTld.alert'
        });
      }

      if (errors.length > 0) {
        Ember.get(this, 'isInvalid')(Ember.set(this, 'invalid', true));
        Ember.get(this, 'urlError')(errors);
      } else {
        Ember.get(this, 'isInvalid')(Ember.set(this, 'invalid', false));
        Ember.get(this, 'urlError')([]);
      }

      if (warnings.length > 0) {
        Ember.get(this, 'urlWarning')(warnings);
      } else {
        Ember.get(this, 'urlWarning')([]);
      }

      return val;
    }
  });
});