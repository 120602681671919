define('@rancher/ember-api-store/models/collection', ['exports', '@rancher/ember-api-store/mixins/type', '@rancher/ember-api-store/utils/apply-headers', '@rancher/ember-api-store/utils/normalize'], function (exports, _type, _applyHeaders, _normalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  exports.default = Ember.ArrayProxy.extend(_type.default, {
    type: 'collection',
    createDefaults: null,
    createTypes: null,
    filters: null,
    pagination: null,
    sort: null,
    sortLinks: null,

    reservedKeys: ['content'],

    toString: function toString() {
      return 'collection:' + this.get('resourceType') + '[' + this.get('length') + ']';
    },

    request: function request(opt) {
      var store = this.get('store');

      if (!opt.headers) {
        opt.headers = {};
      }

      var cls = getOwner(store).lookup('model:' + (0, _normalize.normalizeType)(this.get('resourceType')));

      if (cls && cls.constructor.headers) {
        (0, _applyHeaders.copyHeaders)(cls.constructor.headers, opt.headers);
      }

      return store.request(opt);
    },

    depaginate: function depaginate(depth) {
      var self = this;

      depth = depth || 1;
      /*
      if ( depth > 5 )
      {
        console.log('Depaginate, max depth reached');
        return new Ember.RSVP.Promise(function(resolve,reject) {
          resolve();
        });
      }
      */

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var next = self.get('pagination.next');
        if (next) {
          console.log('Depaginate, requesting', next);
          self.request({
            method: 'GET',
            url: next,
            depaginate: false,
            forPagination: true
          }).then(gotPage, fail);
        } else {
          resolve();
        }

        function gotPage(body) {
          //console.log('Depaginate, got page');
          self.set('pagination', body.get('pagination'));
          body.forEach(function (obj) {
            self.pushObject(obj);
          });

          if (self.get('pagination.next')) {
            //console.log('Depaginate, more pages');
            // 98 bottles of beer on the wall...
            resolve(self.depaginate(depth + 1));
          } else {
            //console.log('Depaginate, no more pages');
            resolve();
          }
        }

        function fail(body) {
          //console.log('Depaginate fail',body);
          reject(body);
        }
      }, 'Depaginate, depth ' + depth);

      return promise;
    }
  });
});