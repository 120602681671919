define('global-admin/security/authentication/azuread/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      var gs = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        azureADConfig: gs.find('authconfig', 'azuread', { forceReload: true }),
        principals: gs.all('principal')
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }
  });
});