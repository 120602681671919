define('shared/mixins/resource-usage', ['exports', 'shared/utils/util', 'shared/utils/parse-unit'], function (exports, _util, _parseUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),

    cpuTotal: Ember.computed('allocatable.cpu', function () {
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.cpu'));

      if (total) {
        var minExp = (0, _parseUnit.exponentNeeded)(total);
        var totalStr = (0, _parseUnit.formatSi)(total, 1000, '', '', 0, minExp, 1);

        return totalStr + ' Core' + (totalStr === '1' ? '' : 's');
      } else {
        return null;
      }
    }),

    memoryTotal: Ember.computed('allocatable.memory', function () {
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.memory'));

      if (total) {
        var minExp = (0, _parseUnit.exponentNeeded)(total);
        var totalStr = (0, _parseUnit.formatSi)(total, 1024, 'iB', 'B', 0, minExp, 1);

        return totalStr;
      } else {
        return null;
      }
    }),

    cpuUsage: Ember.computed('requested.cpu', 'allocatable.cpu', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.cpu')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.cpu'));

      if (total) {
        var minExp = (0, _parseUnit.exponentNeeded)(total);
        var usedStr = (0, _parseUnit.formatSi)(used, 1000, '', '', 0, minExp, 1).replace(/\s.*$/, '');
        var totalStr = (0, _parseUnit.formatSi)(total, 1000, '', '', 0, minExp, 1);

        return usedStr + '/' + totalStr + ' Core' + (totalStr === '1' ? '' : 's');
      } else {
        return null;
      }
    }),

    cpuUsageCount: Ember.computed('requested.cpu', function () {
      return (0, _parseUnit.parseSi)(Ember.get(this, 'requested.cpu')) || 0;
    }),

    cpuPercent: Ember.computed('requested.cpu', 'allocatable.cpu', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.cpu')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.cpu'));

      if (total) {
        return (0, _util.formatPercent)(100 * used / total, 0);
      } else {
        return null;
      }
    }),

    memoryUsage: Ember.computed('requested.memory', 'allocatable.memory', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.memory')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.memory'));

      if (total) {
        var minExp = (0, _parseUnit.exponentNeeded)(total);
        var usedStr = (0, _parseUnit.formatSi)(used, 1024, '', '', 0, minExp, 1).replace(/\s.*/, '');
        var totalStr = (0, _parseUnit.formatSi)(total, 1024, 'iB', 'B', 0, minExp, 1);

        return usedStr + '/' + totalStr;
      } else {
        return null;
      }
    }),

    memoryUsageCount: Ember.computed('requested.memory', function () {
      return (0, _parseUnit.parseSi)(Ember.get(this, 'requested.memory')) || 0;
    }),

    memoryPercent: Ember.computed('requested.memory', 'allocatable.memory', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.memory')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.memory'));

      if (total) {
        return (0, _util.formatPercent)(100 * used / total, 0);
      } else {
        return null;
      }
    }),

    podUsage: Ember.computed('requested.pods', 'allocatable.pods', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.pods')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.pods'));

      if (total) {
        var minExp = (0, _parseUnit.exponentNeeded)(total);
        var usedStr = (0, _parseUnit.formatSi)(used, 1000, '', '', 0, minExp, 1).replace(/\s.*$/, '');
        var totalStr = (0, _parseUnit.formatSi)(total, 1000, '', '', 0, minExp, 1);

        return usedStr + '/' + totalStr;
      } else {
        return null;
      }
    }),

    podUsageCount: Ember.computed('requested.pods', function () {
      return (0, _parseUnit.parseSi)(Ember.get(this, 'requested.pods')) || 0;
    }),

    podPercent: Ember.computed('requested.pods', 'allocatable.pods', function () {
      var used = (0, _parseUnit.parseSi)(Ember.get(this, 'requested.pods')) || 0;
      var total = (0, _parseUnit.parseSi)(Ember.get(this, 'allocatable.pods'));

      if (total) {
        return (0, _util.formatPercent)(100 * used / total, 0);
      } else {
        return null;
      }
    })
  });
});