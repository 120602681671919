define('shared/components/badge-state/component', ['exports', 'shared/components/badge-state/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'SPAN',
    classNames: ['badge-state', 'vertical-middle'],
    classNameBindings: ['model.stateBackground', 'capitalizeText:text-capitalize'],

    capitalizeText: true
  });
});