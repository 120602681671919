enifed("ember-error-handling/index", ["exports"], function (exports) {
    "use strict";

    exports.getOnerror = getOnerror;
    exports.setOnerror =
    // Ember.onerror setter
    function (handler) {
        onerror = handler;
    };
    exports.getDispatchOverride = getDispatchOverride;
    exports.setDispatchOverride = function (handler) {
        dispatchOverride = handler;
    };
    var onerror = void 0;
    exports.onErrorTarget = {
        get onerror() {
            return onerror;
        }
    };
    // Ember.onerror getter

    function getOnerror() {
        return onerror;
    }
    var dispatchOverride = void 0;
    // allows testing adapter to override dispatch
    function getDispatchOverride() {
        return dispatchOverride;
    }
});