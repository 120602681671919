define('shared/components/storage-class/provisioner-aws-ebs/component', ['exports', 'shared/components/storage-class/provisioner-aws-ebs/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VOLUME_TYPES = undefined;
  var VOLUME_TYPES = exports.VOLUME_TYPES = ['gp2', 'io1', 'st1', 'sc1'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    volumeTypes: VOLUME_TYPES,

    volumeType: null,
    zones: null,
    zoneAutomatic: null,
    iopsPerGB: null,
    encrypted: true,
    kmsKeyId: null,
    kmsAutomatic: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};

      changes['volumeType'] = Ember.get(this, 'parameters.type') || 'gp2';
      changes['iopsPerGB'] = Ember.get(this, 'parameters.iopsPerGB') || '';

      var zone = Ember.get(this, 'parameters.zone');
      var zones = Ember.get(this, 'parameters.zones');

      if (zones && zones.length) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zones;
      } else if (zone) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zone;
      } else {
        changes['zoneAutomatic'] = true;
        changes['zones'] = '';
      }

      changes['encrypted'] = Ember.get(this, 'parameters.encrypted') === 'true';

      var key = Ember.get(this, 'parameters.kmsKeyId');

      if (key) {
        changes['kmsKeyId'] = key;
        changes['kmsAutomatic'] = false;
      } else {
        changes['kmsAutomatic'] = true;
      }

      Ember.setProperties(this, changes);
    },


    supportsIops: Ember.computed('volumeType', function () {
      return !!Ember.get(this, 'volumeType').match(/^io\d+$/);
    }),
    updateParams: function updateParams() {
      var type = Ember.get(this, 'volumeType');
      var out = { type: type };

      var zoneAutomatic = Ember.get(this, 'zoneAutomatic');

      if (!zoneAutomatic) {
        var zones = (Ember.get(this, 'zones') || '').trim();

        if (zones) {
          out['zones'] = zones;
        }
      }

      if (Ember.get(this, 'supportsIops')) {
        out['iopsPerGB'] = '' + (Ember.get(this, 'iopsPerGB') || '');
      }

      var encrypted = Ember.get(this, 'encrypted');
      var kmsKeyId = Ember.get(this, 'kmsKeyId');
      var kmsAutomatic = Ember.get(this, 'kmsAutomatic');

      if (encrypted) {
        out['encrypted'] = 'true';
        if (!kmsAutomatic && kmsKeyId) {
          out['kmsKeyId'] = kmsKeyId;
        }
      } else {
        out['encrypted'] = 'false';
      }

      Ember.set(this, 'parameters', out);
    }
  });
});