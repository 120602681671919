define('monitoring/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('index', { path: '/' });
    this.route('cluster-setting');
    this.route('project-setting');
    this.route('node-detail', { path: '/:node_id' });
  });
});