define('shared/mixins/reservation-check', ['exports', 'shared/utils/util', 'shared/utils/parse-unit'], function (exports, _util, _parseUnit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scope: Ember.inject.service(),

    preRequestsCpu: null,
    preRequestsMemory: null,
    clusterLevelMinCpu: 1000,
    clusterLevelMinMemory: 1000,
    projectLevelMinCpu: 500,
    projectLevelMinMemory: 500,

    insufficient: Ember.computed('insufficientMemory', 'insufficientCpu', function () {
      return Ember.get(this, 'insufficientMemory') || Ember.get(this, 'insufficientCpu');
    }),

    insufficientMemory: Ember.computed('preRequestsMemory', 'requestsMemory', 'scope.currentCluster.nodes.@each.{allocatable,requested}', function () {
      var allNodes = Ember.get(this, 'scope.currentCluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      var schedulableNodes = allNodes.filterBy('isUnschedulable', false);
      var leftMemory = 0;

      schedulableNodes.forEach(function (node) {
        var left = (0, _parseUnit.parseSi)(Ember.get(node, 'allocatable.memory'), 1024) / 1048576 - (0, _parseUnit.parseSi)(Ember.get(node, 'requested.memory') || '0', 1024) / 1048576;

        leftMemory += left;
      });

      if (Ember.get(this, 'enabled')) {
        return leftMemory <= parseInt(Ember.get(this, 'requestsMemory'), 10) - parseInt(Ember.get(this, 'preRequestsMemory'), 10);
      } else {
        return leftMemory <= Ember.get(this, 'minMemory');
      }
    }),

    insufficientCpu: Ember.computed('preRequestsCpu', 'requestsCpu', 'scope.currentCluster.nodes.@each.{allocatable,requested}', function () {
      var allNodes = Ember.get(this, 'scope.currentCluster.nodes') || [];

      if (Ember.get(allNodes, 'length') === 0) {
        return false;
      }

      var schedulableNodes = allNodes.filterBy('isUnschedulable', false);

      var leftCpu = 0;

      schedulableNodes.forEach(function (node) {
        var left = (0, _util.convertToMillis)(Ember.get(node, 'allocatable.cpu')) - (0, _util.convertToMillis)(Ember.get(node, 'requested.cpu') || '0');

        leftCpu += left;
      });

      if (Ember.get(this, 'enabled')) {
        return leftCpu <= parseInt(Ember.get(this, 'requestsCpu'), 10) - parseInt(Ember.get(this, 'preRequestsCpu'), 10);
      } else {
        return leftCpu <= Ember.get(this, 'minCpu');
      }
    }),

    minCpu: Ember.computed('requestsCpu', 'clusterLevelMinCpu', 'projectLevelMinCpu', function () {
      var cpu = parseInt(Ember.get(this, 'requestsCpu'), 10);

      if (isNaN(cpu)) {
        cpu = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinCpu') : Ember.get(this, 'projectLevelMinCpu')) + cpu;
    }),

    minMemory: Ember.computed('requestsMemory', 'clusterLevelMinMemory', 'projectLevelMinMemory', function () {
      var memory = parseInt(Ember.get(this, 'requestsMemory'), 10);

      if (isNaN(memory)) {
        memory = 0;
      }

      return (Ember.get(this, 'level') === 'cluster' ? Ember.get(this, 'clusterLevelMinMemory') : Ember.get(this, 'projectLevelMinMemory')) + memory;
    })
  });
});