define('shared/components/containers-header/component', ['exports', 'shared/components/containers-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    showGroup: true,

    tagName: '',
    allStacks: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('allStacks', this.get('store').all('stack'));
    }
  });
});