enifed('ember-template-compiler/lib/plugins/transform-old-class-binding-syntax', ['exports'], function (exports) {
    'use strict';

    exports.default = function (env) {
        var b = env.syntax.builders;
        return {
            name: 'transform-old-class-binding-syntax',
            visitor: {
                MustacheStatement: function (node) {
                    process(b, node);
                },
                BlockStatement: function (node) {
                    process(b, node);
                }
            }
        };
    };

    function process(b, node) {
        var allOfTheMicrosyntaxes = [];
        var allOfTheMicrosyntaxIndexes = [];
        var classPair = void 0;
        each(node.hash.pairs, function (pair, index) {
            var key = pair.key;

            if (key === 'classBinding' || key === 'classNameBindings') {
                allOfTheMicrosyntaxIndexes.push(index);
                allOfTheMicrosyntaxes.push(pair);
            } else if (key === 'class') {
                classPair = pair;
            }
        });
        if (allOfTheMicrosyntaxes.length === 0) {
            return;
        }
        var classValue = [];
        if (classPair) {
            classValue.push(classPair.value);
            classValue.push(b.string(' '));
        } else {
            classPair = b.pair('class', null);
            node.hash.pairs.push(classPair);
        }
        each(allOfTheMicrosyntaxIndexes, function (index) {
            node.hash.pairs.splice(index, 1);
        });
        each(allOfTheMicrosyntaxes, function (_ref) {
            var value = _ref.value,
                microsyntax;

            var sexprs = [];
            // TODO: add helpful deprecation when both `classNames` and `classNameBindings` can
            // be removed.
            if (value.type === 'StringLiteral') {
                microsyntax = parseMicrosyntax(value.original);

                buildSexprs(microsyntax, sexprs, b);
                classValue.push.apply(classValue, sexprs);
            }
        });
        var hash = b.hash();
        classPair.value = b.sexpr(b.path('concat'), classValue, hash);
    }
    function buildSexprs(microsyntax, sexprs, b) {
        var i, _microsyntax$i, propName, activeClass, inactiveClass, sexpr, params, sexprParams, hash;

        for (i = 0; i < microsyntax.length; i++) {
            _microsyntax$i = microsyntax[i], propName = _microsyntax$i[0], activeClass = _microsyntax$i[1], inactiveClass = _microsyntax$i[2];
            sexpr = void 0;
            // :my-class-name microsyntax for static values

            if (propName === '') {
                sexpr = b.string(activeClass);
            } else {
                params = [b.path(propName)];

                if (activeClass || activeClass === '') {
                    params.push(b.string(activeClass));
                } else {
                    sexprParams = [b.string(propName), b.path(propName)];
                    hash = b.hash();

                    if (activeClass !== undefined) {
                        hash.pairs.push(b.pair('activeClass', b.string(activeClass)));
                    }
                    if (inactiveClass !== undefined) {
                        hash.pairs.push(b.pair('inactiveClass', b.string(inactiveClass)));
                    }
                    params.push(b.sexpr(b.path('-normalize-class'), sexprParams, hash));
                }
                if (inactiveClass || inactiveClass === '') {
                    params.push(b.string(inactiveClass));
                }
                sexpr = b.sexpr(b.path('if'), params);
            }
            sexprs.push(sexpr);
            sexprs.push(b.string(' '));
        }
    }
    function each(list, callback) {
        var i;

        for (i = 0; i < list.length; i++) {
            callback(list[i], i);
        }
    }
    function parseMicrosyntax(string) {
        var segments = string.split(' '),
            i;
        var ret = [];
        for (i = 0; i < segments.length; i++) {
            ret[i] = segments[i].split(':');
        }
        return ret;
    }
});