define('shared/helpers/date-calendar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateCalendar = dateCalendar;
  function dateCalendar(params, options) {
    var out = (0, _moment.default)(params[0]).calendar();

    if (options && options.withToday !== true) {
      out = out.replace('Today at ', '');
    }

    return out;
  }

  exports.default = Ember.Helper.helper(dateCalendar);
});