define('nodes/components/driver-exoscale/component', ['exports', 'shared/mixins/node-driver', '@rancher/ember-api-store/utils/ajax-promise', 'nodes/components/driver-exoscale/template', 'async'], function (exports, _nodeDriver, _ajaxPromise, _template, _async) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RANCHER_GROUP = 'rancher-machine';
  var DEFAULT_TEMPLATE = 'Linux Ubuntu 16.04 LTS 64-bit';
  var DEFAULT_ZONE = 'ch-dk-2';
  var RANCHER_INGRESS_RULES = [{
    startport: 22,
    endport: 22,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 80,
    endport: 80,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 443,
    endport: 443,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 2376,
    endport: 2376,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 8472,
    endport: 8472,
    cidrlist: '0.0.0.0/0',
    protocol: 'UDP'
  }, {
    startport: 10250,
    endport: 10250,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 30000,
    endport: 32767,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 30000,
    endport: 32767,
    cidrlist: '0.0.0.0/0',
    protocol: 'UDP'
  }, {
    startport: 6443,
    endport: 6443,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    icmptype: 8,
    icmpcode: 0,
    cidrlist: '0.0.0.0/0',
    protocol: 'ICMP'
  }];

  exports.default = Ember.Component.extend(_nodeDriver.default, {
    layout: _template.default,
    driverName: 'exoscale',
    model: null,
    step: 1,

    allInstanceProfiles: null,
    allTemplates: null,

    allZones: null,
    selectedZone: null,
    defaultZone: DEFAULT_ZONE,
    defaultSecurityGroup: RANCHER_GROUP,
    allSecurityGroups: [],
    securityGroup: [],
    whichSecurityGroup: 'default',
    exoscaleApi: 'api.exoscale.ch/compute',
    config: Ember.computed.alias('model.exoscaleConfig'),
    isCustomSecurityGroup: Ember.computed.equal('whichSecurityGroup', 'custom'),

    willDestroyElement: function willDestroyElement() {
      Ember.set(this, 'errors', null);
      Ember.set(this, 'step', 1);
    },


    actions: {
      /* Login step */
      exoscaleLogin: function exoscaleLogin() {
        var _this = this;

        Ember.set(this, 'errors', null);
        Ember.set(this, 'step', 2);

        Ember.set(this, 'config.apiKey', (Ember.get(this, 'config.apiKey') || '').trim());
        Ember.set(this, 'config.apiSecretKey', (Ember.get(this, 'config.apiSecretKey') || '').trim());

        this.apiRequest('listZones').then(function (res) {
          var zones = [];
          var defaultZone = null;

          (res.listzonesresponse.zone || []).forEach(function (zone) {
            var obj = {
              id: zone.id,
              name: zone.name,
              isDefault: zone.name === Ember.get(_this, 'defaultZone')
            };

            zones.push(obj);
            if (zone.isDefault && !defaultZone) {
              defaultZone = obj;
            }
          });

          Ember.set(_this, 'step', 3);
          Ember.set(_this, 'allZones', zones);
          Ember.set(_this, 'defaultZone', defaultZone);
          Ember.set(_this, 'selectedZone', Ember.get(_this, 'config.zone') || Ember.get(_this, 'allZones.firstObject.id'));
        }, function (err) {
          var errors = Ember.get(_this, 'errors') || [];

          errors.pushObject(_this.apiErrorMessage(err, 'listzonesresponse', 'While requesting zones', 'Authentication failure: please check the provided access credentials'));
          Ember.set(_this, 'errors', errors);
          Ember.set(_this, 'step', 1);
        });
      },


      /* Zone selection */
      selectZone: function selectZone() {
        var _this2 = this;

        Ember.set(this, 'errors', null);

        Ember.set(this, 'config.zone', Ember.get(this, 'selectedZone'));
        (Ember.get(this, 'allZones') || []).forEach(function (zone) {
          if (zone.id === Ember.get(_this2, 'selectedZone')) {
            Ember.set(_this2, 'config.availabilityZone', zone.name);
          }
        });

        Ember.set(this, 'step', 4);
        this.allSecurityGroups = [];
        this.apiRequest('listSecurityGroups').then(function (res) {
          // Retrieve the list of security groups.
          (res.listsecuritygroupsresponse.securitygroup || []).forEach(function (group) {
            var obj = {
              id: group.id,
              name: group.name,
              description: group.description,
              isDefault: group.name === Ember.get(_this2, 'defaultSecurityGroup')
            };

            _this2.allSecurityGroups.push(obj);
          });

          // Move to next step
          Ember.set(_this2, 'step', 5);
          Ember.set(_this2, 'securityGroup', [Ember.get(_this2, 'config.securityGroup')]);
        }, function (err) {
          var errors = Ember.get(_this2, 'errors') || [];

          errors.pushObject(_this2.apiErrorMessage(err, 'listsecuritygroupsresponse', 'While requesting security groups', 'Authentication failure: please check the provided access credentials'));
          Ember.set(_this2, 'errors', errors);
          Ember.set(_this2, 'step', 3);
        });
      },


      /* Security group selection */
      selectSecurityGroup: function selectSecurityGroup() {
        var _this3 = this;

        Ember.set(this, 'errors', null);

        /* When selecting a custom security group, we don't have to do anything more */
        if (Ember.get(this, 'isCustomSecurityGroup')) {
          Ember.set(this, 'config.securityGroup', [Ember.get(this, 'securityGroup')]);
          this.fetchInstanceSettings();

          return;
        }

        /* Otherwise, do we need to create the default security group? */
        Ember.set(this, 'config.securityGroup', [Ember.get(this, 'defaultSecurityGroup')]);
        var group = Ember.get(this, 'defaultSecurityGroup');

        /* Check if default security group allready exist in all security group*/
        var i;

        for (i = 0; i < this.allSecurityGroups.length; i++) {
          if (this.allSecurityGroups[i].name === group) {
            Ember.set(this, 'config.securityGroup', [group]);
            this.fetchInstanceSettings();

            /* Already exists, we assume that it contains the appropriate rules */
            return;
          }
        }

        /* We need to create the security group */
        Ember.set(this, 'step', 6);
        this.apiRequest('createSecurityGroup', {
          name: Ember.get(this, 'defaultSecurityGroup'),
          description: Ember.get(this, 'settings.appName') + ' default security group'
        }).then(function (res) {
          RANCHER_INGRESS_RULES.push({
            startport: 2379,
            endport: 2380,
            'usersecuritygrouplist[0].account': res.createsecuritygroupresponse.securitygroup.account,
            'usersecuritygrouplist[0].group': res.createsecuritygroupresponse.securitygroup.name,
            protocol: 'TCP'
          });

          return (0, _async.eachSeries)(RANCHER_INGRESS_RULES, function (item, cb) {
            item.securitygroupid = res.createsecuritygroupresponse.securitygroup.id;
            _this3.apiRequest('authorizeSecurityGroupIngress', item).then(function () {
              return cb();
            }, function (err) {
              return cb(err);
            });
          }, function (err) {
            if (err) {
              var errors = Ember.get(_this3, 'errors') || [];

              errors.pushObject(_this3.apiErrorMessage(err, 'authorizesecuritygroupingressresponse', 'While setting default security group', 'Unable to configure the default security group'));
              Ember.set(_this3, 'errors', errors);
              Ember.set(_this3, 'step', 5);
            } else {
              _this3.fetchInstanceSettings();
            }
          });
        }, function (err) {
          var errors = Ember.get(_this3, 'errors') || [];

          errors.pushObject(_this3.apiErrorMessage(err, 'createsecuritygroupresponse', 'While creating default security group', 'Unable to create the default security group'));
          Ember.set(_this3, 'errors', errors);
          Ember.set(_this3, 'step', 5);
        });
      }
    },

    afterInit: function () {
      this._super();

      var cur = Ember.get(this, 'config.securityGroup');

      if (cur === RANCHER_GROUP) {
        this.setProperties({
          whichSecurityGroup: 'default',
          securityGroup: null
        });
      } else {
        this.setProperties({
          whichSecurityGroup: 'custom',
          securityGroup: [cur]
        });
      }
    }.on('init'),

    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: 'exoscaleConfig',
        apiKey: '',
        apiSecretKey: '',
        diskSize: 50,
        image: DEFAULT_TEMPLATE,
        instanceProfile: 'Medium',
        securityGroup: RANCHER_GROUP
      });

      var model = Ember.get(this, 'model');

      Ember.set(model, 'exoscaleConfig', config);
    },
    fetchInstanceSettings: function fetchInstanceSettings() {
      var _this4 = this;

      Ember.set(this, 'step', 7);

      /* First, get a list of templates to get available disk sizes */
      this.apiRequest('listTemplates', {
        templatefilter: 'featured',
        zoneid: Ember.get(this, 'config.zone')
      }).then(function (res) {
        Ember.set(_this4, 'allTemplates', res.listtemplatesresponse.template.filter(function (item) {
          return item.name.startsWith('Linux');
        }).map(function (item) {
          return item.name;
        }).sort().uniq());

        /* Also get the instance types */
        return _this4.apiRequest('listServiceOfferings', { issystem: 'false' }).then(function (res) {
          Ember.set(_this4, 'allInstanceProfiles', res.listserviceofferingsresponse.serviceoffering.sort(function (a, b) {
            if (a.memory < b.memory) {
              return -1;
            }
            if (b.memory < a.memory) {
              return 1;
            }

            return 0;
          }).map(function (item) {
            return {
              name: item.name,
              displaytext: item.displaytext
            };
          }));
          Ember.set(_this4, 'step', 8);
        }, function (err) {
          var errors = Ember.get(_this4, 'errors') || [];

          errors.pushObject(_this4.apiErrorMessage(err, 'listserviceofferingsresponse', 'While getting list of instance types', 'Unable to get list of instance types'));
          Ember.set(_this4, 'errors', errors);
          Ember.set(_this4, 'step', 5);
        });
      }, function (err) {
        var errors = Ember.get(_this4, 'errors') || [];

        errors.pushObject(_this4.apiErrorMessage(err, 'listtemplatesresponse', 'While getting list of available images', 'Unable to get list of available images'));
        Ember.set(_this4, 'errors', errors);
        Ember.set(_this4, 'step', 5);
      });
    },
    apiErrorMessage: function apiErrorMessage(err, kind, prefix, def) {
      var answer = (err.xhr || {}).responseJSON || {};
      var text = (answer[kind] || {}).errortext;

      if (text) {
        return prefix + ': ' + text;
      } else {
        return def;
      }
    },
    apiRequest: function apiRequest(command, params) {
      var _this5 = this;

      var url = Ember.get(this, 'app.proxyEndpoint') + '/' + this.exoscaleApi;

      params = params || {};
      params.command = command;
      params.apiKey = Ember.get(this, 'config.apiKey');
      params.response = 'json';

      return (0, _ajaxPromise.ajaxPromise)({
        url: url,
        method: 'POST',
        dataType: 'json',
        headers: {
          Accept: 'application/json',
          'X-API-Headers-Restrict': 'Content-Length'
        },
        beforeSend: function beforeSend(xhr, settings) {
          // Append 'rancher:' to Content-Type
          xhr.setRequestHeader('Content-Type', 'rancher:' + settings.contentType);

          // Compute the signature
          var qs = settings.data.split('&').map(function (q) {
            return q.replace(/\+/g, '%20').replace(/\%5B/g, '[').replace(/\%5D/g, ']');
          }).map(Function.prototype.call, String.prototype.toLowerCase).sort().join('&');

          settings.data += '&signature=' + encodeURIComponent(AWS.util.crypto.hmac(Ember.get(_this5, 'config.apiSecretKey'), qs, 'base64', 'sha1'));

          return true;
        },
        data: params
      }, true);
    }
  });
});