define('shared/utils/util', ['exports', 'shared/utils/parse-unit', 'ipaddr.js'], function (exports, _parseUnit, _ipaddr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayDiff = arrayDiff;
  exports.arrayIntersect = arrayIntersect;
  exports.compareDisplayEndpoint = compareDisplayEndpoint;
  exports.convertToMillis = convertToMillis;
  exports.filterByValues = filterByValues;
  exports.download = download;
  exports.popupWindowOptions = popupWindowOptions;
  exports.escapeHtml = escapeHtml;
  exports.escapeRegex = escapeRegex;
  exports.addQueryParam = addQueryParam;
  exports.addQueryParams = addQueryParams;
  exports.parseUrl = parseUrl;
  exports.absoluteUrl = absoluteUrl;
  exports.addAuthorization = addAuthorization;
  exports.ucFirst = ucFirst;
  exports.lcFirst = lcFirst;
  exports.strPad = strPad;
  exports.sortableNumericSuffix = sortableNumericSuffix;
  exports.timerFuzz = timerFuzz;
  exports.random32 = random32;
  exports.randomStr = randomStr;
  exports.formatPercent = formatPercent;
  exports.formatMib = formatMib;
  exports.formatSecond = formatSecond;
  exports.formatKbps = formatKbps;
  exports.roundValue = roundValue;
  exports.formatGB = formatGB;
  exports.constructUrl = constructUrl;
  exports.pluralize = pluralize;
  exports.uniqKeys = uniqKeys;
  exports.camelToTitle = camelToTitle;
  exports.isNumeric = isNumeric;
  exports.hostname = hostname;
  exports.isPrivate = isPrivate;
  exports.stripScheme = stripScheme;
  exports.isBadTld = isBadTld;
  exports.removeEmpty = removeEmpty;
  exports.underlineToCamel = underlineToCamel;
  exports.keysToCamel = keysToCamel;
  exports.deepCopy = deepCopy;
  exports.validateEndpoint = validateEndpoint;
  exports.validateCertWeakly = validateCertWeakly;
  exports.validateKeyWeakly = validateKeyWeakly;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function arrayDiff(a, b) {
    return a.filter(function (i) {
      return !b.includes(i);
    });
  }

  function arrayIntersect(a, b) {
    return a.filter(function (i) {
      return b.includes(i);
    });
  }

  function compareDisplayEndpoint(i1, i2) {
    if (!i1) {
      return 1;
    }

    if (!i2) {
      return -1;
    }

    var in1 = i1.displayEndpoint;
    var in2 = i2.displayEndpoint;

    if (!in1) {
      return 1;
    }

    if (!in2) {
      return -1;
    }

    if (in1.startsWith('/') && !in2.startsWith('/')) {
      return -1;
    } else if (!in1.startsWith('/') && in2.startsWith('/')) {
      return 1;
    }

    if (in1 === '80/http' && in2 !== '80/http') {
      return -1;
    } else if (in1 !== '80/http' && in2 === '80/http') {
      return 1;
    }

    if (in1 === '443/https' && in2 !== '443/https') {
      return -1;
    } else if (in1 !== '443/https' && in2 === '443/https') {
      return 1;
    }

    return in1.localeCompare(in2);
  }

  function convertToMillis(strValue) {
    if (!strValue) {
      return '';
    }
    if (strValue.endsWith('m')) {
      return parseInt(strValue.substr(0, strValue.length - 1), 10);
    } else {
      return parseInt(strValue, 10) * 1000;
    }
  }

  function filterByValues(ary, field, values) {
    return ary.filter(function (obj) {
      return values.includes(obj.get(field));
    });
  }

  function download(url) {
    var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '__downloadIframe';

    var iframe = document.getElementById(id);

    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.id = id;
      document.body.appendChild(iframe);
    }

    iframe.src = url;
  }

  function popupWindowOptions(width, height) {
    var s = window.screen;
    var opt = {
      width: Math.min(s.width, width || 1040),
      height: Math.min(s.height, height || 768),
      resizable: 1,
      scrollbars: 1
    };

    opt.left = Math.max(0, (s.width - opt.width) / 2);
    opt.top = Math.max(0, (s.height - opt.height) / 2);

    var optStr = Object.keys(opt).map(function (k) {
      return k + '=' + opt[k];
    }).join(',');

    return optStr;
  }

  var entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
  };

  function escapeHtml(html) {
    return String(html).replace(/[&<>"'\/]/g, function (s) {
      return entityMap[s];
    });
  }

  function escapeRegex(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  function addQueryParam(url, key, val) {
    var out = url + (url.indexOf('?') >= 0 ? '&' : '?');

    // val can be a string or an array of strings
    if (!Array.isArray(val)) {
      val = [val];
    }
    out += val.map(function (v) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(v);
    }).join('&');

    return out;
  }

  function addQueryParams(url, params) {
    if (params && (typeof params === 'undefined' ? 'undefined' : _typeof(params)) === 'object') {
      Object.keys(params).forEach(function (key) {
        url = addQueryParam(url, key, params[key]);
      });
    }

    return url;
  }

  function parseUrl(url) {
    var a = document.createElement('a');

    a.href = url;

    return a.cloneNode(false);
  }

  function absoluteUrl(url) {
    return parseUrl(url).href;
  }

  function addAuthorization(url, user, pass) {
    url = absoluteUrl(url);
    var pos = url.indexOf('//');

    if (pos >= 0) {
      url = url.substr(0, pos + 2) + (user ? encodeURIComponent(user) : '') + (pass ? ':' + encodeURIComponent(pass) : '') + '@' + url.substr(pos + 2);
    } else {
      throw new Error('That doesn\'t look like a URL: ' + url);
    }

    return url;
  }

  function ucFirst(str) {
    str = str || '';

    return str.substr(0, 1).toUpperCase() + str.substr(1);
  }

  function lcFirst(str) {
    str = str || '';

    return str.substr(0, 1).toLowerCase() + str.substr(1);
  }

  function strPad(str, toLength, padChars, right) {
    str = '' + str;
    padChars = padChars || ' ';

    if (str.length >= toLength) {
      return str;
    }

    var neededLen = toLength - str.length + 1;
    var padStr = new Array(neededLen).join(padChars);

    padStr = padStr.substr(0, neededLen);

    if (right) {
      return str + padStr;
    } else {
      return padStr + str;
    }
  }

  // Turn thing1 into thing00000001 so that the numbers sort numerically
  function sortableNumericSuffix(str) {
    str = str || '';
    var match = str.match(/^(.*[^0-9])([0-9]+)$/);

    if (match) {
      return match[1] + Util.strPad(match[2], 8, '0');
    }

    return str;
  }

  function timerFuzz(ms) {
    var maxFuzz = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.1;

    var factor = Math.random() * 2 * maxFuzz + (1 - maxFuzz);

    return Math.max(1, ms * factor);
  }

  function random32(count) {
    count = Math.max(0, count || 1);
    var out = [];
    var i;

    if (window.crypto && window.crypto.getRandomValues) {
      var tmp = new Uint32Array(count);

      window.crypto.getRandomValues(tmp);
      for (i = 0; i < tmp.length; i++) {
        out[i] = tmp[i];
      }
    } else {
      for (i = 0; i < count; i++) {
        out[i] = Math.random() * 4294967296; // Math.pow(2,32);
      }
    }

    if (count === 1) {
      return out[0];
    } else {
      return out;
    }
  }

  var alpha = 'abcdefghijklmnopqrstuvwxyz';
  var num = '0123456789';
  var sym = '!@#$%^&*()_+-=[]{};:,./<>?|';
  var randomCharsets = {
    numeric: num,
    novowels: 'bcdfghjklmnpqrstvwxz2456789',
    loweralpha: alpha,
    upperalpha: alpha.toUpperCase(),
    hex: num + 'ABCDEF',
    alpha: alpha + alpha.toUpperCase(),
    alphanum: alpha + alpha.toUpperCase() + num,
    password: alpha + alpha.toUpperCase() + num + alpha + alpha.toUpperCase() + num + sym
    // ^-- includes alpha / ALPHA / num twice to reduce the occurrence of symbols
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getCharset(str) {
    var out = '';

    for (var i = 0; i < str.length; i++) {
      var current = str.charAt(i);

      if (current !== '-') {
        out += current;
      } else {
        var start = str.charAt(i - 1);
        var end = str.charAt(i + 1);
        var alphanum = randomCharsets.alphanum;

        out += alphanum.substr(alphanum.indexOf(start) + 1, alphanum.indexOf(end));
        i++;
      }
    }

    return out;
  }

  function randomStr() {
    var minLength = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;
    var maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
    var charset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'alphanum';

    var chars = randomCharsets[charset];

    if (!chars) {
      if (charset) {
        chars = getCharset(charset);
      } else {
        return null;
      }
    }

    var charCount = chars.length;

    return random32(getRandomInt(minLength, maxLength)).map(function (val) {
      return chars[val % charCount];
    }).join('');
  }

  function formatPercent(value) {
    var maxPrecision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (value < 1 && maxPrecision >= 2) {
      return Math.round(value * 100) / 100 + '%';
    } else if (value < 10 && maxPrecision >= 1) {
      return Math.round(value * 10) / 10 + '%';
    } else {
      return Math.round(value) + '%';
    }
  }

  function formatMib(value) {
    return (0, _parseUnit.formatSi)(value, 1024, 'iB', 'B', 2);
  }

  function formatSecond(value) {
    if (value < 0.1) {
      return roundValue(value * 1000) + ' ms';
    } else {
      return roundValue(value) + ' s';
    }
  }

  function formatKbps(value) {
    return (0, _parseUnit.formatSi)(value, 1000, 'bps', 'Bps', 1);
  }

  function roundValue(value) {
    if (value < 1) {
      return Math.round(value * 100) / 100;
    } else if (value < 10) {
      return Math.round(value * 10) / 10;
    } else {
      return Math.round(value);
    }
  }

  function formatGB(inMB) {
    return (0, _parseUnit.formatSi)(inMB, 1000, 'B', 'B', 2);
  }

  function constructUrl(ssl, host, port) {
    var out = 'http' + (ssl ? 's' : '') + '://' + host;

    if (ssl && port !== 443 || !ssl && port !== 80) {
      out += ':' + port;
    }

    return out;
  }

  function pluralize(count, singular, plural) {
    if (!plural) {
      if (singular.substr(-1, 1) === 's') {
        plural = singular + 'es';
      } else {
        plural = singular + 's';
      }
    }

    if (count === 1) {
      return count + ' ' + singular;
    } else {
      return count + ' ' + plural;
    }
  }

  function uniqKeys(data) {
    var field = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

    // Make a map of all the unique category names.
    // If multiple casings of the same name are present, first wins.
    var cased = {};

    data.map(function (obj) {
      return field ? obj[field] : obj;
    }).filter(function (str) {
      return str && str.length;
    }).forEach(function (str) {
      var lc = str.toLowerCase();

      if (!cased[lc]) {
        cased[lc] = str;
      }
    });

    return Object.keys(cased).uniq().sort().map(function (str) {
      return cased[str];
    });
  }

  function camelToTitle(str) {
    return (str || '').dasherize().split('-').map(function (str) {
      return ucFirst(str);
    }).join(' ');
  }

  function isNumeric(str) {
    return ('' + str).match(/^([0-9]+\.)?[0-9]*$/);
  }

  function hostname(str) {
    return (str || '').trim().replace(/^[a-z0-9]+:\/+/i, '').replace(/\/.*$/g, '');
  }
  function isPrivate(name) {
    return _ipaddr.default.parse(name).range() === 'private' ? true : false;
  }

  function stripScheme(url) {
    var val = (url || '').trim();

    return val.replace(/^https?:\/\//, '');
  }

  function isBadTld(name) {
    if (hostname(name).match(/\.local$/)) {
      return true;
    } else {
      return false;
    }
  }

  function keyNotType(k) {
    return Object.keys(k).filter(function (key) {
      return key !== 'type';
    }).length > 0;
  }

  function removeEmpty(obj) {
    var excludedKeys = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    return Object.keys(obj).filter(function (k) {
      return !Ember.isEmpty(obj[k]) && (_typeof(obj[k]) !== 'object' || keyNotType(obj[k]));
    }).reduce(function (newObj, k) {
      return !Ember.isArray(obj[k]) && _typeof(obj[k]) === 'object' && excludedKeys.indexOf(k) === -1 ? Object.assign(newObj, _defineProperty({}, k, removeEmpty(obj[k], excludedKeys))) : Object.assign(newObj, _defineProperty({}, k, obj[k]));
    }, {});
  }

  function underlineToCamel(str) {
    return (str || '').split('_').map(function (t, i) {
      if (i === 0) {
        return t;
      } else {
        return t === 'qps' ? 'QPS' : t.capitalize();
      }
    }).join('');
  }

  function keysToCamel(obj) {
    if (!(typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object' || typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return obj;
    }
    var keys = Object.keys(obj);
    var n = keys.length;

    while (n--) {
      var key = keys[n];
      var titleKey = underlineToCamel(key);

      obj[titleKey] = keysToCamel(obj[key]);
      if (key !== titleKey) {
        delete obj[key];
      }
    }

    return obj;
  }

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function validateEndpoint(str) {
    // credit to https://stackoverflow.com/questions/4460586/javascript-regular-expression-to-check-for-ip-addresses
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(str)
    );
  }

  function validateCertWeakly(certs) {
    var BEGIN_CERTIFICATE = ['-----BEGIN CERTIFICATE-----'];

    return certs.trim().startsWith(BEGIN_CERTIFICATE[0]);
  }

  function validateKeyWeakly(key) {
    var valid = false;
    var BEGIN_KEY = ['-----BEGIN PRIVATE KEY-----', '-----BEGIN EC PRIVATE KEY-----', '-----BEGIN RSA PRIVATE KEY-----'];

    BEGIN_KEY.forEach(function (prefix) {
      if (key.trim().startsWith(prefix)) {
        valid = true;
      }
    });

    return valid;
  }

  var Util = {
    absoluteUrl: absoluteUrl,
    addAuthorization: addAuthorization,
    addQueryParam: addQueryParam,
    addQueryParams: addQueryParams,
    arrayDiff: arrayDiff,
    arrayIntersect: arrayIntersect,
    compareDisplayEndpoint: compareDisplayEndpoint,
    camelToTitle: camelToTitle,
    convertToMillis: convertToMillis,
    constructUrl: constructUrl,
    download: download,
    deepCopy: deepCopy,
    escapeHtml: escapeHtml,
    escapeRegex: escapeRegex,
    filterByValues: filterByValues,
    formatGB: formatGB,
    formatKbps: formatKbps,
    formatMib: formatMib,
    formatSecond: formatSecond,
    formatPercent: formatPercent,
    hostname: hostname,
    isBadTld: isBadTld,
    isNumeric: isNumeric,
    isPrivate: isPrivate,
    keysToCamel: keysToCamel,
    lcFirst: lcFirst,
    parseUrl: parseUrl,
    pluralize: pluralize,
    popupWindowOptions: popupWindowOptions,
    random32: random32,
    randomStr: randomStr,
    removeEmpty: removeEmpty,
    roundValue: roundValue,
    sortableNumericSuffix: sortableNumericSuffix,
    strPad: strPad,
    stripScheme: stripScheme,
    timerFuzz: timerFuzz,
    ucFirst: ucFirst,
    uniqKeys: uniqKeys,
    underlineToCamel: underlineToCamel,
    validateEndpoint: validateEndpoint
  };

  window.Util = Util;
  exports.default = Util;
});