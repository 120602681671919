define('shared/mixins/safe-style', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    safeStyle: null,
    _safeStyle: function () {
      if (this.get('safeStyle')) {
        return this.get('safeStyle').htmlSafe();
      } else {
        return ''.htmlSafe();
      }
    }.property('safeStyle'),

    attributeBindings: ['_safeStyle:style']
  });
});