define('global-admin/security/policies/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      return this.get('globalStore').find('podSecurityPolicyTemplate');
    }
  });
});