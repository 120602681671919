enifed('ember-template-compiler/lib/plugins/deprecate-render', ['exports', '@ember/debug', '@ember/deprecated-features', 'ember-template-compiler/lib/system/calculate-location-display'], function (exports, _debug, _deprecatedFeatures, _calculateLocationDisplay) {
    'use strict';

    exports.default =

    // Remove after 3.4 once _ENABLE_RENDER_SUPPORT flag is no longer needed.
    function (env) {
        var moduleName, deprecationMessage;

        if (_deprecatedFeatures.RENDER_HELPER) {
            moduleName = env.meta.moduleName;
            deprecationMessage = function (node) {
                var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
                var componentName = node.params[0].original;

                return 'Please refactor `' + ('{{render "' + componentName + '"}}') + '` to a component and invoke via' + (' `' + ('{{' + componentName + '}}') + '`. ' + sourceInformation);
            };

            return {
                name: 'deprecate-render',
                visitor: {
                    MustacheStatement: function (node) {
                        if (node.path.original !== 'render') {
                            return;
                        }
                        if (node.params.length !== 1) {
                            return;
                        }
                        node.params.forEach(function (param) {
                            if (param.type !== 'StringLiteral') {
                                return;
                            }
                            true && !false && (0, _debug.deprecate)(deprecationMessage(node), false, {
                                id: 'ember-template-compiler.deprecate-render',
                                until: '3.0.0',
                                url: 'https://emberjs.com/deprecations/v2.x#toc_code-render-code-helper'
                            });
                        });
                    }
                }
            };
        }
        return undefined;
    };
});