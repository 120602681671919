enifed('ember-template-compiler/tests/plugins/deprecate-send-action-test', ['ember-babel', 'ember-template-compiler/index', 'internal-test-helpers'], function (_emberBabel, _index, _internalTestHelpers) {
  'use strict';

  var DeprecateSendActionTest = function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(DeprecateSendActionTest, _AbstractTestCase);

    function DeprecateSendActionTest() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    return DeprecateSendActionTest;
  }(_internalTestHelpers.AbstractTestCase);

  ['insert-newline', 'enter', 'escape-press', 'focus-in', 'focus-out', 'key-press', 'key-up', 'key-down'].forEach(function (e) {
    DeprecateSendActionTest.prototype['@test Using `{{input ' + e + '="actionName"}}` provides a deprecation'] = function () {

      expectDeprecation(function () {
        (0, _index.compile)('{{input ' + e + '="foo-bar"}}', { moduleName: 'baz/foo-bar' });
      }, 'Please refactor `{{input ' + e + '="foo-bar"}}` to `{{input ' + e + '=(action "foo-bar")}}. (\'baz/foo-bar\' @ L1:C0) ');
    };
  });

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: deprecate-send-action', DeprecateSendActionTest);
});