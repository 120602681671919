define('shared/components/input-paste/component', ['exports', 'shared/mixins/intl-placeholder', 'shared/components/input-paste/template'], function (exports, _intlPlaceholder, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend(_intlPlaceholder.default, {
    layout: _template.default,

    separators: null,

    _onPaste: null,

    didInsertElement: function didInsertElement() {
      this._super();

      this.set('_onPaste', this.handlePaste.bind(this));
      this.$().on('paste', Ember.get(this, '_onPaste'));
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('paste', Ember.get(this, '_onPaste'));
      this._super();
    },
    handlePaste: function handlePaste(event) {
      var e = event.originalEvent;
      var separators = Ember.get(this, 'separators');

      if (e && e.clipboardData && e.clipboardData.getData && e.clipboardData.types) {
        if (e.clipboardData.types.includes('text/plain')) {
          var text = e.clipboardData.getData('text/plain');
          var hasSeparator = true;

          if (text && separators) {
            hasSeparator = separators.some(function (separator) {
              return text.indexOf(separator) > -1;
            });
          }

          if (text && hasSeparator) {
            e.stopPropagation();
            e.preventDefault();
            this.sendAction('pasted', text, event.target);

            return false;
          }
        }

        return true;
      }
    }
  });
});