define('shared/components/storage-class/provisioner-azure-disk/component', ['exports', 'shared/components/storage-class/provisioner-azure-disk/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KIND_OPTIONS = [{
    value: 'shared',
    translationKey: 'cruStorageClass.azure-disk.kind.shared'
  }, {
    value: 'dedicated',
    translationKey: 'cruStorageClass.azure-disk.kind.dedicated'
  }, {
    value: 'managed',
    translationKey: 'cruStorageClass.azure-disk.kind.managed'
  }];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    kindOptions: KIND_OPTIONS,

    skuName: null,
    location: null,
    storageAccount: null,
    storageaccounttype: null,

    kind: 'shared',
    provisioner: 'azure-disk',

    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};
      var _parameters = this.parameters,
          parameters = _parameters === undefined ? {} : _parameters;
      var _parameters$storageac = parameters.storageaccounttype,
          storageaccounttype = _parameters$storageac === undefined ? '' : _parameters$storageac,
          _parameters$kind = parameters.kind,
          kind = _parameters$kind === undefined ? 'shared' : _parameters$kind;


      changes['storageaccounttype'] = storageaccounttype;
      changes['kind'] = kind;

      Ember.setProperties(this, changes);
    },


    // registered in the StorageClassProvisioner mixin
    updateParams: function updateParams() {
      var storageaccounttype = this.storageaccounttype,
          kind = this.kind,
          _out = this.out,
          out = _out === undefined ? {} : _out;


      if (storageaccounttype) {
        out['storageaccounttype'] = storageaccounttype;
      }
      if (kind) {
        out['kind'] = kind;
      }

      Ember.set(this, 'parameters', out);
    }
  });
});