define('global-admin/multi-cluster-apps/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    catalog: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),

    model: function model() {
      return this.globalStore.findAll('multiclusterapp').then(function (resp) {
        return { apps: resp };
      });
    },
    afterModel: function afterModel(model /* , transition */) {
      var _this = this;

      var promises = [];

      Ember.get(model, 'apps').forEach(function (app) {
        promises.push(_this.catalog.fetchTemplate(Ember.get(app, 'templateVersionId'), true));
      });

      // we don't have to retrun the promise here because this data is really needed for the upgrade data. because we dont have it doesn't mean we should crash the page.
      Ember.RSVP.allSettled(promises).then(function () {
        _this.catalog.fetchMultiClusterAppTemplates(Ember.get(model, 'apps'));
      });

      return;
    }
  });
});