enifed('ember-views/lib/utils/lookup-component', ['exports'], function (exports) {
  'use strict';

  exports.default = function (owner, name, options) {
    var componentLookup = owner.lookup('component-lookup:main'),
        localResult;

    if (options && (options.source || options.namespace)) {
      localResult = lookupComponentPair(componentLookup, owner, name, options);


      if (localResult.component || localResult.layout) {
        return localResult;
      }
    }

    return lookupComponentPair(componentLookup, owner, name);
  };

  function lookupComponentPair(componentLookup, owner, name, options) {

    var component = componentLookup.componentFor(name, owner, options);
    var layout = componentLookup.layoutFor(name, owner, options);

    return { layout: layout, component: component };
  }
});