enifed('@ember/canary-features/index', ['exports', '@ember/polyfills', 'ember-environment'], function (exports, _polyfills, _emberEnvironment) {
    'use strict';

    exports.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION = exports.EMBER_TEMPLATE_BLOCK_LET_HELPER = exports.GLIMMER_CUSTOM_COMPONENT_MANAGER = exports.EMBER_METAL_TRACKED_PROPERTIES = exports.EMBER_MODULE_UNIFICATION = exports.EMBER_ENGINES_MOUNT_PARAMS = exports.EMBER_ROUTING_ROUTER_SERVICE = exports.EMBER_GLIMMER_NAMED_ARGUMENTS = exports.EMBER_IMPROVED_INSTRUMENTATION = exports.EMBER_LIBRARIES_ISREGISTERED = exports.FEATURES = exports.DEFAULT_FEATURES = undefined;
    exports.isEnabled =
    /**
      Determine whether the specified `feature` is enabled. Used by Ember's
      build tools to exclude experimental features from beta/stable builds.
    
      You can define the following configuration options:
    
      * `EmberENV.ENABLE_OPTIONAL_FEATURES` - enable any features that have not been explicitly
        enabled/disabled.
    
      @method isEnabled
      @param {String} feature The feature to check
      @return {Boolean}
      @for Ember.FEATURES
      @since 1.1.0
      @public
    */
    function (feature) {
        var featureValue = FEATURES[feature];
        if (featureValue === true || featureValue === false) {
            return featureValue;
        } else if (_emberEnvironment.ENV.ENABLE_OPTIONAL_FEATURES) {
            return true;
        } else {
            return false;
        }
    };

    /**
     @module ember/canary-features
     @private
    */
    var DEFAULT_FEATURES = exports.DEFAULT_FEATURES = {
        EMBER_LIBRARIES_ISREGISTERED: false,
        EMBER_IMPROVED_INSTRUMENTATION: false,
        EMBER_GLIMMER_NAMED_ARGUMENTS: true,
        EMBER_ROUTING_ROUTER_SERVICE: true,
        EMBER_ENGINES_MOUNT_PARAMS: true,
        EMBER_MODULE_UNIFICATION: false,
        GLIMMER_CUSTOM_COMPONENT_MANAGER: true,
        EMBER_TEMPLATE_BLOCK_LET_HELPER: true,
        EMBER_METAL_TRACKED_PROPERTIES: false,
        EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION: true
    };
    /**
      The hash of enabled Canary features. Add to this, any canary features
      before creating your application.
    
      Alternatively (and recommended), you can also define `EmberENV.FEATURES`
      if you need to enable features flagged at runtime.
    
      @class FEATURES
      @namespace Ember
      @static
      @since 1.1.0
      @public
    */
    var FEATURES = exports.FEATURES = (0, _polyfills.assign)(DEFAULT_FEATURES, _emberEnvironment.ENV.FEATURES);
    function featureValue(value) {
        if (_emberEnvironment.ENV.ENABLE_OPTIONAL_FEATURES && value === null) {
            return true;
        }
        return value;
    }
    exports.EMBER_LIBRARIES_ISREGISTERED = featureValue(FEATURES.EMBER_LIBRARIES_ISREGISTERED);
    exports.EMBER_IMPROVED_INSTRUMENTATION = featureValue(FEATURES.EMBER_IMPROVED_INSTRUMENTATION);
    exports.EMBER_GLIMMER_NAMED_ARGUMENTS = featureValue(FEATURES.EMBER_GLIMMER_NAMED_ARGUMENTS);
    exports.EMBER_ROUTING_ROUTER_SERVICE = featureValue(FEATURES.EMBER_ROUTING_ROUTER_SERVICE);
    exports.EMBER_ENGINES_MOUNT_PARAMS = featureValue(FEATURES.EMBER_ENGINES_MOUNT_PARAMS);
    exports.EMBER_MODULE_UNIFICATION = featureValue(FEATURES.EMBER_MODULE_UNIFICATION);
    exports.EMBER_METAL_TRACKED_PROPERTIES = featureValue(FEATURES.EMBER_METAL_TRACKED_PROPERTIES);
    exports.GLIMMER_CUSTOM_COMPONENT_MANAGER = featureValue(FEATURES.GLIMMER_CUSTOM_COMPONENT_MANAGER);
    exports.EMBER_TEMPLATE_BLOCK_LET_HELPER = featureValue(FEATURES.EMBER_TEMPLATE_BLOCK_LET_HELPER);
    exports.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION = featureValue(FEATURES.EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION);
});