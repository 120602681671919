define('shared/mixins/modal-base', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['modal-container'],

    modalService: Ember.inject.service('modal'),
    modalOpts: Ember.computed.alias('modalService.modalOpts'),

    keyUp: function keyUp(e) {
      if (e.which === _constants.default.KEY.ESCAPE && this.escToClose()) {
        this.get('modalService').toggleModal();
      }
    },
    escToClose: function escToClose() {
      var modalService = this.get('modalService');

      if (modalService.get('modalVisible') && modalService.get('modalOpts.escToClose')) {
        return true;
      } else {
        return false;
      }
    },


    actions: {
      close: function close() {
        this.get('modalService').toggleModal();
      },
      cancel: function cancel() {
        this.get('modalService').toggleModal();
      }
    }
  });
});