define('shared/components/cru-cloud-provider/component', ['exports', 'shared/components/cru-cloud-provider/template', 'ui/utils/constants', 'shared/components/cru-cloud-provider/cloud-provider-info'], function (exports, _template, _constants, _cloudProviderInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var azureDefaults = _constants.default.AZURE_DEFAULTS;
  var GENERIC_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.cloudConfig';
  var AWS_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.awsCloudProvider';
  var AZURE_PATH = 'cluster.rancherKubernetesEngineConfig.cloudProvider.azureCloudProvider';

  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    configType: null,
    cluster: null,
    driver: null,
    selectedCloudProvider: 'none',
    mode: 'new',
    hasBuiltIn: false,
    configAnswers: null,
    azureDefaults: azureDefaults,
    azureDescriptions: _cloudProviderInfo.azure,

    configName: Ember.computed.alias('cluster.rancherKubernetesEngineConfig.cloudProvider.name'),

    init: function init() {
      this._super.apply(this, arguments);

      var cloudProviderName = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.name');

      if (cloudProviderName === 'aws') {
        Ember.setProperties(this, {
          selectedCloudProvider: 'amazonec2',
          configAnswers: Ember.get(this, AWS_PATH)
        });
      } else if (cloudProviderName === 'azure') {
        var reorderedAnswers = this.prioritizeCloudConfigFields(this.globalStore.getById('schema', 'azurecloudprovider'), Ember.get(this, AZURE_PATH));

        this.setCpFields('azureCloudProvider', reorderedAnswers);

        Ember.setProperties(this, {
          selectedCloudProvider: 'azure',
          configAnswers: reorderedAnswers
        });
      } else if (!cloudProviderName) {
        Ember.set(this, 'selectedCloudProvider', 'none');
      } else {
        Ember.setProperties(this, {
          selectedCloudProvider: 'generic',
          configAnswers: Ember.get(this, GENERIC_PATH)
        });
      }
    },


    driverDidChange: Ember.observer('driver', function () {
      Ember.set(this, 'selectedCloudProvider', 'none');
    }),

    modeChanged: Ember.observer('selectedCloudProvider', function () {
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');

      if (selectedCloudProvider !== 'none') {
        this.constructConfig();
      } else {
        var config = Ember.get(this, 'cluster.rancherKubernetesEngineConfig');

        if (config && Ember.get(config, 'cloudProvider')) {
          delete config.cloudProvider;
        }
      }
    }),

    configAnswersDidChange: Ember.observer('configAnswers', function () {
      var configAnswers = Ember.get(this, 'configAnswers');
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');

      switch (selectedCloudProvider) {
        case 'azure':

          Ember.set(this, AZURE_PATH, configAnswers);

          break;

        case 'amazonec2':

          Ember.set(this, AWS_PATH, configAnswers);

          break;

        default:

          Ember.set(this, GENERIC_PATH, configAnswers);

          break;
      }
    }),
    checkDefaults: function checkDefaults(record) {
      Ember.get(this, 'azureDefaults').forEach(function (def) {
        if (Ember.isEmpty(record[def])) {
          Ember.set(record, def, null);
        }
      });
    },
    prioritizeCloudConfigFields: function prioritizeCloudConfigFields(schema, answers) {
      var schemaFields = schema.getFieldNames();
      var resourceFields = Ember.get(schema, 'resourceFields');
      var passwordFields = schemaFields.filter(function (item) {
        return Ember.get(resourceFields, item + '.type') === 'password';
      }).sort();
      var keysWithoutFields = schemaFields.filter(function (item) {
        return !passwordFields.includes(item);
      }).sort();
      var prioritizedKeys = keysWithoutFields.unshiftObjects(passwordFields);
      var reorderedFields = {};

      prioritizedKeys.forEach(function (item) {
        var resourceField = Ember.get(resourceFields, item);

        if (answers.hasOwnProperty(item)) {
          Ember.set(reorderedFields, item, Ember.get(answers, item));
        } else {
          if (Ember.get(resourceField, 'type') === 'password') {
            Ember.set(reorderedFields, item, null);
          }
        }
      });

      return reorderedFields;
    },
    constructConfig: function constructConfig() {
      var nue = {};
      var selectedCloudProvider = Ember.get(this, 'selectedCloudProvider');
      var cluster = Ember.get(this, 'cluster');
      var config = Ember.get(cluster, 'rancherKubernetesEngineConfig') || Ember.set(cluster, 'rancherKubernetesEngineConfig', {});

      switch (selectedCloudProvider) {
        case 'azure':

          nue = Ember.get(this, 'globalStore').createRecord({ type: 'azureCloudProvider' });

          delete nue.type;

          this.checkDefaults(nue);

          nue = this.prioritizeCloudConfigFields(this.globalStore.getById('schema', 'azurecloudprovider'), nue);

          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'azure',
            azureCloudProvider: nue
          }));

          Ember.setProperties(this, {
            hasBuiltIn: true,
            'cluster.rancherKubernetesEngineConfig': config,
            configAnswers: nue
          });

          break;

        case 'amazonec2':

          nue = Ember.get(this, 'globalStore').createRecord({ type: 'awsCloudProvider' });

          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            name: 'aws',
            awsCloudProvider: nue
          }));

          Ember.set(this, 'configAnswers', nue);

          break;

        default:

          Ember.set(config, 'cloudProvider', Ember.get(this, 'globalStore').createRecord({
            type: 'cloudProvider',
            cloudConfig: nue
          }));

          Ember.set(this, 'configAnswers', nue);

          break;
      }
    },
    setCpFields: function setCpFields() {
      throw new Error('setCpFields action is required!');
    }
  });
});