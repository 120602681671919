define('global-admin/security/roles/index/controller', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HEADERS = [{
    name: 'state',
    sort: ['state', 'name'],
    translationKey: 'generic.state',
    type: 'string',
    width: 125
  }, {
    name: 'name',
    sort: ['name'],
    translationKey: 'rolesPage.index.table.name'
  }, {
    name: 'builtin',
    sort: ['builtin'],
    translationKey: 'rolesPage.index.table.builtin',
    width: 120
  }, {
    name: 'Default',
    sort: ['newUserDefault', 'clusterCreatorDefault', 'projectCreatorDefault'],
    translationKey: 'rolesPage.index.table.global',
    width: 120
  }, {
    classNames: 'text-right pr-20',
    name: 'created',
    sort: ['created'],
    searchField: false,
    translationKey: 'generic.created',
    width: 250
  }];

  exports.default = Ember.Controller.extend({
    queryParams: ['context'],

    sortBy: 'name',
    context: 'global',
    searchText: '',
    headers: null,
    showOnlyDefaults: false,

    readableMode: Ember.computed('context', function () {
      return Ember.get(this, 'context').capitalize();
    }),

    globalRows: Ember.computed('model.globalRoles.@each.{name,state}', function () {
      return Ember.get(this, 'model.globalRoles');
    }),

    projectRows: Ember.computed('model.roleTemplates.@each.{name,state}', function () {
      // context should not be blank but if it is include it here as well, we removed it from the UI but you could still create a role without context via api
      return Ember.get(this, 'model.roleTemplates').filter(function (role) {
        return !Ember.get(role, 'hidden') && (Ember.get(role, 'context') !== 'cluster' || !role.hasOwnProperty('context'));
      });
    }),

    clusterRows: Ember.computed('model.roleTemplates.@each.{name,state}', function () {
      // context should not be blank but if it is include it here as well, we removed it from the UI but you could still create a role without context via api
      return Ember.get(this, 'model.roleTemplates').filter(function (role) {
        return !Ember.get(role, 'hidden') && Ember.get(role, 'context') !== 'project' || !role.hasOwnProperty('context');
      });
    }),

    filteredContent: Ember.computed('context', 'model.roleTemplates.@each.{name,state,transitioning}', 'showOnlyDefaults', function () {
      var _this = this;

      var content = null;
      var context = this.context,
          showOnlyDefaults = this.showOnlyDefaults;

      var headers = [].concat(HEADERS);
      var dynamicHeader = headers.findBy('name', 'Default');
      var nueTranslationKey = '';

      switch (context) {
        case 'cluster':
          content = Ember.get(this, 'clusterRows');

          if (showOnlyDefaults) {
            content = content.filterBy('clusterCreatorDefault');
          }

          nueTranslationKey = 'rolesPage.index.table.cluster';

          break;
        case 'project':
          content = Ember.get(this, 'projectRows');

          if (showOnlyDefaults) {
            content = content.filterBy('projectCreatorDefault');
          }

          nueTranslationKey = 'rolesPage.index.table.project';

          break;
        case 'global':
          content = Ember.get(this, 'globalRows');
          headers = headers.filter(function (header) {
            return header.name !== 'builtin';
          });

          if (showOnlyDefaults) {
            content = content.filterBy('newUserDefault');
          }

          nueTranslationKey = 'rolesPage.index.table.global';

          break;
        default:
          break;
      }

      Ember.set(dynamicHeader, 'translationKey', nueTranslationKey);

      Ember.run.next(function () {
        Ember.set(_this, 'headers', headers);
      });

      return content.filter(function (row) {
        return _constants.default.ACTIVEISH_STATES.includes(row.state) || row.type === 'globalRole';
      });
    })
  });
});