define('nodes/node-templates/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HEADERS = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.state',
    width: 100
  }, {
    name: 'name',
    sort: ['name', 'id'],
    translationKey: 'nodeTemplatesPage.table.name'
  }, {
    name: 'provider',
    sort: ['displayProvider', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.provider',
    width: 150
  }, {
    name: 'location',
    sort: ['displayLocation', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.location',
    width: 150
  }, {
    name: 'size',
    sort: ['displaySize', 'name', 'id'],
    translationKey: 'nodeTemplatesPage.table.size',
    width: 150
  }];

  exports.default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal'),
    sortBy: 'name',
    headers: HEADERS,

    actions: {
      newTemplate: function newTemplate() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template');
      }
    },

    filteredNodeTemplates: Ember.computed('model.nodeTemplates.@each.{id,name,state,displayLocation,displaySize}', function () {
      return Ember.get(this, 'model.nodeTemplates').filterBy('displayName');
    })
  });
});