define('@rancher/ember-api-store/index', ['exports', '@rancher/ember-api-store/version'], function (exports, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EmberApiStore = Ember.Namespace.create({
    VERSION: _version.default
  });

  if (Ember.libraries) {
    Ember.libraries.registerCoreLibrary('Ember API Store', EmberApiStore.VERSION);
  }

  exports.default = EmberApiStore;
});