define('shared/components/schema/input-boolean/component', ['exports', 'shared/components/schema/input-boolean/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('value') === 'true') {
          _this.set('value', true);
        } else if (_this.get('value') === 'false') {
          _this.set('value', false);
        }
      });
    }
  });
});