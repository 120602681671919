define('shared/bulk-action-handler/service', ['exports', 'shared/utils/download-files'], function (exports, _downloadFiles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    modalService: Ember.inject.service('modal'),
    app: Ember.inject.service(),
    promptStop: function promptStop(nodes) {
      this.get('modalService').toggleModal('modal-container-stop', {
        escToClose: true,
        model: nodes
      });
    },
    promptDelete: function promptDelete(nodes) {
      this.get('modalService').toggleModal('confirm-delete', {
        escToClose: true,
        resources: nodes
      });
    },
    drain: function drain(nodes) {
      this.get('modalService').toggleModal('modal-drain-node', {
        escToClose: true,
        resources: nodes
      });
    },
    move: function move(nodes) {
      this.get('modalService').toggleModal('modal-move-namespace', nodes);
    },
    downloadYaml: function downloadYaml(nodes) {
      (0, _downloadFiles.downloadResourceYaml)(nodes);
    }
  });
});