define('@rancher/ember-api-store/utils/apply-headers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.copyHeaders = copyHeaders;
  exports.applyHeaders = applyHeaders;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  // Copy the headers from `more` into the object `dest`
  // including ones with a value of undefined, so they
  // can be removed later by someone calling applyHeaders.
  function copyHeaders(more, dest) {
    if (!more || (typeof more === 'undefined' ? 'undefined' : _typeof(more)) !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = Ember.get(more, key);
      var normalizedKey = key.toLowerCase();
      dest[normalizedKey] = val;
    });
  }

  // Apply the headers from `more` into the object `dest`
  function applyHeaders(more, dest, copyUndefined) {
    if (!more || (typeof more === 'undefined' ? 'undefined' : _typeof(more)) !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = Ember.get(more, key);
      var normalizedKey = key.toLowerCase();
      if (val === undefined && copyUndefined !== true) {
        delete dest[normalizedKey];
      } else {
        dest[normalizedKey] = val;
      }
    });
  }
});