define('shared/components/input-suggest/component', ['exports', 'shared/components/input-suggest/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    placheolder: null,

    grouped: null, // {group1: [val1, val2], group2: [val3, val4]}
    choices: null, // or [val1, val2, val3, val4]

    classNames: ['input-group'],

    init: function init() {
      this._super.apply(this, arguments);
      // event handlers don't get bound context by default...
      this.onOpen = onOpen.bind(this);
    },


    actions: {
      select: function select(value) {
        this.set('value', value);
      }
    }
  });


  function onOpen() {
    this.$('.dropdown-menu').css({
      right: '0',
      maxWidth: '200px',
      maxHeight: '300px',
      overflow: 'hidden',
      overfloyY: 'auto'
    });
  }
});