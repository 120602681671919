define('shared/helpers/link-ssl-domain', ['exports', 'shared/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params /* , options*/) {
      var name = params[0] || '';

      if (name.substr(0, 1) === '*') {
        return name;
      } else {
        return ('<a href="https://' + encodeURIComponent(name) + '" target="_blank" rel="nofollow noreferrer noopener">' + (0, _util.escapeHtml)(name) + ' <i class="icon icon-sm icon-external-link"/></a>').htmlSafe();
      }
    }
  });
});