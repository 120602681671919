define('shared/helpers/maybe-t', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params) {
      var key = params[0];
      var fallback = params[1];

      var intl = Ember.get(this, 'intl');

      if (key && intl.exists(key)) {
        return intl.t(key);
      } else if (key && !fallback) {
        return key;
      } else {
        return fallback;
      }
    }
  });
});