define('shared/components/error-sub-row/component', ['exports', 'shared/components/error-sub-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    fullColspan: null,
    leftColspan: 1,
    rightColspan: 1,

    tagName: '',

    mainColspan: Ember.computed('fullColspan', function () {
      return (this.get('fullColspan') || 2) - this.get('leftColspan') - this.get('rightColspan');
    })
  });
});