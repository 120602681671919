enifed('@ember/map/index', ['exports', '@ember/debug', 'ember-utils', '@ember/map/lib/ordered-set', '@ember/map/lib/utils', '@ember/deprecated-features'], function (exports, _debug, _emberUtils, _orderedSet, _utils, _deprecatedFeatures) {
  'use strict';

  /**
  @module @ember/map
  @private
  */

  var Map = void 0;

  if (_deprecatedFeatures.MAP) {
    /*
    JavaScript (before ES6) does not have a Map implementation. Objects,
    which are often used as dictionaries, may only have Strings as keys.
     Because Ember has a way to get a unique identifier for every object
    via `guidFor`, we can implement a performant Map with arbitrary
    keys. Because it is commonly used in low-level bookkeeping, Map is
    implemented as a pure JavaScript object for performance.
     This implementation follows the current iteration of the ES6 proposal for
    maps (http://wiki.ecmascript.org/doku.php?id=harmony:simple_maps_and_sets),
    with one exception:  as we do not have the luxury of in-VM iteration, we implement a
    forEach method for iteration.
     Map is mocked out to look like an Ember object, so you can do
    `EmberMap.create()` for symmetry with other Ember classes.
    */

    /**
    A Map stores values indexed by keys. Unlike JavaScript's
    default Objects, the keys of a Map can be any JavaScript
    object.
     Internally, a Map has two data structures:
     1. `keys`: an OrderedSet of all of the existing keys
    2. `values`: a JavaScript Object indexed by the `guidFor(key)`
     When a key/value pair is added for the first time, we
    add the key to the `keys` OrderedSet, and create or
    replace an entry in `values`. When an entry is deleted,
    we delete its entry in `keys` and `values`.
     @class Map
    @private
    @constructor
    @deprecated use native `Map` instead.
    */
    Map = function () {
      function Map() {
        false && !false && (0, _debug.deprecate)('Use of @ember/Map is deprecated. Please use native `Map` instead', false, {
          id: 'ember-map-deprecation',
          until: '3.5.0'
        });

        this._keys = new _orderedSet.default();
        this._values = Object.create(null);
        this.size = 0;
      }

      /**
      @method create
      @static
      @private
      */

      Map.create = function () {
        var Constructor = this;
        return new Constructor();
      };

      Map.prototype.get = function (key) {
        if (this.size === 0) {
          return;
        }

        var values = this._values;
        var guid = (0, _emberUtils.guidFor)(key);

        return values[guid];
      };

      Map.prototype.set = function (key, value) {
        var keys = this._keys;
        var values = this._values;
        var guid = (0, _emberUtils.guidFor)(key);

        // ensure we don't store -0
        var k = key === -0 ? 0 : key; // eslint-disable-line no-compare-neg-zero

        keys.add(k, guid);

        values[guid] = value;

        this.size = keys.size;

        return this;
      };

      Map.prototype.delete = function (key) {
        if (this.size === 0) {
          return false;
        }
        // don't use ES6 "delete" because it will be annoying
        // to use in browsers that are not ES6 friendly;
        var keys = this._keys;
        var values = this._values;
        var guid = (0, _emberUtils.guidFor)(key);

        if (keys.delete(key, guid)) {
          delete values[guid];
          this.size = keys.size;
          return true;
        } else {
          return false;
        }
      };

      Map.prototype.has = function (key) {
        return this._keys.has(key);
      };

      Map.prototype.forEach = function (callback /*, ...thisArg*/) {
        false && !(typeof callback === 'function') && (0, _debug.assert)(Object.prototype.toString.call(callback) + ' is not a function', typeof callback === 'function');

        if (this.size === 0) {
          return;
        }

        var map = this;
        var cb = void 0,
            thisArg = void 0;

        if (arguments.length === 2) {
          thisArg = arguments[1];
          cb = function (key) {
            return callback.call(thisArg, map.get(key), key, map);
          };
        } else {
          cb = function (key) {
            return callback(map.get(key), key, map);
          };
        }

        this._keys.forEach(cb);
      };

      Map.prototype.clear = function () {
        this._keys.clear();
        this._values = Object.create(null);
        this.size = 0;
      };

      Map.prototype.copy = function () {
        return (0, _utils.copyMap)(this, new Map());
      };

      return Map;
    }();
  }

  exports.default = Map;
});