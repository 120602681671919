enifed('ember-runtime/lib/system/namespace', ['exports', 'ember-babel', 'ember-metal', 'ember-utils', 'ember-runtime/lib/system/object'], function (exports, _emberBabel, _emberMetal, _emberUtils, _object) {
  'use strict';

  var Namespace = function (_EmberObject) {
    (0, _emberBabel.inherits)(Namespace, _EmberObject);

    function Namespace() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _EmberObject.apply(this, arguments));
    }

    Namespace.prototype.init = function () {
      (0, _emberMetal.addNamespace)(this);
    };

    Namespace.prototype.toString = function () {
      var name = (0, _emberMetal.get)(this, 'name') || (0, _emberMetal.get)(this, 'modulePrefix');
      if (name) {
        return name;
      }
      (0, _emberMetal.findNamespaces)();
      name = (0, _emberUtils.getName)(this);
      if (name === undefined) {
        name = (0, _emberUtils.guidFor)(this);
        (0, _emberUtils.setName)(this, name);
      }
      return name;
    };

    Namespace.prototype.nameClasses = function () {
      (0, _emberMetal.processNamespace)(this);
    };

    Namespace.prototype.destroy = function () {
      (0, _emberMetal.removeNamespace)(this);
      _EmberObject.prototype.destroy.call(this);
    };

    return Namespace;
  }(_object.default);

  exports.default = Namespace;

  Namespace.prototype.isNamespace = true;
  Namespace.NAMESPACES = _emberMetal.NAMESPACES;
  Namespace.NAMESPACES_BY_ID = _emberMetal.NAMESPACES_BY_ID;
  Namespace.processAll = _emberMetal.processAllNamespaces;
  Namespace.byName = _emberMetal.findNamespace;
});