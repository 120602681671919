define('shared/utils/subscribe-project', ['exports', 'shared/mixins/subscribe'], function (exports, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_subscribe.default, {
    scope: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'endpoint', Ember.get(this, 'app.projectSubscribeEndpoint'));
    },
    validate: function validate() {
      var socket = Ember.get(this, 'subscribeSocket');
      var metadata = socket.getMetadata();
      var socketProjectId = Ember.get(metadata, 'projectId');
      var currentProjectId = Ember.get(this, 'scope.currentProject.id');

      if (!currentProjectId || currentProjectId === socketProjectId) {
        return true;
      } else {
        console.error(this.label + ' Subscribe ignoring message, current=' + currentProjectId + ' socket=' + socketProjectId + ' ' + this.forStr());

        return false;
      }
    }
  });
});