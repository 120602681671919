define('shared/components/input-answer-row/component', ['exports', 'shared/components/input-answer-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    question: null,
    answer: null,
    namespaceId: '',

    layout: _template.default,

    tagName: 'div'

  });
});