define('shared/mixins/state-counts', ['exports', 'ui/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    // defineStateCounts('arrangedInstances', 'instanceStates', 'instanceCountSort');
    defineStateCounts: function defineStateCounts(inputKey, countsProperty, sortProperty) {
      var _this = this;

      // after a mixin is instantiated they seal the props which prevents us from pushing more objs into reservedKeys
      // BUT they dont freeze them so we can set it again. just clone it push the new values in and then set it
      var rkCln = this.get('reservedKeys').slice(0);

      rkCln.pushObjects([countsProperty, sortProperty]);
      this.set('reservedKeys', rkCln);
      Ember.defineProperty(this, countsProperty, Ember.computed(inputKey + '.@each.displayState', function () {
        var byName = [];
        var byColor = [];
        var good = 0;
        var notGood = 0;

        (_this.get(inputKey) || []).sortBy('sortState').forEach(function (inst) {
          var color = inst.get('stateBackground');

          if (color === 'bg-muted') {
            color = 'bg-success';
          }

          if (color === 'bg-success') {
            good++;
          } else {
            notGood++;
          }

          var state = inst.get('displayState');
          var entry = byName.findBy('state', state);

          if (entry) {
            entry.count++;
          } else {
            entry = {
              state: state,
              color: color,
              count: 1
            };
            byName.push(entry);
          }

          entry = byColor.findBy('color', color);
          if (entry) {
            entry.count++;
          } else {
            entry = {
              color: color,
              count: 1
            };
            byColor.push(entry);
          }
        });

        return {
          byName: byName,
          byColor: byColor,
          good: good,
          notGood: notGood
        };
      }));

      Ember.defineProperty(this, sortProperty, Ember.computed(countsProperty, inputKey + '.[]', function () {
        var colors = _this.get(countsProperty + '.byColor');
        var success = (colors.findBy('bg-success') || {}).count + (colors.findBy('bg-muted') || {}).coun;
        var error = (colors.findBy('bg-error') || {}).count;
        var other = _this.get(inputKey + '.length') - success - error;

        return _util.default.strPad(error, 6, '0') + _util.default.strPad(other, 6, '0') + _util.default.strPad(success, 6, '0');
      }));
    }
  });
});