define('shared/mixins/grafana', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    grafana: Ember.inject.service(),

    grafanaUrl: Ember.computed('grafana.dashboards', function () {
      var dashboardName = Ember.get(this, 'baseType') === 'workload' ? (Ember.get(this, 'type') || '').capitalize() : Ember.get(this, 'grafanaDashboardName');

      var dashboard = (Ember.get(this, 'grafana.dashboards') || []).findBy('title', dashboardName);

      if (!dashboard) {
        return;
      }

      var found = Ember.get(this, 'globalStore').all('project').findBy('isSystemProject', true);
      var isProjectReady = Ember.get(this, 'scope.currentProject.isMonitoringReady');
      var grafanaUrl = void 0;

      if (isProjectReady) {
        grafanaUrl = '' + Ember.get(this, 'scope.currentProject.monitoringStatus.grafanaEndpoint') + dashboard.url;
      } else if (found) {
        grafanaUrl = '' + Ember.get(this, 'scope.currentCluster.monitoringStatus.grafanaEndpoint') + dashboard.url;
      }

      switch (Ember.get(this, 'type')) {
        case 'node':
          grafanaUrl += '?var-node=' + Ember.get(this, 'grafanaResourceId') + '&var-port=9796';
          break;
        case 'deployment':
          grafanaUrl += '?var-deployment_namespace=' + Ember.get(this, 'namespaceId') + '&var-deployment_name=' + Ember.get(this, 'grafanaResourceId');
          break;
        case 'daemonSet':
          grafanaUrl += '?var-daemonset_namespace=' + Ember.get(this, 'namespaceId') + '&var-daemonset_name=' + Ember.get(this, 'grafanaResourceId');
          break;
        case 'statefulSet':
          grafanaUrl += '?var-statefulset_namespace=' + Ember.get(this, 'namespaceId') + '&var-statefulset_name=' + Ember.get(this, 'grafanaResourceId');
          break;
        case 'pod':
          grafanaUrl += '?var-namespace=' + Ember.get(this, 'namespaceId') + '&var-pod=' + Ember.get(this, 'grafanaResourceId') + '&var-container=All';
          break;
        case 'container':
          grafanaUrl += '?var-namespace=' + Ember.get(this, 'namespaceId') + '&var-pod=' + Ember.get(this, 'podName') + '&var-container=' + Ember.get(this, 'grafanaResourceId');
          break;
      }

      return grafanaUrl;
    })
  });
});