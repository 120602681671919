enifed('@glimmer/reference', ['exports', 'ember-babel', '@glimmer/util'], function (exports, _emberBabel, _util) {
    'use strict';

    exports.isModified = exports.ReferenceCache = exports.map = exports.CachedReference = exports.UpdatableTag = exports.CachedTag = exports.combine = exports.combineSlice = exports.combineTagged = exports.DirtyableTag = exports.bump = exports.isConstTag = exports.isConst = exports.CURRENT_TAG = exports.VOLATILE_TAG = exports.CONSTANT_TAG = exports.TagWrapper = exports.RevisionTag = exports.VOLATILE = exports.INITIAL = exports.CONSTANT = exports.IteratorSynchronizer = exports.ReferenceIterator = exports.IterationArtifacts = exports.ListItem = exports.ConstReference = undefined;

    var CONSTANT = 0;
    var INITIAL = 1;
    var VOLATILE = NaN;

    var RevisionTag = function () {
        function RevisionTag() {}

        RevisionTag.prototype.validate = function (snapshot) {
            return this.value() === snapshot;
        };

        return RevisionTag;
    }();

    RevisionTag.id = 0;
    var VALUE = [];
    var VALIDATE = [];

    var TagWrapper = function () {
        function TagWrapper(type, inner) {

            this.type = type;
            this.inner = inner;
        }

        TagWrapper.prototype.value = function () {
            var func = VALUE[this.type];
            return func(this.inner);
        };

        TagWrapper.prototype.validate = function (snapshot) {
            var func = VALIDATE[this.type];
            return func(this.inner, snapshot);
        };

        return TagWrapper;
    }();

    function register(Type) {
        var type = VALUE.length;
        VALUE.push(function (tag) {
            return tag.value();
        });
        VALIDATE.push(function (tag, snapshot) {
            return tag.validate(snapshot);
        });
        Type.id = type;
    }
    ///
    // CONSTANT: 0
    VALUE.push(function () {
        return CONSTANT;
    });
    VALIDATE.push(function (_tag, snapshot) {
        return snapshot === CONSTANT;
    });
    var CONSTANT_TAG = new TagWrapper(0, null);
    // VOLATILE: 1
    VALUE.push(function () {
        return VOLATILE;
    });
    VALIDATE.push(function (_tag, snapshot) {
        return snapshot === VOLATILE;
    });
    var VOLATILE_TAG = new TagWrapper(1, null);
    // CURRENT: 2
    VALUE.push(function () {
        return $REVISION;
    });
    VALIDATE.push(function (_tag, snapshot) {
        return snapshot === $REVISION;
    });
    var CURRENT_TAG = new TagWrapper(2, null);

    ///
    var $REVISION = INITIAL;
    function bump() {
        $REVISION++;
    }

    var DirtyableTag = function (_RevisionTag) {
        (0, _emberBabel.inherits)(DirtyableTag, _RevisionTag);

        DirtyableTag.create = function () {
            var revision = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $REVISION;

            return new TagWrapper(this.id, new DirtyableTag(revision));
        };

        function DirtyableTag() {
            var revision = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : $REVISION;


            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _RevisionTag.call(this));

            _this.revision = revision;
            return _this;
        }

        DirtyableTag.prototype.value = function () {
            return this.revision;
        };

        DirtyableTag.prototype.dirty = function () {
            this.revision = ++$REVISION;
        };

        return DirtyableTag;
    }(RevisionTag);

    register(DirtyableTag);

    function _combine(tags) {
        switch (tags.length) {
            case 0:
                return CONSTANT_TAG;
            case 1:
                return tags[0];
            case 2:
                return TagsPair.create(tags[0], tags[1]);
            default:
                return TagsCombinator.create(tags);
        }
    }

    var CachedTag = function (_RevisionTag2) {
        (0, _emberBabel.inherits)(CachedTag, _RevisionTag2);

        function CachedTag() {

            var _this2 = (0, _emberBabel.possibleConstructorReturn)(this, _RevisionTag2.apply(this, arguments));

            _this2.lastChecked = null;
            _this2.lastValue = null;
            return _this2;
        }

        CachedTag.prototype.value = function () {
            var lastChecked = this.lastChecked,
                lastValue = this.lastValue;

            if (lastChecked !== $REVISION) {
                this.lastChecked = $REVISION;
                this.lastValue = lastValue = this.compute();
            }
            return this.lastValue;
        };

        CachedTag.prototype.invalidate = function () {
            this.lastChecked = null;
        };

        return CachedTag;
    }(RevisionTag);

    var TagsPair = function (_CachedTag) {
        (0, _emberBabel.inherits)(TagsPair, _CachedTag);

        TagsPair.create = function (first, second) {
            return new TagWrapper(this.id, new TagsPair(first, second));
        };

        function TagsPair(first, second) {

            var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedTag.call(this));

            _this3.first = first;
            _this3.second = second;
            return _this3;
        }

        TagsPair.prototype.compute = function () {
            return Math.max(this.first.value(), this.second.value());
        };

        return TagsPair;
    }(CachedTag);

    register(TagsPair);

    var TagsCombinator = function (_CachedTag2) {
        (0, _emberBabel.inherits)(TagsCombinator, _CachedTag2);

        TagsCombinator.create = function (tags) {
            return new TagWrapper(this.id, new TagsCombinator(tags));
        };

        function TagsCombinator(tags) {

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedTag2.call(this));

            _this4.tags = tags;
            return _this4;
        }

        TagsCombinator.prototype.compute = function () {
            var tags = this.tags,
                i,
                value;

            var max = -1;
            for (i = 0; i < tags.length; i++) {
                value = tags[i].value();

                max = Math.max(value, max);
            }
            return max;
        };

        return TagsCombinator;
    }(CachedTag);

    register(TagsCombinator);

    var UpdatableTag = function (_CachedTag3) {
        (0, _emberBabel.inherits)(UpdatableTag, _CachedTag3);

        UpdatableTag.create = function (tag) {
            return new TagWrapper(this.id, new UpdatableTag(tag));
        };

        function UpdatableTag(tag) {

            var _this5 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedTag3.call(this));

            _this5.tag = tag;
            _this5.lastUpdated = INITIAL;
            return _this5;
        }

        UpdatableTag.prototype.compute = function () {
            return Math.max(this.lastUpdated, this.tag.value());
        };

        UpdatableTag.prototype.update = function (tag) {
            if (tag !== this.tag) {
                this.tag = tag;
                this.lastUpdated = $REVISION;
                this.invalidate();
            }
        };

        return UpdatableTag;
    }(CachedTag);

    register(UpdatableTag);

    var CachedReference = function () {
        function CachedReference() {

            this.lastRevision = null;
            this.lastValue = null;
        }

        CachedReference.prototype.value = function () {
            var tag = this.tag,
                lastRevision = this.lastRevision,
                lastValue = this.lastValue;

            if (lastRevision === null || !tag.validate(lastRevision)) {
                lastValue = this.lastValue = this.compute();
                this.lastRevision = tag.value();
            }
            return lastValue;
        };

        CachedReference.prototype.invalidate = function () {
            this.lastRevision = null;
        };

        return CachedReference;
    }();

    var MapperReference = function (_CachedReference) {
        (0, _emberBabel.inherits)(MapperReference, _CachedReference);

        function MapperReference(reference, mapper) {

            var _this6 = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

            _this6.tag = reference.tag;
            _this6.reference = reference;
            _this6.mapper = mapper;
            return _this6;
        }

        MapperReference.prototype.compute = function () {
            var reference = this.reference,
                mapper = this.mapper;

            return mapper(reference.value());
        };

        return MapperReference;
    }(CachedReference);

    //////////

    var ReferenceCache = function () {
        function ReferenceCache(reference) {

            this.lastValue = null;
            this.lastRevision = null;
            this.initialized = false;
            this.tag = reference.tag;
            this.reference = reference;
        }

        ReferenceCache.prototype.peek = function () {
            if (!this.initialized) {
                return this.initialize();
            }
            return this.lastValue;
        };

        ReferenceCache.prototype.revalidate = function () {
            if (!this.initialized) {
                return this.initialize();
            }
            var reference = this.reference,
                lastRevision = this.lastRevision;

            var tag = reference.tag;
            if (tag.validate(lastRevision)) return NOT_MODIFIED;
            this.lastRevision = tag.value();
            var lastValue = this.lastValue;

            var value = reference.value();
            if (value === lastValue) return NOT_MODIFIED;
            this.lastValue = value;
            return value;
        };

        ReferenceCache.prototype.initialize = function () {
            var reference = this.reference;

            var value = this.lastValue = reference.value();
            this.lastRevision = reference.tag.value();
            this.initialized = true;
            return value;
        };

        return ReferenceCache;
    }();

    var NOT_MODIFIED = 'adb3b78e-3d22-4e4b-877a-6317c2c5c145';


    var ConstReference = function () {
        function ConstReference(inner) {

            this.inner = inner;
            this.tag = CONSTANT_TAG;
        }

        ConstReference.prototype.value = function () {
            return this.inner;
        };

        return ConstReference;
    }();

    var ListItem = function (_ListNode) {
        (0, _emberBabel.inherits)(ListItem, _ListNode);

        function ListItem(iterable, result) {

            var _this7 = (0, _emberBabel.possibleConstructorReturn)(this, _ListNode.call(this, iterable.valueReferenceFor(result)));

            _this7.retained = false;
            _this7.seen = false;
            _this7.key = result.key;
            _this7.iterable = iterable;
            _this7.memo = iterable.memoReferenceFor(result);
            return _this7;
        }

        ListItem.prototype.update = function (item) {
            this.retained = true;
            this.iterable.updateValueReference(this.value, item);
            this.iterable.updateMemoReference(this.memo, item);
        };

        ListItem.prototype.shouldRemove = function () {
            return !this.retained;
        };

        ListItem.prototype.reset = function () {
            this.retained = false;
            this.seen = false;
        };

        return ListItem;
    }(_util.ListNode);

    var IterationArtifacts = function () {
        function IterationArtifacts(iterable) {

            this.iterator = null;
            this.map = (0, _util.dict)();
            this.list = new _util.LinkedList();
            this.tag = iterable.tag;
            this.iterable = iterable;
        }

        IterationArtifacts.prototype.isEmpty = function () {
            var iterator = this.iterator = this.iterable.iterate();
            return iterator.isEmpty();
        };

        IterationArtifacts.prototype.iterate = function () {
            var iterator = void 0;
            if (this.iterator === null) {
                iterator = this.iterable.iterate();
            } else {
                iterator = this.iterator;
            }
            this.iterator = null;
            return iterator;
        };

        IterationArtifacts.prototype.has = function (key) {
            return !!this.map[key];
        };

        IterationArtifacts.prototype.get = function (key) {
            return this.map[key];
        };

        IterationArtifacts.prototype.wasSeen = function (key) {
            var node = this.map[key];
            return node !== undefined && node.seen;
        };

        IterationArtifacts.prototype.append = function (item) {
            var map = this.map,
                list = this.list,
                iterable = this.iterable;

            var node = map[item.key] = new ListItem(iterable, item);
            list.append(node);
            return node;
        };

        IterationArtifacts.prototype.insertBefore = function (item, reference) {
            var map = this.map,
                list = this.list,
                iterable = this.iterable;

            var node = map[item.key] = new ListItem(iterable, item);
            node.retained = true;
            list.insertBefore(node, reference);
            return node;
        };

        IterationArtifacts.prototype.move = function (item, reference) {
            var list = this.list;

            item.retained = true;
            list.remove(item);
            list.insertBefore(item, reference);
        };

        IterationArtifacts.prototype.remove = function (item) {
            var list = this.list;

            list.remove(item);
            delete this.map[item.key];
        };

        IterationArtifacts.prototype.nextNode = function (item) {
            return this.list.nextNode(item);
        };

        IterationArtifacts.prototype.head = function () {
            return this.list.head();
        };

        return IterationArtifacts;
    }();

    var ReferenceIterator = function () {
        // if anyone needs to construct this object with something other than
        // an iterable, let @wycats know.
        function ReferenceIterator(iterable) {

            this.iterator = null;
            var artifacts = new IterationArtifacts(iterable);
            this.artifacts = artifacts;
        }

        ReferenceIterator.prototype.next = function () {
            var artifacts = this.artifacts;

            var iterator = this.iterator = this.iterator || artifacts.iterate();
            var item = iterator.next();
            if (item === null) return null;
            return artifacts.append(item);
        };

        return ReferenceIterator;
    }();

    var Phase;
    (function (Phase) {
        Phase[Phase["Append"] = 0] = "Append";
        Phase[Phase["Prune"] = 1] = "Prune";
        Phase[Phase["Done"] = 2] = "Done";
    })(Phase || (Phase = {}));

    var IteratorSynchronizer = function () {
        function IteratorSynchronizer(_ref2) {
            var target = _ref2.target,
                artifacts = _ref2.artifacts;


            this.target = target;
            this.artifacts = artifacts;
            this.iterator = artifacts.iterate();
            this.current = artifacts.head();
        }

        IteratorSynchronizer.prototype.sync = function () {
            var phase = Phase.Append;
            while (true) {
                switch (phase) {
                    case Phase.Append:
                        phase = this.nextAppend();
                        break;
                    case Phase.Prune:
                        phase = this.nextPrune();
                        break;
                    case Phase.Done:
                        this.nextDone();
                        return;
                }
            }
        };

        IteratorSynchronizer.prototype.advanceToKey = function (key) {
            var current = this.current,
                artifacts = this.artifacts;

            var seek = current;
            while (seek !== null && seek.key !== key) {
                seek.seen = true;
                seek = artifacts.nextNode(seek);
            }
            if (seek !== null) {
                this.current = artifacts.nextNode(seek);
            }
        };

        IteratorSynchronizer.prototype.nextAppend = function () {
            var iterator = this.iterator,
                current = this.current,
                artifacts = this.artifacts;

            var item = iterator.next();
            if (item === null) {
                return this.startPrune();
            }
            var key = item.key;

            if (current !== null && current.key === key) {
                this.nextRetain(item);
            } else if (artifacts.has(key)) {
                this.nextMove(item);
            } else {
                this.nextInsert(item);
            }
            return Phase.Append;
        };

        IteratorSynchronizer.prototype.nextRetain = function (item) {
            var artifacts = this.artifacts,
                current = this.current;

            current = current;
            current.update(item);
            this.current = artifacts.nextNode(current);
            this.target.retain(item.key, current.value, current.memo);
        };

        IteratorSynchronizer.prototype.nextMove = function (item) {
            var current = this.current,
                artifacts = this.artifacts,
                target = this.target;
            var key = item.key;

            var found = artifacts.get(item.key);
            found.update(item);
            if (artifacts.wasSeen(item.key)) {
                artifacts.move(found, current);
                target.move(found.key, found.value, found.memo, current ? current.key : null);
            } else {
                this.advanceToKey(key);
            }
        };

        IteratorSynchronizer.prototype.nextInsert = function (item) {
            var artifacts = this.artifacts,
                target = this.target,
                current = this.current;

            var node = artifacts.insertBefore(item, current);
            target.insert(node.key, node.value, node.memo, current ? current.key : null);
        };

        IteratorSynchronizer.prototype.startPrune = function () {
            this.current = this.artifacts.head();
            return Phase.Prune;
        };

        IteratorSynchronizer.prototype.nextPrune = function () {
            var artifacts = this.artifacts,
                target = this.target,
                current = this.current;

            if (current === null) {
                return Phase.Done;
            }
            var node = current;
            this.current = artifacts.nextNode(node);
            if (node.shouldRemove()) {
                artifacts.remove(node);
                target.delete(node.key);
            } else {
                node.reset();
            }
            return Phase.Prune;
        };

        IteratorSynchronizer.prototype.nextDone = function () {
            this.target.done();
        };

        return IteratorSynchronizer;
    }();

    exports.ConstReference = ConstReference;
    exports.ListItem = ListItem;
    exports.IterationArtifacts = IterationArtifacts;
    exports.ReferenceIterator = ReferenceIterator;
    exports.IteratorSynchronizer = IteratorSynchronizer;
    exports.CONSTANT = CONSTANT;
    exports.INITIAL = INITIAL;
    exports.VOLATILE = VOLATILE;
    exports.RevisionTag = RevisionTag;
    exports.TagWrapper = TagWrapper;
    exports.CONSTANT_TAG = CONSTANT_TAG;
    exports.VOLATILE_TAG = VOLATILE_TAG;
    exports.CURRENT_TAG = CURRENT_TAG;
    exports.isConst = function (_ref) {
        var tag = _ref.tag;

        return tag === CONSTANT_TAG;
    };
    exports.isConstTag = function (tag) {
        return tag === CONSTANT_TAG;
    };
    exports.bump = bump;
    exports.DirtyableTag = DirtyableTag;
    exports.combineTagged = function (tagged) {
        var optimized = [],
            i,
            l,
            tag;
        for (i = 0, l = tagged.length; i < l; i++) {
            tag = tagged[i].tag;

            if (tag === VOLATILE_TAG) return VOLATILE_TAG;
            if (tag === CONSTANT_TAG) continue;
            optimized.push(tag);
        }
        return _combine(optimized);
    };
    exports.combineSlice = function (slice) {
        var optimized = [],
            tag;
        var node = slice.head();
        while (node !== null) {
            tag = node.tag;

            if (tag === VOLATILE_TAG) return VOLATILE_TAG;
            if (tag !== CONSTANT_TAG) optimized.push(tag);
            node = slice.nextNode(node);
        }
        return _combine(optimized);
    };
    exports.combine = function (tags) {
        var optimized = [],
            i,
            l,
            tag;
        for (i = 0, l = tags.length; i < l; i++) {
            tag = tags[i];

            if (tag === VOLATILE_TAG) return VOLATILE_TAG;
            if (tag === CONSTANT_TAG) continue;
            optimized.push(tag);
        }
        return _combine(optimized);
    };
    exports.CachedTag = CachedTag;
    exports.UpdatableTag = UpdatableTag;
    exports.CachedReference = CachedReference;
    exports.map = function (reference, mapper) {
        return new MapperReference(reference, mapper);
    };
    exports.ReferenceCache = ReferenceCache;
    exports.isModified = function (value) {
        return value !== NOT_MODIFIED;
    };
});