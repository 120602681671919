define('global-admin/global-dns/providers/new/controller', ['exports', 'shared/mixins/view-new-edit'], function (exports, _viewNewEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DNS_PROVIDERS = ['route53', 'alidns', 'cloudflare'];

  exports.default = Ember.Controller.extend(_viewNewEdit.default, {
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    queryParams: ['id', 'activeProvider'],
    id: null,

    activeProvider: 'route53',
    mode: 'new',
    saveDisabled: false,
    config: Ember.computed.alias('model'),
    primaryResource: Ember.computed.alias('config'),

    actions: {
      switchProvider: function switchProvider(provider) {
        Ember.set(this, 'activeProvider', provider);
      },
      cancel: function cancel() {
        this.router.transitionTo('global-admin.global-dns.providers.index');
      },
      addAuthorizedPrincipal: function addAuthorizedPrincipal(principal) {
        if (principal) {
          var _model$members = this.model.members,
              members = _model$members === undefined ? [] : _model$members;


          Ember.set(principal, 'accessType', 'owner');

          members.pushObject(this.globalStore.createRecord(principal));

          Ember.set(this, 'model.members', members);
        }
      },
      removeMember: function removeMember(member) {
        var members = this.model.members;


        members.removeObject(member);
      }
    },

    availableProviders: Ember.computed('isEdit', function () {
      if (Ember.get(this, 'isEdit')) {
        return [{ name: Ember.get(this, 'activeProvider') }];
      } else {
        return DNS_PROVIDERS.map(function (p) {
          return { name: p };
        });
      }
    }),

    validate: function validate() {
      var providerConfig = Ember.get(this, 'config.' + this.activeProvider + 'ProviderConfig');
      var mode = this.mode;


      if (mode === 'edit' && providerConfig && providerConfig.hasOwnProperty('secretKey')) {
        if (providerConfig.secretKey === '' || providerConfig.secretKey === null) {
          delete providerConfig.secretKey;
        }
      }

      return this._super.apply(this, arguments);
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});