define('shared/components/modal-edit-driver/component', ['exports', 'shared/mixins/new-or-edit', 'shared/mixins/modal-base', 'shared/components/modal-edit-driver/template'], function (exports, _newOrEdit, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    settings: Ember.inject.service(),

    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    clone: null,
    errors: null,

    originalModel: Ember.computed.alias('modalService.modalOpts'),
    primaryResource: Ember.computed.alias('originalModel'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var clone = Ember.get(this, 'originalModel').clone();

      Ember.setProperties(this, {
        clone: clone,
        model: clone
      });

      Ember.run.scheduleOnce('afterRender', function () {
        _this.$('INPUT')[0].focus();
      });
    },


    actions: {
      updateWhitelist: function updateWhitelist(list) {
        Ember.set(this, 'primaryResource.whitelistDomains', list);
      }
    },

    driverType: Ember.computed('model.type', function () {
      return Ember.get(this, 'model.type') === 'nodeDriver' ? 'Node' : 'Cluster';
    }),

    editing: Ember.computed('clone.id', function () {
      return !!Ember.get(this, 'clone.id');
    }),

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});