define('nodes/custom-drivers/node-drivers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({ drivers: this.get('globalStore').findAll('nodeDriver') }).then(function (hash) {
        return hash;
      });
    }
  });
});