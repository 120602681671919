define('nodes/helpers/linkify', ['exports', 'shared/helpers/linkify', 'url-regex'], function (exports, _linkify, _urlRegex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _linkify.default;
    }
  });
});