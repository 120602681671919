define('shared/components/storage-class/provisioner-gce-pd/component', ['exports', 'shared/components/storage-class/provisioner-gce-pd/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.VOLUME_TYPES = undefined;
  var VOLUME_TYPES = exports.VOLUME_TYPES = ['pd-standard', 'pd-ssd'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    volumeTypes: VOLUME_TYPES,

    volumeType: null,
    zones: null,
    zoneAutomatic: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};

      changes['volumeType'] = Ember.get(this, 'parameters.type') || 'pd-standard';

      var zone = Ember.get(this, 'parameters.zone');
      var zones = Ember.get(this, 'parameters.zones');

      if (zones && zones.length) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zones;
      } else if (zone) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zone;
      } else {
        changes['zoneAutomatic'] = true;
        changes['zones'] = '';
      }

      Ember.setProperties(this, changes);
    },
    updateParams: function updateParams() {
      var type = Ember.get(this, 'volumeType');
      var out = { type: type };

      var zoneAutomatic = Ember.get(this, 'zoneAutomatic');

      if (!zoneAutomatic) {
        var zones = (Ember.get(this, 'zones') || '').trim();

        if (zones) {
          out['zones'] = zones;
        }
      }

      Ember.set(this, 'parameters', out);
    }
  });
});