define('shared/mixins/optionally-namespaced', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    projectType: 'Set me to the type for project-level resources',
    namespacedType: 'Set me to the type for namespaced resources',

    namespacePromise: function namespacePromise() {
      var scope = Ember.get(this, 'scope');
      var pr = Ember.get(this, 'primaryResource');
      var ns = Ember.get(this, 'namespace');

      if (!Ember.get(this, 'isNew')) {
        return Ember.RSVP.resolve();
      }

      if (scope === 'namespace' && !ns) {
        return Ember.RSVP.reject('No namespace specified');
      }

      var obj = pr.serialize();

      if (scope === 'namespace') {
        obj.type = Ember.get(this, 'namespacedType');
        pr = Ember.get(this, 'store').createRecord(obj);
        Ember.set(this, 'primaryResource', pr);

        if (Ember.get(ns, 'id')) {
          Ember.set(pr, 'namespaceId', Ember.get(ns, 'id'));

          return Ember.RSVP.resolve();
        } else {
          return ns.save().then(function (newNs) {
            Ember.set(pr, 'namespaceId', Ember.get(newNs, 'id'));

            return newNs.waitForState('active');
          });
        }
      } else {
        obj.type = Ember.get(this, 'projectType');
        pr = Ember.get(this, 'store').createRecord(obj);
        Ember.set(this, 'primaryResource', pr);

        return Ember.RSVP.resolve();
      }
    },
    doSave: function doSave() {
      var _arguments = arguments;

      var self = this;
      var sup = self._super;

      return this.namespacePromise().then(function () {
        return sup.apply(self, _arguments);
      });
    }
  });
});