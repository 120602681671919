enifed('ember-template-compiler/tests/plugins/transform-inline-link-to-test', ['ember-babel', 'ember-template-compiler/index', 'internal-test-helpers'], function (_emberBabel, _index, _internalTestHelpers) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: inline-link-to', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype['@test Can transform an inline {{link-to}} without error'] = function (assert) {
      assert.expect(0);

      (0, _index.compile)('{{link-to \'foo\' \'index\'}}', {
        moduleName: 'foo/bar/baz'
      });
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});