define('shared/components/storage-class/provisioner-glusterfs/component', ['exports', 'shared/components/storage-class/provisioner-glusterfs/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['resturl', 'restuser', 'restuserkey', 'secretNamespace', 'secretName', 'clusterid', 'gidMin', 'gidMax', 'volumetype'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'glusterfs',
    fields: FIELDS
  });
});