define('shared/helpers/linkify', ['exports', 'url-regex'], function (exports, _urlRegex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linkify = linkify;


  var MAX_LENGTH = 1000;

  function convertToLink(match) {
    var url = void 0;
    var displayUrl = match.trim();

    if (displayUrl.startsWith('https://') || displayUrl.startsWith('http://') || displayUrl.startsWith('//')) {
      url = displayUrl;
    } else {
      url = '//' + displayUrl;
    }

    return '<a href="' + url + '" target="_blank">' + displayUrl + '</a>';
  }

  function linkify(params) {
    var content = params[0] || '';

    if (content.length > MAX_LENGTH) {
      return content;
    }

    content = Ember.Handlebars.Utils.escapeExpression(content);

    content = content.replace((0, _urlRegex.default)({ strict: false }), convertToLink);

    return Ember.String.htmlSafe(content);
  }

  exports.default = Ember.Helper.helper(linkify);
});