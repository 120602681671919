define('nodes/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('custom-drivers', { path: '/drivers' }, function () {
      this.route('node-drivers', { path: '/node' });
      this.route('cluster-drivers', { path: '/cluster' });
    });
    this.route('node-templates');
  });
});