enifed('ember-template-compiler/lib/plugins/transform-old-binding-syntax', ['exports', '@ember/debug', '@ember/deprecated-features', 'ember-template-compiler/lib/system/calculate-location-display'], function (exports, _debug, _deprecatedFeatures, _calculateLocationDisplay) {
    'use strict';

    exports.default = function (env) {
        var moduleName, b, exprToString, processHash;

        if (_deprecatedFeatures.BINDING_SUPPORT) {
            moduleName = env.meta.moduleName;
            b = env.syntax.builders;
            exprToString = function (expr) {
                switch (expr.type) {
                    case 'StringLiteral':
                        return '"' + expr.original + '"';
                    case 'PathExpression':
                        return expr.original;
                }
                return '';
            };
            processHash = function (node) {
                var i, pair, key, value, sourceInformation, newKey;

                for (i = 0; i < node.hash.pairs.length; i++) {
                    pair = node.hash.pairs[i];
                    key = pair.key, value = pair.value;
                    sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, pair.loc);

                    if (key === 'classBinding') {
                        return;
                    }
                    true && !(key !== 'attributeBindings') && (0, _debug.assert)('Setting \'attributeBindings\' via template helpers is not allowed ' + sourceInformation, key !== 'attributeBindings');

                    if (key.substr(-7) === 'Binding') {
                        newKey = key.slice(0, -7);

                        true && !false && (0, _debug.deprecate)('You\'re using legacy binding syntax: ' + key + '=' + exprToString(value) + ' ' + sourceInformation + '. Please replace with ' + newKey + '=' + value.original, false, {
                            id: 'ember-template-compiler.transform-old-binding-syntax',
                            until: '3.0.0'
                        });

                        pair.key = newKey;
                        if (value.type === 'StringLiteral') {
                            pair.value = b.path(value.original);
                        }
                    }
                }
            };

            return {
                name: 'transform-old-binding-syntax',
                visitor: {
                    BlockStatement: processHash,
                    MustacheStatement: processHash
                }
            };
        }
        return undefined;
    };
});