define('global-admin/global-dns/entries/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HEADERS = [{
    translationKey: 'generic.state',
    name: 'state',
    width: 100
  }, {
    translationKey: 'globalDnsPage.entriesPage.table.fqdn',
    name: 'fqdn',
    sort: ['fqdn', 'name', 'id']
  }, {
    translationKey: 'globalDnsPage.entriesPage.table.provider',
    name: 'providerName',
    sort: ['providerName', 'name', 'id'],
    width: 150
  }, {
    translationKey: 'globalDnsPage.entriesPage.table.target',
    name: 'target',
    sort: ['target', 'name', 'id']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    searchField: false,
    sort: ['created'],
    width: 150
  }];

  exports.default = Ember.Controller.extend({
    sortBy: 'name',
    searchText: '',
    headers: HEADERS,

    rows: Ember.computed('model.[]', function () {
      return Ember.get(this, 'model');
    })
  });
});