define('shared/components/save-cancel/component', ['exports', 'shared/components/save-cancel/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['text-center', 'pt-20'],
    classNamesBindings: ['forFooter: footer-actions'],

    cancelColor: 'bg-transparent',
    cancelDisabled: false,
    cancelLabel: 'saveCancel.cancel',
    createLabel: 'saveCancel.create',
    editLabel: 'saveCancel.edit',
    editing: null,
    forFooter: true,
    saveColor: 'bg-primary',
    saveDisabled: false,
    saved: false,
    savedColor: 'bg-success',
    savedLabel: 'saveCancel.saved',
    saving: false,
    savingLabel: 'saveCancel.saving',

    actions: {
      save: function save() {
        var _this = this;

        // The save action must take a callback and call it when done (whether it succeded or not)
        // to update the state of the button.
        //
        this.set('saving', true);
        this.set('saved', false);
        this.sendAction('save', function (success) {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.set('saving', false);
          _this.set('saved', success === true);
        });
      },
      cancel: function cancel() {
        this.sendAction('cancel');
      },
      doNothing: function doNothing() {}
    },

    btnLabel: function () {
      if (this.get('saved')) {
        return this.get('savedLabel');
      } else if (this.get('editing')) {
        return this.get('editLabel');
      } else {
        return this.get('createLabel');
      }
    }.property('saved', 'editing', 'savedLabel', 'editLabel', 'createLabel'),

    savedChanged: function () {
      var _this2 = this;

      if (this.get('saved')) {
        Ember.run.later(this, function () {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          _this2.set('saved', false);
        }, 5000);
      }
    }.observes('saved')
  });
});