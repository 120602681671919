define('shared/components/used-percent-gauge/component', ['exports', 'shared/mixins/throttled-resize', 'ui/utils/used-percent-gauge', 'shared/components/used-percent-gauge/template', 'monitoring/components/percent-gauge/component'], function (exports, _throttledResize, _usedPercentGauge, _template, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_throttledResize.default, {
    layout: _template.default,
    tagName: 'div',
    classNames: ['percent-gauge'],
    value: null,
    title: null,
    subtitle: null,
    ticks: null,
    svg: null,
    ready: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.set(this, 'svg', (0, _usedPercentGauge.default)({
        el: this.$()[0],
        value: Ember.get(this, 'value'),
        live: Ember.get(this, 'live'),
        title: Ember.get(this, 'title'),
        subtitle: Ember.get(this, 'subtitle'),
        ticks: Ember.get(this, 'ticks'),
        liveTitle: Ember.get(this, 'liveTitle'),
        liveTicks: Ember.get(this, 'liveTicks'),
        maxValue: Ember.get(this, 'maxValue')
      }));

      Ember.run.next(this, function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        Ember.set(_this, 'ready', true);
      });
    },


    updateValue: Ember.observer('value', 'live', 'maxValue', function () {
      Ember.get(this, 'svg').updateValue(Ember.get(this, 'value'), Ember.get(this, 'live'), Ember.get(this, 'maxValue'));
    }),

    updateLiveLabel: Ember.observer('liveTitle', function () {
      Ember.get(this, 'svg').updateLiveLabel(Ember.get(this, 'liveTitle'));
    }),

    updateTicks: Ember.observer('ticks.@each.{label,value}', 'liveTicks.@each.{label,value}', 'maxValue', function () {
      Ember.get(this, 'svg').updateTicks(Ember.get(this, 'ticks'), Ember.get(this, 'liveTicks'), Ember.get(this, 'live'), Ember.get(this, 'maxValue'));
    })
  });
});