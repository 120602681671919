define('shared/utils/parse-version', ['exports', 'shared/utils/util', 'semver'], function (exports, _util, _semver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.satisfies = satisfies;
  exports.maxSatisfying = maxSatisfying;
  exports.parse = parse;
  exports.compare = compare;
  exports.minorVersion = minorVersion;
  exports.coerceVersion = coerceVersion;
  exports.isDevBuild = isDevBuild;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function satisfies(version, range) {
    // Semver doesn't take padding zeros like 17.03.1
    range = range.replace(/\.0+(\d+)/g, '.$1');
    version = version.replace(/\.0+(\d+)/g, '.$1');
    version = version.replace(/\-.*$/g, ''); // strip hyphen (prerelease in semver) because cloud providers dont use it as prerelease

    if (!_semver.default.validRange(range)) {
      console.error('Invalid semver range:', range);

      return false;
    }

    if (!_semver.default.valid(version)) {
      console.error('Invalid semver version:', version);

      return false;
    }

    return _semver.default.satisfies(version, range);
  }

  function maxSatisfying(versions, range) {
    var versionsErrors = [];
    var nueVersions = [].concat(versions);

    var satisfiedVersion = null;

    // Semver doesn't take padding zeros like 17.03.1
    range = range.replace(/\.0+(\d+)/g, '.$1');

    var coercedVersions = [];
    var origin = [];

    nueVersions.forEach(function (version, i, ary) {
      ary[i] = version.replace(/\.0+(\d+)/g, '.$1');
      ary[i] = version.replace(/\-.*$/g, ''); // strip hyphen (prerelease in semver) because cloud providers dont use it as prerelease

      if (!_semver.default.valid(_semver.default.coerce(version))) {
        versionsErrors.pushObject('Invalid semver version: ' + version);
      } else {
        coercedVersions.push(coerceVersion(version));
        origin.push(version);
      }
    });

    if (!_semver.default.validRange(range)) {
      console.error('Invalid semver range:', range);

      return false;
    }

    if (versionsErrors.length > 0) {
      versionsErrors.forEach(function (v) {
        return console.log(v);
      });

      return false;
    }

    satisfiedVersion = _semver.default.maxSatisfying(coercedVersions, range);

    if (satisfiedVersion) {
      return origin.find(function (v) {
        return coerceVersion(v).includes(satisfiedVersion);
      });
    } else {
      return null;
    }
  }

  // @TODO replace with semver calls and verify compare works the same for -preX tags
  function parse(str) {
    str = '' + str;

    // Trim off leading 'v'
    if (str.substr(0, 1).toLowerCase() === 'v') {
      str = str.substr(1);
    }

    var parts = str.split(/[.-]/);

    return parts;
  }

  function comparePart(in1, in2) {
    in1 = ('' + in1).toLowerCase();
    in2 = ('' + in2).toLowerCase();

    if (_util.default.isNumeric(in1) && _util.default.isNumeric(in2)) {
      var num1 = parseInt(in1, 10);
      var num2 = parseInt(in2, 10);

      if (!isNaN(num1) && !isNaN(num2)) {
        return num1 - num2;
      }
    }

    return in1.localeCompare(in2);
  }

  function compare(in1, in2) {
    if (!in1) {
      return 1;
    }

    if (!in2) {
      return -1;
    }

    var p1 = parse(in1);
    var p2 = parse(in2);

    var minLen = Math.min(p1.length, p2.length);

    for (var i = 0; i < minLen; i++) {
      var res = comparePart(p1[i], p2[i]);

      if (res !== 0) {
        return res;
      }
    }

    return p1.length - p2.length;
  }

  function minorVersion(str) {
    var _parse = parse(str),
        _parse2 = _slicedToArray(_parse, 2),
        major = _parse2[0],
        minor = _parse2[1];

    if (!minor) {
      return 'v' + major + '.0';
    }

    return 'v' + major + '.' + minor;
  }

  function coerceVersion(version) {
    var out = _semver.default.coerce(version) || {};

    if (_semver.default.valid(out)) {
      return out.version;
    }
  }

  function isDevBuild(version) {
    if (['dev', 'master', 'head'].includes(version) || version.endsWith('-head') || version.match(/-rc\d+$/)) {
      return true;
    }

    return false;
  }
});