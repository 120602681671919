define('shared/components/modal-confirm-yaml-switch/component', ['exports', 'shared/mixins/modal-base', 'shared/components/modal-confirm-yaml-switch/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['medium-modal'],
    model: Ember.computed.alias('modalService.modalOpts.propertiesGoingToBeLost'),

    actions: {
      confirm: function confirm() {
        this.modalService.modalOpts.finish(this.close.bind(this));
      },
      cancel: function cancel() {
        this.modalService.modalOpts.finish(this.close.bind(this), true);
      }
    },

    close: function close() {
      this.send('close');
    }
  });
});