define('shared/components/input-integer/component', ['exports', 'ui/utils/platform', 'shared/components/input-integer/template'], function (exports, _platform, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sanitize(val) {
    val = ('' + val).trim().replace(/[^0-9-]/g, '');
    val = val.substr(0, 1) + val.substr(1).replace('-', '');

    return val;
  }

  exports.default = Ember.TextField.extend({
    layout: _template.default,

    editing: true,

    attributeBindings: ['pattern', 'inputmode'],
    pattern: '[0-9]*',
    inputmode: 'numeric',

    type: Ember.computed(function () {
      return _platform.isMobile ? 'number' : 'text';
    }),

    _elementValueDidChange: function _elementValueDidChange() {
      var val = this.element.value;
      var cur = val;

      val = sanitize(val);

      var num = parseInt(val, 10);
      var max = parseInt(this.get('max'), 10);

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = '' + max;
      }

      if (cur !== val) {
        this.element.value = val;
      }
      this.set('value', val);
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var val = this.element.value;
      var cur = val;

      val = sanitize(val);

      var num = parseInt(val, 10);
      var min = parseInt(this.get('min'), 10);

      if (!isNaN(num) && !isNaN(min) && num < min) {
        val = '' + min;
      }

      if (cur !== val) {
        this.element.value = val;
        this.set('value', val);
      }
    }
  });
});