define('shared/mixins/subscribe', ['exports', 'ui/utils/socket', 'ui/utils/constants', 'ui/utils/queue'], function (exports, _socket, _constants, _queue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    label: '',
    endpoint: null,
    intl: null,
    growl: null,
    scope: null,
    store: null,
    watchState: false,
    watchStateOf: null,
    globalStore: null,
    clusterStore: null,

    updateProjectStore: true,
    updateGlobalStore: true,
    updateClusterStore: true,
    validate: null,

    subscribeSocket: null,
    reconnect: true,
    connected: false,
    queue: null,
    queueTimer: null,
    warningShown: false,
    wasConnected: false,
    disconnectedTimer: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var queue = new _queue.default();

      Ember.set(this, 'queue', queue);
      var socket = _socket.default.create();

      socket.on('message', function (event) {
        Ember.run.schedule('actions', _this, function () {
          // Fail-safe: make sure the message is for the current project/cluster
          if (this.validate && !this.validate()) {
            this.connect();

            return;
          }

          if (!event || !event.data) {
            return;
          }

          try {
            var d = JSON.parse(event.data);
          } catch (e) {
            console.error('Error parsing ' + this.label + ' change event:', e);

            return;
          }

          switch (d.name) {
            case 'resource.change':
            case 'resource.remove':
              queue.enqueue(d);
              // console.log(`${this.label} Change event`, queue.getLength(), 'in queue');
              break;
            case 'logout':
              this.send('logout', null);
              break;
            case 'ping':
              this.subscribePing(d);
              break;
          }
        });
      });

      socket.on('connected', function (tries, after) {
        _this.subscribeConnected(tries, after);
      });

      socket.on('frameTimeout', function () {
        _this.showDisconnectedWarning();
      });

      socket.on('disconnected', function () {
        _this.subscribeDisconnected(Ember.get(_this, 'tries'));
      });

      Ember.set(this, 'subscribeSocket', socket);
    },
    processQueue: function processQueue() {
      var queue = Ember.get(this, 'queue');

      if (!queue.getLength()) {
        return;
      }

      var projectStore = Ember.get(this, 'store');
      var clusterStore = Ember.get(this, 'clusterStore');
      var globalStore = Ember.get(this, 'globalStore');

      // let count = 0;
      var event = queue.dequeue();
      var projectId = void 0,
          type = void 0,
          forceRemove = void 0;

      Ember.beginPropertyChanges();
      while (event) {
        if (!event.data) {
          event = queue.dequeue();
          continue;
        }

        projectId = Ember.get(event.data, 'projectId');
        type = Ember.get(event.data, 'type');
        forceRemove = event.name === 'resource.remove';

        // console.log(this.label, (forceRemove ? 'Remove' : 'Change'), type +':'+ event.data.id,  projectId);

        if (Ember.get(this, 'updateProjectStore') && projectId && projectStore.hasType(type)) {
          // console.log('  Update project store', type, event.data.id, projectId);
          updateStore(projectStore, event.data, forceRemove);
        }

        if (Ember.get(this, 'updateClusterStore') && clusterStore.hasType(type)) {
          // console.log('  Update cluster store', type, event.data.id);
          updateStore(clusterStore, event.data, forceRemove);
        }

        if (Ember.get(this, 'updateGlobalStore') && globalStore.hasType(type)) {
          // console.log('  Update global store', type, event.data.id);
          updateStore(globalStore, event.data, forceRemove);
        }

        // count++;

        event = queue.dequeue();
      }
      Ember.endPropertyChanges();
      // console.log(`Processed ${count} ${this.label} change events`);

      function updateStore(store, data) {
        var forceRemove = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

        // Update the state to removed before we remove it from store
        if (forceRemove) {
          data.state = 'removed';
        }
        // Typeify adds or updates the store entry
        var resource = store._typeify(data);

        if (resource) {
          // Remove from store if the resource is removed
          if (forceRemove || _constants.default.REMOVEDISH_STATES.includes(resource.state)) {
            var _type = Ember.get(resource, 'type');

            store._remove(_type, resource);
          }
        }
      }
    },
    connect: function connect() {
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var clusterId = arguments[1];
      var projectId = arguments[2];

      if (Ember.get(this, 'watchState')) {
        var state = Ember.get(this, 'watchStateOf.relevantState');

        if (state !== 'active') {
          console.log(this.label + ' Subscribe not connecting because state isn\'t active (' + state + ')');

          return;
        }
      }

      if (Ember.get(this, 'connected') && !force) {
        return;
      }

      var socket = Ember.get(this, 'subscribeSocket');

      projectId = projectId || Ember.get(this, 'scope.pendingProject.id');
      clusterId = clusterId || Ember.get(this, 'scope.pendingCluster.id');

      var url = 'ws://' + window.location.host + Ember.get(this, 'endpoint').replace(Ember.get(this, 'app.projectToken'), projectId).replace(Ember.get(this, 'app.clusterToken'), clusterId);

      Ember.set(this, 'reconnect', true);

      socket.setProperties({
        url: url,
        autoReconnect: true
      });
      socket.reconnect({
        projectId: projectId,
        clusterId: clusterId
      });
    },
    disconnect: function disconnect(cb) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve /* , reject*/) {
        Ember.setProperties(_this2, {
          reconnect: false,
          tries: 0
        });

        var socket = Ember.get(_this2, 'subscribeSocket');

        if (!socket || socket._state === 'disconnected') {
          if (cb) {
            cb();
          }

          resolve();

          return;
        }

        console.log(_this2.label + ' Subscribe disconnect ' + _this2.forStr());
        socket.set('tries', 0);
        socket.disconnect(function () {
          if (cb) {
            cb();
          }

          resolve();
        });
      });
    },


    watchedStateChanged: Ember.observer('watchStateOf.relevantState', function () {
      var state = Ember.get(this, 'watchState.relevantState');

      if (state === 'active') {
        this.connect();
      } else {
        this.disconnect();
      }
    }),

    forStr: function forStr() {
      var out = '';
      var socket = Ember.get(this, 'subscribeSocket');

      if (socket) {
        out = '(sockId=' + socket.getId() + ')';
      }

      return out;
    },


    // WebSocket connected
    subscribeConnected: function subscribeConnected(tries, msec) {
      Ember.set(this, 'queueTimer', setInterval(this.processQueue.bind(this), 1000));
      Ember.set(this, 'connected', true);
      Ember.set(this, 'wasConnected', true);

      if (Ember.get(this, 'warningShown')) {
        Ember.get(this, 'growl').close();
        Ember.set(this, 'warningShown', false);
      }

      Ember.run.cancel(Ember.get(this, 'disconnectedTimer'));

      var msg = this.label + ' Subscribe connected ' + this.forStr();

      if (tries > 0) {
        msg += ' (after ' + tries + ' ' + (tries === 1 ? 'try' : 'tries');
        if (msec) {
          msg += ', ' + msec / 1000 + ' sec';
        }

        msg += ')';
      }

      console.log(msg);
    },


    // WebSocket disconnected (unexpectedly)
    subscribeDisconnected: function subscribeDisconnected() {
      Ember.set(this, 'connected', false);
      Ember.get(this, 'queue').clear();
      clearInterval(Ember.get(this, 'queueTimer'));

      console.log(this.label + ' Subscribe disconnected ' + this.forStr());
      if (Ember.get(this, 'reconnect')) {
        this.showDisconnectedWarning();
      }
    },
    subscribePing: function subscribePing() {
      // This is annoying now with 3 connections alternating messages
      // console.log('Subscribe ping ' + this.forStr());
    },
    showDisconnectedWarning: function showDisconnectedWarning() {
      if (!Ember.get(this, 'warningShown') && Ember.get(this, 'wasConnected')) {
        //      const intl = get(this,'intl');
        // @TODO-2.0      get(this,'growl').error(intl.t('growl.webSocket.connecting.title'), intl.t('growl.webSocket.connecting.disconnectedWarning'));
        Ember.set(this, 'warningShown', true);
        Ember.set(this, 'disconnectedTimer', Ember.run.later(this, function () {
          if (window.navigator.onLine) {
            window.location.reload();
          } else {
            window.ononline = function () {
              window.location.reload();
            };
          }
        }, _constants.default.WEBSOCKET.SUBSCRIBE_DISCONNECTED_TIMEOUT));
      }
    }
  });
});