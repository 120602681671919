define('nodes/components/modal-edit-node-template/component', ['exports', 'shared/mixins/modal-base', 'nodes/components/modal-edit-node-template/template', 'shared/utils/load-script', 'ui/mixins/preload', 'ui/utils/constants'], function (exports, _modalBase, _template, _loadScript, _preload, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, _preload.default, {
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    model: null,
    driver: null,
    onAdd: null,

    loading: true,
    editing: false,
    cloning: false,
    allNodeDrivers: null,

    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    customDrivers: null,
    rejectedDriverIds: null,

    showPicker: Ember.computed.empty('model.id'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var opts = Ember.get(this, 'modalService.modalOpts') || {};
      var originalModel = Ember.get(opts, 'nodeTemplate');
      var driver = this.driver;

      var override = Ember.get(opts, 'driver');
      var newModel = null;
      var editing = false;
      var cloning = false;

      if (Ember.get(opts, 'edit')) {
        newModel = originalModel.clone();
        editing = true;
      } else if (Ember.get(opts, 'clone')) {
        newModel = originalModel.cloneForNew();
        editing = true;
        cloning = true;
      }

      if (originalModel) {
        Ember.setProperties(this, {
          model: newModel,
          originalModel: originalModel,
          editing: editing,
          cloning: cloning
        });
      } else {
        Ember.set(this, 'model', Ember.get(this, 'globalStore').createRecord({ type: 'nodeTemplate' }));
      }

      if (override) {
        driver = override;

        Ember.set(this, 'showPicker', false);
      }

      Ember.set(this, 'onAdd', Ember.get(opts, 'onAdd'));

      // need to reload the schemas to fetch any new custom node template schemas so they are available for the availableDrivers
      return this.loadSchemas('globalStore').then(function () {
        return Ember.get(_this, 'globalStore').findAll('nodeDriver').then(function (allNodeDrivers) {
          Ember.set(_this, 'allNodeDrivers', allNodeDrivers);

          var active = Ember.get(_this, 'availableDrivers');

          if (!driver || !active.findBy('name', driver)) {
            driver = Ember.get(active, 'firstObject.name');
          }

          Ember.set(_this, 'driver', driver);

          return _this.loadCustomUi().then(function () {
            Ember.set(_this, 'loading', false);
          });
        });
      });
    },


    actions: {
      switchDriver: function switchDriver(name) {
        Ember.set(this, 'driver', name);
      },
      saved: function saved() {
        var fn = Ember.get(this, 'onAdd');
        var model = Ember.get(this, 'model');

        if (fn) {
          fn(model);
        }
      },
      hidePicker: function hidePicker() {
        this.set('showPicker', false);
      }
    },

    availableDrivers: Ember.computed('allNodeDrivers.@each.state', 'rejectedDriverIds.[]', function () {
      var _this2 = this;

      var out = [];
      var rejectedDriverIds = Ember.get(this, 'rejectedDriverIds') || [];
      var drivers = Ember.get(this, 'allNodeDrivers').filter(function (driver) {
        if (Ember.get(driver, 'state') === 'active' && !rejectedDriverIds.includes(Ember.get(driver, 'id'))) {
          return driver;
        }
      }).sortBy('name');

      drivers.forEach(function (driver) {
        var configName = Ember.get(driver, 'name') + 'Config';
        var driverSchema = Ember.get(_this2, 'globalStore').getById('schema', configName.toLowerCase());

        if (driverSchema) {
          out.push(driver);
        }
      });

      return out;
    }),

    availableDriversGroups: Ember.computed('availableDrivers.@each.state', function () {
      var availableDrivers = this.availableDrivers;

      var group = [];
      var groupIndex = 0;

      availableDrivers.forEach(function (item, index) {
        if (index % 5 === 0) {
          group.push([item]);
          groupIndex++;
        } else {
          group[groupIndex - 1].push(item);
        }
      });

      return group;
    }),

    driverObj: Ember.computed('driver', function () {
      return Ember.get(this, 'availableDrivers').filterBy('name', Ember.get(this, 'driver'))[0];
    }),

    uiFieldHints: Ember.computed('driverObj.annotations.[]', function () {
      var uiFieldHints = Ember.get(this, 'driverObj.annotations')[_constants.default.LABEL.UI_HINTS] || null;

      if (uiFieldHints) {
        return JSON.parse(uiFieldHints);
      } else {
        return {};
      }
    }),

    // Loads the custom UI CSS/JS for drivers that have a uiUrl,
    loadCustomUi: function loadCustomUi() {
      var _this3 = this;

      var promises = [];

      var customDrivers = Ember.get(this, 'customDrivers') || [];

      Ember.get(this, 'availableDrivers').forEach(function (driver) {
        var name = Ember.get(driver, 'name');
        var uiUrl = Ember.get(driver, 'uiUrl');

        if (uiUrl) {
          if (!customDrivers.includes(name)) {
            customDrivers.pushObject(name);
          }
          var jsUrl = (0, _loadScript.proxifyUrl)(driver.uiUrl, _this3.get('app.proxyEndpoint'));
          var cssUrl = (0, _loadScript.proxifyUrl)(driver.uiUrl.replace(/\.js$/, '.css'), _this3.get('app.proxyEndpoint'));

          promises.push((0, _loadScript.loadScript)(jsUrl, 'driver-ui-js-' + name));
          promises.push((0, _loadScript.loadStylesheet)(cssUrl, 'driver-ui-css-' + name));
        }
      });

      Ember.set(this, 'customDrivers', customDrivers);

      return Ember.RSVP.allSettled(promises).then(function (results) {
        var rejectedResutls = results.filterBy('state', 'rejected');

        if (rejectedResutls.length >= 1) {
          var rejectedIds = rejectedResutls.map(function (rej) {
            return (Ember.get(rej, 'reason.srcElement.id') || '').split('-').get('lastObject');
          }).compact().uniq();

          Ember.set(_this3, 'rejectedDriverIds', rejectedIds);

          rejectedIds.forEach(function (reject) {
            _this3.growl.fromError(_this3.intl.t('nodeDriver.externalError', { driverName: reject }));
          });
        }

        Ember.RSVP.resolve();
      });
    }
  });
});