define('global-admin/security/authentication/github/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var gs = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        githubConfig: gs.find('authconfig', 'github', { forceReload: true }),
        principals: gs.all('principal')
      }).catch(function (e) {
        return e;
      });
    },
    setupController: function setupController(controller, model) {
      var hostname = Ember.get(model, 'githubConfig.hostname');

      controller.setProperties({
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: Ember.get(this, 'session.orgs') || [],
        errors: null,
        isEnterprise: hostname && hostname !== 'github.com' ? true : false
      });

      controller.set('saved', true);
    }
  });
});