define('@rancher/ember-api-store/instance-initializers/initialize-store-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initializeStoreService;
  function initializeStoreService(application) {
    var container = application.lookup ? application : application.container;
    container.lookup('service:store');
  }
});