define('shared/mixins/storage-class-provisioner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    parameters: null,
    editing: null,
    fields: null,
    model: null,

    init: function init() {
      if (!Ember.get(this, 'fields')) {
        Ember.set(this, 'fields', []);
      }

      this._super.apply(this, arguments);
      this.sendAction('registerHook', this.updateParams.bind(this), { name: 'updateParams' });
    },
    updateParams: function updateParams() {
      var _this = this;

      // Override this to handle custom parameters
      //
      var fields = Ember.get(this, 'fields') || [];

      if (fields.length > 0) {
        var out = {};

        fields.forEach(function (field) {
          var key = field.id ? field.id : field;
          var value = Ember.get(_this, 'model.' + key) || '';

          if (value) {
            out[key] = value;
          }
        });

        Ember.set(this, 'parameters', out);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this2 = this;

      var fields = Ember.get(this, 'fields') || [];

      if (fields.length > 0) {
        var changes = {};

        fields.forEach(function (field) {
          var key = field.id ? field.id : field;

          changes[key] = Ember.get(_this2, 'parameters.' + key) || Ember.get(field, 'options.firstObject.value') || '';
        });
        Ember.setProperties(this, { model: changes });
      }
    },


    fieldsGroup: Ember.computed('fields.[]', function () {
      var fields = Ember.get(this, 'fields') || [];
      var group = [];
      var groupIndex = 0;

      fields.forEach(function (item, index) {
        if (index % 3 === 0) {
          group.push([item]);
          groupIndex++;
        } else {
          group[groupIndex - 1].push(item);
        }
      });

      return group;
    })
  });
});