define('shared/components/page-numbers/component', ['exports', 'ember-cli-pagination/components/page-numbers', 'shared/components/page-numbers/template'], function (exports, _pageNumbers, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pageNumbers.default.extend({
    layout: _template.default,
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
});