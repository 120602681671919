define('shared/components/tooltip-expiring/component', ['exports', 'shared/components/tooltip-expiring/template', 'shared/mixins/tooltip'], function (exports, _template, _tooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tooltip.default, {
    layout: _template.default,

    model: Ember.computed.alias('tooltipService.tooltipOpts.model'),

    actions: {
      rotateCertificates: function rotateCertificates() {
        this.model.send('rotateCertificates');
        this.tooltipService.hide();
      }
    }
  });
});