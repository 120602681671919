define('shared/helpers/format-ip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params, options) {
      var noIp = options.noIp || 'formatIp.noIp';
      var ip = params[0];

      if (ip === '0:0:0:0:0:0:0:1') {
        ip = '::1';
      }

      if (ip) {
        return ip;
      } else {
        return ('<span class="text-muted">' + this.get('intl').t(noIp) + '</span>').htmlSafe();
      }
    }
  });
});