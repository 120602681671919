define('shared/helpers/array-includes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    _haystack: null,

    shouldUpdate: Ember.observer('_haystack.[]', function () {
      this.recompute();
    }),

    compute: function compute(params) {
      var haystack = params[0];

      if (!haystack) {
        return;
      }
      var _haystack = this.get('_haystack');

      if (haystack !== _haystack) {
        _haystack = new Ember.A(haystack);
        this.set('_haystack', _haystack);
      }

      var result = void 0;

      for (var i = 1; i < params.length; i++) {
        var needle = params[i];

        if (needle) {
          result = _haystack.includes(needle);
        }

        if (!result) {
          return false;
        }
      }

      return result;
    }
  });
});