enifed('ember-runtime/lib/ext/rsvp', ['exports', 'rsvp', '@ember/runloop', 'ember-error-handling', '@ember/debug'], function (exports, _rsvp, _runloop, _emberErrorHandling, _debug) {
  'use strict';

  exports.onerrorDefault = onerrorDefault;

  _rsvp.configure('async', function (callback, promise) {
    _runloop.backburner.schedule('actions', null, callback, promise);
  });

  _rsvp.configure('after', function (cb) {
    _runloop.backburner.schedule(_runloop._rsvpErrorQueue, null, cb);
  });

  _rsvp.on('error', onerrorDefault);

  function onerrorDefault(reason) {
    var error = errorFor(reason),
        overrideDispatch;
    if (error) {
      overrideDispatch = (0, _emberErrorHandling.getDispatchOverride)();

      if (overrideDispatch) {
        overrideDispatch(error);
      } else {
        throw error;
      }
    }
  }

  function errorFor(reason) {
    if (!reason) return;

    if (reason.errorThrown) {
      return unwrapErrorThrown(reason);
    }

    if (reason.name === 'UnrecognizedURLError') {
      false && !false && (0, _debug.assert)('The URL \'' + reason.message + '\' did not match any routes in your application', false);

      return;
    }

    if (reason.name === 'TransitionAborted') {
      return;
    }

    return reason;
  }

  function unwrapErrorThrown(reason) {
    var error = reason.errorThrown;
    if (typeof error === 'string') {
      error = new Error(error);
    }
    Object.defineProperty(error, '__reason_with_error_thrown__', {
      value: reason,
      enumerable: false
    });
    return error;
  }

  exports.default = _rsvp;
});