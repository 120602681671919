define('shared/components/custom-catalog/component', ['exports', 'shared/components/custom-catalog/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headers = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'scope',
    sort: ['clusterId', 'projectId'],
    searchField: ['clusterId', 'projectId'],
    translationKey: 'generic.scope',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name',
    width: 250
  }, {
    name: 'url',
    sort: ['url', 'displayName'],
    translationKey: 'catalogSettings.more.url.label'
  }, {
    name: 'branch',
    sort: ['branch', 'displayName'],
    translationKey: 'catalogSettings.more.branch.label',
    width: 120
  }];

  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    headers: headers,
    tagName: null,
    catalogs: null,
    sortBy: 'name',
    descending: false,
    paging: true,
    rightActions: true
  });
});