enifed('ember-views/lib/system/utils', ['exports', 'ember-owner', 'ember-utils'], function (exports, _emberOwner, _emberUtils) {
  'use strict';

  exports.elMatches = undefined;
  exports.isSimpleClick =

  /**
  @module ember
  */

  function (event) {
    var modifier = event.shiftKey || event.metaKey || event.altKey || event.ctrlKey;
    var secondaryClick = event.which > 1; // IE9 may return undefined

    return !modifier && !secondaryClick;
  }
  /* globals Element */
  ;
  exports.constructStyleDeprecationMessage = function (affectedStyle) {
    return '' + 'Binding style attributes may introduce cross-site scripting vulnerabilities; ' + 'please ensure that values being bound are properly escaped. For more information, ' + 'including how to disable this warning, see ' + 'https://emberjs.com/deprecations/v1.x/#toc_binding-style-attributes. ' + 'Style affected: "' + affectedStyle + '"';
  }

  /**
    @private
    @method getRootViews
    @param {Object} owner
  */
  ;
  exports.getRootViews = function (owner) {
    var registry = owner.lookup('-view-registry:main');

    var rootViews = [];

    Object.keys(registry).forEach(function (id) {
      var view = registry[id];

      if (view.parentView === null) {
        rootViews.push(view);
      }
    });

    return rootViews;
  }

  /**
    @private
    @method getViewId
    @param {Ember.View} view
   */
  ;
  exports.getViewId = getViewId;
  exports.getViewElement =

  /**
    @private
    @method getViewElement
    @param {Ember.View} view
   */
  function (view) {
    return view[VIEW_ELEMENT];
  };
  exports.initViewElement = function (view) {
    view[VIEW_ELEMENT] = null;
  };
  exports.setViewElement = function (view, element) {
    return view[VIEW_ELEMENT] = element;
  };
  exports.getChildViews =

  /**
    @private
    @method getChildViews
    @param {Ember.View} view
  */
  function (view) {
    var owner = (0, _emberOwner.getOwner)(view);
    var registry = owner.lookup('-view-registry:main');
    return collectChildViews(view, registry);
  };
  exports.initChildViews = initChildViews;
  exports.addChildView = function (parent, child) {
    var childViews = CHILD_VIEW_IDS.get(parent);
    if (childViews === undefined) {
      childViews = initChildViews(parent);
    }

    childViews.add(getViewId(child));
  };
  exports.collectChildViews = collectChildViews;
  exports.getViewBounds = getViewBounds;
  exports.getViewRange = getViewRange;
  exports.getViewClientRects =

  /**
    `getViewClientRects` provides information about the position of the border
    box edges of a view relative to the viewport.
  
    It is only intended to be used by development tools like the Ember Inspector
    and may not work on older browsers.
  
    @private
    @method getViewClientRects
    @param {Ember.View} view
  */
  function (view) {
    var range = getViewRange(view);
    return range.getClientRects();
  }

  /**
    `getViewBoundingClientRect` provides information about the position of the
    bounding border box edges of a view relative to the viewport.
  
    It is only intended to be used by development tools like the Ember Inspector
    and may not work on older browsers.
  
    @private
    @method getViewBoundingClientRect
    @param {Ember.View} view
  */
  ;
  exports.getViewBoundingClientRect = function (view) {
    var range = getViewRange(view);
    return range.getBoundingClientRect();
  }

  /**
    Determines if the element matches the specified selector.
  
    @private
    @method matches
    @param {DOMElement} el
    @param {String} selector
  */
  ;
  exports.matches = function (el, selector) {
    return elMatches.call(el, selector);
  };function getViewId(view) {
    if (view.tagName !== '' && view.elementId) {
      return view.elementId;
    } else {
      return (0, _emberUtils.guidFor)(view);
    }
  }

  var VIEW_ELEMENT = (0, _emberUtils.symbol)('VIEW_ELEMENT');

  var CHILD_VIEW_IDS = new WeakMap();

  function initChildViews(view) {
    var childViews = new Set();
    CHILD_VIEW_IDS.set(view, childViews);
    return childViews;
  }

  function collectChildViews(view, registry) {
    var views = [];
    var childViews = CHILD_VIEW_IDS.get(view);

    if (childViews !== undefined) {
      childViews.forEach(function (id) {
        var view = registry[id];
        if (view && !view.isDestroying && !view.isDestroyed) {
          views.push(view);
        }
      });
    }

    return views;
  }

  /**
    @private
    @method getViewBounds
    @param {Ember.View} view
  */
  function getViewBounds(view) {
    return view.renderer.getBounds(view);
  }

  /**
    @private
    @method getViewRange
    @param {Ember.View} view
  */
  function getViewRange(view) {
    var bounds = getViewBounds(view);

    var range = document.createRange();
    range.setStartBefore(bounds.firstNode);
    range.setEndAfter(bounds.lastNode);

    return range;
  }var elMatches = exports.elMatches = typeof Element !== 'undefined' && (Element.prototype.matches || Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector);
});