enifed('ember-template-compiler/tests/plugins/transform-input-type-syntax-test', ['ember-babel', 'ember-template-compiler/index', 'internal-test-helpers'], function (_emberBabel, _index, _internalTestHelpers) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: input type syntax', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype['@test Can compile an {{input}} helper that has a sub-expression value as its type'] = function (assert) {
      assert.expect(0);

      (0, _index.compile)('{{input type=(if true \'password\' \'text\')}}');
    };

    _class.prototype['@test Can compile an {{input}} helper with a string literal type'] = function (assert) {
      assert.expect(0);

      (0, _index.compile)('{{input type=\'text\'}}');
    };

    _class.prototype['@test Can compile an {{input}} helper with a type stored in a var'] = function (assert) {
      assert.expect(0);

      (0, _index.compile)('{{input type=_type}}');
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});