enifed('ember-template-compiler/lib/compat', ['exports', 'ember-template-compiler/lib/system/compile', 'ember-template-compiler/lib/system/compile-options', 'ember-template-compiler/lib/system/precompile'], function (exports, _compile, _compileOptions, _precompile) {
    'use strict';

    exports.default = function (Ember) {
        var EmberHandlebars = Ember.Handlebars;
        if (!EmberHandlebars) {
            Ember.Handlebars = EmberHandlebars = {};
        }
        var EmberHTMLBars = Ember.HTMLBars;
        if (!EmberHTMLBars) {
            Ember.HTMLBars = EmberHTMLBars = {};
        }
        EmberHTMLBars.precompile = EmberHandlebars.precompile = _precompile.default;
        EmberHTMLBars.compile = EmberHandlebars.compile = _compile.default;
        EmberHTMLBars.registerPlugin = _compileOptions.registerPlugin;
    };
});