define('shared/helpers/pretty-cron', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Prettycron = window.Prettycron;

  function prettyCronHelper(param) {
    if (!param[0]) {
      return '';
    }

    return Prettycron[param[1]](param[0]);
  }
  exports.default = Ember.Helper.helper(prettyCronHelper);
});