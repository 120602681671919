define('nodes/components/driver-aliyunecs/component', ['exports', 'shared/mixins/node-driver', 'nodes/components/driver-aliyunecs/template'], function (exports, _nodeDriver, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ENDPOINT = 'https://ecs.aliyuncs.com';
  var PAGE_SIZE = 50;
  var NONE_OPT_DISK = [{ value: 'cloud' }];

  var OPT_DISK = [{ value: 'cloud_efficiency' }, { value: 'cloud_ssd' }];

  var DEFAULT_INSTANCE_TYPE = 'ecs.s2.large';

  exports.default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),

    layout: _template.default,

    driverName: 'aliyunecs',
    zones: null,
    regions: null,
    securityGroups: null,
    images: null,
    instanceTypes: null,

    ecsClient: null,
    step: 1,

    config: Ember.computed.alias('model.aliyunecsConfig'),

    actions: {
      alyLogin: function alyLogin(cb) {
        var _this = this;

        Ember.setProperties(this, {
          'errors': null,
          'config.accessKeyId': (Ember.get(this, 'config.accessKeyId') || '').trim(),
          'config.accessKeySecret': (Ember.get(this, 'config.accessKeySecret') || '').trim()
        });

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var accessKey = Ember.get(this, 'config.accessKeyId');
        var accessSecret = Ember.get(this, 'config.accessKeySecret');

        if (!accessKey) {
          errors.push(intl.t('nodeDriver.aliyunecs.errors.accessKeyRequired'));
        }

        if (!accessSecret) {
          errors.push(intl.t('nodeDriver.aliyunecs.errors.accessSecretRequired'));
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        var ecs = void 0;

        try {
          var location = window.location;
          var endpoint = Ember.get(this, 'config.apiEndpoint') ? Ember.get(this, 'config.apiEndpoint') : ENDPOINT;

          endpoint = Ember.get(this, 'app.proxyEndpoint') + '/' + endpoint.replace('//', '/');
          endpoint = '' + location.origin + endpoint;

          ecs = new ALY.ECS({
            accessKeyId: Ember.get(this, 'config.accessKeyId'),
            secretAccessKey: Ember.get(this, 'config.accessKeySecret'),
            apiVersion: '2014-05-26',
            endpoint: endpoint
          });

          ecs.describeRegions({}, function (err, res) {
            if (err) {
              var _errors = Ember.get(_this, 'errors') || [];

              _errors.pushObject(err.message || err);
              Ember.set(_this, 'errors', _errors);
              cb();

              return;
            }

            Ember.set(_this, 'ecsClient', ecs);
            Ember.set(_this, 'regions', res.Regions.Region.map(function (region) {
              return {
                value: region.RegionId,
                label: region.LocalName
              };
            }));
            _this.regionDidChange();
            Ember.set(_this, 'step', 2);
            cb();
          });
        } catch (err) {
          var _errors2 = Ember.get(this, 'errors') || [];

          _errors2.pushObject(err.message || err);
          Ember.set(this, 'errors', _errors2);
          cb();

          return;
        }
      },
      loadStorageTypes: function loadStorageTypes(cb) {
        Ember.set(this, 'errors', null);

        var errors = Ember.get(this, 'errors') || [];
        var intl = Ember.get(this, 'intl');

        var zone = Ember.get(this, 'config.zone');
        var vpcId = Ember.get(this, 'config.vpcId');
        var vswitchId = Ember.get(this, 'config.vswitchId');

        if (zone) {
          if (!vpcId) {
            errors.push(intl.t('nodeDriver.aliyunecs.errors.vpcIdRequired'));
          }
          if (!vswitchId) {
            errors.push(intl.t('nodeDriver.aliyunecs.errors.vswitchIdRequired'));
          }
        }

        if (errors.length > 0) {
          Ember.set(this, 'errors', errors);
          cb();

          return;
        }

        if (!Ember.get(this, 'config.securityGroup')) {
          Ember.set(this, 'config.securityGroup', 'docker-machine');
        }
        Ember.set(this, 'step', 3);
        this.diskCategoryChoicesDidChange();
        cb();
      },
      loadInstanceTypes: function loadInstanceTypes(cb) {
        var _this2 = this;

        this.fetch('Image', 'Images').then(function (images) {
          Ember.set(_this2, 'images', images.filter(function (image) {
            return image.raw.OSType === 'linux' && ((_this2, 'config.ioOptimized') === 'none' || image.raw.IsSupportIoOptimized);
          }).map(function (image) {
            return {
              label: image.raw.ImageOwnerAlias === 'system' ? image.raw.OSName : image.raw.ImageName,
              value: image.value,
              raw: image.raw
            };
          }));
          var imageId = Ember.get(_this2, 'config.imageId');
          var found = Ember.get(_this2, 'images').findBy('value', imageId);

          if (!found) {
            Ember.set(_this2, 'config.imageId', Ember.get(_this2, 'images.firstObject.value'));
          }
          _this2.fetch('InstanceType', 'InstanceTypes').then(function (instanceTypes) {
            Ember.set(_this2, 'instanceTypes', instanceTypes.map(function (instanceType) {
              return {
                group: instanceType.raw.InstanceTypeFamily,
                value: instanceType.value,
                label: instanceType.raw.InstanceTypeId + ' ( ' + instanceType.raw.CpuCoreCount + ' ' + (instanceType.raw.CpuCoreCount > 1 ? 'Cores' : 'Core') + ' ' + instanceType.raw.MemorySize + 'GB RAM )'
              };
            }));

            var instanceType = void 0;

            if (Ember.get(_this2, 'instanceTypes').findBy('value', Ember.get(_this2, 'config.instanceType'))) {
              instanceType = Ember.get(_this2, 'config.instanceType');
            } else {
              instanceType = Ember.get(_this2, 'instanceTypes.firstObject.value');
            }

            Ember.set(_this2, 'config.instanceType', instanceType);
            Ember.set(_this2, 'step', 4);
            cb();
          }).catch(function (err) {
            var errors = Ember.get(_this2, 'errors') || [];

            errors.pushObject(err.message || err);
            Ember.set(_this2, 'errors', errors);
            cb();

            return;
          });
        }).catch(function (err) {
          var errors = Ember.get(_this2, 'errors') || [];

          errors.pushObject(err.message || err);
          Ember.set(_this2, 'errors', errors);
          cb();

          return;
        });
      }
    },

    zoneDidChange: Ember.observer('config.zone', function () {
      var _this3 = this;

      if (Ember.get(this, 'config.vpcId') && !Ember.get(this, 'vswitches')) {
        this.fetch('VSwitch', 'VSwitches').then(function (vswitches) {
          Ember.set(_this3, 'vswitches', vswitches);
          _this3.resetVswitch();
        });
      } else {
        this.resetVswitch();
      }
    }),

    vpcDidChange: Ember.observer('config.vpcId', function () {
      var _this4 = this;

      var vpcId = Ember.get(this, 'config.vpcId');

      if (vpcId) {
        this.fetch('VSwitch', 'VSwitches').then(function (vswitches) {
          Ember.set(_this4, 'vswitches', vswitches);
          var selectedVSwitch = Ember.get(_this4, 'config.vswitchId');

          if (selectedVSwitch) {
            var found = vswitches.findBy('value', selectedVSwitch);

            if (!found) {
              Ember.set(_this4, 'config.vswitchId', null);
            }
          }
        });

        this.fetch('SecurityGroup', 'SecurityGroups').then(function (securityGroups) {
          Ember.set(_this4, 'securityGroups', securityGroups);
          var selectedSecurityGroup = Ember.get(_this4, 'config.securityGroup');

          if (selectedSecurityGroup) {
            var found = securityGroups.findBy('value', selectedSecurityGroup);

            if (!found) {
              Ember.set(_this4, 'config.securityGroup', 'docker-machine');
            }
          }
        });
      } else {
        Ember.set(this, 'config.vswitchId', null);
        Ember.set(this, 'config.securityGroup', 'docker-machine');
      }
    }),

    regionDidChange: Ember.observer('config.region', function () {
      var _this5 = this;

      var region = Ember.get(this, 'config.region');

      if (region) {
        this.fetch('Vpc', 'Vpcs').then(function (vpcs) {
          Ember.set(_this5, 'vpcs', vpcs);
          var selectedVPC = Ember.get(_this5, 'config.vpcId');

          if (selectedVPC) {
            var found = vpcs.findBy('value', selectedVPC);

            if (!found) {
              Ember.set(_this5, 'config.vpcId', null);
            } else {
              _this5.vpcDidChange();
            }
          }
        });

        this.fetch('Zone', 'Zones').then(function (zones) {
          Ember.set(_this5, 'zones', zones);
          var selectedZone = Ember.get(_this5, 'config.zone');

          if (selectedZone) {
            var found = zones.findBy('value', selectedZone);

            if (!found) {
              Ember.set(_this5, 'config.zone', null);
            } else {
              _this5.zoneDidChange();
            }
          }
        });
      }
    }),

    diskCategoryChoicesDidChange: Ember.observer('diskCategoryChoices.@each.value', function () {
      var systemDiskCategory = Ember.get(this, 'config.systemDiskCategory');
      var found = Ember.get(this, 'diskCategoryChoices').findBy('value', systemDiskCategory);

      if (!found) {
        Ember.set(this, 'config.systemDiskCategory', Ember.get(this, 'diskCategoryChoices.firstObject.value'));
      }

      var diskCategory = Ember.get(this, 'config.diskCategory');

      found = Ember.get(this, 'diskCategoryChoices').findBy('value', diskCategory);
      if (!found) {
        Ember.set(this, 'config.diskCategory', Ember.get(this, 'diskCategoryChoices.firstObject.value'));
      }
    }),

    filteredVSwitches: Ember.computed('vswitches.[]', 'config.zone', function () {
      var zone = Ember.get(this, 'config.zone');

      return (Ember.get(this, 'vswitches') || []).filter(function (swith) {
        if (zone && zone !== swith.raw.ZoneId) {
          return false;
        }

        return true;
      });
    }),

    diskCategoryChoices: Ember.computed('config.ioOptimized', function () {
      return Ember.get(this, 'config.ioOptimized') === 'none' ? NONE_OPT_DISK : OPT_DISK;
    }),

    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: 'aliyunecsConfig',
        accessKeySecret: '',
        instanceType: DEFAULT_INSTANCE_TYPE,
        ioOptimized: 'optimized'
      });

      Ember.set(this, 'model.engineInstallURL', 'http://dev-tool.oss-cn-shenzhen.aliyuncs.com/docker-install/1.13.1.sh');
      Ember.set(this, 'model.engineRegistryMirror', ['https://s06nkgus.mirror.aliyuncs.com']);
      Ember.set(this, 'model.aliyunecsConfig', config);
    },
    resetVswitch: function resetVswitch() {
      var switches = Ember.get(this, 'filteredVSwitches');
      var selectedVSwitch = Ember.get(this, 'config.vswitchId');

      if (selectedVSwitch) {
        var found = switches.findBy('value', selectedVSwitch);

        if (!found) {
          Ember.set(this, 'config.vswitchId', null);
        }
      }
    },
    validate: function validate() {
      this._super();
      var errors = Ember.get(this, 'model').validationErrors();

      var intl = Ember.get(this, 'intl');

      var name = Ember.get(this, 'model.name');

      var sshPassword = Ember.get(this, 'config.sshPassword');

      var lower = /[a-z]/.test(sshPassword) ? 1 : 0;
      var upper = /[A-Z]/.test(sshPassword) ? 1 : 0;
      var number = /[0-9]/.test(sshPassword) ? 1 : 0;
      var special = /[?+*$^().|<>';:\-=\[\]\{\},&%#@!~`\\]/.test(sshPassword) ? 1 : 0;

      if (!name) {
        errors.push('Name is required');
      }

      if (sshPassword && sshPassword.length < 8 || sshPassword.length > 30) {
        errors.push(intl.t('nodeDriver.aliyunecs.errors.sshPasswordLengthNotValid'));
      }

      if (sshPassword && !/[?+*$^().|<>';:\-=\[\]\{\},&%#@!~`\\a-zA-Z0-9]+/.test(sshPassword)) {
        errors.push(intl.t('nodeDriver.aliyunecs.errors.sshPasswordInvalidCharacter'));
      }

      if (sshPassword && lower + upper + number + special < 3) {
        errors.push(Ember.get(this, 'intl').t('nodeDriver.aliyunecs.errors.sshPasswordFormatError'));
      }

      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    },
    fetch: function fetch(resource, plural) {
      var _this6 = this;

      var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;

      var ecs = Ember.get(this, 'ecsClient');
      var region = Ember.get(this, 'config.region');
      var results = [];
      var params = {
        PageSize: PAGE_SIZE,
        PageNumber: page
      };

      switch (resource) {
        case 'InstanceType':
          params = {};
          break;
        case 'VSwitch':
          params.VpcId = Ember.get(this, 'config.vpcId');
          break;
        case 'SecurityGroup':
          params.VpcId = Ember.get(this, 'config.vpcId');
          params.RegionId = region;
          break;
        case 'Zone':
          params = { RegionId: region };
          break;
        default:
          params.RegionId = region;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        ecs['describe' + plural](params, function (err, res) {
          if (err) {
            reject(err);

            return;
          }

          var current = res['' + plural][resource];

          results.pushObjects(current.map(function (item) {
            return {
              label: item[resource + 'Id'],
              value: item[resource + 'Id'],
              raw: item
            };
          }));

          if (res.TotalCount > PAGE_SIZE * (page - 1) + current.length) {
            return _this6.fetch(resource, plural, page + 1).then(function (array) {
              results.pushObjects(array);
              resolve(results);
            }).catch(function (err) {
              reject(err);
            });
          } else {
            resolve(results);
          }
        });
      });
    }
  });
});