define('global-admin/components/form-hostpath-policy/component', ['exports', 'global-admin/components/form-hostpath-policy/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,

    classNames: ['accordion-wrapper'],

    model: null,
    paths: null,
    readOnly: false,

    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model.allowedHostPaths', this.get('model.allowedHostPaths') || []);
      this.set('paths', this.get('model.allowedHostPaths'));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },


    actions: {
      add: function add() {
        this.get('paths').pushObject(this.get('globalStore').createRecord({
          type: 'allowedHostPath',
          pathPrefix: ''
        }));
      },
      remove: function remove(obj) {
        this.get('paths').removeObject(obj);
      }
    },

    pathDidChange: function () {
      this.set('model.allowedHostPaths', this.get('paths').filter(function (p) {
        return p.pathPrefix;
      }));
    }.observes('paths.@each.pathPrefix')

  });
});