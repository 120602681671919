enifed('ember-template-compiler/tests/system/dasherize-component-name-test', ['ember-babel', 'ember-template-compiler/lib/system/dasherize-component-name', 'internal-test-helpers'], function (_emberBabel, _dasherizeComponentName, _internalTestHelpers) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('dasherize-component-name', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype['@test names are correctly dasherized'] = function (assert) {
      assert.equal(_dasherizeComponentName.default.get('Foo'), 'foo');
      assert.equal(_dasherizeComponentName.default.get('foo-bar'), 'foo-bar');
      assert.equal(_dasherizeComponentName.default.get('FooBar'), 'foo-bar');
      assert.equal(_dasherizeComponentName.default.get('XBlah'), 'x-blah');
      assert.equal(_dasherizeComponentName.default.get('X-Blah'), 'x-blah');
      assert.equal(_dasherizeComponentName.default.get('Foo::BarBaz'), 'foo::bar-baz');
      assert.equal(_dasherizeComponentName.default.get('Foo::Bar-Baz'), 'foo::bar-baz');
      assert.equal(_dasherizeComponentName.default.get('Foo@BarBaz'), 'foo@bar-baz');
      assert.equal(_dasherizeComponentName.default.get('Foo@Bar-Baz'), 'foo@bar-baz');
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});