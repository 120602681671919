define('shared/components/upgrade-btn/component', ['exports', 'shared/mixins/upgrade-component', 'shared/components/upgrade-btn/template'], function (exports, _upgradeComponent, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_upgradeComponent.default, {
    layout: _template.default,
    tagName: 'button',
    classNames: ['btn'],
    classNameBindings: ['color', 'pad'],

    pad: Ember.computed('color', function () {
      if (this.get('color') === 'bg-transparent') {
        return 'p-0';
      }
    }),

    click: function click() {
      this.doUpgrade();
    }
  });
});