enifed('ember-template-compiler/lib/system/initializer', ['require', 'ember-template-compiler/lib/system/bootstrap'], function (_require2, _bootstrap) {
    'use strict';

    // Globals mode template compiler

    var emberEnv, emberGlimmer, emberApp, Application, hasTemplate, setTemplate, hasDOM;
    if ((0, _require2.has)('@ember/application') && (0, _require2.has)('ember-browser-environment') && (0, _require2.has)('ember-glimmer')) {
        // tslint:disable:no-require-imports
        emberEnv = (0, _require2.default)('ember-browser-environment');
        emberGlimmer = (0, _require2.default)('ember-glimmer');
        emberApp = (0, _require2.default)('@ember/application');
        Application = emberApp.default;
        hasTemplate = emberGlimmer.hasTemplate, setTemplate = emberGlimmer.setTemplate;
        hasDOM = emberEnv.hasDOM;


        Application.initializer({
            name: 'domTemplates',
            initialize: function () {
                var context = void 0;
                if (hasDOM) {
                    context = document;
                }
                (0, _bootstrap.default)({ context: context, hasTemplate: hasTemplate, setTemplate: setTemplate });
            }
        });
    }
});