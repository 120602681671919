define('shared/cookies/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function parseValue(value) {
    if (value.charAt(0) === '"') {
      value = value.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\').replace(/\+/g, ' ');
    }

    return decodeURIComponent(value);
  }

  function getAll() {
    var cookies = document.cookie.split(/;\s*/);
    var ret = {};

    cookies.forEach(function (cookie) {
      if (!cookie) {
        return;
      }

      var idx = cookie.indexOf('=');

      if (idx === -1) {
        idx = cookie.length;
      }

      try {
        var name = decodeURIComponent(cookie.substr(0, idx));
        var val = parseValue(cookie.substr(idx + 1));

        ret[name] = val;
      } catch (e) {}
    });

    return ret;
  }

  exports.default = Ember.Service.extend({
    app: Ember.inject.service(),
    unknownProperty: function unknownProperty(key) {
      var all = getAll();

      return all[key] || null;
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      if (key !== 'app') {
        this.setWithOptions(key, value);
      }

      return value;
    },


    // Opt: expire: date or number of days, path, domain, secure
    setWithOptions: function setWithOptions(name, value, opt) {
      opt = opt || {};
      opt.path = typeof opt.path === 'undefined' ? '/' : opt.path;

      if (typeof opt.secure === 'undefined' || opt.secure === 'auto') {
        opt.secure = window.location.protocol === 'https:';
      } else {
        opt.secure = !!opt.secure;
      }

      var str = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (opt.expire) {
        var date = void 0;

        if (_typeof(opt.expire) === 'object') {
          date = opt.expire;
        } else {
          date = new Date(new Date().getTime() + 86400000 * opt.expire);
        }

        str += ';expires=' + date.toGMTString();
      }

      if (opt.path) {
        str += ';path=' + opt.path;
      }

      if (opt.domain) {
        str += ';domain=' + opt.domain;
      }

      if (opt.secure) {
        str += ';secure';
      }

      try {
        document.cookie = str;
      } catch (e) {
        return false;
      }

      this.notifyPropertyChange(name);

      return true;
    },
    remove: function remove(name, opt) {
      opt = opt || {};
      opt.expire = new Date('Wed, 24 Feb 1982 18:42:00 UTC');

      return this.setWithOptions(name, 'removed', opt);
    }
  });
});