enifed('ember-runtime/lib/system/core_object', ['exports', 'ember-babel', 'container', '@ember/deprecated-features', '@ember/polyfills', 'ember-utils', '@ember/runloop', 'ember-meta', 'ember-metal', 'ember-runtime/lib/mixins/action_handler', '@ember/debug', 'ember-environment'], function (exports, _emberBabel, _container, _deprecatedFeatures, _polyfills, _emberUtils, _runloop, _emberMeta, _emberMetal, _action_handler, _debug, _emberEnvironment) {
  'use strict';

  /**
    @module @ember/object
  */

  var _reopen = _emberMetal.Mixin.prototype.reopen;

  var wasApplied = new _emberUtils.WeakSet();

  var factoryMap = new WeakMap();

  var prototypeMixinMap = new WeakMap();

  /**
    @class CoreObject
    @public
  */

  var CoreObject = function () {
    CoreObject._initFactory = function (factory) {
      factoryMap.set(this, factory);
    };

    function CoreObject(properties) {
      var _self, concatenatedProperties, mergedProperties, hasConcatenatedProps, hasMergedProps, keyNames, i, keyName, value, possibleDesc, isDescriptor, baseValue;

      // pluck off factory
      var initFactory = factoryMap.get(this.constructor);
      if (initFactory !== undefined) {
        factoryMap.delete(this.constructor);
        _container.FACTORY_FOR.set(this, initFactory);
      }

      // prepare prototype...
      this.constructor.proto();

      var self = this; // only used in debug builds to enable the proxy trap

      // using DEBUG here to avoid the extraneous variable when not needed


      var m = (0, _emberMeta.meta)(self);
      var proto = m.proto;
      m.proto = self;

      if (properties !== undefined) {
        false && !(typeof properties === 'object' && properties !== null) && (0, _debug.assert)('EmberObject.create only accepts objects.', typeof properties === 'object' && properties !== null);
        false && !!(properties instanceof _emberMetal.Mixin) && (0, _debug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

        concatenatedProperties = self.concatenatedProperties;
        mergedProperties = self.mergedProperties;
        hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
        hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;
        keyNames = Object.keys(properties);


        for (i = 0; i < keyNames.length; i++) {
          keyName = keyNames[i];
          value = properties[keyName];


          if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT && _emberMetal.Mixin.detectBinding(keyName)) {
            m.writeBindings(keyName, value);
          }

          false && !!(value instanceof _emberMetal.ComputedProperty) && (0, _debug.assert)('EmberObject.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty));
          false && !!(typeof value === 'function' && value.toString().indexOf('._super') !== -1) && (0, _debug.assert)('EmberObject.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1));
          false && !!(keyName === 'actions' && _action_handler.default.detect(this)) && (0, _debug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this)));

          possibleDesc = (0, _emberMeta.descriptorFor)(self, keyName, m);
          isDescriptor = possibleDesc !== undefined;


          if (!isDescriptor) {
            baseValue = self[keyName];


            if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
              if (baseValue) {
                value = (0, _emberUtils.makeArray)(baseValue).concat(value);
              } else {
                value = (0, _emberUtils.makeArray)(value);
              }
            }

            if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
              value = (0, _polyfills.assign)({}, baseValue, value);
            }
          }

          if (isDescriptor) {
            possibleDesc.set(self, keyName, value);
          } else if (typeof self.setUnknownProperty === 'function' && !(keyName in self)) {
            self.setUnknownProperty(keyName, value);
          } else {
            self[keyName] = value;
          }
        }
      }

      if (_deprecatedFeatures.BINDING_SUPPORT && _emberEnvironment.ENV._ENABLE_BINDING_SUPPORT) {
        _emberMetal.Mixin.finishPartial(self, m);
      }

      // using DEBUG here to avoid the extraneous variable when not needed

      (_self = self).init.apply(_self, arguments);

      m.proto = proto;
      (0, _emberMetal.finishChains)(m);
      (0, _emberMetal.sendEvent)(self, 'init', undefined, undefined, undefined, m);

      // only return when in debug builds and `self` is the proxy created above
    }

    CoreObject.prototype.reopen = function () {
      var _len, args, _key;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      (0, _emberMetal.applyMixin)(this, args, true);
      return this;
    };

    CoreObject.prototype.init = function () {};

    CoreObject.prototype.destroy = function () {
      var m = (0, _emberMeta.peekMeta)(this);
      if (m.isSourceDestroying()) {
        return;
      }

      m.setSourceDestroying();

      (0, _runloop.schedule)('actions', this, this.willDestroy);
      (0, _runloop.schedule)('destroy', this, this._scheduledDestroy, m);

      return this;
    };

    CoreObject.prototype.willDestroy = function () {};

    CoreObject.prototype._scheduledDestroy = function (m) {
      if (m.isSourceDestroyed()) {
        return;
      }
      (0, _emberMeta.deleteMeta)(this);
      m.setSourceDestroyed();
    };

    CoreObject.prototype.toString = function () {
      var hasToStringExtension = typeof this.toStringExtension === 'function';
      var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

      var ret = '<' + ((0, _emberUtils.getName)(this) || _container.FACTORY_FOR.get(this) || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

      return ret;
    };

    CoreObject.extend = function () {
      var Class = function (_ref) {
        (0, _emberBabel.inherits)(Class, _ref);

        function Class() {
          return (0, _emberBabel.possibleConstructorReturn)(this, _ref.apply(this, arguments));
        }

        return Class;
      }(this);
      _reopen.apply(Class.PrototypeMixin, arguments);
      return Class;
    };

    CoreObject.create = function (props, extra) {
      var C = this;

      if (extra === undefined) {
        return new C(props);
      } else {
        return new C(flattenProps.apply(this, arguments));
      }
    };

    CoreObject.reopen = function () {
      this.willReopen();
      _reopen.apply(this.PrototypeMixin, arguments);
      return this;
    };

    CoreObject.willReopen = function () {
      var p = this.prototype;
      if (wasApplied.has(p)) {
        wasApplied.delete(p);

        // If the base mixin already exists and was applied, create a new mixin to
        // make sure that it gets properly applied. Reusing the same mixin after
        // the first `proto` call will cause it to get skipped.
        if (prototypeMixinMap.has(this)) {
          prototypeMixinMap.set(this, _emberMetal.Mixin.create(this.PrototypeMixin));
        }
      }
    };

    CoreObject.reopenClass = function () {
      (0, _emberMetal.applyMixin)(this, arguments, false);
      return this;
    };

    CoreObject.detect = function (obj) {
      if ('function' !== typeof obj) {
        return false;
      }
      while (obj) {
        if (obj === this) {
          return true;
        }
        obj = obj.superclass;
      }
      return false;
    };

    CoreObject.detectInstance = function (obj) {
      return obj instanceof this;
    };

    CoreObject.metaForProperty = function (key) {
      var proto = this.proto(); // ensure prototype is initialized
      var possibleDesc = (0, _emberMeta.descriptorFor)(proto, key);

      false && !(possibleDesc !== undefined) && (0, _debug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== undefined);

      return possibleDesc._meta || {};
    };

    CoreObject.eachComputedProperty = function (callback) {
      var binding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this;

      this.proto(); // ensure prototype is initialized
      var empty = {};

      (0, _emberMeta.meta)(this.prototype).forEachDescriptors(function (name, descriptor) {
        var _meta;

        if (descriptor.enumerable) {
          _meta = descriptor._meta || empty;

          callback.call(binding, name, _meta);
        }
      });
    };

    CoreObject.proto = function () {
      var p = this.prototype,
          parent;
      if (!wasApplied.has(p)) {
        wasApplied.add(p);
        parent = this.superclass;

        if (parent) {
          parent.proto();
        }

        // If the prototype mixin exists, apply it. In the case of native classes,
        // it will not exist (unless the class has been reopened).
        if (prototypeMixinMap.has(this)) {
          this.PrototypeMixin.apply(p);
        }
      }
      return p;
    };

    (0, _emberBabel.createClass)(CoreObject, [{
      key: 'isDestroyed',
      get: function () {
        return (0, _emberMeta.peekMeta)(this).isSourceDestroyed();
      },
      set: function () {
        false && !false && (0, _debug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', false);
      }
    }, {
      key: 'isDestroying',
      get: function () {
        return (0, _emberMeta.peekMeta)(this).isSourceDestroying();
      },
      set: function () {
        false && !false && (0, _debug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', false);
      }
    }], [{
      key: 'PrototypeMixin',
      get: function () {
        var prototypeMixin = prototypeMixinMap.get(this);
        if (prototypeMixin === undefined) {
          prototypeMixin = _emberMetal.Mixin.create();
          prototypeMixin.ownerConstructor = this;
          prototypeMixinMap.set(this, prototypeMixin);
        }
        return prototypeMixin;
      }
    }, {
      key: 'superclass',
      get: function () {
        var c = Object.getPrototypeOf(this);
        if (c !== Function.prototype) return c;
      }
    }]);
    return CoreObject;
  }();

  CoreObject.toString = _emberMetal.classToString;
  (0, _emberUtils.setName)(CoreObject, 'Ember.CoreObject');

  CoreObject.isClass = true;
  CoreObject.isMethod = false;

  function flattenProps() {
    var concatenatedProperties = this.concatenatedProperties,
        mergedProperties = this.mergedProperties,
        _len2,
        props,
        _key2,
        i,
        properties,
        keyNames,
        j,
        k,
        keyName,
        value,
        baseValue,
        _baseValue;

    var hasConcatenatedProps = concatenatedProperties !== undefined && concatenatedProperties.length > 0;
    var hasMergedProps = mergedProperties !== undefined && mergedProperties.length > 0;

    var initProperties = {};

    for (_len2 = arguments.length, props = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      props[_key2] = arguments[_key2];
    }

    for (i = 0; i < props.length; i++) {
      properties = props[i];


      false && !!(properties instanceof _emberMetal.Mixin) && (0, _debug.assert)('EmberObject.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

      keyNames = Object.keys(properties);


      for (j = 0, k = keyNames.length; j < k; j++) {
        keyName = keyNames[j];
        value = properties[keyName];


        if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
          baseValue = initProperties[keyName];


          if (baseValue) {
            value = (0, _emberUtils.makeArray)(baseValue).concat(value);
          } else {
            value = (0, _emberUtils.makeArray)(value);
          }
        }

        if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
          _baseValue = initProperties[keyName];


          value = (0, _polyfills.assign)({}, _baseValue, value);
        }

        initProperties[keyName] = value;
      }
    }

    return initProperties;
  }

  exports.default = CoreObject;
});