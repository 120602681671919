enifed('ember-template-compiler/index', ['exports', 'ember-template-compiler/lib/system/precompile', 'ember-template-compiler/lib/system/compile', 'ember-template-compiler/lib/system/compile-options', 'ember-template-compiler/lib/plugins/index', '@ember/canary-features', 'ember-environment', 'ember/version', 'ember-template-compiler/lib/compat', 'ember-template-compiler/lib/system/bootstrap', 'ember-template-compiler/lib/system/initializer'], function (exports, _precompile, _compile, _compileOptions, _index, _canaryFeatures, _emberEnvironment, _version, _compat) {
    'use strict';

    exports.defaultPlugins = exports.unregisterPlugin = exports.registerPlugin = exports.compileOptions = exports.compile = exports.precompile = exports._Ember = undefined;
    Object.defineProperty(exports, 'precompile', {
        enumerable: true,
        get: function () {
            return _precompile.default;
        }
    });
    Object.defineProperty(exports, 'compile', {
        enumerable: true,
        get: function () {
            return _compile.default;
        }
    });
    Object.defineProperty(exports, 'compileOptions', {
        enumerable: true,
        get: function () {
            return _compileOptions.default;
        }
    });
    Object.defineProperty(exports, 'registerPlugin', {
        enumerable: true,
        get: function () {
            return _compileOptions.registerPlugin;
        }
    });
    Object.defineProperty(exports, 'unregisterPlugin', {
        enumerable: true,
        get: function () {
            return _compileOptions.unregisterPlugin;
        }
    });
    Object.defineProperty(exports, 'defaultPlugins', {
        enumerable: true,
        get: function () {
            return _index.default;
        }
    });
    var _Ember = exports._Ember = typeof _emberEnvironment.context.imports.Ember === 'object' && _emberEnvironment.context.imports.Ember || {};
    // private API used by ember-cli-htmlbars to setup ENV and FEATURES
    if (!_Ember.ENV) {
        _Ember.ENV = _emberEnvironment.ENV;
    }
    if (!_Ember.FEATURES) {
        _Ember.FEATURES = _canaryFeatures.FEATURES;
    }
    if (!_Ember.VERSION) {
        _Ember.VERSION = _version.default;
    }
    // used for adding Ember.Handlebars.compile for backwards compat

    (0, _compat.default)(_Ember);
    // used to bootstrap templates

    // add domTemplates initializer (only does something if `ember-template-compiler`
    // is loaded already)
});