define('shared/tab-session/service', ['exports', 'ui/utils/browser-storage'], function (exports, _browserStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_browserStorage.default, {
    backing: window.sessionStorage,
    app: Ember.inject.service()
  });
});