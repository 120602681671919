define('shared/components/code-block/component', ['exports', 'shared/components/code-block/template', 'prismjs', 'prismjs/components/prism-bash', 'prismjs/components/prism-yaml', 'prismjs/components/prism-json'], function (exports, _template, _prismjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    language: 'javascript',
    code: '',
    hide: false,
    constrained: true,

    tagName: 'PRE',
    classNames: ['line-numbers'],
    classNameBindings: ['languageClass', 'hide:hide', 'constrained:constrained'],

    highlighted: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this.highlightedChanged();
    },


    highlightedChanged: Ember.observer('language', 'code', function () {
      var lang = this.get('language');

      this.set('highlighted', _prismjs.default.highlight(this.get('code') || '', _prismjs.default.languages[lang], lang));
    }),

    languageClass: function () {
      var lang = this.get('language');

      if (lang) {
        return 'language-' + lang;
      }
    }.property('language')

  });
});