define('shared/components/form-ingress-rows/component', ['exports', 'shared/components/form-ingress-rows/template', 'shared/utils/constants'], function (exports, _template, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    capabilities: Ember.inject.service(),

    layout: _template.default,

    mode: 'automatic',

    rule: null,
    rules: null,
    ingress: null,
    editing: true,
    existingHost: null,
    selectedProvider: null,
    ingressControllersCapabilities: Ember.computed.alias('capabilities.ingressCapabilities.ingressControllersCapabilities'),
    defaultProvider: Ember.computed.alias('capabilities.ingressCapabilities.defaultIngressProvider'),
    defaultBackend: Ember.computed.alias('ingress.defaultBackend'),

    init: function init() {
      this._super.apply(this, arguments);

      var xip = Ember.get(this, 'settings.' + _constants.default.SETTING.INGRESS_IP_DOMAIN);
      var host = Ember.get(this, 'rule.host');

      if (Ember.get(this, 'rule.defaultBackend')) {
        Ember.set(this, 'mode', 'default');
      } else if (host && host === xip) {
        Ember.set(this, 'mode', 'automatic');
      } else if (!Ember.get(this, 'rule.new')) {
        Ember.set(this, 'mode', 'existing');
        Ember.set(this, 'existingHost', host);
      }

      this.modeChanged();

      if (Ember.get(this, 'ingressControllersCapabilities.length') >= 1) {
        Ember.set(this, 'selectedProvider', Ember.get(this, 'ingressControllersCapabilities.firstObject.ingressProvider'));
      }
    },


    actions: {
      removeRule: function removeRule(rule) {
        this.sendAction('removeRule', rule);
        if (rule.defaultBackend) {
          Ember.set(this, 'ingress.defaultBackend', null);
        }
      }
    },

    modeChanged: Ember.observer('mode', function () {
      var mode = this.mode,
          rule = this.rule,
          defaultBackend = this.defaultBackend;


      if (mode === 'default' && !Ember.get(this, 'rule.defaultBackend')) {
        Ember.set(rule, 'defaultBackend', true);
      } else if (mode !== 'default' && defaultBackend && Ember.get(this, 'rule.defaultBackend')) {
        Ember.set(rule, 'defaultBackend', false);
        Ember.set(this, 'ingress.defaultBackend', null);
      }

      var xip = Ember.get(this, 'settings.' + _constants.default.SETTING.INGRESS_IP_DOMAIN);
      var existingHost = Ember.get(this, 'existingHost');

      switch (mode) {
        case 'automatic':
          Ember.set(rule, 'host', xip);
          break;
        case 'existing':
          Ember.set(rule, 'host', existingHost);
          break;
        default:
          if (Ember.get(rule, 'host') === xip) {
            Ember.set(rule, 'host', existingHost || '');
          }
          break;
      }
    }),

    isDefault: Ember.computed('mode', function () {
      return Ember.get(this, 'mode') === 'default';
    }),

    defaultDisabled: Ember.computed('rules.@each.defaultBackend', function () {
      var ingressControllersCapabilities = this.ingressControllersCapabilities,
          selectedProvider = this.selectedProvider;


      var def = Ember.get(this, 'ingress.defaultBackend');
      var me = Ember.get(this, 'rule.defaultBackend');
      var cap = ingressControllersCapabilities.length >= 1 ? ingressControllersCapabilities.findBy('ingressProvider', selectedProvider) : null;

      // if we dont have capabilities at all we don't want to disable to ability to use the default backend
      var customDefaultBackend = true;

      if (cap !== null) {
        customDefaultBackend = Ember.get(cap, 'customDefaultBackend');
      }

      return !!def && !me || !customDefaultBackend;
    })

  });
});