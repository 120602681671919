define('global-admin/mixins/saml-auth', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    settings: Ember.inject.service(),
    saml: Ember.inject.service(),
    errors: null,
    redirectUrl: null,
    saving: false,
    saved: false,
    testing: false,
    disableAuth: true,
    urlError: null,
    urlWarning: false,
    urlInvalid: false,
    protocol: 'https://',
    authConfig: Ember.computed.alias('model.authConfig'),
    providerNamePath: null,
    providerName: null,

    didChange: Ember.observer('authConfig.rancherApiHost', function () {
      if (!Ember.get(this, 'authConfig.rancherApiHost')) {
        Ember.set(this, 'authConfig.rancherApiHost', Ember.get(this, 'model.serverUrl.value'));
      }
    }),

    actions: {
      authTest: function authTest(cb) {
        var _this = this;

        this.send('clearError');

        var model = Ember.get(this, 'authConfig');
        var am = Ember.get(model, 'accessMode') || 'unrestricted';

        Ember.setProperties(model, { accessMode: am });

        var errors = model.validationErrors();

        if (errors.get('length')) {
          Ember.setProperties(this, {
            errors: errors,
            testing: false
          });

          Ember.set(model, 'enabled', false);
          cb(false);
        } else {
          Ember.set(this, 'testing', true);

          var allowedPrincipals = [];

          if (Ember.get(this, 'editing')) {
            allowedPrincipals = Ember.get(model, 'allowedPrincipalIds');
          }

          Ember.setProperties(model, {
            'enabled': false, // It should already be, but just in case..
            'allowedPrincipalIds': allowedPrincipals
          });

          model.save().then(function () {
            model.doAction('testAndEnable', { finalRedirectUrl: window.location.origin + '/verify-auth?config=' + Ember.get(_this, 'providerName') }).then(function (resp) {
              Ember.get(_this, 'saml').test(resp, function (popupResults) {
                if (popupResults.type === 'error') {
                  _this.set('errors', [popupResults.message]);
                  cb(false);
                } else {
                  _this.send('waitAndRefresh');
                }
              });
            }).catch(function (err) {
              _this.set('errors', [err]);
              cb(false);
            });
          }).catch(function (err) {
            _this.set('errors', [err]);
            cb(false);
          });
        }
      }
    },

    apiHost: Ember.computed('authConfig.rancherApiHost', {
      get: function get() {
        var host = Ember.get(this, 'authConfig.rancherApiHost') || '';

        if (host.length > 0) {
          host = host.slice(8, host.length);
        }

        return host;
      },
      set: function set(key, value) {
        var host = '';

        if (host.indexOf(Ember.get(this, 'protocol'))) {
          host = '' + Ember.get(this, 'protocol') + value;
        } else {
          host = value;
        }

        Ember.set(this, 'authConfig.rancherApiHost', host);

        return value;
      }
    }),

    numUsers: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'wasRestricted', function () {
      var type = 'PROJECT.TYPE_' + Ember.get(this, 'providerName').capitalize() + '_USER';

      type = Ember.get(_constants.default, type);

      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    }),

    numOrgs: Ember.computed('authConfig.allowedIdentities.@each.externalIdType', 'wasRestricted', function () {
      var type = 'PROJECT.TYPE_' + Ember.get(this, 'providerName').capitalize() + '_GROUP';

      type = Ember.get(_constants.default, type);

      return (this.get('authConfig.allowedIdentities') || []).filterBy('externalIdType', type).get('length');
    })

  });
});