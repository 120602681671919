define('global-admin/security/authentication/shibboleth/controller', ['exports', 'ui/utils/util', 'ui/utils/constants'], function (exports, _util, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    session: Ember.inject.service(),
    shibbolethAuth: Ember.inject.service(),
    providerName: 'authPage.shibboleth.providerName.shibboleth',
    errors: null,
    confirmDisable: false,
    redirectUrl: null,
    saving: false,
    saved: false,
    testing: false,
    disableAuth: true,
    config: Ember.computed.alias('model.shibbolethConfig'),
    actions: {
      disable: function disable() {
        var _this = this;

        var model = this.get('model').clone();

        model.setProperties({
          'allowedIdentities': [],
          'accessMode': 'unrestricted',
          'enabled': false
        });

        model.save().then(function () {
          _this.get('shibbolethAuth').waitAndRefresh();
        }).catch(function (err) {
          _this.send('gotError', err);
        }).finally(function () {
          _this.set('confirmDisable', false);
        });
      },
      promptDisable: function promptDisable() {
        this.set('confirmDisable', true);
        Ember.run.later(this, function () {
          this.set('confirmDisable', false);
        }, 10000);
      },
      save: function save() {
        var _this2 = this;

        if (this.validate()) {
          this.set('saving', true);
          this.get('model').setProperties({
            'provider': 'shibbolethconfig',
            'enabled': false, // It should already be, but just in case..
            'accessMode': 'unrestricted',
            'allowedIdentities': []
          });
          this.get('model').save().then(function () /* resp*/{
            _this2.get('shibbolethAuth').getToken().then(function (token) {
              _this2.setProperties({
                saving: false,
                saved: true,
                disableAuth: false,
                redirectUrl: token.redirectUrl
              });
              _this2.get('access').set('token', token);
            });
          }).catch(function (err) {
            _this2.set('errors', [err]);
          });
        }
      },
      authTest: function authTest() {
        var _this3 = this;

        var responded = false;

        this.set('testing', true);
        window.onShibbolethTest = function (err) {
          if (!responded) {
            responded = true;
            if (err) {
              _this3.set('errors', [err]);
            } else {
              _this3.set('testing', false);
              _this3.get('shibbolethAuth').authenticationSucceeded(_this3.get('model'));
            }
          }
        };

        var popup = window.open(this.get('shibbolethAuth').buildRedirectUrl(this.get('redirectUrl'), true), 'rancherAuth', _util.default.popupWindowOptions());
        var timer = setInterval(function () {
          if (!popup || popup.closed) {
            clearInterval(timer);
            if (!responded) {
              responded = true;
              _this3.set('testing', false);
              _this3.set('errors', ['Shibboleth access was not authorized']);
            }
          }
        }, 500);
      }
    },
    numUsers: function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', _constants.default.PROJECT.TYPE_SHIBBOLETH_USER).get('length');
    }.property('model.allowedIdentities.@each.externalIdType', 'wasRestricted'),

    numOrgs: function () {
      return (this.get('model.allowedIdentities') || []).filterBy('externalIdType', _constants.default.PROJECT.TYPE_SHIBBOLETH_GROUP).get('length');
    }.property('model.allowedIdentities.@each.externalIdType', 'wasRestricted'),
    validate: function validate() {
      var model = Ember.Object.create(this.get('config'));
      var errors = [];

      if ((model.get('displayNameField') || '').trim().length === 0) {
        errors.push('Display Name is required');
      }
      if ((model.get('userNameField') || '').trim().length === 0) {
        errors.push('User Name is required');
      }
      if ((model.get('uidField') || '').trim().length === 0) {
        errors.push('User ID is required');
      }
      if ((model.get('groupsField') || '').trim().length === 0) {
        errors.push('Groups is required');
      }
      if ((model.get('displayNameField') || '').trim().length === 0) {
        errors.push('Display Name is required');
      }
      if ((model.get('spCert') || '').trim().length === 0) {
        errors.push('Certificate is required');
      }
      if ((model.get('spKey') || '').trim().length === 0) {
        errors.push('Key is required');
      }
      if ((model.get('idpMetadataContent') || '').trim().length === 0) {
        errors.push('Metadata XML is required');
      }
      if (errors.length) {
        this.set('errors', errors.uniq());

        return false;
      } else {
        this.set('errors', null);
      }

      return true;
    }
  });
});