define('shared/components/check-box/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Checkbox.extend({
    attributeBindings: ['nodeId'],
    nodeId: null
  });
});