define('shared/components/input-identity/component', ['exports', 'ui/utils/constants', 'shared/components/input-identity/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    access: Ember.inject.service(),
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    allowTeams: true,
    checking: false,
    addInput: '',
    allPrincipals: null,
    selected: null,
    selectExactOnBlur: true,
    includeLocal: true,

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'allPrincipals', Ember.get(this, 'globalStore').all('principal'));
    },


    actions: {
      add: function add() {
        var _this = this;

        if (Ember.get(this, 'checking')) {
          return;
        }

        var addInput = Ember.get(this, 'addInput');

        if (!addInput) {
          Ember.set(this, 'selected', null);

          this.sendAction('action', null);

          // console.log('@add:Cleared principal');

          return;
        }

        Ember.set(this, 'checking', true);

        var input = Ember.get(addInput, 'value').trim();
        var match = Ember.get(this, 'allPrincipals').findBy('id', input);

        if (match) {
          this.send('selectExact', match);

          Ember.set(this, 'checking', false);
        } else {
          Ember.get(this, 'globalStore').rawRequest({
            url: 'principals/' + encodeURIComponent(input),
            method: 'GET'
          }).then(function (xhr) {
            if (xhr.status === 204) {
              return;
            }

            if (xhr.body && _typeof(xhr.body) === 'object') {
              var principal = Ember.get(xhr, 'body');

              _this.send('selectExact', principal);
            }
          }).catch(function (xhr) {
            _this.sendAction('onError', 'Principal not found: ' + xhr.statusText);
          }).finally(function () {
            Ember.set(_this, 'checking', false);
          });
        }
      },
      addObject: function addObject(info) {
        this.sendAction('action', info);

        Ember.setProperties(this, {
          selected: info,
          filter: Ember.get(info, 'name')
        });
        // console.log('@addObject:Set principal:', JSON.stringify(info));
      },
      selectExact: function selectExact(match) {
        var cur = Ember.get(this, 'selected');

        if (!cur) {
          this.sendAction('action', match);

          Ember.setProperties(this, {
            addInput: '',
            selected: match
          });
          // console.log('@selectExact:Set principal:', JSON.stringify(match));
        }
      }
    },

    showDropdown: Ember.computed('access.provider', function () {
      return Ember.get(this, 'access.provider') !== 'localauthconfig';
    }),

    addDisabled: Ember.computed('addInput', 'checking', function () {
      var input = Ember.get(this, 'addInput.value') || '';

      return Ember.get(this, 'checking') || input.trim().length === 0;
    }),

    dropdownChoices: Ember.computed('allPrincipals.@each.{logicalType,id}', 'allowTeams', function () {
      var allowTeams = Ember.get(this, 'allowTeams');

      return Ember.get(this, 'allPrincipals').filter(function (principal) {
        var type = Ember.get(principal, 'parsedExternalType');
        var logicalType = Ember.get(principal, 'logicalType');

        // Don't show other junk that was added to the store after load
        if (!Ember.get(principal, '_mine')) {
          return false;
        }

        // Don't show people
        if (logicalType === _constants.default.PROJECT.PERSON) {
          return false;
        }

        // Don't show teams if disabled
        if (!allowTeams && type === _constants.default.PROJECT.TYPE_GITHUB_TEAM) {
          return false;
        }

        return true;
      }).sortBy('logicalTypeSort', 'profileUrl', 'name');
    }),

    dropdownLabel: Ember.computed('access.provider', 'intl.locale', function () {
      var out = '';
      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'access.provider') === 'githubconfig') {
        out = intl.findTranslationByKey('inputIdentity.dropdownLabel.teams');
      } else {
        out = intl.findTranslationByKey('inputIdentity.dropdownLabel.groups');
      }

      return intl.formatMessage(out);
    })
  });
});