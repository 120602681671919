enifed('ember-template-compiler/lib/plugins/transform-action-syntax', ['exports'], function (exports) {
    'use strict';

    exports.default =
    /**
     @module ember
    */
    /**
      A Glimmer2 AST transformation that replaces all instances of
    
      ```handlebars
     <button {{action 'foo'}}>
     <button onblur={{action 'foo'}}>
     <button onblur={{action (action 'foo') 'bar'}}>
      ```
    
      with
    
      ```handlebars
     <button {{action this 'foo'}}>
     <button onblur={{action this 'foo'}}>
     <button onblur={{action this (action this 'foo') 'bar'}}>
      ```
    
      @private
      @class TransformActionSyntax
    */
    function (_ref) {
        var syntax = _ref.syntax;
        var b = syntax.builders;

        return {
            name: 'transform-action-syntax',
            visitor: {
                ElementModifierStatement: function (node) {
                    if (isAction(node)) {
                        insertThisAsFirstParam(node, b);
                    }
                },
                MustacheStatement: function (node) {
                    if (isAction(node)) {
                        insertThisAsFirstParam(node, b);
                    }
                },
                SubExpression: function (node) {
                    if (isAction(node)) {
                        insertThisAsFirstParam(node, b);
                    }
                }
            }
        };
    };
    function isAction(node) {
        return node.path.original === 'action';
    }
    function insertThisAsFirstParam(node, builders) {
        node.params.unshift(builders.path('this'));
    }
});