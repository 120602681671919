define('@rancher/ember-api-store/utils/fetch', ['exports', 'fetch'], function (exports, _fetch2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fetch = fetch;
  function fetch(url, opt) {
    opt = opt || {};
    if (!opt.credentials) {
      opt.credentials = 'same-origin';
    }

    if (opt.data && !opt.body) {
      opt.body = opt.data;
      delete opt.data;
    }

    return (0, _fetch2.default)(url, opt).then(done);
  }

  function done(res) {
    var ct = '';
    if (res && res.headers) {
      ct = res.headers.get("content-type");
    }

    if (res.status === 204) {
      return respond(res);
    } else {
      return res.text().then(function (body) {
        if (body.length) {
          if (ct && ct.toLowerCase().indexOf("application/json") >= 0) {
            // return res.json().then(function(body) {
            return respond(res, JSON.parse(body));
            // });
          } else {
            return respond(res, body);
          }
        } else {
          // return res.text().then(function(body) {
          return respond(res, null);
          // });
        }
      });
    }
  }

  function respond(res, body) {
    var out = {
      body: body,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers
    };

    if (res.ok) {
      return out;
    } else {
      return Ember.RSVP.reject(out);
    }
  }

  exports.default = fetch;
});