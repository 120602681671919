define('shared/components/language-dropdown/component', ['exports', 'shared/components/language-dropdown/template', 'shared/utils/constants'], function (exports, _template, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    language: Ember.inject.service('user-language'),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    settings: Ember.inject.service(),

    layout: _template.default,
    tagName: 'div',
    classNames: ['dropdown', 'language-dropdown', 'inline-block'],
    classNameBindings: ['hideSingle:hide'],

    // Set to true on login to savesession value instead of user-pref
    login: false,

    locales: Ember.computed.alias('language.locales'),

    actions: {
      selectLanguage: function selectLanguage(language) {
        var _this = this;

        if (this.get('login')) {
          this.get('session').set(_constants.default.SESSION.LOGIN_LANGUAGE, language);
        }

        this.get('language').sideLoadLanguage(language).then(function () {
          if (!_this.get('login')) {
            _this.get('language').setLanguage(language);
          }
        });
      }
    },

    hideSingle: function () {
      return Object.keys(this.get('locales')).length <= 1;
    }.property('locales'),

    selected: Ember.computed('intl.locale', function () {
      var locale = this.get('intl.locale');

      if (locale) {
        return locale[0];
      }

      return null;
    }),

    selectedLabel: Ember.computed('selected', 'locales', function () {
      var sel = this.get('selected');
      var out = '';

      if (sel) {
        out = this.get('locales')[sel];
      }

      if (!out) {
        out = 'Language';
      }

      // Strip parens for display
      return out.replace(/\s+\(.+\)$/, '');
    })

  });
});