define('global-admin/components/other-role-row/component', ['exports', 'global-admin/components/other-role-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    otherRoles: null,
    choices: null,
    readOnly: null,

    tagName: 'TR',
    classNames: 'main-row',

    init: function init() {
      this._super.apply(this, arguments);
      var otherRoles = this.get('otherRoles');

      this.set('choices', otherRoles.map(function (role) {
        return {
          label: role.name,
          value: role.id
        };
      }).sortBy('label'));
    },

    actions: {
      remove: function remove() {
        this.sendAction('remove', this.get('model'));
      }
    }

  });
});