define('shared/components/sortable-table/component', ['exports', 'shared/mixins/sortable-base', 'shared/mixins/sticky-table-header', 'shared/components/sortable-table/template', 'ember-cli-pagination/computed/paged-array', 'shared/utils/platform'], function (exports, _sortableBase, _stickyTableHeader, _template, _pagedArray, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.matches = matches;


  function toggleInput(node, on) {
    var id = Ember.get(node, 'id');

    if (id) {
      var input = $('input[nodeid="' + id + '"]'); // eslint-disable-line

      if (input && input.length) {
        // can't reuse the input ref here because the table has rerenderd and the ref is no longer good
        $('input[nodeid="' + id + '"]').prop('checked', on); // eslint-disable-line

        var tr = $('input[nodeid="' + id + '"]').closest('tr'); // eslint-disable-line
        var first = true;

        while (tr && (first || tr.hasClass('sub-row'))) {
          tr.toggleClass('row-selected', on);
          tr = tr.next();
          first = false;
        }
      }
    }
  }

  function matches(fields, token, item) {
    var tokenMayBeIp = /^[0-9a-f\.:]+$/i.test(token);

    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];

      if (field) {
        // Modifiers:
        //  id: The token must match id format (i.e. 1i123)
        var idx = field.indexOf(':');
        var modifier = null;

        if (idx > 0) {
          modifier = field.substr(idx + 1);
          field = field.substr(0, idx);
        }

        var val = Ember.get(item, field);

        if (val === undefined) {
          continue;
        }

        val = ('' + val).toLowerCase();
        if (!val) {
          continue;
        }

        switch (modifier) {
          case 'exact':
            if (val === token) {
              return true;
            }

            break;
          case 'ip':
            if (tokenMayBeIp) {
              var re = new RegExp('(?:^|.)' + token + '(?:.|$)');

              if (re.test(val)) {
                return true;
              }
            }

            break;
          case 'prefix':
            if (val.indexOf(token) === 0) {
              return true;
            }

            break;
          default:
            if (val.indexOf(token) >= 0) {
              return true;
            }

            break;
        }
      }
    }

    return false;
  }

  exports.default = Ember.Component.extend(_sortableBase.default, _stickyTableHeader.default, {
    prefs: Ember.inject.service(),
    intl: Ember.inject.service(),
    bulkActionHandler: Ember.inject.service(),

    layout: _template.default,
    body: null,
    groupByKey: null,
    groupByRef: null,
    preSorts: null,
    sortBy: null,
    descending: false,
    headers: null,
    extraSearchFields: null,
    extraSearchSubFields: null,
    prefix: false,
    suffix: false,
    bulkActions: true,
    rowActions: true,
    search: true,
    searchToWormhole: null,
    paging: true,
    subRows: false,
    checkWidth: 40,
    actionsWidth: 40,

    availableActions: null,
    selectedNodes: null,
    prevNode: null,
    searchText: null,
    isVisible: true,
    page: 1,
    pagingLabel: 'pagination.generic',

    showHeader: Ember.computed.or('bulkActions', 'searchInPlace'),

    // -----
    sortableContent: Ember.computed.alias('body'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, 'selectedNodes', []);

      if (Ember.get(this, 'bulkActions')) {
        this.actionsChanged();
      }

      if (Ember.get(this, 'bulkActions')) {
        Ember.run.schedule('afterRender', function () {
          var table = $(_this.element).find('> TABLE'); // eslint-disable-line
          var self = _this; // need this context in click function and can't use arrow func there

          table.on('click', '> TBODY > TR', function (e) {
            self.rowClick(e);
          });

          table.on('mousedown', '> TBODY > TR', function (e) {
            if ((0, _platform.isRange)(e) || e.target.tagName === 'INPUT') {
              e.preventDefault();
            }
          });
        });
      }

      var watchKey = 'pagedContent.[]';

      if (Ember.get(this, 'groupByKey')) {
        watchKey = 'pagedContent.@each.' + Ember.get(this, 'groupByKey').replace(/\..*/g, '');
      }

      Ember.defineProperty(this, 'groupedContent', Ember.computed(watchKey, function () {
        var ary = [];
        var map = {};

        var groupKey = Ember.get(_this, 'groupByKey');
        var refKey = Ember.get(_this, 'groupByRef');

        Ember.get(_this, 'pagedContent').forEach(function (obj) {
          var group = Ember.get(obj, groupKey) || '';
          var ref = Ember.get(obj, refKey) || { displayName: group };
          var entry = map[group];

          if (entry) {
            entry.items.push(obj);
          } else {
            entry = {
              group: group,
              ref: ref,
              items: [obj]
            };

            map[group] = entry;

            ary.push(entry);
          }
          if (Ember.get(_this, 'selectedNodes').includes(obj)) {
            Ember.run.next(_this, function () {
              toggleInput(obj, true);
            });
          }
        });

        return ary;
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (Ember.get(this, 'isVisible')) {
        this.triggerResize();
      }
    },


    actions: {
      clearSearch: function clearSearch() {
        Ember.set(this, 'searchText', '');
      },
      executeBulkAction: function executeBulkAction(name, e) {
        e.preventDefault();

        var handler = Ember.get(this, 'bulkActionHandler');
        var nodes = Ember.get(this, 'selectedNodes');

        if ((0, _platform.isAlternate)(e)) {
          var available = Ember.get(this, 'availableActions');
          var action = available.findBy('action', name);
          var alt = Ember.get(action, 'altAction');

          if (alt) {
            name = alt;
          }
        }

        if (typeof handler[name] === 'function') {
          Ember.get(this, 'bulkActionHandler')[name](nodes);
        } else {
          nodes.forEach(function (node) {
            node.send(name);
          });
        }
      },
      executeAction: function executeAction(action) {
        var node = Ember.get(this, 'selectedNodes')[0];

        node.send(action);
      }
    },

    // Pick a new sort if the current column disappears.
    headersChanged: Ember.observer('headers.@each.name', function () {
      var sortBy = Ember.get(this, 'sortBy');
      var headers = Ember.get(this, 'headers') || [];

      if (headers && headers.get('length')) {
        var cur = headers.findBy('name', sortBy);

        if (!cur) {
          Ember.run.next(this, function () {
            this.send('changeSort', headers.get('firstObject.name'));
          });
        }
      }
    }),

    pagedContentChanged: Ember.observer('pagedContent.[]', function () {
      this.cleanupOrphans();
    }),

    pageCountChanged: Ember.observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      var from = Ember.get(this, 'indexFrom');
      var last = Ember.get(this, 'filtered.length');
      var perPage = Ember.get(this, 'perPage');

      if (Ember.get(this, 'page') > 1 && from > last) {
        var page = Math.ceil(last / perPage);

        Ember.set(this, 'page', page);
      }
    }),

    sortKeyChanged: Ember.observer('sortBy', function () {
      Ember.set(this, 'page', 1);
    }),

    actionsChanged: Ember.observer('selectedNodes.@each._availableActions', 'pagedContent.@each._availableActions', function () {
      if (!Ember.get(this, 'bulkActions')) {
        return;
      }

      var nodes = Ember.get(this, 'selectedNodes');
      var disableAll = false;

      if (!nodes.length) {
        disableAll = true;

        var firstNode = Ember.get(this, 'pagedContent.firstObject');

        if (firstNode) {
          nodes = [firstNode];
        }
      }

      var map = {};

      Ember.get(this, 'pagedContent').forEach(function (node) {
        Ember.get(node, '_availableActions').forEach(function (act) {
          if (!act.bulkable) {
            return;
          }

          var obj = map[act.action];

          if (!obj) {
            obj = $().extend(true, {}, act); // eslint-disable-line

            map[act.action] = obj;
          }

          if (act.enabled !== false) {
            obj.anyEnabled = true;
          }
        });
      });

      nodes.forEach(function (node) {
        Ember.get(node, '_availableActions').forEach(function (act) {
          if (!act.bulkable) {
            return;
          }

          var obj = map[act.action];

          if (!obj) {
            obj = $().extend(true, {}, act); // eslint-disable-line

            map[act.action] = obj;
          }

          obj.available = (obj.available || 0) + (act.enabled === false ? 0 : 1);
          obj.total = (obj.total || 0) + 1;
        });
      });

      var out = Object.values(map).filterBy('anyEnabled', true);

      if (disableAll) {
        out.forEach(function (x) {
          Ember.set(x, 'enabled', false);
        });
      } else {
        out.forEach(function (x) {
          if (x.available < x.total) {
            Ember.set(x, 'enabled', false);
          } else {
            Ember.set(x, 'enabled', true);
          }
        });
      }

      Ember.set(this, 'availableActions', out);
    }),
    searchInPlace: Ember.computed('search', 'searchToWormhole', function () {
      return Ember.get(this, 'search') && !Ember.get(this, 'searchToWormhole');
    }),

    perPage: Ember.computed('paging', 'prefs.tablePerPage', function () {
      if (Ember.get(this, 'paging')) {
        return Ember.get(this, 'prefs.tablePerPage');
      } else {
        return 100000;
      }
    }),

    // hide bulckActions if content is empty.
    internalBulkActions: Ember.computed('bulkActions', 'sortableContent.[]', function () {
      var bulkActions = Ember.get(this, 'bulkActions');

      if (bulkActions && Ember.get(this, 'sortableContent')) {
        var sortableContent = Ember.get(this, 'sortableContent');

        return !!sortableContent.get('length');
      } else {
        return false;
      }
    }),
    // Flow: body [-> sortableContent] -> arranged -> filtered -> pagedContent [-> groupedContent]
    pagedContent: (0, _pagedArray.default)('filtered.[]', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage')
    }),

    // For data-title properties on <td>s
    dt: Ember.computed('headers.@each.{name,label,translationKey}', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var out = {
        select: intl.t('generic.select') + ': ',
        actions: intl.t('generic.actions') + ': '
      };

      Ember.get(this, 'headers').forEach(function (header) {
        var name = Ember.get(header, 'name');
        var dtKey = Ember.get(header, 'dtTranslationKey');
        var key = Ember.get(header, 'translationKey');

        if (dtKey) {
          out[name] = intl.t(dtKey) + ': ';
        } else if (key) {
          out[name] = intl.t(key) + ': ';
        } else {
          out[name] = (Ember.get(header, 'label') || name) + ': ';
        }
      });

      return out;
    }),

    // Table content
    fullColspan: Ember.computed('headers.length', 'bulkActions', 'rowActions', function () {
      return (Ember.get(this, 'headers.length') || 0) + (Ember.get(this, 'bulkActions') ? 1 : 0) + (Ember.get(this, 'rowActions') ? 1 : 0);
    }),

    // -----
    searchFields: Ember.computed('headers.@each.{searchField,name}', 'extraSearchFields.[]', function () {
      var out = headersToSearchField(Ember.get(this, 'headers'));

      return out.addObjects(Ember.get(this, 'extraSearchFields') || []);
    }),

    subFields: Ember.computed('subHeaders.@each.{searchField,name}', 'extraSearchSubFields.[]', function () {
      var out = headersToSearchField(Ember.get(this, 'subHeaders'));

      return out.addObjects(Ember.get(this, 'extraSearchSubFields') || []);
    }),

    showPaging: Ember.computed('filtered.[]', 'pagedContent.content.[]', function () {
      var filtered = Ember.get(this, 'filtered');
      var pagedContent = Ember.get(this, 'pagedContent');

      if (Ember.get(filtered, 'length') > Ember.get(pagedContent, 'length')) {
        return true;
      } else {
        return false;
      }
    }),

    filtered: Ember.computed('arranged.[]', 'searchText', function () {
      var out = Ember.get(this, 'arranged').slice();
      var searchFields = Ember.get(this, 'searchFields');
      var searchText = (Ember.get(this, 'searchText') || '').trim().toLowerCase();
      var subSearchField = Ember.get(this, 'subSearchField');
      var subFields = Ember.get(this, 'subFields');
      var subMatches = null;

      if (searchText.length) {
        subMatches = {};

        var searchTokens = searchText.split(/\s*[, ]\s*/);

        for (var i = out.length - 1; i >= 0; i--) {
          var hits = 0;
          var row = out[i];
          var mainFound = true;

          for (var j = 0; j < searchTokens.length; j++) {
            var expect = true;
            var token = searchTokens[j];

            if (token.substr(0, 1) === '!') {
              expect = false;
              token = token.substr(1);
            }

            if (token && matches(searchFields, token, row) !== expect) {
              mainFound = false;

              break;
            }
          }

          if (subFields && subSearchField) {
            var subRows = row.get(subSearchField) || [];

            for (var k = subRows.length - 1; k >= 0; k--) {
              var subFound = true;

              for (var l = 0; l < searchTokens.length; l++) {
                var _expect = true;
                var _token = searchTokens[l];

                if (_token.substr(0, 1) === '!') {
                  _expect = false;
                  _token = _token.substr(1);
                }

                if (matches(subFields, _token, subRows[k]) !== _expect) {
                  subFound = false;

                  break;
                }
              }

              if (subFound) {
                hits++;
              }
            }

            subMatches[row.get('id')] = hits;
          }

          if (!mainFound && hits === 0) {
            out.removeAt(i);
          }
        }
      }

      Ember.set(this, 'subMatches', subMatches);

      return out;
    }),

    indexFrom: Ember.computed('page', 'perPage', function () {
      var current = Ember.get(this, 'page');
      var perPage = Ember.get(this, 'perPage');

      return Math.max(0, 1 + perPage * (current - 1));
    }),

    indexTo: Ember.computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(Ember.get(this, 'filtered.length'), Ember.get(this, 'indexFrom') + Ember.get(this, 'perPage') - 1);
    }),

    pageCountContent: Ember.computed('indexFrom', 'indexTo', 'pagedContent.totalPages', function () {
      var from = Ember.get(this, 'indexFrom') || 0;
      var to = Ember.get(this, 'indexTo') || 0;
      var count = Ember.get(this, 'filtered.length') || 0;
      var pages = Ember.get(this, 'pagedContent.totalPages') || 0;
      var out = '';

      if (pages <= 1) {
        out = count + ' Item' + (count === 1 ? '' : 's');
      } else {
        out = from + ' - ' + to + ' of ' + count;
      }

      return out;
    }),

    isAll: Ember.computed('selectedNodes.length', 'pagedContent.length', {
      get: function get() {
        return Ember.get(this, 'selectedNodes.length') === Ember.get(this, 'pagedContent.length');
      },
      set: function set(key, value) {
        var content = Ember.get(this, 'pagedContent').filterBy('canBulkRemove');

        if (value) {
          this.toggleMulti(content, []);

          return true;
        } else {
          this.toggleMulti([], content);

          return false;
        }
      }
    }),

    cleanupOrphans: function cleanupOrphans() {
      // Remove selected items not in the current content
      var content = Ember.get(this, 'pagedContent');
      var nodesToAdd = [];
      var nodesToRemove = [];

      Ember.get(this, 'selectedNodes').forEach(function (node) {
        if (content.includes(node)) {
          nodesToAdd.push(node);
        } else {
          nodesToRemove.push(node);
        }
      });

      this.toggleMulti(nodesToAdd, nodesToRemove);
    },


    // ------
    // Clicking
    // ------
    rowClick: function rowClick(e) {
      var tagName = e.target.tagName;
      var tgt = $(e.target); // eslint-disable-line

      if (tagName === 'A' || tagName === 'BUTTON' || tgt.parents('.btn').length || typeof tgt.data('ember-action') !== 'undefined' || tgt.hasClass('copy-btn')) {
        return;
      }

      var content = Ember.get(this, 'pagedContent');
      var selection = Ember.get(this, 'selectedNodes');
      var isCheckbox = tagName === 'INPUT' || tgt.hasClass('row-check');
      var tgtRow = $(e.currentTarget); // eslint-disable-line

      if (tgtRow.hasClass('separator-row') || tgt.hasClass('select-all-check')) {
        return;
      }

      while (tgtRow && tgtRow.length && !tgtRow.hasClass('main-row')) {
        tgtRow = tgtRow.prev();
      }

      if (!tgtRow || !tgtRow.length) {
        return;
      }

      var nodeId = tgtRow.find('input[type="checkbox"]').attr('nodeid');

      if (!nodeId) {
        return;
      }

      var node = content.findBy('id', nodeId);

      if (!node) {
        return;
      }

      var isSelected = selection.includes(node);
      var prevNode = Ember.get(this, 'prevNode');

      // PrevNode is only valid if it's in the current content
      if (!prevNode || !content.includes(prevNode)) {
        prevNode = node;
      }

      if ((0, _platform.isMore)(e)) {
        this.toggleSingle(node);
      } else if ((0, _platform.isRange)(e)) {
        var toToggle = this.nodesBetween(prevNode, node);

        if (isSelected) {
          this.toggleMulti([], toToggle);
        } else {
          this.toggleMulti(toToggle, []);
        }
      } else if (isCheckbox) {
        this.toggleSingle(node);
      } else {
        this.toggleMulti([node], content);
      }

      Ember.set(this, 'prevNode', node);
    },
    nodesBetween: function nodesBetween(a, b) {
      var toToggle = [];
      var key = Ember.get(this, 'groupByKey');

      if (key) {
        // Grouped has 2 levels to look through
        var grouped = Ember.get(this, 'groupedContent');
        var from = this.groupIdx(a);
        var to = this.groupIdx(b);

        if (!from || !to) {
          return [];
        }

        // From has to come before To
        if (from.group > to.group || from.group === to.group && from.item > to.item) {
          var _ref = [to, from];
          from = _ref[0];
          to = _ref[1];
        }

        for (var i = from.group; i <= to.group; i++) {
          var items = grouped.objectAt(i).items;
          var j = from.group === i ? from.item : 0;

          while (items[j] && (i < to.group || j <= to.item)) {
            toToggle.push(items[j]);
            j++;
          }
        }
      } else {
        // Ungrouped is much simpler
        var content = Ember.get(this, 'pagedContent');
        var _from = content.indexOf(a);
        var _to = content.indexOf(b);

        var _ref2 = [Math.min(_from, _to), Math.max(_from, _to)];
        _from = _ref2[0];
        _to = _ref2[1];

        toToggle = content.slice(_from, _to + 1);
      }

      return toToggle;
    },
    groupIdx: function groupIdx(node) {
      var grouped = Ember.get(this, 'groupedContent');

      for (var i = 0; i < grouped.get('length'); i++) {
        var items = grouped.objectAt(i).items;

        for (var j = 0; j < items.get('length'); j++) {
          if (items.objectAt(j) === node) {
            return {
              group: i,
              item: j
            };
          }
        }
      }

      return null;
    },
    toggleSingle: function toggleSingle(node) {
      var selectedNodes = Ember.get(this, 'selectedNodes');

      if (selectedNodes.includes(node)) {
        this.toggleMulti([], [node]);
      } else {
        this.toggleMulti([node], []);
      }
    },
    toggleMulti: function toggleMulti(nodesToAdd, nodesToRemove) {
      var selectedNodes = Ember.get(this, 'selectedNodes');

      if (nodesToRemove.length) {
        // removeObjects doesn't use ArrayProxy-safe looping
        if (typeof nodesToRemove.toArray === 'function') {
          nodesToRemove = nodesToRemove.toArray();
        }
        selectedNodes.removeObjects(nodesToRemove);
        toggle(nodesToRemove, false);
      }

      if (nodesToAdd.length) {
        selectedNodes.addObjects(nodesToAdd);
        toggle(nodesToAdd, true);
      }

      function toggle(nodes, on) {
        Ember.run.next(function () {
          nodes.forEach(function (node) {
            toggleInput(node, on);
          });
        });
      }
    }
  });


  function headersToSearchField(headers) {
    var out = [];

    (headers || []).forEach(function (header) {
      var field = Ember.get(header, 'searchField');

      if (field) {
        if (typeof field === 'string') {
          out.addObject(field);
        } else if (Ember.isArray(field)) {
          out.addObjects(field);
        }
      } else if (field === false) {
        // Don't add the name
      } else {
        out.addObject(Ember.get(header, 'name'));
      }
    });

    return out.filter(function (x) {
      return !!x;
    });
  }
});