define('global-admin/accounts/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var store = Ember.get(this, 'globalStore');
      var account = store.createRecord({ type: 'user' });

      return Ember.RSVP.hash({
        users: store.findAll('user'),
        globalRoles: store.findAll('globalrole')
      }).then(function (hash) {
        return Ember.Object.create({
          account: account,
          users: hash.users,
          globalRoles: hash.globalRoles
        });
      });
    },
    resetController: function resetController(controller, isExisting /* , transition*/) {
      if (isExisting) {
        controller.set('errors', null);
      }
    }
  });
});