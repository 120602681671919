define('shared/utils/parse-externalid', ['exports', 'shared/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseExternalId = parseExternalId;
  exports.parseHelmExternalId = parseHelmExternalId;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // Parses externalIds on services into
  // {
  //  kind: what kind of id this is supposed to be
  //  group: for catalog, what group it's in
  //  id: the actual external id
  function parseExternalId(externalId) {
    var CE = _constants.default.EXTERNAL_ID;

    var nameVersion;
    var out = {
      kind: null,
      group: null,
      base: null,
      id: null,
      name: null,
      version: null
    };

    if (!externalId) {
      return out;
    }

    var idx = externalId.indexOf(CE.KIND_SEPARATOR);

    if (idx >= 0) {
      // New style kind://[group:]id
      out.kind = externalId.substr(0, idx);

      var rest = externalId.substr(idx + CE.KIND_SEPARATOR.length);

      idx = rest.indexOf(CE.GROUP_SEPARATOR);
      out.id = rest;
      if (idx >= 0) {
        // With group kind://group/id
        out.group = rest.substr(0, idx);
        nameVersion = rest.substr(idx + 1);
      } else {
        // Without group kind://id
        if (out.kind === CE.KIND_CATALOG) {
          // For catalog kinds, we have a default group
          out.group = CE.CATALOG_DEFAULT_GROUP;
        }

        nameVersion = rest;
      }
    } else {
      var dashedIdx = externalId.lastIndexOf('-');

      // Old style just an ID
      out.kind = CE.KIND_CATALOG;
      var group = CE.CATALOG_DEFAULT_GROUP;
      var name = externalId.substr(0, dashedIdx);
      var version = externalId.substr(dashedIdx + 1);

      nameVersion = '' + name + CE.ID_SEPARATOR + version;
      // defaultgroup:extid:version
      out.id = '' + group + CE.GROUP_SEPARATOR + nameVersion;
      out.group = group;
    }

    if (nameVersion) {
      idx = nameVersion.lastIndexOf(CE.ID_SEPARATOR);
      var nameBase = void 0;

      if (idx > 0) {
        out.version = nameVersion.substr(idx + 1);
        nameBase = nameVersion.substr(0, idx);
      } else {
        nameBase = nameVersion;
      }

      out.templateId = '' + out.group + CE.GROUP_SEPARATOR + nameBase;

      idx = nameBase.lastIndexOf(CE.BASE_SEPARATOR);
      if (idx > 0) {
        out.base = nameBase.substr(0, idx);
        out.name = nameBase.substr(idx + 1);
      } else {
        out.name = nameBase;
      }
    }

    return out;
  }

  function parseHelmExternalId(externalId) {
    var CE = _constants.default.EXTERNAL_ID;
    var out = {
      kind: null,
      group: null,
      base: null,
      id: null,
      name: null,
      version: null
    };

    if (!externalId) {
      return out;
    }

    var idx = externalId.indexOf(CE.KIND_SEPARATOR);

    // not very smart but maybe doesn't need to be?
    if (idx >= 0) {
      out.kind = externalId.substr(0, idx);

      var rest = externalId.substr(idx + CE.KIND_SEPARATOR.length + 1);

      out.id = externalId;
      rest = rest.split('&');
      rest.forEach(function (it) {
        var _it$split = it.split('='),
            _it$split2 = _slicedToArray(_it$split, 2),
            nm = _it$split2[0],
            vl = _it$split2[1];

        out[nm] = vl;
      });
    }

    var catalog = out.catalog;

    if (catalog.indexOf('/') !== -1) {
      catalog = catalog.replace('/', ':');
    } else {
      catalog = 'cattle-global-data:' + catalog;
    }

    out.templateId = catalog + '-' + out.template;
    out.templateVersionId = catalog + '-' + out.template + '-' + out.version;

    return out;
  }
});