define('global-admin/security/authentication/adfs/controller', ['exports', 'global-admin/mixins/authentication', 'global-admin/mixins/saml-auth'], function (exports, _authentication, _samlAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_authentication.default, _samlAuth.default, {
    createLabel: 'authPage.adfs.buttonText.pre',
    saveLabel: 'authPage.adfs.buttonText.post',
    providerName: 'adfs',
    providerNamePath: 'authPage.saml.providerName.adfs'

  });
});