define('shared/mixins/endpoint-ports', ['exports', 'ui/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    displayEndpoints: function () {
      var parts = [];
      var endpoints = (Ember.get(this, 'publicEndpoints') || []).sort(_util.default.compareDisplayEndpoint);

      endpoints.forEach(function (endpoint) {
        if (!Ember.get(endpoint, 'isReady')) {
          return;
        }
        if (Ember.get(endpoint, 'isTcpish')) {
          parts.push('' + ('<span>' + '<a target="_blank" rel="noreferrer nofollow noopener" href="') + _util.default.escapeHtml(Ember.get(endpoint, 'linkEndpoint')) + '">' + _util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint')) + '</a>' + '</span>');
        } else {
          parts.push('<span>' + _util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint')) + '</span>');
        }
      });

      var pub = parts.join(', ');

      if (pub) {
        return pub.htmlSafe();
      } else {
        return '';
      }
    }.property('publicEndpoints.@each.{address,port,protocol}')
  });
});