define('shared/components/storage-class/provisioner-rbd/component', ['exports', 'shared/components/storage-class/provisioner-rbd/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['monitors', 'adminId', 'adminSecretNamespace', 'adminSecretName', 'pool', 'userId', 'userSecretName', 'fsType', 'imageFormat', 'imageFeatures'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'rbd',
    fields: FIELDS
  });
});