define('shared/components/schema/input-workload/component', ['exports', 'shared/components/schema/input-workload/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    allWorkloads: Ember.inject.service(),

    layout: _template.default,
    selected: null, // Selected workload ID
    selectClass: 'form-control',
    withPods: false,
    readOnly: false,
    editing: true,
    exclude: null, // ID or array of IDs to exclude from list
    selectedNamespace: null,
    // For use as a catalog question
    field: null, // Read default from a schema resourceField
    value: null, // namespace/workloadName string output

    // For other abuses
    obj: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('obj')) {
        this.set('selected', this.get('obj.id'));
      }

      var def = this.get('field.default');

      if (def && !this.get('selected')) {
        var exact, justWorkload;

        this.get('allWorkloads.list').forEach(function (entry) {
          if (def === entry.value) {
            exact = entry.id;
          } else if (def === entry.name) {
            justWorkload = entry.id;
          }
        });

        this.set('selected', exact || justWorkload || null);
      }
    },


    selectedChanged: Ember.observer('selected', function () {
      var id = this.get('selected');
      var str = null;
      var workload = null;

      if (id) {
        workload = this.get('allWorkloads').byId(id);
        if (workload) {
          str = workload.get('namespace.name') + '/' + workload.get('name');
        }
      }

      this.set('value', str);
      this.set('obj', workload);
    }),

    grouped: Ember.computed('allWorkloads.list.[]', 'selectedNamespace', function () {
      var _this = this;

      var list = this.get('allWorkloads.list');

      var exclude = this.get('exclude');

      if (exclude) {
        if (!Ember.isArray(exclude)) {
          exclude = [exclude];
        }

        list = list.filter(function (x) {
          return !exclude.includes(x.id);
        });
      }

      if (this.get('selectedNamespace')) {
        list = list.filter(function (x) {
          return x.stackName === _this.get('selectedNamespace.id');
        });
      }

      var out = this.get('allWorkloads').group(list);
      var selected = this.get('allWorkloads').byId(this.get('selected'));

      if (selected && !list.findBy('id', selected.get('id'))) {
        out['(Selected)'] = [{
          id: selected.get('id'),
          name: selected.get('displayName'),
          kind: selected.get('type'),
          obj: selected
        }];
      }

      return out;
    }),

    readableWorkload: Ember.computed('selected', function () {
      var selected = this.selected,
          selectedNamespace = this.selectedNamespace;

      var workload = Ember.get(selectedNamespace, 'workloads').findBy('id', selected);

      var out = 'N/A';

      if (workload) {
        out = Ember.get(workload, 'displayName');
      }

      return out;
    })
  });
});