define('shared/components/cluster-driver/driver-azureaks/component', ['exports', 'shared/mixins/cluster-driver', 'shared/components/cluster-driver/driver-azureaks/template', 'ipaddr.js', 'ui/utils/azure-choices'], function (exports, _clusterDriver, _template, _ipaddr, _azureChoices) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var NETWORK_POLICY = ['Calico'];

  exports.default = Ember.Component.extend(_clusterDriver.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    configField: 'azureKubernetesServiceConfig',

    zones: _azureChoices.aksRegions,
    versions: null,
    machineSizes: _azureChoices.sizes,
    step: 1,
    netMode: 'default',
    monitoringRegionConent: [],

    editing: Ember.computed.equal('mode', 'edit'),
    isNew: Ember.computed.equal('mode', 'new'),

    init: function init() {
      this._super.apply(this, arguments);

      var config = Ember.get(this, 'cluster.azureKubernetesServiceConfig');

      if (!config) {
        config = this.get('globalStore').createRecord({
          agentPoolName: 'rancher',
          type: 'azureKubernetesServiceConfig',
          agentOsdiskSize: 100,
          adminUsername: 'azureuser',
          kubernetesVersion: '1.11.5',
          count: 3,
          agentVmSize: 'Standard_D2_v2',
          location: 'eastus',
          enableHttpApplicationRouting: false,
          enableMonitoring: true
        });

        Ember.set(this, 'cluster.azureKubernetesServiceConfig', config);
      } else {
        var tags = Ember.get(config, 'tags') || [];
        var map = {};

        tags.map(function () {
          var t = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

          var split = t.split('=');

          Ember.set(map, split[0], split[1]);
        });
        Ember.set(this, 'tags', map);

        if (Ember.get(config, 'networkPolicy')) {
          Ember.set(this, 'netMode', 'advanced');
        }
      }
    },


    actions: {
      authenticate: function authenticate(cb) {
        var _this = this;

        var store = Ember.get(this, 'globalStore');
        var data = {
          clientId: Ember.get(this, 'config.clientId'),
          clientSecret: Ember.get(this, 'config.clientSecret'),
          subscriptionId: Ember.get(this, 'config.subscriptionId'),
          tenantId: Ember.get(this, 'config.tenantId'),
          region: Ember.get(this, 'config.location')
        };
        var aksRequest = {
          versions: store.rawRequest({
            url: '/meta/aksVersions',
            method: 'POST',
            data: data
          }),
          virtualNetworks: store.rawRequest({
            url: '/meta/aksVirtualNetworks',
            method: 'POST',
            data: data
          })
        };

        return Ember.RSVP.hash(aksRequest).then(function (resp) {
          var versions = resp.versions,
              virtualNetworks = resp.virtualNetworks;


          Ember.setProperties(_this, {
            step: 2,
            versions: Ember.get(versions, 'body') || [],
            virtualNetworks: Ember.get(virtualNetworks, 'body') || []
          });

          cb(true);
        }).catch(function (xhr) {
          var err = xhr.body.message || xhr.body.code || xhr.body.error;

          Ember.setProperties(_this, { errors: [err] });

          cb(false, [err]);
        });
      },
      setTags: function setTags(section) {
        var out = [];

        for (var key in section) {
          out.pushObject(key + '=' + section[key]);
        }
        Ember.set(this, 'config.tags', out);
      }
    },

    resetAdvancedOptions: Ember.on('init', Ember.observer('netMode', function () {
      if (Ember.get(this, 'isNew') && Ember.get(this, 'netMode') === 'default') {
        var config = Ember.get(this, 'config');

        var _globalStore$getById$ = this.globalStore.getById('schema', 'azurekubernetesserviceconfig').getCreateDefaults(),
            subnet = _globalStore$getById$.subnet,
            virtualNetwork = _globalStore$getById$.virtualNetwork,
            virtualNetworkResourceGroup = _globalStore$getById$.virtualNetworkResourceGroup,
            serviceCidr = _globalStore$getById$.serviceCidr,
            dnsServiceIp = _globalStore$getById$.dnsServiceIp,
            dockerBridgeCidr = _globalStore$getById$.dockerBridgeCidr;

        Ember.setProperties(config, {
          subnet: subnet,
          virtualNetwork: virtualNetwork,
          virtualNetworkResourceGroup: virtualNetworkResourceGroup,
          serviceCidr: serviceCidr,
          dnsServiceIp: dnsServiceIp,
          dockerBridgeCidr: dockerBridgeCidr
        });
      }
    })),

    networkChoice: Ember.computed({
      set: function set(key) {
        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

        var _value$split = value.split(':'),
            _value$split2 = _slicedToArray(_value$split, 3),
            subnet = _value$split2[0],
            virtualNetwork = _value$split2[1],
            virtualNetworkResourceGroup = _value$split2[2];

        var config = Ember.get(this, 'config');

        if (subnet && virtualNetwork && virtualNetworkResourceGroup) {
          Ember.setProperties(config, {
            subnet: subnet,
            virtualNetwork: virtualNetwork,
            virtualNetworkResourceGroup: virtualNetworkResourceGroup
          });
        }

        return value;
      }
    }),

    filteredVirtualNetworks: Ember.computed('config.virtualNetwork', 'virtualNetworks', function () {
      var vnets = Ember.get(this, 'virtualNetworks') || [];
      var subNets = [];

      vnets.forEach(function (vnet) {
        (Ember.get(vnet, 'subnets') || []).forEach(function (subnet) {
          subNets.pushObject({
            name: Ember.get(subnet, 'name') + ' (' + Ember.get(subnet, 'addressRange') + ')',
            group: Ember.get(vnet, 'name'),
            value: Ember.get(subnet, 'name') + ':' + Ember.get(vnet, 'name') + ':' + Ember.get(vnet, 'resourceGroup')
          });
        });
      });

      return subNets;
    }),

    isEditable: Ember.computed('mode', function () {
      return Ember.get(this, 'mode') === 'edit' || Ember.get(this, 'mode') === 'new' ? true : false;
    }),

    saveDisabled: Ember.computed('config.subscriptionId', 'config.tenantId', 'config.clientId', 'config.clientSecret', 'config.location', function () {
      return Ember.get(this, 'config.tenantId') && Ember.get(this, 'config.clientId') && Ember.get(this, 'config.clientSecret') && Ember.get(this, 'config.subscriptionId') && Ember.get(this, 'config.location') ? false : true;
    }),

    networkPolicyContent: Ember.computed(function () {
      return NETWORK_POLICY.map(function (n) {
        return {
          label: n,
          value: n
        };
      });
    }),

    validate: function validate() {
      var intl = Ember.get(this, 'intl');
      var model = Ember.get(this, 'cluster');
      var errors = model.validationErrors() || [];

      var vnetSet = !!Ember.get(this, 'config.virtualNetwork');

      if (vnetSet) {
        errors = errors.concat(this.validateVnetInputs());
      }

      if (!Ember.get(this, 'config.resourceGroup')) {
        errors.push(intl.t('validation.required', { key: intl.t('clusterNew.azureaks.resourceGroup.label') }));
      }

      if (!Ember.get(this, 'config.sshPublicKeyContents')) {
        errors.push(intl.t('validation.required', { key: intl.t('clusterNew.azureaks.ssh.label') }));
      }

      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    },
    validateVnetInputs: function validateVnetInputs() {
      var intl = Ember.get(this, 'intl');
      var errors = [];
      var config = Ember.get(this, 'config');
      var vnet = Ember.get(this, 'virtualNetworks').findBy('name', Ember.get(config, 'virtualNetwork'));

      if (vnet) {
        var subnet = Ember.get(vnet, 'subnets').findBy('name', Ember.get(config, 'subnet'));
        var vnetRange = _ipaddr.default.parseCIDR(Ember.get(subnet, 'addressRange'));

        var serviceCidr = config.serviceCidr,
            dnsServiceIp = config.dnsServiceIp,
            dockerBridgeCidr = config.dockerBridgeCidr;


        var parsedServiceCidr = null;
        var parsedDnsServiceIp = null;
        var parsedDockerBridgeCidr = null;

        if (!serviceCidr && !dnsServiceIp && !dockerBridgeCidr) {
          errors.pushObject('You must include all required fields when using a Virtual Network');
        }

        try {
          parsedServiceCidr = _ipaddr.default.parseCIDR(serviceCidr);

          // check if serviceCidr falls within the VNet/Subnet range
          if (parsedServiceCidr && vnetRange[0].match(parsedServiceCidr)) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedServiceCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.serviceCidr'));
        }

        try {
          parsedDnsServiceIp = _ipaddr.default.parse(dnsServiceIp);

          // check if dnsService exists in range
          if (parsedDnsServiceIp && vnetRange[0].match(parsedDnsServiceIp, vnetRange[1])) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDnsServiceIp'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dnsServiceIp'));
        }

        try {
          parsedDockerBridgeCidr = _ipaddr.default.parseCIDR(dockerBridgeCidr);

          // check that dockerBridge doesn't overlap
          if (parsedDockerBridgeCidr && (vnetRange[0].match(parsedDockerBridgeCidr) || parsedServiceCidr[0].match(parsedDockerBridgeCidr))) {
            errors.pushObject(intl.t('clusterNew.azureaks.errors.included.parsedDockerBridgeCidr'));
          }
        } catch (err) {
          errors.pushObject(intl.t('clusterNew.azureaks.errors.included.dockerBridgeCidr'));
        }
      }

      return errors;
    },
    willSave: function willSave() {
      var enableMonitoring = Ember.get(this, 'config.enableMonitoring');
      var config = Ember.get(this, 'config');

      if (enableMonitoring) {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: '',
          logAnalyticsWorkspace: ''
        });
      } else {
        Ember.setProperties(config, {
          logAnalyticsWorkspaceResourceGroup: null,
          logAnalyticsWorkspace: null
        });
      }

      return this._super.apply(this, arguments);
    }
  });
});