define('global-admin/multi-cluster-apps/catalog/index/controller', ['exports', 'ui/utils/platform'], function (exports, _platform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    catalogController: Ember.inject.controller('multi-cluster-apps.catalog'),
    parentRoute: 'multi-cluster-apps.catalog',
    launchRoute: 'multi-cluster-apps.catalog.launch',
    category: Ember.computed.alias('catalogController.category'),
    catalogId: Ember.computed.alias('catalogController.catalogId'),

    actions: {
      filterAction: function filterAction(catalog) {
        var out = {
          catalogId: '',
          clusterCatalogId: '',
          projectCatalogId: ''
        };
        var scope = Ember.get(catalog, 'scope');
        var scopedId = scope + 'Id';

        out[scopedId] = Ember.get(catalog, 'catalogId');

        this.transitionToRoute(this.get('parentRoute'), { queryParams: out });
      },
      categoryAction: function categoryAction(category, catalogId) {
        this.transitionToRoute(this.get('parentRoute'), {
          queryParams: {
            category: category,
            catalogId: catalogId
          }
        });
      },
      launch: function launch(id, onlyAlternate) {
        if (onlyAlternate && !(0, _platform.isAlternate)(event)) {
          return false;
        }

        this.transitionToRoute(this.get('launchRoute'), id);
      },
      refresh: function refresh() {
        var catalogTab = Ember.getOwner(this).lookup('route:catalog-tab');

        catalogTab.send('refresh');
      }
    }
  });
});