define('nodes/custom-drivers/cluster-drivers/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HEADERS = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName'],
    searchField: 'displayName',
    translationKey: 'generic.name'

  }];

  exports.default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    headers: HEADERS,

    actions: {
      addNewDriver: function addNewDriver() {
        var newDriver = this.get('globalStore').createRecord({
          type: 'kontainerDriver',
          name: null,
          description: null,
          checksum: null,
          url: null,
          active: true
        });

        this.get('modalService').toggleModal('modal-edit-driver', newDriver);
      }
    },

    rows: Ember.computed('model.drivers.@each.{state,id,version,externalId}', function () {
      // possibly add some search here
      var drivers = this.get('model.drivers.content');

      return drivers;
    })

  });
});