define('global-admin/security/roles/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    model: function model(params) {
      var store = Ember.get(this, 'globalStore');

      var role = null;

      if (Ember.get(params, 'type') && Ember.get(params, 'type') === 'global') {
        role = Ember.get(this, 'globalStore').find('globalRole', Ember.get(params, 'role_id'));
      } else {
        role = Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(params.role_id);
      }

      return Ember.RSVP.hash({
        policies: store.find('podSecurityPolicyTemplate'),
        role: role,
        roles: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(null, null)
      }).then(function (hash) {
        return {
          policies: hash.policies,
          role: hash.role.clone(),
          roles: hash.roles
        };
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var type = '';

      if (Ember.get(model, 'role.type') === 'globalRole') {
        type = 'global';
      } else if (Ember.get(model, 'role.context') === 'project') {
        type = 'project';
      } else if (Ember.get(model, 'role.context') === 'cluster') {
        type = 'cluster';
      }

      Ember.setProperties(controller, {
        type: type,
        readOnly: true
      });
    },

    queryParams: { type: { refreshModel: true } }

  });
});