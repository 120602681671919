enifed('ember-template-compiler/lib/plugins/transform-quoted-bindings-into-just-bindings', ['exports'], function (exports) {
    'use strict';

    exports.default = function () /* env */{
        return {
            name: 'transform-quoted-bindings-into-just-bindings',
            visitor: {
                ElementNode: function (node) {
                    var styleAttr = getStyleAttr(node);
                    if (!validStyleAttr(styleAttr)) {
                        return;
                    }
                    styleAttr.value = styleAttr.value.parts[0];
                }
            }
        };
    };

    function validStyleAttr(attr) {
        if (!attr) {
            return false;
        }
        var value = attr.value;
        if (!value || value.type !== 'ConcatStatement' || value.parts.length !== 1) {
            return false;
        }
        var onlyPart = value.parts[0];
        return onlyPart.type === 'MustacheStatement';
    }
    function getStyleAttr(node) {
        var attributes = node.attributes,
            i;
        for (i = 0; i < attributes.length; i++) {
            if (attributes[i].name === 'style') {
                return attributes[i];
            }
        }
        return undefined;
    }
});