define('global-admin/security/roles/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    model: function model(params) {
      var _this = this;

      var store = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        policies: store.find('podSecurityPolicyTemplate'),
        roles: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(null, null)
      }).then(function (res) {
        var id = Ember.get(params, 'id');
        var role = void 0;

        if (id) {
          role = res.roles.findBy('id', id);

          if (!role) {
            _this.replaceWith('security.roles.index');
          }

          role = role.cloneForNew();
          delete role['builtin'];
          delete role['annotations'];
          delete role['labels'];
          delete role['links'];
        } else {
          role = store.createRecord({
            type: 'roleTemplate',
            context: Ember.get(params, 'context') || 'project',
            name: '',
            rules: [],
            hidden: false,
            locked: false
          });
        }

        Ember.set(res, 'role', role);

        return res;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.setProperties(controller, { type: Ember.get(model, 'role.context') });
    },

    queryParams: { context: { refreshModel: false } }

  });
});