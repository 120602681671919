define('shared/helpers/driver-name', ['exports', 'shared/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute: function compute(params /* , options*/) {
      var driver = params[0];

      var intl = Ember.get(this, 'intl');
      var key = 'nodeDriver.displayName.' + driver;
      var name = (0, _util.ucFirst)(driver);

      if (intl.exists(key)) {
        name = intl.t(key);
      }

      return name;
    }
  });
});