define('global-admin/components/new-edit-policy/component', ['exports', 'ui/mixins/new-or-edit'], function (exports, _newOrEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_newOrEdit.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),

    model: null,
    readOnly: false,

    actions: {
      cancel: function cancel() {
        this.goBack();
      },
      setLabels: function setLabels(labels) {
        var out = {};

        labels.forEach(function (row) {
          out[row.key] = row.value;
        });

        Ember.set(this, 'model.labels', out);
      }
    },

    goBack: function goBack() {
      this.get('router').transitionTo('global-admin.security.policies.index');
    },
    doneSaving: function doneSaving() {
      this.goBack();
    }
  });
});