define('global-admin/clusters/new/route', ['exports', 'shared/utils/load-script'], function (exports, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    catalog: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),

    mustAbortBecauseImAnExternalRoute: false,

    init: function init() {
      this._super.apply(this, arguments);

      var allowed = ['import', 'custom', 'googlegke', 'amazoneks', 'azureaks'];
      var parsedQp = Ember.get(this, 'router._routerMicrolib.activeTransition.queryParams.provider');

      if (!allowed.includes(parsedQp)) {
        // we can't use transitionTo here as it does not seem to abort the current transition
        Ember.set(this, 'mustAbortBecauseImAnExternalRoute', true);
      }
    },
    beforeModel: function beforeModel() {
      if (Ember.get(this, 'mustAbortBecauseImAnExternalRoute')) {
        Ember.set(this, 'mustAbortBecauseImAnExternalRoute', false);
        this.transitionTo({ queryParams: { provider: 'googlegke' } });
      }
    },
    model: function model(params) {
      var gs = Ember.get(this, 'globalStore');

      var cluster = gs.createRecord({ type: 'cluster' });

      if (Ember.get(params, 'provider')) {
        Ember.set(this, 'provider', Ember.get(params, 'provider'));
      }

      return Ember.RSVP.hash({
        cluster: cluster,
        clusterRoleTemplateBinding: gs.findAll('clusterRoleTemplateBinding'),
        cloudCredentials: gs.findAll('cloudcredential'),
        kontainerDrivers: gs.findAll('kontainerDriver'),
        me: Ember.get(this, 'access.principal'),
        nodeDrivers: gs.findAll('nodeDriver'),
        nodeTemplates: gs.findAll('nodeTemplate'),
        psps: gs.findAll('podSecurityPolicyTemplate'),
        roleTemplates: Ember.get(this, 'roleTemplateService').fetchFilteredRoleTemplates(),
        users: gs.findAll('user')
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;

      // load the css/js url here, if the url loads fail we should error the driver out
      // show the driver in the ui, greyed out, and possibly add error text "can not load comonent from url [put url here]"

      var kontainerDrivers = model.kontainerDrivers;

      var externalDrivers = kontainerDrivers.filter(function (d) {
        return d.uiUrl !== '' && d.state === 'active';
      });
      var promises = {};

      externalDrivers.forEach(function (d) {
        if (Ember.get(d, 'hasUi')) {
          var jsUrl = (0, _loadScript.proxifyUrl)(d.uiUrl, _this.get('app.proxyEndpoint'));
          var cssUrl = (0, _loadScript.proxifyUrl)(d.uiUrl.replace(/\.js$/, '.css'), Ember.get(_this, 'app.proxyEndpoint'));

          // skip setProperties cause of weird names
          Ember.set(promises, d.name + 'Js', (0, _loadScript.loadScript)(jsUrl, 'driver-ui-js-' + d.name));
          Ember.set(promises, d.name + 'Css', (0, _loadScript.loadStylesheet)(cssUrl, 'driver-ui-css-' + d.name));
        }
      });

      return Ember.RSVP.hashSettled(promises).then(function (settled) {
        var allkeys = Object.keys(settled);

        allkeys.forEach(function (key) {
          if (Ember.get(settled, key + '.state') === 'rejected') {
            var tmp = key.indexOf('Js') > -1 ? key.replace(/\Js$/, '') : key.replace(/\Css$/, '');
            var match = kontainerDrivers.findBy('id', tmp);

            console.log('Error Loading External Component for: ', match);
            if (match && Ember.get(match, 'scriptError') !== true) {
              Ember.set(match, 'scriptError', Ember.get(_this, 'intl').t('clusterNew.externalError'));
            }
          }
        });
      }).finally(function () {
        return model;
      });
    },
    setupController: function setupController(controller /* , model */) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'step', 1);
    },


    queryParams: { provider: { refreshModel: true } }

  });
});