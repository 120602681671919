enifed("@ember/debug/lib/testing", ["exports"], function (exports) {
    "use strict";

    exports.isTesting = isTesting;
    exports.setTesting = function (value) {
        testing = !!value;
    };
    var testing = false;
    function isTesting() {
        return testing;
    }
});