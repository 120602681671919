define('shared/components/metrics-action/component', ['exports', 'ui/utils/constants', 'shared/components/metrics-action/template'], function (exports, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var CUSTOM = 'custom';
  var PERIODS = [{
    label: 'metricsAction.periods.custom',
    value: CUSTOM
  }, {
    label: 'metricsAction.periods.5m',
    value: 'now-5m',
    interval: '5s'
  }, {
    label: 'metricsAction.periods.1h',
    value: 'now-1h',
    interval: '60s'
  }, {
    label: 'metricsAction.periods.6h',
    value: 'now-6h',
    interval: '60s'
  }, {
    label: 'metricsAction.periods.24h',
    value: 'now-24h',
    interval: '300s'
  }, {
    label: 'metricsAction.periods.7d',
    value: 'now-168h',
    interval: '1800s'
  }, {
    label: 'metricsAction.periods.30d',
    value: 'now-720h',
    interval: '3600s'
  }];

  exports.default = Ember.Component.extend({
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    prefs: Ember.inject.service(),

    layout: _template.default,
    classNames: 'mb-20',

    resourceType: 'cluster',
    dashboardName: null,
    allowDetail: true,

    selected: 'now-1h',
    periods: PERIODS,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var periodPref = Ember.get(this, 'prefs.' + _constants.default.PREFS.PERIOD);

      if (periodPref) {
        Ember.set(this, 'selected', periodPref);
      }

      Ember.run.next(function () {
        _this.query();
      });
    },


    actions: {
      refresh: function refresh() {
        this.query();
      },
      onOpen: function onOpen() {
        Ember.set(this, 'now', new Date().getTime());
      },
      fromDidChange: function fromDidChange(from) {
        if (Ember.get(from, 'length')) {
          Ember.set(this, 'from', Ember.get(from, 'firstObject').getTime());
        }
      },
      toDidChange: function toDidChange(to) {
        if (Ember.get(to, 'length')) {
          Ember.set(this, 'to', Ember.get(to, 'firstObject').getTime());
        }
      },
      onTimePickerClose: function onTimePickerClose() {
        this.query(false);
      },
      toggle: function toggle(detail) {
        if (!Ember.get(this, 'state.loading')) {
          Ember.set(this, 'state.detail', detail);
          this.query();
        }
      }
    },

    periodDidChange: Ember.observer('selected', function () {
      Ember.setProperties(this, {
        from: new Date().getTime() - 60000,
        to: new Date().getTime(),
        now: new Date().getTime()
      });
      if (Ember.get(this, 'selected') !== CUSTOM) {
        Ember.set(this, 'prefs.' + _constants.default.PREFS.PERIOD, Ember.get(this, 'selected'));
      }

      this.query();
    }),

    query: function query() {
      var forceRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      var period = Ember.get(this, 'selected');
      var from = void 0;
      var to = void 0;
      var interval = void 0;
      var isCustom = void 0;

      if (period !== CUSTOM) {
        var params = PERIODS.findBy('value', Ember.get(this, 'selected'));

        from = period;
        to = 'now';
        interval = Ember.get(params, 'interval');
        isCustom = false;
      } else {
        from = Ember.get(this, 'from').toString();
        to = Ember.get(this, 'to').toString() || new Date().getTime().toString();
        var i = Math.round((to - from) / 120000);

        i = i > 1 ? i : 1;
        interval = i + 's';
        isCustom = true;
      }
      Ember.setProperties(Ember.get(this, 'state'), {
        from: from,
        to: to,
        interval: interval,
        isCustom: isCustom
      });

      if (period === CUSTOM) {
        if (!forceRefresh && Ember.get(this, 'preFrom') === from && Ember.get(this, 'preTo') === to) {
          return;
        } else {
          Ember.setProperties(this, {
            preFrom: from,
            preTo: to
          });
        }
      }
      this.sendAction('queryAction');
    }
  });
});