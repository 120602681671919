define('shared/utils/subscribe-global', ['exports', 'shared/mixins/subscribe'], function (exports, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_subscribe.default, {
    updateClusterStore: false,
    updateProjectStore: false,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'endpoint', Ember.get(this, 'app.globalSubscribeEndpoint'));
    }
  });
});