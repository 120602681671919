define('shared/components/form-count/component', ['exports', 'shared/components/form-count/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function newMax(val, curMax, absoluteMax) {
    return Math.min(absoluteMax, Math.max(curMax, Math.ceil(val / 10) * 10));
  }

  exports.default = Ember.Component.extend({
    layout: _template.default,
    initialScale: null,
    min: 1,
    max: 100,

    userInput: null,
    sliderMax: 10,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('userInput', '' + (this.get('initialScale') || 1));
      this.set('sliderMax', newMax(this.get('asInteger'), this.get('sliderMax'), this.get('max')));
    },


    actions: {
      increase: function increase() {
        this.set('userInput', Math.min(this.get('max'), this.get('asInteger') + 1));
      },
      decrease: function decrease() {
        this.set('userInput', Math.max(this.get('min'), this.get('asInteger') - 1));
      },
      showAdvanced: function showAdvanced() {
        this.set('advancedShown', true);
      }
    },

    scaleChanged: Ember.observer('asInteger', function () {
      var cur = this.get('asInteger');

      this.sendAction('setScale', cur);

      this.set('sliderMax', newMax(cur, this.get('sliderMax'), this.get('max')));
    }),
    asInteger: Ember.computed('userInput', function () {
      return parseInt(this.get('userInput'), 10) || 0;
    })

  });
});