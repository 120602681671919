define('shared/mixins/cluster-driver', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    configField: '<override me>',

    mode: null,
    save: null, // Action to save
    close: null, // Action on complete

    globalStore: Ember.inject.service(),

    cluster: Ember.computed.alias('model.cluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    errors: null,

    config: Ember.computed('configField', function () {
      var field = 'cluster.' + Ember.get(this, 'configField');

      return Ember.get(this, field);
    }),

    actions: {
      driverSave: function driverSave(cb) {
        var _this = this;

        cb = cb || function () {};

        Ember.RSVP.resolve(this.willSave()).then(function (ok) {
          if (!ok) {
            // Validation or something else said not to save
            cb(false);

            return;
          }

          _this.sendAction('save', function (ok) {
            if (ok) {
              _this.doneSaving().finally(function () {
                cb(ok);
              });
            } else {
              cb(ok);
            }
          });
        });
      },
      setLabels: function setLabels(labels) {
        Ember.set(this, 'labels', labels);
        var out = {};

        labels.forEach(function (row) {
          out[row.key] = row.value;
        });

        this.set('labels', out);
      },
      registerHook: function registerHook() {
        var args = [].slice.call(arguments);

        args.unshift('registerHook');
        this.sendAction.apply(this, args);
      },
      close: function close() {
        this.sendAction('close');
      }
    },

    willSave: function willSave() {
      var cluster = Ember.get(this, 'cluster');
      var field = Ember.get(this, 'configField');

      cluster.clearProvidersExcept(field);

      Ember.set(this, 'errors', null);
      var ok = this.validate();

      return ok;
    },
    validate: function validate() {
      var model = Ember.get(this, 'cluster');
      var errors = model.validationErrors();

      Ember.set(this, 'errors', errors);

      return errors.length === 0;
    },
    doneSaving: function doneSaving() {
      return Ember.RSVP.resolve();
    }
  });
});