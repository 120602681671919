define('global-admin/security/authentication/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    // TODO 2.0
    model: function model() {
      var _this = this;

      var route = (this.get('access.provider') || '').toLowerCase().replace(/config$/i, '');

      if (route === 'ldap') {
        route = 'activedirectory';
      }

      return Ember.get(this, 'globalStore').find('authconfig', null, { filter: { enabled: true } }).then(function (configs) {
        if (Ember.get(configs, 'length') === 2) {
          var neuRt = configs.find(function (config) {
            return Ember.get(config, 'id') !== 'local';
          });

          _this.replaceWith('security.authentication.' + Ember.get(neuRt, 'id'));
        } else if (Ember.get(configs, 'length') > 2) {
          // ???
        } else {
          _this.replaceWith('security.authentication.localauth');
        }
      });
    }
  });
});