define('shared/resource-actions/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    model: null,
    open: false,
    tooltipActions: null,
    actionToggle: null,
    actionMenu: null,
    actionContext: null,
    app: Ember.inject.service(),

    setActionItems: function setActionItems(model, context) {
      this.set('model', model);
      this.set('context', context);
    },
    triggerAction: function triggerAction(actionName) {
      this.get('model').send(actionName, this.get('context'));
    },


    activeActions: Ember.computed('model._availableActions.@each.{enabled,single,divider}', 'modelo', function () {
      var list = (this.get('model._availableActions') || []).filter(function (act) {
        if (Ember.get(act, 'single') === false || Ember.get(act, 'enabled') === false) {
          return false;
        }

        return true;
      });

      // Remove dividers at the beginning
      while (list.get('firstObject.divider') === true) {
        list.shiftObject();
      }

      // Remove dividers at the end
      while (list.get('lastObject.divider') === true) {
        list.popObject();
      }

      // Remove consecutive dividers
      var last = null;

      list = list.filter(function (act) {
        var cur = act.divider === true;
        var ok = !cur || cur && !last;

        last = cur;

        return ok;
      });

      return list;
    })
  });
});