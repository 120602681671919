define('shared/components/copy-to-clipboard/component', ['exports', 'ui/utils/platform', 'shared/components/copy-to-clipboard/template'], function (exports, _platform, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DELAY = 1000;
  var DEFAULT_TEXT = 'copyToClipboard.tooltip';

  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',

    model: null,

    /* Component Params*/
    buttonText: null,
    tooltipText: null,
    status: null,
    color: 'bg-transparent',
    icon: 'icon-copy',
    size: null,
    target: null,
    clipboardText: null,
    textChangedEvent: null,
    buttonClass: null,

    init: function init() {
      this._super.apply(this, arguments);
      // otherwise the tooltip doesn't show up on the first hover
      Ember.set(this, 'model', { tooltipText: DEFAULT_TEXT });
    },


    actions: {
      alertSuccess: function alertSuccess() {
        var _this = this;

        var orig = Ember.get(this, 'model.tooltipText');

        this.setProperties({
          status: 'success',
          model: { tooltipText: 'copyToClipboard.copied' }
        });

        Ember.run.later(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.setProperties({
            status: null,
            model: { tooltipText: orig }
          });
        }, DELAY);
      }
    },

    isSupported: function () {
      return Ember.get(this, 'clipboardText.length') && (!_platform.isSafari || document.queryCommandSupported('copy'));
    }.property('clipboardText'),

    buttonClasses: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      var out = 'btn ' + Ember.get(this, 'color');

      if (status) {
        out += ' text-success';
      } else {
        out += ' text-muted';
      }

      if (Ember.get(this, 'size')) {
        out += ' small';
      }

      if (Ember.get(this, 'buttonClass')) {
        out += ' ' + Ember.get(this, 'buttonClass');
      }

      return out;
    }),
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'model', { tooltipText: DEFAULT_TEXT });
    }
  });
});