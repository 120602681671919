define('ember-math-helpers/helpers/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;


  /**
   * Sums two or more numbers
   *
   * ```hbs
   * {{add a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to sum
   * @return {number} The sum of all the passed numbers
   */
  function add(numbers) {
    return numbers.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
  }

  exports.default = Ember.Helper.helper(add);
});