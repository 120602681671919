define('shared/utils/navigation-tree', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getClusterId = exports.getProjectId = undefined;
  exports.addItem = addItem;
  exports.bulkAdd = bulkAdd;
  exports.removeId = removeId;
  exports.get = get;


  // Useful context/condition shortcuts
  var getProjectId = exports.getProjectId = function getProjectId() {
    return this.get('projectId');
  };
  var getClusterId = exports.getClusterId = function getClusterId() {
    return this.get('clusterId');
  };

  /* Tree item options
    {
      id: 'str' (identifier to allow removal... should be unique)
      localizedLabel: 'i18n key', (or function that returns one)
      label: 'Displayed unlocalized label', (or function that returns string)
      icon: 'icon icon-something',
      condition: function() {
        // return true if this item should be displayed
        // condition can depend on anything page-header/component.js shouldUpdateNavTree() depends on
      }
      target: '_blank', (for url only)
      route: 'target.route.path', // as in link-to
      ctx: ['values', 'asContextToRoute', orFunctionThatReturnsValue, anotherFunction]
      qp: {a: 'hello', b: 'world'],
      moreCurrentWhen: ['additional.routes','for.current-when'],
  
      submenu: [
        // Another tree item (only one level of submenu supported, no arbitrary depth nesting)
        {...},
        {...}
      ]
    },
  */
  var navTree = [];

  function addItem(opt) {
    navTree.pushObject(opt);
    syncCurrentWhen();
  }

  function bulkAdd(opts) {
    navTree.pushObjects(opts);
    syncCurrentWhen();
  }

  function removeId(id) {
    // These go backwards so that entries aren't skipped if multiple are deleted
    for (var i = navTree.length - 1; i >= 0; i--) {
      if (navTree[i].id === id) {
        navTree.removeAt(i);
      } else if (navTree[i].submenu && Ember.isArray(navTree[i].submenu)) {
        var sub = navTree[i].submenu;

        for (var j = sub.length - 1; j >= 0; j--) {
          if (sub[j].id === id) {
            sub.removeAt(j);
          }
        }
      }
    }

    syncCurrentWhen();
  }

  function get() {
    // return JSON.parse(JSON.stringify(navTree));
    return (0, _emberCopy.copy)(navTree, true);
  }

  function syncCurrentWhen() {
    for (var i = 0; i < navTree.length; i++) {
      var when = (navTree[i].moreCurrentWhen || []).slice();

      var sub = navTree[i].submenu;

      if (sub) {
        when.addObjects(sub.map(function (x) {
          return x.route;
        }));
        when = when.filter(function (x) {
          return !!x && x.length;
        });
      }

      navTree[i].currentWhen = when;
    }
  }

  exports.default = {
    get: get,
    removeId: removeId,
    addItem: addItem,
    getProjectId: getProjectId,
    getClusterId: getClusterId,
    bulkAdd: bulkAdd
  };
});