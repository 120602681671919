enifed('container', ['exports', '@ember/debug', '@ember/polyfills', 'ember-owner', 'ember-utils', '@ember/deprecated-features', 'ember-environment'], function (exports, _debug, _polyfills, _emberOwner, _emberUtils, _deprecatedFeatures, _emberEnvironment) {
    'use strict';

    exports.FACTORY_FOR = exports.Container = exports.privatize = exports.Registry = undefined;

    /**
     A container used to instantiate and cache objects.
    
     Every `Container` must be associated with a `Registry`, which is referenced
     to determine the factory and options that should be used to instantiate
     objects.
    
     The public API for `Container` is still in flux and should not be considered
     stable.
    
     @private
     @class Container
     */

    var Container = function () {
        function Container(registry) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};


            this.registry = registry;
            this.owner = options.owner || null;
            this.cache = (0, _emberUtils.dictionary)(options.cache || null);
            this.factoryManagerCache = (0, _emberUtils.dictionary)(options.factoryManagerCache || null);
            this.isDestroyed = false;
            this.isDestroying = false;
        }
        /**
         @private
         @property registry
         @type Registry
         @since 1.11.0
         */
        /**
         @private
         @property cache
         @type InheritingDict
         */
        /**
         @private
         @property validationCache
         @type InheritingDict
         */
        /**
         Given a fullName return a corresponding instance.
          The default behavior is for lookup to return a singleton instance.
         The singleton is scoped to the container, allowing multiple containers
         to all have their own locally scoped singletons.
          ```javascript
         let registry = new Registry();
         let container = registry.container();
          registry.register('api:twitter', Twitter);
          let twitter = container.lookup('api:twitter');
          twitter instanceof Twitter; // => true
          // by default the container will return singletons
         let twitter2 = container.lookup('api:twitter');
         twitter2 instanceof Twitter; // => true
          twitter === twitter2; //=> true
         ```
          If singletons are not wanted, an optional flag can be provided at lookup.
          ```javascript
         let registry = new Registry();
         let container = registry.container();
          registry.register('api:twitter', Twitter);
          let twitter = container.lookup('api:twitter', { singleton: false });
         let twitter2 = container.lookup('api:twitter', { singleton: false });
          twitter === twitter2; //=> false
         ```
          @private
         @method lookup
         @param {String} fullName
         @param {Object} [options]
         @param {String} [options.source] The fullname of the request source (used for local lookup)
         @return {any}
         */

        Container.prototype.lookup = function (fullName, options) {
            false && !!this.isDestroyed && (0, _debug.assert)('expected container not to be destroyed', !this.isDestroyed);
            false && !this.registry.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.registry.isValidFullName(fullName));

            return _lookup(this, this.registry.normalize(fullName), options);
        };

        Container.prototype.destroy = function () {
            destroyDestroyables(this);
            this.isDestroying = true;
        };

        Container.prototype.finalizeDestroy = function () {
            resetCache(this);
            this.isDestroyed = true;
        };

        Container.prototype.reset = function (fullName) {
            if (this.isDestroyed) return;
            if (fullName === undefined) {
                destroyDestroyables(this);
                resetCache(this);
            } else {
                resetMember(this, this.registry.normalize(fullName));
            }
        };

        Container.prototype.ownerInjection = function () {
            var _ref;

            return _ref = {}, _ref[_emberOwner.OWNER] = this.owner, _ref;
        };

        Container.prototype.factoryFor = function (fullName) {
            var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
            false && !!this.isDestroyed && (0, _debug.assert)('expected container not to be destroyed', !this.isDestroyed);

            var normalizedName = this.registry.normalize(fullName);
            false && !this.registry.isValidFullName(normalizedName) && (0, _debug.assert)('fullName must be a proper full name', this.registry.isValidFullName(normalizedName));
            false && !(false || !options.namespace) && (0, _debug.assert)('EMBER_MODULE_UNIFICATION must be enabled to pass a namespace option to factoryFor', false || !options.namespace);

            if (options.source || options.namespace) {
                normalizedName = this.registry.expandLocalLookup(fullName, options);
                if (!normalizedName) {
                    return;
                }
            }
            return _factoryFor(this, normalizedName, fullName);
        };

        return Container;
    }();
    /*
     * Wrap a factory manager in a proxy which will not permit properties to be
     * set on the manager.
     */

    function isSingleton(container, fullName) {
        return container.registry.getOption(fullName, 'singleton') !== false;
    }
    function isInstantiatable(container, fullName) {
        return container.registry.getOption(fullName, 'instantiate') !== false;
    }
    function _lookup(container, fullName) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
            cached;
        false && !(false || !options.namespace) && (0, _debug.assert)('EMBER_MODULE_UNIFICATION must be enabled to pass a namespace option to lookup', false || !options.namespace);

        var normalizedName = fullName;
        if (options.source || options.namespace) {
            normalizedName = container.registry.expandLocalLookup(fullName, options);
            if (!normalizedName) {
                return;
            }
        }
        if (options.singleton !== false) {
            cached = container.cache[normalizedName];

            if (cached !== undefined) {
                return cached;
            }
        }
        return instantiateFactory(container, normalizedName, fullName, options);
    }
    function _factoryFor(container, normalizedName, fullName) {
        var cached = container.factoryManagerCache[normalizedName];
        if (cached !== undefined) {
            return cached;
        }
        var factory = container.registry.resolve(normalizedName);
        if (factory === undefined) {
            return;
        }

        var manager = new FactoryManager(container, factory, fullName, normalizedName);

        container.factoryManagerCache[normalizedName] = manager;
        return manager;
    }
    function isSingletonClass(container, fullName, _ref2) {
        var instantiate = _ref2.instantiate,
            singleton = _ref2.singleton;

        return singleton !== false && !instantiate && isSingleton(container, fullName) && !isInstantiatable(container, fullName);
    }
    function isSingletonInstance(container, fullName, _ref3) {
        var instantiate = _ref3.instantiate,
            singleton = _ref3.singleton;

        return singleton !== false && instantiate !== false && isSingleton(container, fullName) && isInstantiatable(container, fullName);
    }
    function isFactoryClass(container, fullname, _ref4) {
        var instantiate = _ref4.instantiate,
            singleton = _ref4.singleton;

        return instantiate === false && (singleton === false || !isSingleton(container, fullname)) && !isInstantiatable(container, fullname);
    }
    function isFactoryInstance(container, fullName, _ref5) {
        var instantiate = _ref5.instantiate,
            singleton = _ref5.singleton;

        return instantiate !== false && (singleton !== false || isSingleton(container, fullName)) && isInstantiatable(container, fullName);
    }
    function instantiateFactory(container, normalizedName, fullName, options) {
        var factoryManager = _factoryFor(container, normalizedName, fullName);
        if (factoryManager === undefined) {
            return;
        }
        // SomeClass { singleton: true, instantiate: true } | { singleton: true } | { instantiate: true } | {}
        // By default majority of objects fall into this case
        if (isSingletonInstance(container, fullName, options)) {
            return container.cache[normalizedName] = factoryManager.create();
        }
        // SomeClass { singleton: false, instantiate: true }
        if (isFactoryInstance(container, fullName, options)) {
            return factoryManager.create();
        }
        // SomeClass { singleton: true, instantiate: false } | { instantiate: false } | { singleton: false, instantiation: false }
        if (isSingletonClass(container, fullName, options) || isFactoryClass(container, fullName, options)) {
            return factoryManager.class;
        }
        throw new Error('Could not create factory');
    }
    function processInjections(container, injections, result) {
        var hash = result.injections,
            i,
            _injections$i,
            property,
            specifier,
            source;
        if (hash === undefined) {
            hash = result.injections = {};
        }
        for (i = 0; i < injections.length; i++) {
            _injections$i = injections[i], property = _injections$i.property, specifier = _injections$i.specifier, source = _injections$i.source;


            if (source) {
                hash[property] = _lookup(container, specifier, { source: source });
            } else {
                hash[property] = _lookup(container, specifier);
            }
            if (!result.isDynamic) {
                result.isDynamic = !isSingleton(container, specifier);
            }
        }
    }
    function buildInjections(container, typeInjections, injections) {
        var result = {
            injections: undefined,
            isDynamic: false
        };
        if (typeInjections !== undefined) {
            processInjections(container, typeInjections, result);
        }
        if (injections !== undefined) {
            processInjections(container, injections, result);
        }
        return result;
    }
    function injectionsFor(container, fullName) {
        var registry = container.registry;

        var _fullName$split = fullName.split(':'),
            type = _fullName$split[0];

        var typeInjections = registry.getTypeInjections(type);
        var injections = registry.getInjections(fullName);
        return buildInjections(container, typeInjections, injections);
    }
    function destroyDestroyables(container) {
        var cache = container.cache,
            i,
            key,
            value;
        var keys = Object.keys(cache);
        for (i = 0; i < keys.length; i++) {
            key = keys[i];
            value = cache[key];

            if (value.destroy) {
                value.destroy();
            }
        }
    }
    function resetCache(container) {
        container.cache = (0, _emberUtils.dictionary)(null);
        container.factoryManagerCache = (0, _emberUtils.dictionary)(null);
    }
    function resetMember(container, fullName) {
        var member = container.cache[fullName];
        delete container.factoryManagerCache[fullName];
        if (member) {
            delete container.cache[fullName];
            if (member.destroy) {
                member.destroy();
            }
        }
    }
    var FACTORY_FOR = new WeakMap();

    var FactoryManager = function () {
        function FactoryManager(container, factory, fullName, normalizedName) {

            this.container = container;
            this.owner = container.owner;
            this.class = factory;
            this.fullName = fullName;
            this.normalizedName = normalizedName;
            this.madeToString = undefined;
            this.injections = undefined;
            FACTORY_FOR.set(this, this);
        }

        FactoryManager.prototype.toString = function () {
            if (this.madeToString === undefined) {
                this.madeToString = this.container.registry.makeToString(this.class, this.fullName);
            }
            return this.madeToString;
        };

        FactoryManager.prototype.create = function (options) {
            var injectionsCache = this.injections,
                _injectionsFor,
                injections,
                isDynamic;
            if (injectionsCache === undefined) {
                _injectionsFor = injectionsFor(this.container, this.normalizedName), injections = _injectionsFor.injections, isDynamic = _injectionsFor.isDynamic;


                injectionsCache = injections;
                if (!isDynamic) {
                    this.injections = injections;
                }
            }
            var props = injectionsCache;
            if (options !== undefined) {
                props = (0, _polyfills.assign)({}, injectionsCache, options);
            }

            if (!this.class.create) {
                throw new Error('Failed to create an instance of \'' + this.normalizedName + '\'. Most likely an improperly defined class or' + ' an invalid module export.');
            }
            // required to allow access to things like
            // the customized toString, _debugContainerKey,
            // owner, etc. without a double extend and without
            // modifying the objects properties
            if (typeof this.class._initFactory === 'function') {
                this.class._initFactory(this);
            } else {
                // in the non-EmberObject case we need to still setOwner
                // this is required for supporting glimmer environment and
                // template instantiation which rely heavily on
                // `options[OWNER]` being passed into `create`
                // TODO: clean this up, and remove in future versions
                if (options === undefined || props === undefined) {
                    // avoid mutating `props` here since they are the cached injections
                    props = (0, _polyfills.assign)({}, props);
                }
                (0, _emberOwner.setOwner)(props, this.owner);
            }
            var instance = this.class.create(props);
            FACTORY_FOR.set(instance, this);
            return instance;
        };

        return FactoryManager;
    }();

    var VALID_FULL_NAME_REGEXP = /^[^:]+:[^:]+$/;
    var missingResolverFunctionsDeprecation = 'Passing a `resolver` function into a Registry is deprecated. Please pass in a Resolver object with a `resolve` method.';
    /**
     A registry used to store factory and option information keyed
     by type.
    
     A `Registry` stores the factory and option information needed by a
     `Container` to instantiate and cache objects.
    
     The API for `Registry` is still in flux and should not be considered stable.
    
     @private
     @class Registry
     @since 1.11.0
    */

    var Registry = function () {
        function Registry() {
            var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};


            this.fallback = options.fallback || null;
            this.resolver = options.resolver || null;
            if (_emberEnvironment.ENV._ENABLE_RESOLVER_FUNCTION_SUPPORT !== true) {
                false && !(typeof this.resolver !== 'function') && (0, _debug.assert)(missingResolverFunctionsDeprecation, typeof this.resolver !== 'function');
            }
            if (_deprecatedFeatures.REGISTRY_RESOLVER_AS_FUNCTION && typeof this.resolver === 'function' && _emberEnvironment.ENV._ENABLE_RESOLVER_FUNCTION_SUPPORT === true) {
                false && !false && (0, _debug.deprecate)(missingResolverFunctionsDeprecation, false, {
                    id: 'ember-application.registry-resolver-as-function',
                    until: '3.0.0',
                    url: 'https://emberjs.com/deprecations/v2.x#toc_registry-resolver-as-function'
                });

                this.resolver = { resolve: this.resolver };
            }
            this.registrations = (0, _emberUtils.dictionary)(options.registrations || null);
            this._typeInjections = (0, _emberUtils.dictionary)(null);
            this._injections = (0, _emberUtils.dictionary)(null);
            this._localLookupCache = Object.create(null);
            this._normalizeCache = (0, _emberUtils.dictionary)(null);
            this._resolveCache = (0, _emberUtils.dictionary)(null);
            this._failSet = new Set();
            this._options = (0, _emberUtils.dictionary)(null);
            this._typeOptions = (0, _emberUtils.dictionary)(null);
        }
        /**
         A backup registry for resolving registrations when no matches can be found.
            @private
         @property fallback
         @type Registry
         */
        /**
         An object that has a `resolve` method that resolves a name.
            @private
         @property resolver
         @type Resolver
         */
        /**
         @private
         @property registrations
         @type InheritingDict
         */
        /**
         @private
            @property _typeInjections
         @type InheritingDict
         */
        /**
         @private
            @property _injections
         @type InheritingDict
         */
        /**
         @private
            @property _normalizeCache
         @type InheritingDict
         */
        /**
         @private
            @property _resolveCache
         @type InheritingDict
         */
        /**
         @private
            @property _options
         @type InheritingDict
         */
        /**
         @private
            @property _typeOptions
         @type InheritingDict
         */
        /**
         Creates a container based on this registry.
            @private
         @method container
         @param {Object} options
         @return {Container} created container
         */

        Registry.prototype.container = function (options) {
            return new Container(this, options);
        };

        Registry.prototype.register = function (fullName, factory) {
            var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
            false && !this.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.isValidFullName(fullName));
            false && !(factory !== undefined) && (0, _debug.assert)('Attempting to register an unknown factory: \'' + fullName + '\'', factory !== undefined);

            var normalizedName = this.normalize(fullName);
            false && !!this._resolveCache[normalizedName] && (0, _debug.assert)('Cannot re-register: \'' + fullName + '\', as it has already been resolved.', !this._resolveCache[normalizedName]);

            this._failSet.delete(normalizedName);
            this.registrations[normalizedName] = factory;
            this._options[normalizedName] = options;
        };

        Registry.prototype.unregister = function (fullName) {
            false && !this.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.isValidFullName(fullName));

            var normalizedName = this.normalize(fullName);
            this._localLookupCache = Object.create(null);
            delete this.registrations[normalizedName];
            delete this._resolveCache[normalizedName];
            delete this._options[normalizedName];
            this._failSet.delete(normalizedName);
        };

        Registry.prototype.resolve = function (fullName, options) {
            var factory = _resolve(this, this.normalize(fullName), options),
                _fallback;
            if (factory === undefined && this.fallback !== null) {

                factory = (_fallback = this.fallback).resolve.apply(_fallback, arguments);
            }
            return factory;
        };

        Registry.prototype.describe = function (fullName) {
            if (this.resolver !== null && this.resolver.lookupDescription) {
                return this.resolver.lookupDescription(fullName);
            } else if (this.fallback !== null) {
                return this.fallback.describe(fullName);
            } else {
                return fullName;
            }
        };

        Registry.prototype.normalizeFullName = function (fullName) {
            if (this.resolver !== null && this.resolver.normalize) {
                return this.resolver.normalize(fullName);
            } else if (this.fallback !== null) {
                return this.fallback.normalizeFullName(fullName);
            } else {
                return fullName;
            }
        };

        Registry.prototype.normalize = function (fullName) {
            return this._normalizeCache[fullName] || (this._normalizeCache[fullName] = this.normalizeFullName(fullName));
        };

        Registry.prototype.makeToString = function (factory, fullName) {
            if (this.resolver !== null && this.resolver.makeToString) {
                return this.resolver.makeToString(factory, fullName);
            } else if (this.fallback !== null) {
                return this.fallback.makeToString(factory, fullName);
            } else {
                return factory.toString();
            }
        };

        Registry.prototype.has = function (fullName, options) {
            if (!this.isValidFullName(fullName)) {
                return false;
            }
            var source = options && options.source && this.normalize(options.source);
            var namespace = options && options.namespace || undefined;
            return _has(this, this.normalize(fullName), source, namespace);
        };

        Registry.prototype.optionsForType = function (type, options) {
            this._typeOptions[type] = options;
        };

        Registry.prototype.getOptionsForType = function (type) {
            var optionsForType = this._typeOptions[type];
            if (optionsForType === undefined && this.fallback !== null) {
                optionsForType = this.fallback.getOptionsForType(type);
            }
            return optionsForType;
        };

        Registry.prototype.options = function (fullName, _options) {
            var normalizedName = this.normalize(fullName);
            this._options[normalizedName] = _options;
        };

        Registry.prototype.getOptions = function (fullName) {
            var normalizedName = this.normalize(fullName);
            var options = this._options[normalizedName];
            if (options === undefined && this.fallback !== null) {
                options = this.fallback.getOptions(fullName);
            }
            return options;
        };

        Registry.prototype.getOption = function (fullName, optionName) {
            var options = this._options[fullName];
            if (options !== undefined && options[optionName] !== undefined) {
                return options[optionName];
            }
            var type = fullName.split(':')[0];
            options = this._typeOptions[type];
            if (options && options[optionName] !== undefined) {
                return options[optionName];
            } else if (this.fallback !== null) {
                return this.fallback.getOption(fullName, optionName);
            }
            return undefined;
        };

        Registry.prototype.typeInjection = function (type, property, fullName) {
            false && !this.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.isValidFullName(fullName));

            var fullNameType = fullName.split(':')[0];
            false && !(fullNameType !== type) && (0, _debug.assert)('Cannot inject a \'' + fullName + '\' on other ' + type + '(s).', fullNameType !== type);

            var injections = this._typeInjections[type] || (this._typeInjections[type] = []);
            injections.push({ property: property, specifier: fullName });
        };

        Registry.prototype.injection = function (fullName, property, injectionName) {
            false && !this.isValidFullName(injectionName) && (0, _debug.assert)('Invalid injectionName, expected: \'type:name\' got: ' + injectionName, this.isValidFullName(injectionName));

            var normalizedInjectionName = this.normalize(injectionName);
            if (fullName.indexOf(':') === -1) {
                return this.typeInjection(fullName, property, normalizedInjectionName);
            }
            false && !this.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.isValidFullName(fullName));

            var normalizedName = this.normalize(fullName);
            var injections = this._injections[normalizedName] || (this._injections[normalizedName] = []);
            injections.push({ property: property, specifier: normalizedInjectionName });
        };

        Registry.prototype.knownForType = function (type) {
            var localKnown = (0, _emberUtils.dictionary)(null),
                index,
                fullName,
                itemType;
            var registeredNames = Object.keys(this.registrations);
            for (index = 0; index < registeredNames.length; index++) {
                fullName = registeredNames[index];
                itemType = fullName.split(':')[0];

                if (itemType === type) {
                    localKnown[fullName] = true;
                }
            }
            var fallbackKnown = void 0,
                resolverKnown = void 0;
            if (this.fallback !== null) {
                fallbackKnown = this.fallback.knownForType(type);
            }
            if (this.resolver !== null && this.resolver.knownForType) {
                resolverKnown = this.resolver.knownForType(type);
            }
            return (0, _polyfills.assign)({}, fallbackKnown, localKnown, resolverKnown);
        };

        Registry.prototype.isValidFullName = function (fullName) {
            return VALID_FULL_NAME_REGEXP.test(fullName);
        };

        Registry.prototype.getInjections = function (fullName) {
            var injections = this._injections[fullName],
                fallbackInjections;
            if (this.fallback !== null) {
                fallbackInjections = this.fallback.getInjections(fullName);

                if (fallbackInjections !== undefined) {
                    injections = injections === undefined ? fallbackInjections : injections.concat(fallbackInjections);
                }
            }
            return injections;
        };

        Registry.prototype.getTypeInjections = function (type) {
            var injections = this._typeInjections[type],
                fallbackInjections;
            if (this.fallback !== null) {
                fallbackInjections = this.fallback.getTypeInjections(type);

                if (fallbackInjections !== undefined) {
                    injections = injections === undefined ? fallbackInjections : injections.concat(fallbackInjections);
                }
            }
            return injections;
        };

        Registry.prototype.expandLocalLookup = function (fullName, options) {
            var normalizedFullName, normalizedSource;

            if (this.resolver !== null && this.resolver.expandLocalLookup) {
                false && !this.isValidFullName(fullName) && (0, _debug.assert)('fullName must be a proper full name', this.isValidFullName(fullName));
                false && !(!options.source || this.isValidFullName(options.source)) && (0, _debug.assert)('options.source must be a proper full name', !options.source || this.isValidFullName(options.source));

                normalizedFullName = this.normalize(fullName);
                normalizedSource = this.normalize(options.source);

                return _expandLocalLookup(this, normalizedFullName, normalizedSource, options.namespace);
            } else if (this.fallback !== null) {
                return this.fallback.expandLocalLookup(fullName, options);
            } else {
                return null;
            }
        };

        return Registry;
    }();

    function _expandLocalLookup(registry, normalizedName, normalizedSource, namespace) {
        var cache = registry._localLookupCache;
        var normalizedNameCache = cache[normalizedName];
        if (!normalizedNameCache) {
            normalizedNameCache = cache[normalizedName] = Object.create(null);
        }
        var cacheKey = namespace || normalizedSource;
        var cached = normalizedNameCache[cacheKey];
        if (cached !== undefined) {
            return cached;
        }
        var expanded = registry.resolver.expandLocalLookup(normalizedName, normalizedSource, namespace);
        return normalizedNameCache[cacheKey] = expanded;
    }
    function _resolve(registry, _normalizedName, options) {
        var normalizedName = _normalizedName;
        // when `source` is provided expand normalizedName
        // and source into the full normalizedName
        if (options !== undefined && (options.source || options.namespace)) {
            normalizedName = registry.expandLocalLookup(_normalizedName, options);
            if (!normalizedName) {
                return;
            }
        }
        var cached = registry._resolveCache[normalizedName];
        if (cached !== undefined) {
            return cached;
        }
        if (registry._failSet.has(normalizedName)) {
            return;
        }
        var resolved = void 0;
        if (registry.resolver) {
            resolved = registry.resolver.resolve(normalizedName);
        }
        if (resolved === undefined) {
            resolved = registry.registrations[normalizedName];
        }
        if (resolved === undefined) {
            registry._failSet.add(normalizedName);
        } else {
            registry._resolveCache[normalizedName] = resolved;
        }
        return resolved;
    }
    function _has(registry, fullName, source, namespace) {
        return registry.resolve(fullName, { source: source, namespace: namespace }) !== undefined;
    }
    var privateNames = (0, _emberUtils.dictionary)(null);
    var privateSuffix = ('' + Math.random() + Date.now()).replace('.', '');


    /*
    Public API for the container is still in flux.
    The public API, specified on the application namespace should be considered the stable API.
    // @module container
      @private
    */

    exports.Registry = Registry;
    exports.privatize = function (_ref6) {
        var fullName = _ref6[0];

        var name = privateNames[fullName];
        if (name) {
            return name;
        }

        var _fullName$split2 = fullName.split(':'),
            type = _fullName$split2[0],
            rawName = _fullName$split2[1];

        return privateNames[fullName] = (0, _emberUtils.intern)(type + ':' + rawName + '-' + privateSuffix);
    };
    exports.Container = Container;
    exports.FACTORY_FOR = FACTORY_FOR;
});