define('shared/utils/headers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VOLUMES = [{
    name: 'serviceName',
    sort: ['instance.service.displayName:desc', 'instanceId:desc'],
    translationKey: 'volumesPage.mounts.table.instance'
  }, {
    name: 'instanceName',
    sort: ['instanceName:desc', 'instanceId:desc'],
    translationKey: 'volumesPage.mounts.table.instance'
  }, {
    name: 'path',
    sort: ['path'],
    translationKey: 'volumesPage.mounts.table.path'
  }, {
    name: 'permission',
    sort: ['permission'],
    translationKey: 'volumesPage.mounts.table.permission'
  }];

  exports.default = { volumes: VOLUMES };
});