define('shared/components/metrics-summary/component', ['exports', 'shared/components/metrics-summary/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    title: null,

    expandedDidChange: Ember.observer('expanded', function () {
      if (Ember.get(this, 'expanded')) {
        Ember.$(window).trigger('resize');
      }
    })
  });
});