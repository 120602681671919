define('global-admin/initializers/ember-api-store', ['exports', '@rancher/ember-api-store/initializers/store'], function (exports, _store) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-api-store',
    initialize: (0, _store.default)()
  };
});