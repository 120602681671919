define('shared/helpers/date-str', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateStr = dateStr;
  function dateStr(params, options) {
    var format = 'MMM DD, YYYY hh:mm:ss A';

    if (options && options.format) {
      format = options.format;
    }

    return (0, _moment.default)(params[0]).format(format);
  }

  exports.default = Ember.Helper.helper(dateStr);
});