define('@rancher/ember-api-store/models/error', ['exports', '@rancher/ember-api-store/models/resource'], function (exports, _resource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _resource.default.extend({
    type: 'error',

    toString: function toString() {
      return JSON.stringify(this);
    }
  });
});