define('nodes/components/node-template-row/component', ['exports', 'nodes/components/node-template-row/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,

    tagName: 'TR',
    classNames: 'main-row'
  });
});