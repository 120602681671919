define('shared/components/advanced-section/component', ['exports', 'shared/components/advanced-section/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    advanced: false,

    tagName: null,

    actions: {
      toggle: function toggle() {
        this.set('advanced', !this.get('advanced'));
      }
    }
  });
});