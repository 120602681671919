define('shared/helpers/run-time', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.runTime = runTime;
  function runTime(params) {
    var s = (0, _moment.default)(params[0]);
    var e = (0, _moment.default)(params[1]);
    var time = Math.round(e.diff(s) / 100) / 10;

    if (time) {
      if (time > 60) {
        time = Math.round(time);
      }

      return time + ' sec';
    } else {
      return '<span class="text-muted">-</span>'.htmlSafe();
    }
  }

  exports.default = Ember.Helper.helper(runTime);
});