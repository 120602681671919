define('shared/components/textarea-autogrow/component', ['exports', 'ui/utils/platform', 'shared/mixins/intl-placeholder'], function (exports, _platform, _intlPlaceholder) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextArea.extend(_intlPlaceholder.default, {
    intl: Ember.inject.service(),

    minHeight: 0,
    curHeight: null,
    maxHeight: 200,

    tagName: 'textarea',
    classNames: ['no-resize', 'no-ease'],
    attributeBindings: ['spellcheck'],
    classNameBindings: ['bg'],

    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, 'initHeights');
    },


    bg: Ember.computed('disabled', function () {
      if (Ember.get(this, 'disabled')) {
        return 'bg-disabled';
      }
    }),

    changed: function () {
      Ember.run.debounce(this, 'autoSize', 100);
    }.observes('value'),

    isSmall: function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      return this.$().hasClass('input-sm');
    }.property(),

    initHeights: function initHeights() {
      var _this = this;

      if (this.get('minHeight') === 0) {
        this.set('minHeight', this.get('isSmall') ? 31 : 36);
      }

      this.autoSize();

      this.$().on('paste', function () {
        Ember.run.later(_this, 'autoSize', 100);
      });
    },
    autoSize: function autoSize() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var el = this.element;
      var $el = $(el); // eslint-disable-line

      Ember.run(function () {
        $el.css('height', '1px');

        var border = parseInt($el.css('borderTopWidth'), 10) || 0 + parseInt($el.css('borderBottomWidth'), 10) || 0;
        var magic = (_this2.get('isSmall') ? -2 : 0) + (_platform.isGecko ? 1 : 2); // Sigh, but it's wrong without magic fudge
        var neu = Math.max(_this2.get('minHeight'), Math.min(el.scrollHeight + border + magic, _this2.get('maxHeight')));

        $el.css('overflowY', el.scrollHeight > neu ? 'auto' : 'hidden');
        $el.css('height', neu + 'px');
        _this2.set('curHeight', neu);
      });
    }
  });
});