define('shared/mixins/sortable-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sortableContent: Ember.computed.alias('model'),
    headers: null,
    preSorts: null,
    sortBy: null,
    groupByRef: null,
    descending: false,

    actions: {
      changeSort: function changeSort(name) {
        if (this.get('sortBy') === name) {
          this.set('descending', !this.get('descending'));
        } else {
          this.setProperties({
            descending: false,
            sortBy: name
          });
        }
      },


      // Like changeSort, but without the auto-flipping
      setSort: function setSort(name) {
        this.setProperties({
          descending: false,
          sortBy: name
        });
      }
    },

    currentSort: Ember.computed('sortBy', 'groupByRef', 'headers.@each.{sortBy}', 'descending', function () {
      var headers = this.get('headers');
      var desc = this.get('descending');
      var sort = this.get('preSorts') || [];

      if (Ember.get(this, 'groupByRef')) {
        var groupSortBy = Ember.get(this, 'groupByRef') + '.displayName';

        if (desc) {
          sort.push(groupSortBy + ':desc');
        } else {
          sort.push(groupSortBy);
        }
      }

      if (headers) {
        var header = headers.findBy('name', this.get('sortBy'));

        if (header) {
          var more = Ember.get(header, 'sort');

          if (more && more.length) {
            if (desc) {
              sort.pushObjects(more.map(function (x) {
                var parts = x.split(/:/);

                if (parts.length === 2 && parts[1] === 'desc') {
                  return parts[0];
                } else {
                  return x + ':desc';
                }
              }));
            } else {
              sort.pushObjects(more);
            }

            return sort;
          }
        }
      }

      if (desc) {
        sort.push('id:desc');
      } else {
        sort.push('id');
      }

      return sort;
    }),

    arranged: Ember.computed.sort('sortableContent', 'currentSort')
  });
});