enifed('ember-views/lib/system/jquery', ['exports', 'ember-environment', 'ember-browser-environment'], function (exports, _emberEnvironment, _emberBrowserEnvironment) {
  'use strict';

  exports.jQueryDisabled = undefined;

  var jQuery = void 0;
  var jQueryDisabled = exports.jQueryDisabled = _emberEnvironment.ENV._JQUERY_INTEGRATION === false;

  if (_emberBrowserEnvironment.hasDOM) {
    jQuery = _emberEnvironment.context.imports.jQuery;

    if (!jQueryDisabled && jQuery) {
      if (jQuery.event.addProp) {
        jQuery.event.addProp('dataTransfer');
      } else {
        // http://www.whatwg.org/specs/web-apps/current-work/multipage/dnd.html#dndevents
        ['dragstart', 'drag', 'dragenter', 'dragleave', 'dragover', 'drop', 'dragend'].forEach(function (eventName) {
          jQuery.event.fixHooks[eventName] = {
            props: ['dataTransfer']
          };
        });
      }
    } else {
      exports.jQueryDisabled = jQueryDisabled = true;
    }
  }

  exports.default = jQueryDisabled ? undefined : jQuery;
});