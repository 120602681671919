define('shared/components/confirm-delete/component', ['exports', 'ui/utils/platform', 'ui/mixins/modal-base', 'shared/components/confirm-delete/template', 'async'], function (exports, _platform, _modalBase, _template, _async) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['medium-modal'],
    alternateLabel: _platform.alternateLabel,
    resources: Ember.computed.alias('modalService.modalOpts.resources'),
    didRender: function didRender() {
      var _this = this;

      setTimeout(function () {
        try {
          _this.$('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    },

    actions: {
      confirm: function confirm() {
        var resources = Ember.get(this, 'resources').slice().reverse();

        (0, _async.eachLimit)(resources, 5, function (resource, cb) {
          if (!resource) {
            return cb();
          }

          if (resource.cb) {
            var out = resource.cb();

            if (out && out.finally) {
              out.finally(cb);
            } else {
              cb();
            }

            return;
          } else {
            resource.delete().finally(cb);
          }
        });

        this.send('cancel');
      }
    },

    showProtip: Ember.computed('modalService.modalOpts.showProtip', function () {
      var show = Ember.get(this, 'modalService.modalOpts.showProtip');

      if (show === undefined) {
        show = true;
      }

      return show;
    }),

    isEnvironment: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'project');
    }),

    isCluster: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'cluster');
    }),

    isClusterRoleTemplateBinding: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'clusterRoleTemplateBinding');
    }),

    isSystemProject: Ember.computed('resources', function () {
      var project = Ember.get(this, 'resources').findBy('type', 'project');

      return project && Ember.get(project, 'isSystemProject');
    }),

    hasSystemProjectNamespace: Ember.computed('resources', function () {
      var namespaces = Ember.get(this, 'resources').filter(function (resource) {
        return Ember.get(resource, 'type') === 'namespace' && Ember.get(resource, 'project.isSystemProject');
      });

      return Ember.get(namespaces, 'length') > 0;
    })

  });
});