define('shared/components/schema/input-relative-service/component', ['exports', 'shared/components/schema/input-relative-service/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CUSTOM = '__CUSTOM__';

  exports.default = Ember.Component.extend({
    allWorkloads: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    stack: null, // The default stack
    value: null, // The [stack/]service string value
    exclude: null, // ID or array of IDs to exclude from list
    inputClass: 'form-control',

    obj: null, // The selected service object
    custom: false,

    init: function init() {
      this._super.apply(this, arguments);

      var value = this.get('value');

      if (value) {
        var obj = this.get('allWorkloads').matching(value, this.get('stack'));

        if (obj) {
          this.setProperties({
            obj: obj,
            custom: false
          });
        } else {
          this.set('custom', true);
        }
      }
    },


    actions: {
      standard: function standard() {
        if (!this.get('obj')) {
          this.set('value', null);
        }

        this.set('custom', false);
      }
    },

    list: function () {
      var stackId = this.get('stack.id');
      var list = this.get('allWorkloads.list').sortBy('combined');

      list.forEach(function (item) {
        if (item.obj.stackId === stackId) {
          Ember.set(item, 'value', item.name);
        } else {
          Ember.set(item, 'value', item.combined);
        }
      });

      list.push({
        group: null,
        value: CUSTOM,
        name: this.get('intl').t('schema.inputService.custom')
      });

      var exclude = this.get('exclude') || [];

      if (!Ember.isArray(exclude)) {
        exclude = [exclude];
      }
      if (exclude.get('length')) {
        list = list.filter(function (row) {
          return !exclude.includes(row.id);
        });
      }

      return list;
    }.property('grouped.[]', 'intl.locale', 'exclude', 'stack.name'),

    valueChanged: function () {
      var value = this.get('value');

      if (value === CUSTOM) {
        this.setProperties({
          value: '',
          custom: true,
          obj: null
        });
      } else if (value) {
        var obj = this.get('allWorkloads').matching(value, this.get('stack'));

        this.set('obj', obj);
      }
    }.observes('value')
  });
});