define('shared/components/modal-shell/component', ['exports', 'shared/mixins/modal-base', 'shared/components/modal-shell/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    containerName: Ember.computed.alias('modalService.modalOpts.containerName'),
    init: function init() {
      this._super.apply(this, arguments);
      this.shortcuts.disable();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.shortcuts.enable();
    }
  });
});