define('shared/components/form-versions/component', ['exports', 'shared/components/form-versions/template', 'shared/utils/constants', 'shared/utils/parse-version', 'shared/utils/sort', 'semver'], function (exports, _template, _constants, _parseVersion, _sort, _semver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,

    cluster: null,
    versionChoices: null,
    versions: null,
    initialVersion: null,
    editing: false,
    value: null,

    defaultK8sVersionRange: Ember.computed.alias('settings.' + _constants.default.SETTING.VERSION_SYSTEM_K8S_DEFAULT_RANGE),
    supportedVersionsRange: Ember.computed.alias('settings.' + _constants.default.SETTING.VERSION_K8S_SUPPORTED_RANGE),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        _this.initVersions();
      });
    },


    isRke: Ember.computed('cluster', function () {
      var cluster = this.cluster;


      if (Ember.get(cluster, 'rancherKubernetesEngineConfig')) {
        return true;
      }

      return false;
    }),

    initVersions: function initVersions() {
      var _this2 = this;

      var defaultK8sVersionRange = this.defaultK8sVersionRange,
          versions = this.versions,
          supportedVersionsRange = this.supportedVersionsRange,
          editing = this.editing,
          initialVersion = this.initialVersion;

      var maxVersion = (0, _parseVersion.maxSatisfying)(versions, defaultK8sVersionRange);

      if (!editing && defaultK8sVersionRange) {
        if (maxVersion) {
          Ember.set(this, 'value', maxVersion);
        }
      }

      var out = versions;

      if (!out.includes(initialVersion) && editing) {
        out.unshift(initialVersion);
      }

      Ember.set(this, 'versionChoices', (0, _sort.sortVersions)(out).reverse().map(function (v) {
        var label = v;
        var out = null;

        var version = (0, _parseVersion.coerceVersion)(v);

        if ((0, _semver.gt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
          label = v + ' ' + _this2.intl.t('formVersions.experimental');
        }

        out = {
          label: label,
          value: v
        };

        if (supportedVersionsRange && !(0, _parseVersion.satisfies)(version, supportedVersionsRange) || editing && initialVersion && (0, _semver.lt)(version, (0, _parseVersion.coerceVersion)(initialVersion))) {
          if (!(0, _semver.gt)(version, (0, _parseVersion.coerceVersion)(maxVersion))) {
            Ember.setProperties(out, {
              disabled: true,
              label: label + ' ' + _this2.intl.t('formVersions.unsupported')
            });
          }
        }

        return out;
      }));
    }
  });
});