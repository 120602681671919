enifed('ember-template-compiler/lib/system/precompile', ['exports', '@glimmer/compiler', 'ember-template-compiler/lib/system/compile-options'], function (exports, _compiler, _compileOptions) {
  'use strict';

  exports.default =

  /**
    Uses HTMLBars `compile` function to process a string into a compiled template string.
    The returned string must be passed through `Ember.HTMLBars.template`.
  
    This is not present in production builds.
  
    @private
    @method precompile
    @param {String} templateString This is the string to be compiled by HTMLBars.
  */
  /**
  @module ember
  */
  function (templateString, options) {
    return (0, _compiler.precompile)(templateString, (0, _compileOptions.default)(options));
  };
});