define('shared/helpers/format-mib', ['exports', 'ui/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatMib = formatMib;
  function formatMib(params /* , options*/) {
    return _util.default.formatMib(params[0]);
  }

  exports.default = Ember.Helper.helper(formatMib);
});