define('nodes/components/driver-rackspace/component', ['exports', 'shared/mixins/node-driver', 'ui/utils/rackspace-choices', 'nodes/components/driver-rackspace/template'], function (exports, _nodeDriver, _rackspaceChoices, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var flavorChoices = [];

  _rackspaceChoices.default.forEach(function (flavor) {
    var parts = flavor.name.match(/^(\d+)\s*([a-z]+)\s*(.*)$/i);

    if (parts) {
      var sizeMb = parseInt(parts[1], 10);

      if (parts[2].toLowerCase() === 'gb') {
        sizeMb *= 1024;
      }

      flavorChoices.push({
        group: parts[3],
        label: parts[3] + ' : ' + parts[1] + ' ' + parts[2],
        value: flavor.id,
        sizeMb: sizeMb
      });
    }
  });

  flavorChoices.sort(function (a, b) {
    var ag = a.group;
    var bg = b.group;
    var as = a.sizeMb;
    var bs = b.sizeMb;

    if (ag < bg) {
      return -1;
    } else if (ag > bg) {
      return 1;
    } else {
      return as - bs;
    }
  });

  exports.default = Ember.Component.extend(_nodeDriver.default, {
    layout: _template.default,
    driverName: 'rackspace',
    flavorChoices: flavorChoices,
    regionChoices: [{
      label: 'Dallas (DFW)',
      value: 'DFW'
    }, {
      label: 'Chicago (ORD)',
      value: 'ORD'
    }, {
      label: 'Hong Kong (HKG)',
      value: 'HKG'
    }, {
      label: 'London (LON)',
      value: 'LON'
    }, {
      label: 'Northern Virginia (IAD)',
      value: 'IAD'
    }, {
      label: 'Sydney (SYD)',
      value: 'SYD'
    }],

    config: Ember.computed.alias('model.rackspaceConfig'),
    bootstrap: function bootstrap() {
      var store = this.get('globalStore');

      var config = store.createRecord({
        type: 'rackspaceConfig',
        username: '',
        apiKey: '',
        region: 'DFW',
        flavorId: 'general1-2'
      });

      this.set('model.rackspaceConfig', config);
    },
    validate: function validate() {
      var errors = [];

      if (!this.get('model.name')) {
        errors.push('Name is required');
      }

      this.set('errors', errors);

      return errors.length === 0;
    }
  });
});