define('global-admin/security/authentication/okta/controller', ['exports', 'global-admin/mixins/authentication', 'global-admin/mixins/saml-auth'], function (exports, _authentication, _samlAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_authentication.default, _samlAuth.default, {
    createLabel: 'authPage.okta.buttonText.pre',
    saveLabel: 'authPage.okta.buttonText.post',
    providerName: 'okta',
    providerNamePath: 'authPage.saml.providerName.okta'

  });
});