define('shared/mixins/child-hook', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_KEY = '_childHooks';
  var NEXT_ID = 1;

  exports.default = Ember.Mixin.create({
    actions: {
      // sendAction('registerHook', fn);
      // sendAction('registerHook', fn, 'name');
      // sendAction('registerHook', fn, {name: 'name', priority: 99, key: 'alternateKey'})
      registerHook: function registerHook(boundFn, opt) {
        if (typeof opt === 'string') {
          opt = { name: opt };
        } else if (!opt) {
          opt = {};
        }

        if (!opt.name) {
          opt.name = 'hook_' + NEXT_ID;
          NEXT_ID++;
        }

        var key = opt.key || DEFAULT_KEY;

        var hooks = Ember.get(this, key);

        if (!hooks) {
          hooks = [];
          Ember.set(this, key, hooks);
        }

        var entry = hooks.findBy('name', opt.name);

        if (!entry) {
          entry = opt;
          hooks.push(entry);
        }

        entry.priority = opt.priority || 99;
        entry.fn = boundFn;
      }
    },

    applyHooks: function applyHooks() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_KEY;

      var hooks = (Ember.get(this, key) || []).sortBy('priority', 'name');

      return Ember.RSVP.all(hooks.map(function (x) {
        return x.fn();
      }));
    }
  });
});