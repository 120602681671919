define('shared/utils/subscribe-cluster', ['exports', 'shared/mixins/subscribe'], function (exports, _subscribe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(_subscribe.default, {
    updateProjectStore: false,

    scope: Ember.inject.service(),
    watchState: true,
    watchStateOf: Ember.computed.alias('scope.pendingCluster'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'endpoint', Ember.get(this, 'app.clusterSubscribeEndpoint'));
    },
    validate: function validate() {
      var socket = Ember.get(this, 'subscribeSocket');
      var metadata = socket.getMetadata();
      var socketClusterId = Ember.get(metadata, 'clusterId');
      var currentClusterId = Ember.get(this, 'scope.currentCluster.id');

      if (!currentClusterId || currentClusterId === socketClusterId) {
        return true;
      } else {
        console.error(this.label + ' Subscribe ignoring message, current=' + currentClusterId + ' socket=' + socketClusterId + ' ' + this.forStr());

        return false;
      }
    }
  });
});