define('nodes/node-templates/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      return Ember.RSVP.hash({
        nodeTemplates: this.globalStore.findAll('nodeTemplate'),
        cloudCredential: this.globalStore.findAll('cloudcredential')
      });
    }
  });
});