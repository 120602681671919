enifed('ember-template-compiler/tests/plugins/assert-reserved-named-arguments-test', ['ember-babel', 'ember-template-compiler/index', 'internal-test-helpers'], function (_emberBabel, _index, _internalTestHelpers) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-reserved-named-arguments (EMBER_GLIMMER_NAMED_ARGUMENTS) ', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype['@test \'@arguments\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@arguments}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@arguments\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @arguments}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@arguments\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @arguments "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@arguments\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@args\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@args}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@args\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @args}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@args\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @args "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@args\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@block\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@block}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@block\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @block}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@block\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @block "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@block\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@else\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@else}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@else\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @else}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@else\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @else "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@else\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@Arguments\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@Arguments}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Arguments\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @Arguments}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Arguments\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @Arguments "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Arguments\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@Args\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@Args}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Args\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @Args}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Args\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @Args "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Args\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@FOO\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@FOO}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@FOO\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @FOO}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@FOO\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @FOO "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@FOO\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@Foo\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@Foo}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Foo\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @Foo}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Foo\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @Foo "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@Foo\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@.\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@.}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@.\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @.}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@.\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @. "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@.\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@_\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@_}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@_\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @_}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@_\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @_ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@_\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@-\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@-}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@-\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @-}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@-\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @- "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@-\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@$\' is reserved'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('{{@$}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@$\' is reserved. (\'baz/foo-bar\' @ L1:C2) ');

      expectAssertion(function () {
        (0, _index.compile)('{{#if @$}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@$\' is reserved. (\'baz/foo-bar\' @ L1:C6) ');

      expectAssertion(function () {
        (0, _index.compile)('{{input type=(if @$ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, '\'@$\' is reserved. (\'baz/foo-bar\' @ L1:C17) ');
    };

    _class.prototype['@test \'@\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@0\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@0}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @0}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @0 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@1\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@1}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @1}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @1 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@2\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@2}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @2}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @2 "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@@\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@@}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @@}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @@ "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@=\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@=}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @=}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @= "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    _class.prototype['@test \'@!\' is de facto reserved (parse error)'] = function (assert) {
      assert.throws(function () {
        (0, _index.compile)('{{@!}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{#if @!}}Yup{{/if}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);

      assert.throws(function () {
        (0, _index.compile)('{{input type=(if @! "bar" "baz")}}', {
          moduleName: 'baz/foo-bar'
        });
      }, /Expecting 'ID'/);
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});