define('global-admin/global-dns/entries/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return Ember.RSVP.hash({
        allProjects: this.globalStore.findAll('project'),
        allMCApps: this.globalStore.findAll('multiclusterapp')
      });
    },
    model: function model() {
      return this.globalStore.findAll('globaldns');
    }
  });
});