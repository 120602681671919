define('global-admin/security/authentication/keycloak/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    resourceType: 'keyCloakConfig',

    model: function model() {
      var gs = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        authConfig: gs.find('authconfig', 'keycloak', { forceReload: true }),
        principals: gs.all('principal'),
        serverUrl: gs.find('setting', _constants.default.SETTING.SERVER_URL)
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }
  });
});