define('shared/utils/fetch-yaml', ['exports', '@rancher/ember-api-store/utils/ajax-promise'], function (exports, _ajaxPromise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = fetchYaml;
  function fetchYaml(yamlLink) {
    return (0, _ajaxPromise.ajaxPromise)({
      method: 'GET',
      url: yamlLink,
      headers: { Accept: 'application/yaml' }
    }).then(function (data) {
      return data.xhr.responseText;
    });
  }
});