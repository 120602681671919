define('shared/utils/used-percent-gauge', ['exports', 'd3', 'shared/utils/percent-gauge'], function (exports, _d, _percentGauge) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initGraph;


  var OUT_RING_MARGIN = 3;

  function initGraph(options) {
    var _getConfig = (0, _percentGauge.getConfig)(options),
        el = _getConfig.el,
        width = _getConfig.width,
        height = _getConfig.height,
        margin = _getConfig.margin,
        thickness = _getConfig.thickness,
        fontSize = _getConfig.fontSize;

    var svg = (0, _d.select)(el).append('svg').attr('width', width).attr('height', height);

    var value = options.value,
        ticks = options.ticks,
        _options$live = options.live,
        live = _options$live === undefined ? 0 : _options$live,
        liveTicks = options.liveTicks,
        _options$maxValue = options.maxValue,
        maxValue = _options$maxValue === undefined ? 100 : _options$maxValue;


    var currentLive = live;
    var currentLiveTicks = liveTicks;
    var currentMaxValue = maxValue;

    var _addArcValue = addArcValue(svg, width, margin, thickness, options.value, options, live),
        valuePath = _addArcValue.valuePath,
        maxPath = _addArcValue.maxPath,
        liveMaxPath = _addArcValue.liveMaxPath,
        liveValuePath = _addArcValue.liveValuePath;

    var tooltip = addTooltip();

    addTicks(svg, tooltip, width, height, margin, ticks, options.value, thickness, liveTicks, live, currentMaxValue);

    var _addLabels = addLabels(svg, options, width, height, fontSize),
        valueLabel = _addLabels.valueLabel,
        titleLabel = _addLabels.titleLabel,
        subtitleLabel = _addLabels.subtitleLabel,
        liveLabel = _addLabels.liveLabel,
        reservedLabel = _addLabels.reservedLabel;

    return {
      updateTitle: function updateTitle(text) {
        titleLabel.text(text);
      },
      updateSubTitle: function updateSubTitle(text) {
        subtitleLabel.text(text);
      },
      updateLiveLabel: function updateLiveLabel(text) {
        liveLabel.text(text);
      },
      updateValue: function updateValue(reserved, live, maxValue) {
        var _getConfig2 = (0, _percentGauge.getConfig)({ el: el }),
            width = _getConfig2.width,
            height = _getConfig2.height,
            margin = _getConfig2.margin,
            thickness = _getConfig2.thickness;

        svg.attr('width', width).attr('height', height);

        live = live ? live + '%' : '';
        reserved = reserved ? reserved + '%' : '';
        valueLabel.text(live);
        reservedLabel.text(reserved);

        var r = (0, _percentGauge.calcR)(width, margin);
        var outR = (0, _percentGauge.calcR)(width, OUT_RING_MARGIN);

        if (maxValue) {
          currentMaxValue = maxValue;
          maxPath.attr('d', (0, _percentGauge.createArc)(-135, maxValue, outR, Math.round(thickness / 2)));
        }

        if (reserved) {
          value = parseInt(reserved, 10);

          valuePath.attr('d', (0, _percentGauge.createArc)(-135, multiMaxPercent(value, currentMaxValue), outR, Math.round(thickness / 2)));
        }

        if (live) {
          currentLive = parseInt(live, 10);

          liveValuePath.attr('d', (0, _percentGauge.createArc)(-135, currentLive, r, thickness));
        }
      },
      updateTicks: function updateTicks(t, liveTicks, live, maxValue) {
        ticks = t;
        currentLiveTicks = liveTicks;
        repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness, live, liveTicks, maxValue);
      },
      fit: function fit() {
        _fit(svg, el, value, ticks, tooltip, valuePath, maxPath, valueLabel, titleLabel, subtitleLabel, liveMaxPath, liveValuePath, liveLabel, currentLive, currentLiveTicks, currentMaxValue, reservedLabel);
      }
    };
  }

  function _fit(svg, el, value, ticks, tooltip, valuePath, maxPath, valueLabel, titleLabel, subtitleLabel, liveMaxPath, liveValuePath, liveLabel, live, liveTicks, currentMaxValue, reservedLabel) {
    var _getConfig3 = (0, _percentGauge.getConfig)({ el: el }),
        width = _getConfig3.width,
        height = _getConfig3.height,
        margin = _getConfig3.margin,
        thickness = _getConfig3.thickness,
        fontSize = _getConfig3.fontSize;

    svg.attr('width', width).attr('height', height);
    repaintArc(width, margin, value, thickness, valuePath, maxPath, liveMaxPath, liveValuePath, live, currentMaxValue);
    repaintLabels(valueLabel, titleLabel, subtitleLabel, width, height, fontSize, liveLabel, reservedLabel);
    repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness, live, liveTicks, currentMaxValue);
  }

  function repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness, live, liveTicks, currentMaxValue) {
    svg.selectAll('path[tick = "custom"]').remove();
    svg.selectAll('circle[tick = "custom"]').remove();
    addTicks(svg, tooltip, width, height, margin, ticks, value, thickness, liveTicks, live, currentMaxValue);
  }

  function repaintArc(width, margin, value, thickness, valuePath, maxPath, liveMaxPath, liveValuePath, live, currentMaxValue) {
    var r = (0, _percentGauge.calcR)(width, margin);

    var outR = (0, _percentGauge.calcR)(width, OUT_RING_MARGIN);

    liveValuePath.attr('d', (0, _percentGauge.createArc)(-135, live, r, thickness)).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
    liveMaxPath.attr('d', (0, _percentGauge.createArc)(-135, 100, r, thickness)).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
    maxPath.attr('d', (0, _percentGauge.createArc)(-135, currentMaxValue, outR, Math.round(thickness / 2))).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
    valuePath.attr('d', (0, _percentGauge.createArc)(-135, multiMaxPercent(value, currentMaxValue), outR, Math.round(thickness / 2))).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
  }

  function addTooltip() {
    var tooltip = (0, _d.select)('#percent-gauge-tooltip');

    if (tooltip.empty()) {
      tooltip = (0, _d.select)('body').append('div').attr('class', 'hover-label').attr('class', 'percent-gauge-tooltip').attr('id', 'percent-gauge-tooltip').style('opacity', 0);
    }

    return tooltip;
  }

  function addArcValue(svg, width, margin, thickness, value, options, live) {
    var liveMaxPath = (0, _percentGauge.addArc)(svg, width, margin, thickness, 'gauge-max-path', 100);
    var liveValuePath = (0, _percentGauge.addArc)(svg, width, margin, thickness, 'gauge-live-value-path', live > 100 ? 100 : live);
    var maxPath = (0, _percentGauge.addArc)(svg, width, OUT_RING_MARGIN, Math.round(thickness / 2), 'gauge-max-path', options.maxValue);
    var valuePath = (0, _percentGauge.addArc)(svg, width, OUT_RING_MARGIN, Math.round(thickness / 2), 'gauge-value-path', value > 100 ? 100 : value);

    return {
      valuePath: valuePath,
      maxPath: maxPath,
      liveMaxPath: liveMaxPath,
      liveValuePath: liveValuePath
    };
  }

  function getValueLabelY(height, fontSize) {
    return height / 5 + fontSize / 1.2;
  }

  function getLiveLabelY(height, fontSize) {
    return height / 5 + 1.7 * fontSize;
  }

  function getReservedLabelY(height, fontSize) {
    return height / 5 + 2.5 * fontSize;
  }

  function getSubtitleLabelY(height, fontSize) {
    return height / 5 + 3.1 * fontSize;
  }

  function getTitleLabelY(height, fontSize) {
    return height / 5 + 4.3 * fontSize;
  }

  function addLabels(svg, options, width, height, fontSize) {
    return {
      valueLabel: (0, _percentGauge.addText)(options.live ? options.live + '%' : '0%', svg, width / 2, getValueLabelY(height, fontSize), fontSize, 'usedPercent', 3.5),
      reservedLabel: (0, _percentGauge.addText)(options.value ? options.value + '%' : '0%', svg, width / 2, getReservedLabelY(height, fontSize), fontSize / 2, 'reservedPercent', 2),
      titleLabel: (0, _percentGauge.addText)(options.title, svg, width / 2, getTitleLabelY(height, fontSize), fontSize / 3, 'title'),
      subtitleLabel: (0, _percentGauge.addText)(options.subtitle, svg, width / 2, getSubtitleLabelY(height, fontSize), fontSize / 3, 'subtitle'),
      liveLabel: (0, _percentGauge.addText)(options.liveTitle, svg, width / 2, getLiveLabelY(height, fontSize), fontSize / 3, 'liveLabel')
    };
  }

  function repaintLabels(valueLabel, titleLabel, subtitleLabel, width, height, fontSize, liveLabel, reservedLabel) {
    valueLabel.attr('x', width / 2).attr('y', getValueLabelY(height, fontSize)).attr('dy', fontSize / 2).style('font-size', fontSize + 'px');
    reservedLabel.attr('x', width / 2).attr('y', getReservedLabelY(height, fontSize)).attr('dy', fontSize / 4).style('font-size', fontSize / 2 + 'px');
    titleLabel.attr('x', width / 2).attr('y', getTitleLabelY(height, fontSize)).attr('dy', fontSize / 6).style('font-size', fontSize / 3 + 'px');
    subtitleLabel.attr('x', width / 2).attr('y', getSubtitleLabelY(height, fontSize)).attr('dy', fontSize / 6).style('font-size', fontSize / 3 + 'px');
    liveLabel.attr('x', width / 2).attr('y', getLiveLabelY(height, fontSize)).attr('dy', fontSize / 6).style('font-size', fontSize / 3 + 'px');
  }

  function addTicks(svg, tooltip, width, height, margin, ticks, currentValue, thickness) {
    var liveTicks = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : [];
    var live = arguments[9];
    var currentMaxValue = arguments[10];

    var _getRange = (0, _percentGauge.getRange)(ticks),
        max = _getRange.max,
        min = _getRange.min;

    var liveMax = (0, _percentGauge.getRange)(liveTicks).max;
    var liveMin = (0, _percentGauge.getRange)(liveTicks).min;

    (ticks || []).forEach(function (tick) {
      if (tick.value !== 0 && !tick.value) {
        return;
      }

      var value = parseInt(tick.value, 10);

      if (value !== max && value !== min || max === min) {
        return;
      }
      if (min === value && value === currentValue && value !== 0) {
        value -= 1;
      }
      if (max === value && value === currentValue && value !== 100) {
        value += 1;
      }

      var point = (0, _percentGauge.valueToPoint)(width, height, OUT_RING_MARGIN, multiMaxPercent(value, currentMaxValue), thickness / 2);

      if (ticks.length > 1) {
        var tr = '';

        tick.labels.forEach(function (label) {
          tr += '<tr>\n                  <td>' + label + '</td>\n                  <td>' + tick.value + '%</td>\n                </tr>';
        });

        svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-circle-fill').attr('cx', point.x).attr('cy', point.y).attr('r', 3);

        var tp = svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-none-fill').attr('cx', point.x).attr('cy', point.y).attr('r', thickness / 4);

        tp.on('mouseover', function () {
          tooltip.transition().duration(200).style('opacity', .9);
          tooltip.html('<table>\n                        <tbody>\n                          <tr>\n                            <th>' + window.l('service:intl').t('clusterDashboard.node') + '</th>\n                            <th>' + window.l('service:intl').t('clusterDashboard.reserved') + '</th>\n                          </tr>\n                          ' + tr + '\n                        </tbody>\n                      </table>').style('left', _d.event.pageX - 30 + 'px').style('top', _d.event.pageY - 30 * (tick.labels.length + 1) + 'px');
        }).on('mouseout', function () {
          tooltip.transition().duration(500).style('opacity', 0);
        });
      }
    });

    (liveTicks || []).map(function (tick) {
      if (tick.value !== 0 && !tick.value) {
        return;
      }
      var value = parseInt(tick.value, 10);

      if (value !== liveMax && value !== liveMin || liveMax === liveMin) {
        return;
      }
      if (liveMin === value && value === live && value !== 0) {
        value -= 1;
      }
      if (liveMax === value && value === live && value !== 100) {
        value += 1;
      }
      var point = (0, _percentGauge.valueToPoint)(width, height, margin, value, thickness);

      if (liveTicks.length > 1) {
        var tr = '';

        tr += '<tr>\n                <td>' + tick.label + '</td>\n                <td>' + tick.value + '%</td>\n              </tr>';

        svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-circle-fill').attr('cx', point.x).attr('cy', point.y).attr('r', 3);

        var tp = svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-none-fill').attr('cx', point.x).attr('cy', point.y).attr('r', thickness / 2);

        tp.on('mouseover', function () {
          tooltip.transition().duration(200).style('opacity', .9);
          tooltip.html('<table>\n                        <tbody>\n                          <tr>\n                            <th>' + window.l('service:intl').t('clusterDashboard.node') + '</th>\n                            <th>' + window.l('service:intl').t('clusterDashboard.used') + '</th>\n                          </tr>\n                          ' + tr + '\n                        </tbody>\n                      </table>').style('left', _d.event.pageX - 30 + 'px').style('top', _d.event.pageY - 30 * 2 + 'px');
        }).on('mouseout', function () {
          tooltip.transition().duration(500).style('opacity', 0);
        });
      }
    });

    if (liveTicks && liveTicks.length > 1 && liveMax - liveMin >= 1) {
      var rangePath = void 0;

      if (liveMin === live && liveMin !== 0) {
        liveMin -= 1;
      }
      if (liveMax === live && liveMax !== 100) {
        liveMax += 1;
      }
      rangePath = (0, _percentGauge.addArc)(svg, width, margin, thickness, 'gauge-tick-path', liveMax, liveMin, 2);

      rangePath.attr('tick', 'custom');
    }

    if (ticks && ticks.length > 1 && max - min >= 1) {
      var _rangePath = void 0;

      if (min === currentValue && min !== 0) {
        min -= 1;
      }
      if (max === currentValue && max !== 100) {
        max += 1;
      }
      _rangePath = (0, _percentGauge.addArc)(svg, width, OUT_RING_MARGIN, thickness / 2, 'gauge-tick-path', multiMaxPercent(max, currentMaxValue), multiMaxPercent(min, currentMaxValue), 2);

      _rangePath.attr('tick', 'custom');
    }
  }

  function multiMaxPercent(value) {
    var maxValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;

    return Math.round(value * maxValue / 100);
  }
});