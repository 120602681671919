define('shared/growl/service', ['exports', 'ui/utils/errors', 'shared/utils/util', 'jgrowl'], function (exports, _errors, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    app: Ember.inject.service(),
    intl: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.initjGrowlDefaults();
    },
    initjGrowlDefaults: function initjGrowlDefaults() {
      var defaults = $.jGrowl.defaults;


      Ember.setProperties(defaults, {
        pool: 6,
        closeTemplate: '<i class="icon icon-x"></i>',
        closerTemplate: '<div><button type="button" class="btn bg-info btn-sm btn-block">Dismiss All Notifications</button></div>'
      });
    },
    close: function close() {
      $("div.jGrowl").jGrowl("close"); // eslint-disable-line
    },
    raw: function raw(title, body, opt) {
      opt = opt || {};

      if (title) {
        opt.header = title;
      }

      return $.jGrowl((0, _util.escapeHtml)(body), opt);
    },
    success: function success(title, body) {
      this.raw(title, body, { theme: 'success' });
    },
    message: function message(title, body) {
      this.raw(title, body, { theme: 'message' });
    },
    error: function error(title, body) {
      this.raw(title, body, {
        sticky: true,
        theme: 'error'
      });
    },
    fromError: function fromError(title, err) {
      if (!err && (typeof title === 'undefined' ? 'undefined' : _typeof(title)) === 'object') {
        err = title;
        title = 'Error';
      }

      var body = _errors.default.stringify(err);

      this.error(title, body);
    }
  });
});