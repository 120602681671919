enifed('ember/index', ['exports', 'require', 'ember-environment', 'node-module', 'ember-utils', 'container', '@ember/instrumentation', 'ember-meta', 'ember-metal', '@ember/canary-features', '@ember/debug', 'backburner', 'ember-console', '@ember/controller', '@ember/controller/lib/controller_mixin', '@ember/string', '@ember/service', '@ember/object/computed', 'ember-runtime', 'ember-glimmer', 'ember/version', 'ember-views', 'ember-routing', 'ember-extension-support', '@ember/error', '@ember/runloop', 'ember-error-handling', 'ember-owner', '@ember/application', '@ember/application/globals-resolver', '@ember/application/instance', '@ember/engine', '@ember/engine/instance', '@ember/map', '@ember/map/with-default', '@ember/map/lib/ordered-set', '@ember/polyfills', '@ember/deprecated-features'], function (exports, _require2, _emberEnvironment, _nodeModule, _emberUtils, _container, _instrumentation, _emberMeta, _emberMetal, _canaryFeatures, _debug, _backburner, _emberConsole, _controller, _controller_mixin, _string, _service, _computed, _emberRuntime, _emberGlimmer, _version, _emberViews, _emberRouting, _emberExtensionSupport, _error, _runloop, _emberErrorHandling, _emberOwner, _application, _globalsResolver, _instance, _engine, _instance2, _map, _withDefault, _orderedSet, _polyfills, _deprecatedFeatures) {
  'use strict';

  // ****ember-environment****

  // eslint-disable-next-line import/no-unresolved

  var Ember = typeof _emberEnvironment.context.imports.Ember === 'object' && _emberEnvironment.context.imports.Ember || {},
      testing;

  Ember.isNamespace = true;
  Ember.toString = function () {
    return 'Ember';
  };

  Object.defineProperty(Ember, 'ENV', {
    get: _emberEnvironment.getENV,
    enumerable: false
  });

  Object.defineProperty(Ember, 'lookup', {
    get: _emberEnvironment.getLookup,
    set: _emberEnvironment.setLookup,
    enumerable: false
  });

  if (_deprecatedFeatures.EMBER_EXTEND_PROTOTYPES) {
    Object.defineProperty(Ember, 'EXTEND_PROTOTYPES', {
      enumerable: false,
      get: function () {
        false && !false && (0, _debug.deprecate)('Accessing Ember.EXTEND_PROTOTYPES is deprecated, please migrate to Ember.ENV.EXTEND_PROTOTYPES', false, {
          id: 'ember-env.old-extend-prototypes',
          until: '4.0.0'
        });

        return _emberEnvironment.ENV.EXTEND_PROTOTYPES;
      }
    });
  }

  // ****@ember/application****
  Ember.getOwner = _emberOwner.getOwner;
  Ember.setOwner = _emberOwner.setOwner;
  Ember.Application = _application.default;
  Ember.DefaultResolver = Ember.Resolver = _globalsResolver.default;
  Ember.ApplicationInstance = _instance.default;

  // ****@ember/engine****
  Ember.Engine = _engine.default;
  Ember.EngineInstance = _instance2.default;

  // ****@ember/map****
  Ember.OrderedSet = _orderedSet.default;
  Ember.__OrderedSet__ = _orderedSet.__OrderedSet__;
  Ember.Map = _map.default;
  Ember.MapWithDefault = _withDefault.default;

  // ****@ember/polyfills****
  Ember.assign = _polyfills.assign;
  Ember.merge = _polyfills.merge;

  // ****ember-utils****
  Ember.generateGuid = _emberUtils.generateGuid;
  Ember.GUID_KEY = _emberUtils.GUID_KEY;
  Ember.guidFor = _emberUtils.guidFor;
  Ember.inspect = _emberUtils.inspect;
  Ember.makeArray = _emberUtils.makeArray;
  Ember.canInvoke = _emberUtils.canInvoke;
  Ember.tryInvoke = _emberUtils.tryInvoke;
  Ember.wrap = _emberUtils.wrap;
  Ember.uuid = _emberUtils.uuid;
  Ember.NAME_KEY = _emberUtils.NAME_KEY;
  Ember._Cache = _emberUtils.Cache;

  // ****container****
  Ember.Container = _container.Container;
  Ember.Registry = _container.Registry;

  // ****@ember/debug****
  Ember.assert = _debug.assert;
  Ember.warn = _debug.warn;
  Ember.debug = _debug.debug;
  Ember.deprecate = _debug.deprecate;
  Ember.deprecateFunc = _debug.deprecateFunc;
  Ember.runInDebug = _debug.runInDebug;

  // ****@ember/error****
  Ember.Error = _error.default;

  /**
    @public
    @class Ember.Debug
  */
  Ember.Debug = {
    registerDeprecationHandler: _debug.registerDeprecationHandler,
    registerWarnHandler: _debug.registerWarnHandler
  };

  // ****@ember/instrumentation****
  Ember.instrument = _instrumentation.instrument;
  Ember.subscribe = _instrumentation.subscribe;
  Ember.Instrumentation = {
    instrument: _instrumentation.instrument,
    subscribe: _instrumentation.subscribe,
    unsubscribe: _instrumentation.unsubscribe,
    reset: _instrumentation.reset
  };

  // ****@ember/runloop****

  // Using _globalsRun here so that mutating the function (adding
  // `next`, `later`, etc to it) is only available in globals builds
  Ember.run = _runloop._globalsRun;
  Ember.run.backburner = _runloop.backburner;
  Ember.run.begin = _runloop.begin;
  Ember.run.bind = _runloop.bind;
  Ember.run.cancel = _runloop.cancel;
  Ember.run.debounce = _runloop.debounce;
  Ember.run.end = _runloop.end;
  Ember.run.hasScheduledTimers = _runloop.hasScheduledTimers;
  Ember.run.join = _runloop.join;
  Ember.run.later = _runloop.later;
  Ember.run.next = _runloop.next;
  Ember.run.once = _runloop.once;
  Ember.run.schedule = _runloop.schedule;
  Ember.run.scheduleOnce = _runloop.scheduleOnce;
  Ember.run.throttle = _runloop.throttle;
  Ember.run.cancelTimers = _runloop.cancelTimers;
  Object.defineProperty(Ember.run, 'currentRunLoop', {
    get: _runloop.getCurrentRunLoop,
    enumerable: false
  });

  // ****ember-metal****

  // Using _globalsComputed here so that mutating the function is only available
  // in globals builds
  var computed = _emberMetal._globalsComputed;
  Ember.computed = computed;
  computed.alias = _emberMetal.alias;
  Ember.ComputedProperty = _emberMetal.ComputedProperty;
  Ember.cacheFor = _emberMetal.getCachedValueFor;
  Ember.meta = _emberMeta.meta;
  Ember.get = _emberMetal.get;
  Ember.getWithDefault = _emberMetal.getWithDefault;
  Ember._getPath = _emberMetal._getPath;
  Ember.set = _emberMetal.set;
  Ember.trySet = _emberMetal.trySet;
  Ember.FEATURES = (0, _polyfills.assign)({ isEnabled: _canaryFeatures.isEnabled }, _canaryFeatures.FEATURES);
  Ember._Cache = _emberUtils.Cache;
  Ember.on = _emberMetal.on;
  Ember.addListener = _emberMetal.addListener;
  Ember.removeListener = _emberMetal.removeListener;
  Ember.sendEvent = _emberMetal.sendEvent;
  Ember.hasListeners = _emberMetal.hasListeners;
  Ember.isNone = _emberMetal.isNone;
  Ember.isEmpty = _emberMetal.isEmpty;
  Ember.isBlank = _emberMetal.isBlank;
  Ember.isPresent = _emberMetal.isPresent;
  if (_deprecatedFeatures.PROPERTY_WILL_CHANGE) {
    Ember.propertyWillChange = _emberMetal.propertyWillChange;
  }
  if (_deprecatedFeatures.PROPERTY_DID_CHANGE) {
    Ember.propertyDidChange = _emberMetal.propertyDidChange;
  }
  Ember.notifyPropertyChange = _emberMetal.notifyPropertyChange;
  Ember.overrideChains = _emberMetal.overrideChains;
  Ember.beginPropertyChanges = _emberMetal.beginPropertyChanges;
  Ember.endPropertyChanges = _emberMetal.endPropertyChanges;
  Ember.changeProperties = _emberMetal.changeProperties;
  Ember.platform = {
    defineProperty: true,
    hasPropertyAccessors: true
  };
  Ember.defineProperty = _emberMetal.defineProperty;
  Ember.watchKey = _emberMetal.watchKey;
  Ember.unwatchKey = _emberMetal.unwatchKey;
  Ember.removeChainWatcher = _emberMetal.removeChainWatcher;
  Ember._ChainNode = _emberMetal.ChainNode;
  Ember.finishChains = _emberMetal.finishChains;
  Ember.watchPath = _emberMetal.watchPath;
  Ember.unwatchPath = _emberMetal.unwatchPath;
  Ember.watch = _emberMetal.watch;
  Ember.isWatching = _emberMetal.isWatching;
  Ember.unwatch = _emberMetal.unwatch;
  Ember.destroy = _emberMeta.deleteMeta;
  Ember.libraries = _emberMetal.libraries;
  Ember.getProperties = _emberMetal.getProperties;
  Ember.setProperties = _emberMetal.setProperties;
  Ember.expandProperties = _emberMetal.expandProperties;
  Ember.addObserver = _emberMetal.addObserver;
  Ember.removeObserver = _emberMetal.removeObserver;
  Ember.aliasMethod = _emberMetal.aliasMethod;
  Ember.observer = _emberMetal.observer;
  Ember.mixin = _emberMetal.mixin;
  Ember.Mixin = _emberMetal.Mixin;

  /**
    A function may be assigned to `Ember.onerror` to be called when Ember
    internals encounter an error. This is useful for specialized error handling
    and reporting code.
  
    ```javascript
    import $ from 'jquery';
  
    Ember.onerror = function(error) {
      $.ajax('/report-error', 'POST', {
        stack: error.stack,
        otherInformation: 'whatever app state you want to provide'
      });
    };
    ```
  
    Internally, `Ember.onerror` is used as Backburner's error handler.
  
    @event onerror
    @for Ember
    @param {Exception} error the error object
    @public
  */
  Object.defineProperty(Ember, 'onerror', {
    get: _emberErrorHandling.getOnerror,
    set: _emberErrorHandling.setOnerror,
    enumerable: false
  });

  Object.defineProperty(Ember, 'testing', {
    get: _debug.isTesting,
    set: _debug.setTesting,
    enumerable: false
  });

  Ember._Backburner = _backburner.default;

  // ****ember-console****
  if (_deprecatedFeatures.LOGGER) {
    Ember.Logger = _emberConsole.default;
  }

  // ****ember-runtime****
  Ember.A = _emberRuntime.A;
  Ember.String = {
    loc: _string.loc,
    w: _string.w,
    dasherize: _string.dasherize,
    decamelize: _string.decamelize,
    camelize: _string.camelize,
    classify: _string.classify,
    underscore: _string.underscore,
    capitalize: _string.capitalize
  };
  Ember.Object = _emberRuntime.Object;
  Ember._RegistryProxyMixin = _emberRuntime.RegistryProxyMixin;
  Ember._ContainerProxyMixin = _emberRuntime.ContainerProxyMixin;
  Ember.compare = _emberRuntime.compare;
  Ember.copy = _emberRuntime.copy;
  Ember.isEqual = _emberRuntime.isEqual;

  /**
  @module ember
  */

  /**
    Namespace for injection helper methods.
  
    @class inject
    @namespace Ember
    @static
    @public
  */
  Ember.inject = function inject() {
    false && !false && (0, _debug.assert)('Injected properties must be created through helpers, see \'' + Object.keys(inject).map(function (k) {
      return '\'inject.' + k + '\'';
    }).join(' or ') + '\'');
  };
  Ember.inject.service = _service.inject;
  Ember.inject.controller = _controller.inject;

  Ember.Array = _emberRuntime.Array;
  Ember.Comparable = _emberRuntime.Comparable;
  Ember.Enumerable = _emberRuntime.Enumerable;
  Ember.ArrayProxy = _emberRuntime.ArrayProxy;
  Ember.ObjectProxy = _emberRuntime.ObjectProxy;
  Ember.ActionHandler = _emberRuntime.ActionHandler;
  Ember.CoreObject = _emberRuntime.CoreObject;
  Ember.NativeArray = _emberRuntime.NativeArray;
  Ember.Copyable = _emberRuntime.Copyable;
  Ember.MutableEnumerable = _emberRuntime.MutableEnumerable;
  Ember.MutableArray = _emberRuntime.MutableArray;
  Ember.TargetActionSupport = _emberRuntime.TargetActionSupport;
  Ember.Evented = _emberRuntime.Evented;
  Ember.PromiseProxyMixin = _emberRuntime.PromiseProxyMixin;
  Ember.Observable = _emberRuntime.Observable;
  Ember.typeOf = _emberRuntime.typeOf;
  Ember.isArray = _emberRuntime.isArray;
  Ember.Object = _emberRuntime.Object;
  Ember.onLoad = _application.onLoad;
  Ember.runLoadHooks = _application.runLoadHooks;
  Ember.Controller = _controller.default;
  Ember.ControllerMixin = _controller_mixin.default;
  Ember.Service = _service.default;
  Ember._ProxyMixin = _emberRuntime._ProxyMixin;
  Ember.RSVP = _emberRuntime.RSVP;
  Ember.Namespace = _emberRuntime.Namespace;

  computed.empty = _computed.empty;
  computed.notEmpty = _computed.notEmpty;
  computed.none = _computed.none;
  computed.not = _computed.not;
  computed.bool = _computed.bool;
  computed.match = _computed.match;
  computed.equal = _computed.equal;
  computed.gt = _computed.gt;
  computed.gte = _computed.gte;
  computed.lt = _computed.lt;
  computed.lte = _computed.lte;
  computed.oneWay = _computed.oneWay;
  computed.reads = _computed.oneWay;
  computed.readOnly = _computed.readOnly;
  computed.deprecatingAlias = _computed.deprecatingAlias;
  computed.and = _computed.and;
  computed.or = _computed.or;

  computed.sum = _computed.sum;
  computed.min = _computed.min;
  computed.max = _computed.max;
  computed.map = _computed.map;
  computed.sort = _computed.sort;
  computed.setDiff = _computed.setDiff;
  computed.mapBy = _computed.mapBy;
  computed.filter = _computed.filter;
  computed.filterBy = _computed.filterBy;
  computed.uniq = _computed.uniq;

  computed.uniqBy = _computed.uniqBy;
  computed.union = _computed.union;
  computed.intersect = _computed.intersect;
  computed.collect = _computed.collect;

  /**
    Defines the hash of localized strings for the current language. Used by
    the `String.loc` helper. To localize, add string values to this
    hash.
  
    @property STRINGS
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(Ember, 'STRINGS', {
    configurable: false,
    get: _string._getStrings,
    set: _string._setStrings
  });

  /**
    Whether searching on the global for new Namespace instances is enabled.
  
    This is only exported here as to not break any addons.  Given the new
    visit API, you will have issues if you treat this as a indicator of
    booted.
  
    Internally this is only exposing a flag in Namespace.
  
    @property BOOTED
    @for Ember
    @type Boolean
    @private
  */
  Object.defineProperty(Ember, 'BOOTED', {
    configurable: false,
    enumerable: false,
    get: _emberMetal.isNamespaceSearchDisabled,
    set: _emberMetal.setNamespaceSearchDisabled
  });

  // ****ember-glimmer****
  Ember.Component = _emberGlimmer.Component;
  _emberGlimmer.Helper.helper = _emberGlimmer.helper;
  Ember.Helper = _emberGlimmer.Helper;
  Ember.Checkbox = _emberGlimmer.Checkbox;
  Ember.TextField = _emberGlimmer.TextField;
  Ember.TextArea = _emberGlimmer.TextArea;
  Ember.LinkComponent = _emberGlimmer.LinkComponent;
  Ember._setComponentManager = _emberGlimmer.setComponentManager;
  Ember._componentManagerCapabilities = _emberGlimmer.capabilities;
  Ember.Handlebars = {
    template: _emberGlimmer.template,
    Utils: {
      escapeExpression: _emberGlimmer.escapeExpression
    }
  };
  Ember.HTMLBars = {
    template: _emberGlimmer.template
  };

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.String) {
    String.prototype.htmlSafe = function () {
      return (0, _emberGlimmer.htmlSafe)(this);
    };
  }
  Ember.String.htmlSafe = _emberGlimmer.htmlSafe;
  Ember.String.isHTMLSafe = _emberGlimmer.isHTMLSafe;

  /**
    Global hash of shared templates. This will automatically be populated
    by the build tools so that you can store your Handlebars templates in
    separate files that get loaded into JavaScript at buildtime.
  
    @property TEMPLATES
    @for Ember
    @type Object
    @private
  */
  Object.defineProperty(Ember, 'TEMPLATES', {
    get: _emberGlimmer.getTemplates,
    set: _emberGlimmer.setTemplates,
    configurable: false,
    enumerable: false
  });

  /**
    The semantic version
  
    @property VERSION
    @type String
    @public
  */
  Ember.VERSION = _version.default;

  // ****ember-views****
  if (!_emberViews.jQueryDisabled) {
    Ember.$ = _emberViews.jQuery;
  }
  Ember.ViewUtils = {
    isSimpleClick: _emberViews.isSimpleClick,
    getViewElement: _emberViews.getViewElement,
    getViewBounds: _emberViews.getViewBounds,
    getViewClientRects: _emberViews.getViewClientRects,
    getViewBoundingClientRect: _emberViews.getViewBoundingClientRect,
    getRootViews: _emberViews.getRootViews,
    getChildViews: _emberViews.getChildViews,
    isSerializationFirstNode: _emberGlimmer.isSerializationFirstNode
  };
  Ember.TextSupport = _emberViews.TextSupport;
  Ember.ComponentLookup = _emberViews.ComponentLookup;
  Ember.EventDispatcher = _emberViews.EventDispatcher;

  // ****ember-routing****
  Ember.Location = _emberRouting.Location;
  Ember.AutoLocation = _emberRouting.AutoLocation;
  Ember.HashLocation = _emberRouting.HashLocation;
  Ember.HistoryLocation = _emberRouting.HistoryLocation;
  Ember.NoneLocation = _emberRouting.NoneLocation;
  Ember.controllerFor = _emberRouting.controllerFor;
  Ember.generateControllerFactory = _emberRouting.generateControllerFactory;
  Ember.generateController = _emberRouting.generateController;
  Ember.RouterDSL = _emberRouting.RouterDSL;
  Ember.Router = _emberRouting.Router;
  Ember.Route = _emberRouting.Route;

  (0, _application.runLoadHooks)('Ember.Application', _application.default);

  Ember.DataAdapter = _emberExtensionSupport.DataAdapter;
  Ember.ContainerDebugAdapter = _emberExtensionSupport.ContainerDebugAdapter;

  if ((0, _require2.has)('ember-template-compiler')) {
    (0, _require2.default)('ember-template-compiler');
  }

  // do this to ensure that Ember.Test is defined properly on the global
  // if it is present.
  if ((0, _require2.has)('ember-testing')) {
    testing = (0, _require2.default)('ember-testing');


    Ember.Test = testing.Test;
    Ember.Test.Adapter = testing.Adapter;
    Ember.Test.QUnitAdapter = testing.QUnitAdapter;
    Ember.setupForTesting = testing.setupForTesting;
  }

  (0, _application.runLoadHooks)('Ember');

  exports.default = Ember;

  if (_nodeModule.IS_NODE) {
    _nodeModule.module.exports = Ember;
  } else {
    _emberEnvironment.context.exports.Ember = _emberEnvironment.context.exports.Em = Ember;
  }

  /**
   @module jquery
   @public
   */

  /**
   @class jquery
   @public
   @static
   */

  /**
    Alias for jQuery
  
    @for jquery
    @method $
    @static
    @public
  */
});