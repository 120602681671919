enifed('ember-template-compiler/lib/plugins/deprecate-render-model', ['exports', '@ember/debug', '@ember/deprecated-features', 'ember-template-compiler/lib/system/calculate-location-display'], function (exports, _debug, _deprecatedFeatures, _calculateLocationDisplay) {
    'use strict';

    exports.default =

    // Remove after 3.4 once _ENABLE_RENDER_SUPPORT flag is no longer needed.
    function (env) {
        var moduleName, deprecationMessage;

        if (_deprecatedFeatures.RENDER_HELPER) {
            moduleName = env.meta.moduleName;
            deprecationMessage = function (node, param) {
                var sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
                var componentName = node.params[0].original;
                var modelName = param.original;

                return 'Please refactor `' + ('{{render "' + componentName + '" ' + modelName + '}}') + '` to a component and invoke via' + (' `' + ('{{' + componentName + ' model=' + modelName + '}}') + '`. ' + sourceInformation);
            };

            return {
                name: 'deprecate-render-model',
                visitor: {
                    MustacheStatement: function (node) {
                        if (node.path.original === 'render' && node.params.length > 1) {
                            node.params.forEach(function (param) {
                                if (param.type !== 'PathExpression') {
                                    return;
                                }
                                true && !false && (0, _debug.deprecate)(deprecationMessage(node, param), false, {
                                    id: 'ember-template-compiler.deprecate-render-model',
                                    until: '3.0.0',
                                    url: 'https://emberjs.com/deprecations/v2.x#toc_model-param-in-code-render-code-helper'
                                });
                            });
                        }
                    }
                }
            };
        }
        return undefined;
    };
});