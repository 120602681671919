define('nodes/engine', ['exports', 'ember-engines/engine', 'ember-load-initializers', 'nodes/resolver', 'nodes/config/environment'], function (exports, _engine, _emberLoadInitializers, _resolver, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modulePrefix = _environment.default.modulePrefix;


  var Eng = _engine.default.extend({
    modulePrefix: modulePrefix,
    Resolver: _resolver.default,
    dependencies: {
      services: ['access', 'app', 'azureAd', 'catalog', 'clusterStore', 'digitalOcean', 'endpoint', 'github', 'globalStore', 'intl', 'modal', 'resource-actions', 'router', 'scope', 'session', 'settings', 'store', 'tooltip', 'user-language', 'user-theme'],
      externalRoutes: ['index', 'failWhale', 'authenticated', 'authenticated.cluster', 'authenticated.cluster.projects', 'authenticated.project', 'authenticated.prefs', 'authenticated.cluster.nodes', 'authenticated.cluster.security.members.index', 'global-admin.security.cloud-credentials', 'logout']
    }
  });

  (0, _emberLoadInitializers.default)(Eng, modulePrefix);

  exports.default = Eng;
});