define('global-admin/components/modal-revert-setting/component', ['exports', 'shared/mixins/modal-base', 'global-admin/components/modal-revert-setting/template'], function (exports, _modalBase, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'medium-modal'],
    primaryResource: Ember.computed.alias('modalOpts.setting'),
    setting: Ember.computed.alias('primaryResource'),
    kind: Ember.computed.alias('modalOpts.kind'),

    actions: {
      save: function save(btnCb) {
        var _this = this;

        var pr = Ember.get(this, 'primaryResource');

        Ember.set(pr, 'value', Ember.get(pr, 'default') || '');

        pr.save().then(function () {
          btnCb(true);
          _this.send('done');
        }).catch(function (err) {
          btnCb(err);
        });
      },
      done: function done() {
        this.send('cancel');
      }
    }

  });
});