define('global-admin/security/authentication/freeipa/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    resourceType: 'freeipaconfig',
    model: function model() {
      var gs = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        freeipaConfig: gs.find('authconfig', 'freeipa', { forceReload: true }),
        principals: gs.all('principal')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: this.get('session.orgs') || [],
        errors: null
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }
  });
});