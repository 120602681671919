define('shared/components/marked-down/component', ['exports', 'shared/components/marked-down/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Marked = null;
  var DOMPurify = null;

  exports.default = Ember.Component.extend({
    layout: _template.default,
    markdown: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!Marked) {
        emberAutoImportDynamic('marked').then(function (module) {
          Marked = module.default;
          emberAutoImportDynamic('dompurify').then(function (module) {
            DOMPurify = module.default;
            _this.notifyPropertyChange('parsedMarkdown');
          });
        });
      }
    },

    parsedMarkdown: Ember.computed('markdown', function () {
      if (!Marked) {
        return '';
      }

      var html = Marked(Ember.get(this, 'markdown'), { breaks: true });

      return Ember.String.htmlSafe(DOMPurify.sanitize(html));
    })

  });
});