define('shared/mixins/preload', ['exports', 'shared/utils/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    access: Ember.inject.service(),
    growl: Ember.inject.service(),

    preload: function preload(type) {
      var storeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'store';
      var opt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      return Ember.get(this, storeName).find(type, null, opt);
    },
    loadSchemas: function loadSchemas(storeName) {
      var store = Ember.get(this, storeName);

      store.resetType('schema');

      return store.rawRequest({
        url: 'schema',
        dataType: 'json'
      }).then(function (xhr) {
        store._state.foundAll['schema'] = true;
        store._bulkAdd('schema', xhr.body.data);
      });
    },
    loadingError: function loadingError(err, transition) {
      var isAuthFail = err && err.status && [401, 403].includes(err.status);

      var msg = _errors.default.stringify(err);

      console.log('Loading Error:', msg, err);
      if (isAuthFail) {
        Ember.set(this, 'access.enabled', true);
        transition.send('logout');
      } else {
        Ember.get(this, 'growl').fromError(err);
        this.replaceWith('global-admin.clusters');
      }
    }
  });
});