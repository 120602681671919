enifed('ember-template-compiler/lib/system/dasherize-component-name', ['exports', 'ember-utils'], function (exports, _emberUtils) {
    'use strict';

    /*
      This diverges from `Ember.String.dasherize` so that`<XFoo />` can resolve to `x-foo`.
      `Ember.String.dasherize` would resolve it to `xfoo`..
    */

    var SIMPLE_DASHERIZE_REGEXP = /[A-Z]/g;
    var ALPHA = /[A-Za-z]/;
    exports.default = new _emberUtils.Cache(1000, function (key) {
        return key.replace(SIMPLE_DASHERIZE_REGEXP, function (char, index) {
            if (index === 0 || !ALPHA.test(key[index - 1])) {
                return char.toLowerCase();
            }
            return '-' + char.toLowerCase();
        });
    });
});