define('shared/tooltip/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DELAY = 250;

  exports.default = Ember.Service.extend({
    app: Ember.inject.service(),

    mouseLeaveTimer: null,
    requireClick: false,
    tooltipOpts: null,
    openedViaContextClick: false,
    childOpened: false,

    startTimer: function startTimer() {
      var _this = this;

      this.set('mouseLeaveTimer', Ember.run.later(function () {
        _this.hide();
      }, DELAY));
    },
    cancelTimer: function cancelTimer() {
      Ember.run.cancel(this.get('mouseLeaveTimer'));
    },
    hide: function hide() {
      if (!Ember.get(this, 'childOpened')) {
        this.set('tooltipOpts', null);
      }
    },
    leave: function leave() {
      if (!this.get('requireClick')) {
        this.startTimer();
      }
    }
  });
});