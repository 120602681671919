define('global-admin/clusters/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['provider'],

    provider: 'googlegke',
    cluster: Ember.computed.alias('model.cluster'),

    actions: {
      close: function close() {
        this.transitionToRoute('clusters.index');
      }
    }
  });
});