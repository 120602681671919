define('global-admin/mixins/authentication', ['exports', 'ui/utils/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    isEnabled: Ember.computed('authConfig.enabled', 'testing', function () {
      var testing = Ember.get(this, 'testing');
      var enabled = Ember.get(this, 'authConfig.enabled');

      return enabled && !testing;
    }),

    editing: false,
    authConfig: null,

    actions: {
      edit: function edit() {
        Ember.set(this, 'editing', true);
      },
      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line

        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },
      gotError: function gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? '(' + err.detail + ')' : ''));
        } else {
          this.send('showError', 'Error (' + err.status + ' - ' + err.code + ')');
        }

        Ember.setProperties(this, {
          testing: false,
          saving: false
        });
      },
      showError: function showError(err) {
        Ember.set(this, 'errors', [_errors.default.stringify(err)]);

        window.scrollY = 0;
      },
      clearError: function clearError() {
        Ember.set(this, 'errors', null);
      },
      disable: function disable() {
        var _this = this;

        this.send('clearError');

        var model = Ember.get(this, 'authConfig');
        var accessMode = Ember.get(model, 'id') === 'github' ? 'restricted' : 'unrestricted';

        Ember.setProperties(model, {
          enabled: false,
          accessMode: accessMode,
          allowedPrincipalIds: []
        });

        model.doAction('disable').then(function () {
          model.save().then(function () {
            _this.send('waitAndRefresh');
          });
        }).catch(function (err) {
          _this.send('gotError', err);
        }).finally(function () {
          Ember.set(_this, 'confirmDisable', false);
        });
      }
    }
  });
});