define('shared/utils/platform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isAlternate = isAlternate;
  exports.isMore = isMore;
  exports.isRange = isRange;
  exports.version = version;
  var platform = exports.platform = (navigator.platform || '').toLowerCase();
  var isLinuxy = exports.isLinuxy = platform.indexOf('linux') >= 0 || platform.indexOf('unix') >= 0;
  var isMac = exports.isMac = platform.indexOf('mac') >= 0;
  var isWin = exports.isWin = platform.indexOf('win') >= 0;

  var alternateKey = exports.alternateKey = 'ctrlKey';
  var alternateLabel = exports.alternateLabel = 'Control';

  var moreKey = exports.moreKey = 'ctrlKey';
  var moreLabel = exports.moreLabel = 'Control';

  var rangeKey = exports.rangeKey = 'shiftKey';
  var rangeLabel = exports.rangeLabel = 'Shift';

  if (isMac) {
    exports.alternateKey = alternateKey = 'metaKey';
    exports.alternateLabel = alternateLabel = 'Command';
    exports.moreKey = moreKey = 'metaKey';
    exports.moreLabel = moreLabel = 'Command';
  }

  function isAlternate(event) {
    return !!event[alternateKey];
  }

  function isMore(event) {
    return !!event[moreKey];
  }

  function isRange(event) {
    return !!event[rangeKey];
  }

  // Only intended to work for Mobile Safari at the moment...
  function version() {
    var match = userAgent.match(/\s+Version\/([0-9.]+)/);

    if (match) {
      return parseFloat(match[1]);
    }

    return null;
  }

  var userAgent = exports.userAgent = navigator.userAgent;
  var isGecko = exports.isGecko = userAgent.indexOf('Gecko/') >= 0;
  var isBlink = exports.isBlink = userAgent.indexOf('Chrome/') >= 0;
  var isWebKit = exports.isWebKit = !isBlink && userAgent.indexOf('AppleWebKit/') >= 0;
  var isSafari = exports.isSafari = !isBlink && userAgent.indexOf('Safari/') >= 0;
  var isMobile = exports.isMobile = /Android|webOS|iPhone|iPad|iPod|IEMobile/i.test(userAgent);

  var xhrConcur = exports.xhrConcur = 99;
  if (isSafari) {
    if (version() && version() < 10) {
      // Safari for iOS9 has problems with multiple simultaneous requests
      exports.xhrConcur = xhrConcur = 1;
    }
  }
});