define('shared/components/top-errors/component', ['exports', 'shared/components/top-errors/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    errors: null,

    classNames: ['banner', 'bg-error'],
    classNameBindings: ['errors.length::hide'],

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'errors', []);
    },


    errorsDidChange: Ember.computed('errors.[]', function () {
      var _this = this;

      if (Ember.get(this, 'errors.length')) {
        Ember.run.later(function () {
          _this.$().scrollIntoView();
        }, 100);
      }
    })
  });
});