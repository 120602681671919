define('ember-math-helpers/helpers/gcd', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.gcd = gcd;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Returns the greatest positive integer that divides each of two integers
   *
   * ```hbs
   * {{gcd a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The GCD of the two numbers passed
   */
  function gcd(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        number1 = _ref2$ === undefined ? 0 : _ref2$,
        _ref2$2 = _ref2[1],
        number2 = _ref2$2 === undefined ? 0 : _ref2$2;

    var a = Math.abs(number1);
    var b = Math.abs(number2);

    if (a === 0) {
      return b;
    }

    if (b === 0) {
      return a;
    }

    return gcd([b, a % b]);
  }

  exports.default = Ember.Helper.helper(gcd);
});