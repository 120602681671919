define('shared/components/accordion-list/component', ['exports', 'shared/components/accordion-list/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    showExpandAll: true,
    expandAll: false,
    actions: {
      expandAll: function expandAll() {
        this.toggleProperty('expandAll');
      }
    },
    expand: function expand(item) {
      item.toggleProperty('expanded');
    }
  });
});