define('shared/components/new-select/component', ['exports', 'shared/components/new-select/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'select',
    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    optionGroupPath: 'group',
    optionDisabledPath: 'disabled',
    // Used to be Ember.K but that has been depreciated - action to fire on change
    value: null,
    localizedPrompt: false,
    localizedLabel: false,
    localizedHtmlLabel: false,
    disabled: false,
    attributeBindings: ['disabled'],
    classNames: ['pl-5'],

    ungroupedContent: null,
    groupedContent: null,

    // leaking changes to it via a 2-way binding
    _selection: Ember.computed.reads('selection'),

    // shadow the passed-in `selection` to avoid
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }

      Ember.defineProperty(this, 'ungroupedContent', Ember.computed('content.@each.' + this.get('optionGroupPath'), function () {
        var groupPath = _this.get('optionGroupPath');
        var out = [];

        _this.get('content').forEach(function (opt) {
          var key = Ember.get(opt, groupPath);

          if (!key) {
            out.push(opt);
          }
        });

        return out;
      }));

      Ember.defineProperty(this, 'groupedContent', Ember.computed('content.@each.' + this.get('optionGroupPath'), function () {
        var groupPath = _this.get('optionGroupPath');
        var out = [];

        _this.get('content').forEach(function (opt) {
          var key = Ember.get(opt, groupPath);

          if (key) {
            var group = out.filterBy('group', key)[0];

            if (!group) {
              group = {
                group: key,
                options: []
              };
              out.push(group);
            }

            group.options.push(opt);
          }
        });

        return out.sortBy(groupPath);
      }));

      this.on('change', this, this._change);
    },
    willDestroyElement: function willDestroyElement() {
      this.off('change', this, this._change);
    },
    action: function action() {
      return this;
    },
    _change: function _change() {
      var selectEl = this.$()[0];
      var selectedIndex = selectEl.selectedIndex;
      var value = Ember.get(this, 'value');

      if (selectedIndex === -1) {
        return;
      }

      var selectedValue = selectEl.options[selectedIndex].value;
      var content = this.get('content');

      var selection = content.filterBy(this.get('optionValuePath'), selectedValue)[0];

      // set the local, shadowed selection to avoid leaking
      // changes to `selection` out via 2-way binding
      this.set('_selection', selection);

      var changeCallback = this.get('action');

      if (changeCallback) {
        changeCallback(selection);
      }

      if (selection) {
        if (typeof value === 'function') {
          value(Ember.get(selection, this.get('optionValuePath')));
        } else {
          this.set('value', Ember.get(selection, this.get('optionValuePath')));
        }
      } else {
        this.set('value', null);
      }
    }
  });
});