define('global-admin/multi-cluster-apps/catalog/launch/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['appId', 'stackId', 'upgrade', 'catalog', 'clone'],
    stackId: null,
    upgrade: null,
    showName: true,
    catalog: null,
    appId: null,
    clone: null,

    parentRoute: 'multi-cluster-apps.catalog.index',

    actions: {
      cancel: function cancel() {
        this.send('goToPrevious', 'global-admin.' + this.parentRoute);
      }
    }
  });
});