define('shared/components/storage-class/provisioner-portworx-volume/component', ['exports', 'shared/components/storage-class/provisioner-portworx-volume/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['fs', 'block_size', 'repl', 'io_priority', 'snap_interval', 'aggregation_level', 'ephemeral'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'portworx-volume',
    fields: FIELDS
  });
});