enifed('ember-template-compiler/tests/plugins/assert-splattribute-expression-test', ['ember-babel', 'internal-test-helpers', 'ember-template-compiler/index'], function (_emberBabel, _internalTestHelpers, _index) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-splattribute-expression', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype.expectedMessage = function (locInfo) {
      return 'Using "...attributes" can only be used in the element position e.g. <div ...attributes />. It cannot be used as a path. (' + locInfo + ') ';
    };

    _class.prototype['@test ...attributes is in element space'] = function (assert) {
      assert.expect(0);

      (0, _index.compile)('<div ...attributes>Foo</div>');
    };

    _class.prototype['@test {{...attributes}} is not valid'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('<div>{{...attributes}}</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage('\'foo-bar\' @ L1:C7'));
    };

    _class.prototype['@test {{...attributes}} is not valid path expression'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('<div>{{...attributes}}</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage('\'foo-bar\' @ L1:C7'));
    };

    _class.prototype['@test {{...attributes}} is not valid modifier'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('<div {{...attributes}}>Wat</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage('\'foo-bar\' @ L1:C7'));
    };

    _class.prototype['@test {{...attributes}} is not valid attribute'] = function () {
      expectAssertion(function () {
        (0, _index.compile)('<div class={{...attributes}}>Wat</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage('\'foo-bar\' @ L1:C13'));
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});