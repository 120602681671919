define('shared/components/modal-root/component', ['exports', 'shared/components/modal-root/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    tagName: 'div',
    classNames: ['modal-overlay'],
    classNameBindings: ['modalVisible:modal-open:modal-closed'],
    modalType: Ember.computed.alias('modalService.modalType'),
    modalVisible: Ember.computed.alias('modalService.modalVisible'),
    click: function click(e) {
      if (this.get('modalService.closeWithOutsideClick') && Ember.$(e.target).hasClass('modal-open')) {
        this.get('modalService').toggleModal();
      }
    }
  });
});