define('shared/saml/service', ['exports', 'shared/utils/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    session: Ember.inject.service(),
    access: Ember.inject.service(),
    app: Ember.inject.service(),
    intl: Ember.inject.service(),

    login: function login(providerName) {
      var _this = this;

      var finalUrl = window.location.origin;
      var provider = Ember.get(this, 'access.providers').findBy('id', providerName);

      return provider.doAction('login', { finalRedirectUrl: finalUrl }).then(function (resp) {
        return window.location.href = resp.idpRedirectUrl;
      }).catch(function () {
        return {
          type: 'error',
          message: Ember.get(_this, 'intl').t('authPage.saml.authError')
        };
      });
    },
    test: function test(config, cb) {
      var _this2 = this;

      var responded = false;

      window.onAuthTest = function (err, code) {
        if (err) {
          responded = true;

          cb({
            type: 'error',
            message: err
          });
        } else if (!responded) {
          var authConfig = code;

          responded = true;

          _this2.finishTest(authConfig, code, cb);
        }
      };

      var url = Ember.get(config, 'idpRedirectUrl');
      var popup = window.open(url, 'rancherAuth', _util.default.popupWindowOptions());

      var intl = Ember.get(this, 'intl');

      var timer = setInterval(function () {
        if (popup && popup.closed) {
          clearInterval(timer);

          if (!responded) {
            responded = true;

            cb({
              type: 'error',
              message: intl.t('authPage.saml.authError')
            });
          }
        } else if (popup === null || typeof popup === 'undefined') {
          clearInterval(timer);

          if (!responded) {
            responded = true;

            cb({
              type: 'error',
              message: intl.t('authPage.saml.popupError')
            });
          }
        }
      }, 500);
    },
    finishTest: function finishTest(config, code, cb) {
      var authConfig = config;
      var am = Ember.get(authConfig, 'accessMode') || 'unrestricted';

      Ember.setProperties(authConfig, {
        enabled: true,
        accessMode: am
      });

      return authConfig.save().then(function () {
        window.location.href = window.location.href;
      }).catch(function (err) {
        cb(err);
      });
    }
  });
});