enifed('ember-template-compiler/tests/system/bootstrap-test', ['ember-babel', '@ember/runloop', 'ember-glimmer', 'ember-template-compiler/lib/system/bootstrap', 'internal-test-helpers'], function (_emberBabel, _runloop, _emberGlimmer, _bootstrap, _internalTestHelpers) {
  'use strict';

  var component = void 0,
      fixture = void 0;

  function checkTemplate(templateName, assert) {
    (0, _runloop.run)(function () {
      return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
    });

    var template = (0, _emberGlimmer.getTemplate)(templateName);
    var qunitFixture = document.querySelector('#qunit-fixture');

    assert.ok(template, 'template is available on Ember.TEMPLATES');
    assert.notOk(qunitFixture.querySelector('script'), 'script removed');

    var owner = (0, _internalTestHelpers.buildOwner)();
    owner.register('template:-top-level', template);
    owner.register('component:-top-level', _emberGlimmer.Component.extend({
      layoutName: '-top-level',
      firstName: 'Tobias',
      drug: 'teamocil'
    }));

    component = owner.lookup('component:-top-level');
    (0, _internalTestHelpers.runAppend)(component);

    assert.equal(qunitFixture.textContent.trim(), 'Tobias takes teamocil', 'template works');
    (0, _internalTestHelpers.runDestroy)(owner);
  }

  (0, _internalTestHelpers.moduleFor)('ember-templates: bootstrap', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.call(this));

      fixture = document.getElementById('qunit-fixture');
      return _this;
    }

    _class.prototype.teardown = function () {
      (0, _emberGlimmer.setTemplates)({});
      fixture = component = null;
    };

    _class.prototype['@test template with data-template-name should add a new template to Ember.TEMPLATES'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars" data-template-name="funkyTemplate">{{firstName}} takes {{drug}}</script>';

      checkTemplate('funkyTemplate', assert);
    };

    _class.prototype['@test template with id instead of data-template-name should add a new template to Ember.TEMPLATES'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars" id="funkyTemplate" >{{firstName}} takes {{drug}}</script>';

      checkTemplate('funkyTemplate', assert);
    };

    _class.prototype['@test template without data-template-name or id should default to application'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars">{{firstName}} takes {{drug}}</script>';

      checkTemplate('application', assert);
    };

    _class.prototype[(typeof Handlebars === 'object' ? '@test' : '@skip') + ' template with type text/x-raw-handlebars should be parsed'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-raw-handlebars" data-template-name="funkyTemplate">{{name}}</script>';

      (0, _runloop.run)(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      });

      var template = (0, _emberGlimmer.getTemplate)('funkyTemplate');

      assert.ok(template, 'template with name funkyTemplate available');

      // This won't even work with Ember templates
      assert.equal(template({ name: 'Tobias' }).trim(), 'Tobias');
    };

    _class.prototype['@test duplicated default application templates should throw exception'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars">first</script><script type="text/x-handlebars">second</script>';

      assert.throws(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      }, /Template named "[^"]+" already exists\./, 'duplicate templates should not be allowed');
    };

    _class.prototype['@test default default application template and id application template present should throw exception'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars">first</script><script type="text/x-handlebars" id="application">second</script>';

      assert.throws(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      }, /Template named "[^"]+" already exists\./, 'duplicate templates should not be allowed');
    };

    _class.prototype['@test default application template and data-template-name application template present should throw exception'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars">first</script><script type="text/x-handlebars" data-template-name="application">second</script>';

      assert.throws(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      }, /Template named "[^"]+" already exists\./, 'duplicate templates should not be allowed');
    };

    _class.prototype['@test duplicated template id should throw exception'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars" id="funkyTemplate">first</script><script type="text/x-handlebars" id="funkyTemplate">second</script>';

      assert.throws(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      }, /Template named "[^"]+" already exists\./, 'duplicate templates should not be allowed');
    };

    _class.prototype['@test duplicated template data-template-name should throw exception'] = function (assert) {
      fixture.innerHTML = '<script type="text/x-handlebars" data-template-name="funkyTemplate">first</script><script type="text/x-handlebars" data-template-name="funkyTemplate">second</script>';

      assert.throws(function () {
        return (0, _bootstrap.default)({ context: fixture, hasTemplate: _emberGlimmer.hasTemplate, setTemplate: _emberGlimmer.setTemplate });
      }, /Template named "[^"]+" already exists\./, 'duplicate templates should not be allowed');
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));
});