define('shared/mixins/sticky-table-header', ['exports', 'shared/mixins/throttled-resize'], function (exports, _throttledResize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tableProps = {
    actionsHeight: 60,
    fixedHeaderHeight: 40
  };

  exports.default = Ember.Mixin.create(_throttledResize.default, {
    stickyHeader: true,
    subRow: false,

    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.buildTableWidths();

      if (this.get('showHeader')) {
        Ember.$(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header').css('width', Ember.$(this.element).find('> table').outerWidth());
      }

      if (!this.get('stickyHeader')) {
        return;
      }

      this.set('_boundScroll', this._scroll.bind(this));
      Ember.$(window).on('scroll', this.get('_boundScroll'));
    },


    _boundScroll: null,
    _scroll: function _scroll() {
      var _this = this;

      Ember.run.throttle(function () {
        _this.updateHeaders();
      }, 30);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (!this.get('stickyHeader')) {
        return;
      }

      Ember.$(window).off('scroll', this.get('_boundScroll'));
    },
    onResize: function onResize() {
      this._super.apply(this, arguments);
      this.buildTableWidths();
    },
    buildTableWidths: function buildTableWidths() {
      if (Ember.$(this.element).is(':visible')) {
        var ths = Ember.$(this.element).find('> table > thead > tr.fixed-header > th');
        var $fixHdr = Ember.$(this.element).find('> table > thead > .fixed-header-actions, > table > thead > .fixed-header');

        Ember.$(this.element).find('> table > thead > tr.fixed-header-placeholder > th').each(function (idx, th) {
          Ember.$(ths[idx]).attr('width', Ember.$(th).outerWidth());
        });

        if (this.get('showHeader')) {
          $fixHdr.css({ 'width': Ember.$(this.element).find('> table').width() });
          if ($fixHdr.is(':visible')) {
            Ember.$(this.element).find('.search-group').show(1, 'linear');
          }
        }
      }
    },
    tearDownTableWidths: function tearDownTableWidths() {
      if (!this.get('stickyHeader')) {
        return;
      }

      Ember.$(this.element).find('> table > thead > tr.fixed-header > th').each(function (idx, td) {
        Ember.$(td).removeAttr('width');
      });
    },
    positionHeaders: function positionHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = Ember.$(this.element);
      var $table = elem.find('> table');
      var $actionRow = $table.find('> thead > .fixed-header-actions');
      var $fixedHeader = $table.find('> thead > tr.fixed-header');
      var showHeader = this.get('showHeader');

      var fudge = this.get('subRow') ? 100 : 0;

      if (showHeader) {
        $actionRow.css({
          'position': 'fixed',
          'top': fudge,
          'height': tableProps.actionsHeight + 'px'
        });
      }
      $fixedHeader.css({
        'position': 'fixed',
        'top': (showHeader && this.get('bulkActions') ? fudge + tableProps.actionsHeight : 0) + 'px',
        'height': tableProps.fixedHeaderHeight + 'px'
      });

      elem.css({ 'padding-top': tableProps.actionsHeight + tableProps.fixedHeaderHeight + 'px' });
    },
    removePositions: function removePositions() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = Ember.$(this.element);
      var $table = elem.find('> table');
      var $actionRow = $table.find('> thead > .fixed-header-actions');
      var $fixedHeader = $table.find('> thead > tr.fixed-header');

      if (this.get('showHeader')) {
        $actionRow.css({
          'position': 'relative',
          'top': ''
        });
      }

      $fixedHeader.css({
        'position': '',
        'top': ''
      });

      elem.css({ 'padding-top': '' });
      this.buildTableWidths();
    },
    updateHeaders: function updateHeaders() {
      if (!this.get('stickyHeader')) {
        return;
      }

      var elem = Ember.$(this.element);
      var $table = elem.find('> table');
      var $floatingHeader = $table.find('> thead > tr.fixed-header');
      var $scrollTop = Ember.$(window).scrollTop();
      var containerBottom = $table.height() + $table.offset().top;
      var offset = elem.find('> table > thead > tr').offset().top - parseInt(elem.css('padding-top'), 10);

      if ($scrollTop < containerBottom && $scrollTop > offset) {
        if ($floatingHeader.css('position') !== 'fixed') {
          this.buildTableWidths();
          this.positionHeaders();
        }
      } else {
        if ($floatingHeader.css('position') === 'fixed') {
          this.tearDownTableWidths();
          this.removePositions();
        }
      }
    }
  });
});