define('shared/capabilities/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    scope: Ember.inject.service(),

    cluster: Ember.computed.alias('scope.currentCluster'),
    capabilities: Ember.computed.alias('cluster.capabilities'),

    nodePoolsCanScale: Ember.computed('capabilities.nodePoolScalingSupported', function () {
      var _ref = this || {},
          capabilities = _ref.capabilities;

      var poolScaleSupported = false;

      if (Ember.get(capabilities, 'nodePoolScalingSupported')) {
        poolScaleSupported = true;
      }

      return poolScaleSupported;
    }),

    allowedNodePortRanges: Ember.computed('capabilities.nodePortRange', function () {
      var capabilities = Ember.get(this, 'capabilities') || {};
      var ccPorts = [];

      if (Ember.get(capabilities, 'nodePortRange')) {
        var temp = Ember.get(capabilities, 'nodePortRange').split('-');

        ccPorts = [parseInt(temp[0], 10), parseInt(temp[1], 10)];
      }

      return ccPorts;
    }),

    ingressCapabilities: Ember.computed('capabilities.ingressControllers.[]', function () {
      var _ref2 = this || {},
          capabilities = _ref2.capabilities;

      var ingressCapabilities = Ember.get(capabilities, 'ingressCapabilities') || [];
      var provider = ingressCapabilities.length > 0 ? Ember.get(ingressCapabilities, 'firstObject.ingressProvider') : null;

      return {
        defaultIngressProvider: provider,
        ingressControllersCapabilities: ingressCapabilities
      };
    }),

    loadBalancerCapabilites: Ember.computed('capabilities.loadBalancerCapabilities.{enabled,healthCheckSupported}', function () {
      return {
        // `enabled` field is not set for imported clusters
        l4LoadBalancerEnabled: Ember.get(this, 'capabilities.loadBalancerCapabilities.enabled') !== false,
        healthCheckSupported: Ember.get(this, 'capabilities.loadBalancerCapabilities.healthCheckSupported')
      };
    })

  });
});