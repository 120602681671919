define('shared/utils/percent-gauge', ['exports', 'd3'], function (exports, _d) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = initGraph;
  exports.addText = addText;
  exports.addArc = addArc;
  exports.createArc = createArc;
  exports.d2r = d2r;
  exports.sin = sin;
  exports.cos = cos;
  exports.calcR = calcR;
  exports.valueToPoint = valueToPoint;
  exports.getWidth = getWidth;
  exports.getConfig = getConfig;
  exports.getRange = getRange;
  function initGraph(options) {
    var _getConfig = getConfig(options),
        el = _getConfig.el,
        width = _getConfig.width,
        height = _getConfig.height,
        margin = _getConfig.margin,
        thickness = _getConfig.thickness,
        fontSize = _getConfig.fontSize;

    var svg = (0, _d.select)(el).append('svg').attr('width', width).attr('height', height);

    var value = options.value,
        ticks = options.ticks,
        mode = options.mode;

    var _addArcValue = addArcValue(svg, width, margin, thickness, options.value),
        valuePath = _addArcValue.valuePath,
        maxPath = _addArcValue.maxPath;

    var tooltip = addTooltip();

    addTicks(svg, tooltip, width, height, margin, ticks, options.value, thickness);

    var _addLabels = addLabels(svg, options, width, height, fontSize, mode),
        valueLabel = _addLabels.valueLabel,
        titleLabel = _addLabels.titleLabel,
        subtitleLabel = _addLabels.subtitleLabel;

    return {
      updateTitle: function updateTitle(text) {
        titleLabel.text(text);
      },
      updateSubTitle: function updateSubTitle(text) {
        subtitleLabel.text(text);
      },
      updateValue: function updateValue(text) {
        var _getConfig2 = getConfig({ el: el }),
            width = _getConfig2.width,
            height = _getConfig2.height,
            margin = _getConfig2.margin,
            thickness = _getConfig2.thickness;

        svg.attr('width', width).attr('height', height);

        text = text ? text + '%' : '';
        valueLabel.text(text);

        if (text) {
          value = parseInt(text, 10);
          var r = calcR(width, margin);

          valuePath.attr('d', createArc(-135, value, r, thickness));
        }
      },
      updateTicks: function updateTicks(t) {
        ticks = t;
        repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness);
      },
      fit: function fit() {
        _fit(svg, el, value, ticks, tooltip, valuePath, maxPath, valueLabel, titleLabel, subtitleLabel, mode);
      }
    };
  }

  function _fit(svg, el, value, ticks, tooltip, valuePath, maxPath, valueLabel, titleLabel, subtitleLabel, mode) {
    var _getConfig3 = getConfig({ el: el }),
        width = _getConfig3.width,
        height = _getConfig3.height,
        margin = _getConfig3.margin,
        thickness = _getConfig3.thickness,
        fontSize = _getConfig3.fontSize;

    svg.attr('width', width).attr('height', height);
    repaintArc(width, margin, value, thickness, valuePath, maxPath);
    repaintLabels(valueLabel, titleLabel, subtitleLabel, width, height, fontSize, mode);
    repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness);
  }

  function repaintTicks(svg, tooltip, width, height, margin, ticks, value, thickness) {
    svg.selectAll('path[tick = "custom"]').remove();
    svg.selectAll('circle[tick = "custom"]').remove();
    addTicks(svg, tooltip, width, height, margin, ticks, value, thickness);
  }

  function repaintArc(width, margin, value, thickness, valuePath, maxPath) {
    var r = calcR(width, margin);

    valuePath.attr('d', createArc(-135, value, r, thickness)).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
    maxPath.attr('d', createArc(-135, 100, r, thickness)).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
  }

  function addTooltip() {
    var tooltip = (0, _d.select)('#percent-gauge-tooltip');

    if (tooltip.empty()) {
      tooltip = (0, _d.select)('body').append('div').attr('class', 'hover-label').attr('class', 'percent-gauge-tooltip').attr('id', 'percent-gauge-tooltip').style('opacity', 0);
    }

    return tooltip;
  }

  function addArcValue(svg, width, margin, thickness, value) {
    var maxPath = addArc(svg, width, margin, thickness, 'gauge-max-path', 100);
    var valuePath = addArc(svg, width, margin, thickness, 'gauge-value-path', value > 100 ? 100 : value);

    return {
      valuePath: valuePath,
      maxPath: maxPath
    };
  }

  function getValueLabelY(height, fontSize) {
    return height / 5 + fontSize / 1.2;
  }

  function getTitleLabelY(height, fontSize, mode) {
    if (mode === 'monitoring') {
      return height / 5 + 4.3 * fontSize;
    }

    return height / 5 + 1.9 * fontSize;
  }

  function getSubtitleLabelY(height, fontSize, mode) {
    if (mode === 'monitoring') {
      return height / 5 + 1.7 * fontSize;
    }

    return height / 5 + 2.5 * fontSize;
  }

  function addLabels(svg, options, width, height, fontSize, mode) {
    var valueClass = mode === 'monitoring' ? 'reservedPercent' : 'value';

    return {
      valueLabel: addText(options.value ? options.value + '%' : '0%', svg, width / 2, getValueLabelY(height, fontSize), fontSize, valueClass, 3.5),
      titleLabel: addText(options.title, svg, width / 2, getTitleLabelY(height, fontSize, mode), fontSize / 3, 'title', mode),
      subtitleLabel: addText(options.subtitle, svg, width / 2, getSubtitleLabelY(height, fontSize, mode), fontSize / 3, 'subtitle', mode)
    };
  }

  function repaintLabels(valueLabel, titleLabel, subtitleLabel, width, height, fontSize, mode) {
    valueLabel.attr('x', width / 2).attr('y', getValueLabelY(height, fontSize)).attr('dy', fontSize / 2).style('font-size', fontSize + 'px');
    titleLabel.attr('x', width / 2).attr('y', getTitleLabelY(height, fontSize, mode)).attr('dy', fontSize / 6).style('font-size', fontSize / 3 + 'px');
    subtitleLabel.attr('x', width / 2).attr('y', getSubtitleLabelY(height, fontSize, mode)).attr('dy', fontSize / 6).style('font-size', fontSize / 3 + 'px');
  }

  function addTicks(svg, tooltip, width, height, margin, ticks, currentValue, thickness) {
    var _getRange = getRange(ticks),
        max = _getRange.max,
        min = _getRange.min;

    (ticks || []).forEach(function (tick) {
      if (tick.value !== 0 && !tick.value) {
        return;
      }

      var value = parseInt(tick.value, 10);

      if (value !== max && value !== min || max === min) {
        return;
      }
      if (min === value && value === currentValue && value !== 0) {
        value -= 1;
      }
      if (max === value && value === currentValue && max !== 100) {
        value += 1;
      }
      var point = valueToPoint(width, height, margin, value, thickness);

      if (ticks.length > 1) {
        var tr = '';

        tick.labels.forEach(function (label) {
          tr += '<tr>\n                  <td>' + label + '</td>\n                  <td>' + tick.value + '%</td>\n                </tr>';
        });

        svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-circle-fill').attr('cx', point.x).attr('cy', point.y).attr('r', 3);

        var tp = svg.append('circle').attr('tick', 'custom').attr('class', 'gauge-none-fill').attr('cx', point.x).attr('cy', point.y).attr('r', thickness / 2);

        tp.on('mouseover', function () {
          tooltip.transition().duration(200).style('opacity', .9);
          tooltip.html('<table>\n                        <tbody>\n                          <tr>\n                            <th>' + window.l('service:intl').t('clusterDashboard.node') + '</th>\n                            <th>' + window.l('service:intl').t('clusterDashboard.value') + '</th>\n                          </tr>\n                          ' + tr + '\n                        </tbody>\n                      </table>').style('left', _d.event.pageX - 30 + 'px').style('top', _d.event.pageY - 30 * (tick.labels.length + 1) + 'px');
        }).on('mouseout', function () {
          tooltip.transition().duration(500).style('opacity', 0);
        });
      }
    });

    if (ticks && ticks.length > 1 && max - min >= 1) {
      var rangePath = void 0;

      if (min === currentValue && min !== 0) {
        min -= 1;
      }
      if (max === currentValue && max !== 100) {
        max += 1;
      }
      rangePath = addArc(svg, width, margin, thickness, 'gauge-tick-path', max, min, 2);

      rangePath.attr('tick', 'custom');
    }
  }

  function addText(text, svg, x, y, fontSize, labelType) {
    var bold = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 0;

    return svg.append('svg:text').attr('x', x).attr('y', y).attr('dy', fontSize / 2).attr('text-anchor', 'middle').text(text).style('font-size', fontSize + 'px').attr('class', 'gauge-' + labelType + '-fill').style('stroke-width', bold + 'px');
  }

  function addArc(svg, width, margin, thickness, gaugeColor, value) {
    var start = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 0;
    var strokeWidth = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : 1;

    value = value || 0;
    var r = calcR(width, margin);

    return svg.append('path').attr('d', createArc(-135, value, r, thickness, start)).style('stroke-width', strokeWidth).attr('class', 'gauge-text-stroke ' + gaugeColor).attr('transform', 'translate(' + (margin + r) + ',' + (margin + r) + '), scale(1, 1)');
  }

  function createArc(sa, ea, r, thickness) {
    var start = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;

    ea = 2.7 * parseInt(ea, 10) - 135;
    sa = 2.7 * parseInt(start, 10) - 135;

    return _d.svg.arc().outerRadius(r).innerRadius(r - thickness).startAngle(d2r(sa)).endAngle(d2r(ea)).cornerRadius(thickness / 2);
  }

  function d2r(d) {
    return d * (Math.PI / 180);
  }

  function sin(value) {
    return Math.sin((45 - 2.7 * value) * Math.PI / 180);
  }

  function cos(value) {
    return Math.cos((45 - 2.7 * value) * Math.PI / 180);
  }

  function calcR(width, margin) {
    return (width - 2 * margin) / 2;
  }

  function valueToPoint(width, height, margin, value, thickness) {
    var r = calcR(width, margin) - thickness / 2;

    return {
      x: width - r * cos(value) - r - margin - thickness / 2,
      y: height - r - margin + r * sin(value) - thickness / 2
    };
  }

  function getWidth(el) {
    var width = el.parentNode.offsetWidth * 0.9;

    return width > 0 ? width : 0;
  }

  function getConfig(options) {
    var width = getWidth(options.el);

    return {
      el: options.el,
      fontSize: width / 7,
      margin: width / 20,
      width: width,
      height: width,
      thickness: width / 20
    };
  }

  function getRange(ticks) {
    var max = void 0;
    var min = void 0;

    (ticks || []).map(function (tick) {
      if (tick.value !== 0 && !tick.value) {
        return {};
      }
      var value = parseInt(tick.value, 10);

      max = max === undefined || value > max ? value : max;
      min = min === undefined || value < min ? value : min;
    });

    return {
      max: max,
      min: min
    };
  }
});