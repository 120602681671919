define('global-admin/settings/advanced/route', ['exports', 'ui/utils/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('settings').load([_constants.default.SETTING.TELEMETRY]);
    },
    model: function model() {
      // let settings = this.get('settings');
      var globalStore = Ember.get(this, 'globalStore');

      return globalStore.find('setting').then(function (settings) {
        var serverUrl = settings.findBy('id', _constants.default.SETTING.SERVER_URL);

        return {
          telemetry: settings.findBy('id', _constants.default.SETTING.TELEMETRY),
          serverUrl: Ember.get(serverUrl, 'value') || window.location.host,
          serverUrlSetting: serverUrl
        };
      });
    },
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('error', null);
      }
    }
  });
});