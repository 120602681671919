define('shared/components/catalog-box/component', ['exports', 'shared/components/catalog-box/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    tagName: 'div',

    classNames: ['catalog-box', 'p-0'],
    classNameBindings: ['active::inactive', 'model.certifiedClass'],

    model: null,
    showIcon: true,
    showSource: false,
    showDescription: true,
    active: true,
    srcSet: false,
    genericIconPath: null,

    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'genericIconPath', Ember.get(this, 'app.baseAssets') + 'assets/images/generic-catalog.svg');
    },
    didRender: function didRender() {
      var _this = this;

      if (!Ember.get(this, 'srcSet')) {
        Ember.set(this, 'srcSet', true);

        var $icon = this.$('.catalog-icon > img');
        var $srcPath = $icon.attr('src');

        if ($srcPath === this.genericIconPath) {
          $icon.attr('src', $icon.data('src'));

          this.$('img').on('error', function () {
            if (_this.isDestroyed || _this.isDestroying) {
              if (_this.$('img')) {
                _this.$('img').off('error');
              }

              return;
            }

            $icon.attr('src', _this.genericIconPath);
          });
        }
      }
    }
  });
});