enifed('ember-template-compiler/lib/plugins/assert-splattribute-expression', ['exports', '@ember/debug', 'ember-template-compiler/lib/system/calculate-location-display'], function (exports, _debug, _calculateLocationDisplay) {
    'use strict';

    exports.default = function (env) {
        var moduleName = env.meta.moduleName;

        return {
            name: 'assert-splattribute-expressions',
            visitor: {
                AttrNode: function (_ref) {
                    var name = _ref.name,
                        loc = _ref.loc;
                },
                PathExpression: function (_ref2) {
                    var original = _ref2.original,
                        loc = _ref2.loc;

                    if (original === '...attributes') {
                        true && !false && (0, _debug.assert)(errorMessage() + ' ' + (0, _calculateLocationDisplay.default)(moduleName, loc));
                    }
                }
            }
        };
    };

    function errorMessage() {
        return 'Using "...attributes" can only be used in the element position e.g. <div ...attributes />. It cannot be used as a path.';
    }
});