define('shared/components/tooltip-warning/component', ['exports', 'shared/mixins/tooltip', 'ui/utils/constants', 'shared/components/tooltip-warning/template'], function (exports, _tooltip, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tooltip.default, {
    prefs: Ember.inject.service(),
    layout: _template.default,
    classNames: ['tooltip-warning-container'],
    actions: {
      hideAccessWarning: function hideAccessWarning() {
        this.set('prefs.' + _constants.default.PREFS.ACCESS_WARNING, false);
        this.destroyTooltip();
      }
    }
  });
});