define('shared/components/storage-class/provisioner-longhorn/component', ['exports', 'shared/components/storage-class/provisioner-longhorn/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['numberOfReplicas', 'staleReplicaTimeout', 'fromBackup'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'longhorn',
    fields: FIELDS
  });
});