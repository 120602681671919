enifed('ember-template-compiler/lib/plugins/assert-reserved-named-arguments', ['exports', '@ember/debug', 'ember-template-compiler/lib/system/calculate-location-display'], function (exports, _debug, _calculateLocationDisplay) {
    'use strict';

    exports.default = function (env) {
        var moduleName = env.meta.moduleName;

        return {
            name: 'assert-reserved-named-arguments',
            visitor: {
                PathExpression: function (_ref) {
                    var original = _ref.original,
                        loc = _ref.loc;

                    if (isReserved(original)) {
                        true && !false && (0, _debug.assert)(assertMessage(original) + ' ' + (0, _calculateLocationDisplay.default)(moduleName, loc));
                    }
                }
            }
        };
    };

    var RESERVED = ['@arguments', '@args', '@block', '@else'];
    var isReserved = void 0,
        assertMessage = void 0;

    isReserved = function (name) {
        return RESERVED.indexOf(name) !== -1 || !!name.match(/^@[^a-z]/);
    };
    assertMessage = function (name) {
        return '\'' + name + '\' is reserved.';
    };
});