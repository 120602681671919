define('global-admin/components/multi-cluster-app/component', ['exports', 'global-admin/components/multi-cluster-app/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['namespace-app'],
    srcSet: false,
    latestVersion: null,

    didRender: function didRender() {
      this.initAppIcon();
    },
    initAppIcon: function initAppIcon() {
      var _this = this;

      if (!this.get('srcSet')) {
        Ember.set(this, 'srcSet', true);

        var $icon = this.$('.catalog-icon > img');

        $icon.attr('src', $icon.data('src'));

        this.$('img').on('error', function () {
          $icon.attr('src', _this.get('app.baseAssets') + 'assets/images/generic-catalog.svg');
        });
      }
    }
  });
});