enifed('ember-views/lib/system/jquery_event_deprecation', ['exports', '@ember/debug', 'ember-environment', 'ember-utils'], function (exports, _debug, _emberEnvironment) {
  'use strict';

  /* global Proxy */
  exports.default = function (jqEvent) {
    return jqEvent;


    var boundFunctions = new Map();

    // wrap the jQuery event in a Proxy to add the deprecation message for originalEvent, according to RFC#294
    // we need a native Proxy here, so we can make sure that the internal use of originalEvent in jQuery itself does
    // not trigger a deprecation
  };
});