define('global-admin/components/form-basic-policy/component', ['exports', 'global-admin/components/form-basic-policy/template', 'ui/utils/constants'], function (exports, _template, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var policies = _constants.default.BASIC_POD_SECURITY_POLICIES;

  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: ['accordion-wrapper'],

    model: null,
    readOnly: false,
    basicPolicies: null,

    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);
      var basicPolicies = [];

      for (var i = 0; i < policies.length / 3; i++) {
        basicPolicies.push(policies.slice(i * 3, i * 3 + 3));
      }
      this.set('basicPolicies', basicPolicies);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    }
  });
});