define('shared/mixins/console', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    queryParams: ['podId', 'kubernetes', 'windows', 'containerName'],
    instanceId: null,
    model: null,

    bootstrap: function () {
      if (this.get('application.isPopup')) {
        Ember.$('body').css('overflow', 'hidden');
      }
    }.on('init'),

    actions: {
      cancel: function cancel() {
        window.close();
      }
    }
  });
});