define('nodes/components/driver-vmwarevsphere/component', ['exports', 'shared/mixins/node-driver', 'nodes/components/driver-vmwarevsphere/template', 'shared/utils/constants'], function (exports, _nodeDriver, _template, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DRIVER = 'vmwarevsphere';
  var CONFIG = 'vmwarevsphereConfig';
  var VAPP_MODE_DISABLED = 'disabled';
  var VAPP_MODE_AUTO = 'auto';
  var VAPP_MODE_MANUAL = 'manual';

  var stringsToParams = function stringsToParams(params, str) {
    var index = str.indexOf('=');

    if (index > -1) {
      params.push({
        key: str.slice(0, index),
        value: str.slice(index + 1)
      });
    }

    return params;
  };

  var paramsToStrings = function paramsToStrings(strs, param) {
    if (param.value && param.key) {
      strs.push(param.key + '=' + param.value);
    }

    return strs;
  };

  var initialVAppOptions = {
    vappIpprotocol: '',
    vappIpallocationpolicy: '',
    vappTransport: '',
    vappProperty: []
  };

  var getDefaultVappOptions = function getDefaultVappOptions(networks) {
    return {
      vappIpprotocol: 'IPv4',
      vappIpallocationpolicy: 'fixedAllocated',
      vappTransport: 'com.vmware.guestInfo',
      vappProperty: networksToVappProperties(networks)
    };
  };

  var networksToVappProperties = function networksToVappProperties(networks) {
    return networks.length === 0 ? [] : networks.reduce(networkToVappProperties, ['guestinfo.dns.servers=${dns:' + networks[0] + '}', 'guestinfo.dns.domains=${searchPath:' + networks[0] + '}']);
  };

  var networkToVappProperties = function networkToVappProperties(props, network, i) {
    var n = i.toString();

    props.push('guestinfo.interface.' + n + '.ip.0.address=ip:' + network, 'guestinfo.interface.' + n + '.ip.0.netmask=${netmask:' + network + '}', 'guestinfo.interface.' + n + '.route.0.gateway=${gateway:' + network + '}');

    return props;
  };

  var getInitialVappMode = function getInitialVappMode(c) {
    var vappProperty = c.vappProperty || [];

    if (!c.vappIpprotocol && !c.vappIpallocationpolicy && !c.vappTransport && vappProperty.length === 0) {
      return VAPP_MODE_DISABLED;
    }

    var d = getDefaultVappOptions(c.network);

    if (c.vappIpprotocol === d.vappIpprotocol && c.vappIpallocationpolicy === d.vappIpallocationpolicy && c.vappTransport === d.vappTransport && vappProperty.length === d.vappProperty.length && vappProperty.join() === d.vappProperty.join()) {
      return VAPP_MODE_AUTO;
    }

    return VAPP_MODE_MANUAL;
  };

  exports.default = Ember.Component.extend(_nodeDriver.default, {
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),

    layout: _template.default,
    driverName: DRIVER,
    model: null,
    showEngineUrl: false,
    initParamArray: null,
    initVappArray: null,
    vappMode: VAPP_MODE_DISABLED,

    config: Ember.computed.alias('model.' + CONFIG),

    init: function init() {
      this._super.apply(this, arguments);
      this.initKeyValueParams('config.cfgparam', 'initParamArray');
      this.initKeyValueParams('config.vappProperty', 'initVappArray');
      this.initVappMode();
    },


    actions: {
      paramChanged: function paramChanged(array) {
        this.updateKeyValueParams('config.cfgparam', array);
      },
      vappPropertyChanged: function vappPropertyChanged(array) {
        this.updateKeyValueParams('config.vappProperty', array);
      },
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }
    },

    network: Ember.computed('config.network', {
      get: function get() {
        return (Ember.get(this, 'config.network') || []).join(', ');
      },
      set: function set(k, value) {
        Ember.set(this, 'config.network', value.split(',').filter(function (x) {
          return x.trim().length !== 0;
        }));

        return value;
      }
    }),

    bootstrap: function bootstrap() {
      var iso = Ember.get(this, 'settings.' + _constants.default.SETTING.ENGINE_ISO_URL) || 'https://releases.rancher.com/os/latest/rancheros-vmware.iso';

      var config = Ember.get(this, 'globalStore').createRecord({
        type: CONFIG,
        cpuCount: 2,
        memorySize: 2048,
        diskSize: 20000,
        vcenterPort: 443,
        network: [],
        cfgparam: ['disk.enableUUID=TRUE'],
        boot2dockerUrl: iso,
        vappIpprotocol: initialVAppOptions.vappIpprotocol,
        vappIpallocationpolicy: initialVAppOptions.vappIpallocationpolicy,
        vappTransport: initialVAppOptions.vappTransport,
        vappProperty: initialVAppOptions.vappProperty
      });

      Ember.set(this, 'model.' + CONFIG, config);
    },
    initKeyValueParams: function initKeyValueParams(pairsKey, paramsKey) {
      Ember.set(this, paramsKey, (Ember.get(this, pairsKey) || []).reduce(stringsToParams, []));
    },
    updateKeyValueParams: function updateKeyValueParams(pairsKey, params) {
      Ember.set(this, pairsKey, params.reduce(paramsToStrings, []));
    },
    initVappMode: function initVappMode() {
      Ember.set(this, 'vappMode', getInitialVappMode(Ember.get(this, 'config')));
    },
    updateVappOptions: function updateVappOptions(opts) {
      Ember.set(this, 'config.vappIpprotocol', opts.vappIpprotocol);
      Ember.set(this, 'config.vappIpallocationpolicy', opts.vappIpallocationpolicy);
      Ember.set(this, 'config.vappTransport', opts.vappTransport);
      Ember.set(this, 'config.vappProperty', opts.vappProperty);
      this.initKeyValueParams('config.vappProperty', 'initVappArray');
    },
    willSave: function willSave() {
      var vappMode = Ember.get(this, 'vappMode');

      if (vappMode === VAPP_MODE_DISABLED) {
        this.updateVappOptions(initialVAppOptions);
      } else if (vappMode === VAPP_MODE_AUTO) {
        var network = Ember.get(this, 'config.network');
        var defaultVappOptions = getDefaultVappOptions(network);

        this.updateVappOptions(defaultVappOptions);
      }

      if (!Ember.get(this, 'config.network')) {
        Ember.set(this, 'config.network', []);
      }

      return this._super.apply(this, arguments);
    },
    validate: function validate() {
      this._super();
      var errors = Ember.get(this, 'errors') || [];

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());

        return false;
      }

      return true;
    }
  });
});