enifed('@ember/engine/lib/engine-parent', ['exports', 'ember-utils'], function (exports, _emberUtils) {
  'use strict';

  exports.getEngineParent =

  /**
    `getEngineParent` retrieves an engine instance's parent instance.
  
    @method getEngineParent
    @param {EngineInstance} engine An engine instance.
    @return {EngineInstance} The parent engine instance.
    @for @ember/engine
    @static
    @private
  */
  /**
  @module @ember/engine
  */
  function (engine) {
    return engine[ENGINE_PARENT];
  }

  /**
    `setEngineParent` sets an engine instance's parent instance.
  
    @method setEngineParent
    @param {EngineInstance} engine An engine instance.
    @param {EngineInstance} parent The parent engine instance.
    @private
  */
  ;
  exports.setEngineParent = function (engine, parent) {
    engine[ENGINE_PARENT] = parent;
  };

  var ENGINE_PARENT = (0, _emberUtils.symbol)('ENGINE_PARENT');
});