define('shared/components/form-value-array/component', ['exports', 'shared/components/form-value-array/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    initialValues: null,
    addActionLabel: 'formValueArray.addActionLabel',
    valueLabel: 'formValueArray.valueLabel',
    valuePlaceholder: 'formValueArray.valuePlaceholder',
    noDataLabel: 'formValueArray.noData',
    showProTip: true,
    editing: true,

    ary: null,
    asValues: null,

    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];

      (Ember.get(this, 'initialValues') || []).forEach(function (value) {
        ary.push(Ember.Object.create({ value: value }));
      });

      Ember.set(this, 'ary', ary);
    },


    actions: {
      add: function add() {
        var _this = this;

        Ember.get(this, 'ary').pushObject(Ember.Object.create({ value: '' }));
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          var elem = _this.$('INPUT.value').last()[0];

          if (elem) {
            elem.focus();
          }
        });
      },
      remove: function remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      },
      pastedValues: function pastedValues(str) {
        var ary = Ember.get(this, 'ary');

        str = str.trim();

        var lines = str.split(/\r?\n/);

        lines.forEach(function (line) {
          line = line.trim();
          if (!line) {
            return;
          }

          ary.pushObject(Ember.Object.create({ value: line }));
        });

        // Clean up empty user entries
        var toRemove = [];

        ary.forEach(function (item) {
          if (!item.get('value')) {
            toRemove.push(item);
          }
        });

        ary.removeObjects(toRemove);
      }
    },

    asValuesObserver: Ember.observer('ary.@each.value', function () {
      var out = Ember.get(this, 'ary').filterBy('value').map(function (row) {
        return row.get('value');
      });

      Ember.set(this, 'asValues', out);
      this.sendAction('changed', out);
    })
  });
});