enifed('ember-template-compiler/tests/system/compile_options_test', ['ember-babel', 'ember-template-compiler/index', 'internal-test-helpers'], function (_emberBabel, _index, _internalTestHelpers) {
  'use strict';

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: default compile options', function (_AbstractTestCase) {
    (0, _emberBabel.inherits)(_class, _AbstractTestCase);

    function _class() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractTestCase.apply(this, arguments));
    }

    _class.prototype['@test default options are a new copy'] = function (assert) {
      assert.notEqual((0, _index.compileOptions)(), (0, _index.compileOptions)());
    };

    _class.prototype['@test has default AST plugins'] = function (assert) {
      assert.expect(_index.defaultPlugins.length);

      var plugins = (0, _index.compileOptions)().plugins.ast,
          i,
          plugin;

      for (i = 0; i < _index.defaultPlugins.length; i++) {
        plugin = _index.defaultPlugins[i];

        assert.ok(plugins.indexOf(plugin) > -1, 'includes ' + plugin);
      }
    };

    return _class;
  }(_internalTestHelpers.AbstractTestCase));

  var customTransformCounter = 0;

  var CustomTransform = function () {
    function CustomTransform(options) {

      customTransformCounter++;
      this.options = options;
      this.syntax = null;
    }

    CustomTransform.prototype.transform = function (ast) {
      var walker = new this.syntax.Walker();

      walker.visit(ast, function (node) {
        var i, attribute;

        if (node.type !== 'ElementNode') {
          return;
        }

        for (i = 0; i < node.attributes.length; i++) {
          attribute = node.attributes[i];


          if (attribute.name === 'data-test') {
            node.attributes.splice(i, 1);
          }
        }
      });

      return ast;
    };

    return CustomTransform;
  }();

  var CustomPluginsTests = function (_RenderingTestCase) {
    (0, _emberBabel.inherits)(CustomPluginsTests, _RenderingTestCase);

    function CustomPluginsTests() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _RenderingTestCase.apply(this, arguments));
    }

    CustomPluginsTests.prototype.afterEach = function () {
      customTransformCounter = 0;
      return _RenderingTestCase.prototype.afterEach.call(this);
    };

    CustomPluginsTests.prototype['@test custom plugins can be used'] = function () {
      this.render('<div data-test="foo" data-blah="derp" class="hahaha"></div>');
      this.assertElement(this.firstChild, {
        tagName: 'div',
        attrs: { class: 'hahaha', 'data-blah': 'derp' },
        content: ''
      });
    };

    CustomPluginsTests.prototype['@test wrapped plugins are only invoked once per template'] = function (assert) {
      this.render('<div>{{#if falsey}}nope{{/if}}</div>');
      assert.equal(customTransformCounter, 1, 'transform should only be instantiated once');
    };

    return CustomPluginsTests;
  }(_internalTestHelpers.RenderingTestCase);

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: registerPlugin with a custom plugins', function (_CustomPluginsTests) {
    (0, _emberBabel.inherits)(_class2, _CustomPluginsTests);

    function _class2() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _CustomPluginsTests.apply(this, arguments));
    }

    _class2.prototype.beforeEach = function () {
      (0, _index.registerPlugin)('ast', CustomTransform);
    };

    _class2.prototype.afterEach = function () {
      (0, _index.unregisterPlugin)('ast', CustomTransform);
      return _CustomPluginsTests.prototype.afterEach.call(this);
    };

    _class2.prototype['@test custom registered plugins are deduplicated'] = function (assert) {
      (0, _index.registerPlugin)('ast', CustomTransform);
      this.registerTemplate('application', '<div data-test="foo" data-blah="derp" class="hahaha"></div>');
      assert.equal(customTransformCounter, 1, 'transform should only be instantiated once');
    };

    return _class2;
  }(CustomPluginsTests));

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: custom plugins passed to compile', function (_RenderingTestCase2) {
    (0, _emberBabel.inherits)(_class3, _RenderingTestCase2);

    function _class3() {
      return (0, _emberBabel.possibleConstructorReturn)(this, _RenderingTestCase2.apply(this, arguments));
    }

    _class3.prototype.compile = function (templateString) {
      return (0, _index.compile)(templateString, {
        plugins: {
          ast: [CustomTransform]
        }
      });
    };

    return _class3;
  }(_internalTestHelpers.RenderingTestCase));
});