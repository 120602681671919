define("nodes/cldrs/nl", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{ "locale": "nl-nl", "parentLocale": "nl" }, { "locale": "nl", "pluralRuleFunction": function pluralRuleFunction(n, ord) {
      var s = String(n).split("."),
          v0 = !s[1];if (ord) return "other";return n == 1 && v0 ? "one" : "other";
    }, "fields": { "year": { "displayName": "jaar", "relative": { "0": "dit jaar", "1": "volgend jaar", "-1": "vorig jaar" }, "relativeTime": { "future": { "one": "over {0} jaar", "other": "over {0} jaar" }, "past": { "one": "{0} jaar geleden", "other": "{0} jaar geleden" } } }, "month": { "displayName": "maand", "relative": { "0": "deze maand", "1": "volgende maand", "-1": "vorige maand" }, "relativeTime": { "future": { "one": "over {0} maand", "other": "over {0} maanden" }, "past": { "one": "{0} maand geleden", "other": "{0} maanden geleden" } } }, "day": { "displayName": "dag", "relative": { "0": "vandaag", "1": "morgen", "2": "overmorgen", "-2": "eergisteren", "-1": "gisteren" }, "relativeTime": { "future": { "one": "over {0} dag", "other": "over {0} dagen" }, "past": { "one": "{0} dag geleden", "other": "{0} dagen geleden" } } }, "hour": { "displayName": "Uur", "relativeTime": { "future": { "one": "over {0} uur", "other": "over {0} uur" }, "past": { "one": "{0} uur geleden", "other": "{0} uur geleden" } } }, "minute": { "displayName": "minuut", "relativeTime": { "future": { "one": "over {0} minuut", "other": "over {0} minuten" }, "past": { "one": "{0} minuut geleden", "other": "{0} minuten geleden" } } }, "second": { "displayName": "seconde", "relative": { "0": "nu" }, "relativeTime": { "future": { "one": "over {0} seconde", "other": "over {0} seconden" }, "past": { "one": "{0} seconde geleden", "other": "{0} seconden geleden" } } } } }];
});