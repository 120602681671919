define('shared/components/schema/input-pvc/component', ['exports', 'shared/components/schema/input-pvc/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['form-control'],

    namespaceId: null,
    fiedl: null,
    value: null,

    pvcs: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'pvcs', Ember.get(this, 'store').all('persistentVolumeClaim'));
    },


    pvcChoices: Ember.computed('pvcs.@each.{name,state}', 'namespaceId', function () {
      return Ember.get(this, 'pvcs').filterBy('namespaceId', Ember.get(this, 'namespaceId')).map(function (v) {
        var label = Ember.get(v, 'displayName');
        var state = Ember.get(v, 'state');
        var disabled = false;

        if (disabled) {
          label += ' (' + state + ')';
        }

        return {
          label: label,
          disabled: disabled,
          value: Ember.get(v, 'name')
        };
      }).sortBy('label');
    })
  });
});