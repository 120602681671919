define('shared/components/authorized-endpoint/component', ['exports', 'shared/components/authorized-endpoint/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),

    layout: _template.default,

    cluster: null,
    enableLocalClusterAuthEndpoint: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.initClusterAuthEndpoint();
    },


    enableClusterAuthEndpointChanged: Ember.observer('enableLocalClusterAuthEndpoint', function () {
      var cluster = this.cluster,
          enableLocalClusterAuthEndpoint = this.enableLocalClusterAuthEndpoint;


      if (enableLocalClusterAuthEndpoint) {
        if (cluster.localClusterAuthEndpoint) {
          Ember.set(cluster, 'localClusterAuthEndpoint.enabled', true);
        } else {
          this.createLocalClusterAuthEndpoint();
        }
      } else {
        Ember.set(cluster, 'localClusterAuthEndpoint.enabled', false);
      }
    }),

    initClusterAuthEndpoint: function initClusterAuthEndpoint() {
      var cluster = this.cluster;


      if (cluster.localClusterAuthEndpoint && cluster.localClusterAuthEndpoint.enabled) {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', true);
      } else {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', false);
      }
    },
    createLocalClusterAuthEndpoint: function createLocalClusterAuthEndpoint() {
      var globalStore = this.globalStore;


      var lcae = globalStore.createRecord({
        type: 'localClusterAuthEndpoint',
        enabled: true
      });

      Ember.set(this, 'cluster.localClusterAuthEndpoint', lcae);
    }
  });
});