define('global-admin/multi-cluster-apps/catalog/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting /* , transition*/) {
      if (isExiting) {
        controller.set('search', '');
      }
    }
  });
});