define('nodes/components/driver-digitalocean/component', ['exports', 'shared/mixins/node-driver', 'nodes/components/driver-digitalocean/template'], function (exports, _nodeDriver, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VALID_IMAGES = ['rancheros', 'centos-7-x64', 'coreos-alpha', 'coreos-beta', 'coreos-stable', 'debian-8-x64', 'debian-9-x64', 'fedora-27-x64', 'fedora-28-x64', 'ubuntu-14-04-x64', 'ubuntu-16-04-x64', 'ubuntu-18-04-x64'];

  exports.default = Ember.Component.extend(_nodeDriver.default, {
    app: Ember.inject.service(),
    digitalOcean: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,

    driverName: 'digitalocean',
    regionChoices: null,
    model: null,
    step: 1,
    sizeChoices: null,
    imageChoices: null,
    tags: null,

    config: Ember.computed.alias('primaryResource.digitaloceanConfig'),

    init: function init() {
      this._super.apply(this, arguments);

      this.initTags();
    },


    actions: {
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential(cred) {
        if (cred) {
          Ember.set(this, 'primaryResource.cloudCredentialId', Ember.get(cred, 'id'));

          this.send('getData');
        }
      },
      getData: function getData(cb) {
        var _this = this;

        var auth = {
          type: 'cloud',
          token: Ember.get(this, 'primaryResource.cloudCredentialId')
        };

        var promises = {
          regions: this.digitalOcean.request(auth, 'regions'),
          images: this.digitalOcean.request(auth, 'images', { params: { type: 'distribution' } }),
          sizes: this.digitalOcean.request(auth, 'sizes')
        };

        Ember.RSVP.hash(promises).then(function (hash) {
          var filteredRegions = hash.regions.regions.filter(function (region) {
            return region.available && region.features.indexOf('metadata') >= 0;
          }).sortBy('name');

          var filteredSizes = hash.sizes.sizes.map(function (size) {
            size.memoryGb = size.memory / 1024;
            size.highMem = size.slug.indexOf('m-') >= 0;

            return size;
          }).filter(function (size) {
            return size.available;
          }).sortBy('highMem', 'memory');

          var filteredImages = hash.images.images.filter(function (image) {
            // 64-bit only
            return !(image.name || '').match(/x32$/);
          }).map(function (image) {
            image.disabled = VALID_IMAGES.indexOf(image.slug) === -1;

            return image;
          });

          filteredImages = filteredImages.sortBy('distribution', 'name');

          Ember.setProperties(_this, {
            regionChoices: filteredRegions,
            sizeChoices: filteredSizes,
            imageChoices: filteredImages,
            step: 2,
            errors: null
          });

          _this.sendAction('hidePicker');
        }, function (err) {
          var errors = Ember.get(_this, 'errors') || [];

          errors.push(err.statusText + ': ' + err.body.message);

          Ember.setProperties(_this, { errors: errors });

          if (cb && typeof cb === 'function') {
            cb();
          }
        });
      }
    },

    imageChanged: Ember.observer('config.image', function () {
      var image = Ember.get(this, 'config.image');

      if (image === 'rancheros') {
        Ember.set(this, 'config.sshUser', 'rancher');
      } else if (image.startsWith('coreos')) {
        Ember.set(this, 'config.sshUser', 'core');
      } else {
        Ember.set(this, 'config.sshUser', 'root');
      }
    }),

    tagsDidChange: Ember.observer('tags', function () {
      Ember.set(this, 'config.tags', Ember.get(this, 'tags').join(','));
    }),

    filteredSizeChoices: Ember.computed('config.region', function () {
      var region = Ember.get(this, 'regionChoices').findBy('slug', Ember.get(this, 'config.region'));
      var sizes = Ember.get(this, 'sizeChoices');
      var out = sizes.filter(function (size) {
        return region.sizes.indexOf(size.slug) >= 0;
      });

      return out;
    }),

    initTags: function initTags() {
      var tags = Ember.get(this, 'config.tags');

      if (tags) {
        Ember.set(this, 'tags', tags.split(','));
      }
    },
    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: 'digitaloceanConfig',
        size: 's-2vcpu-2gb',
        region: 'nyc3',
        image: 'ubuntu-16-04-x64',
        sshUser: 'root'
      });

      var primaryResource = Ember.get(this, 'primaryResource');

      Ember.set(primaryResource, 'digitaloceanConfig', config);
    },
    validate: function validate() {
      this._super();
      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push(this.intl.t('nodeDriver.nameError'));
      }

      if (!this.validateCloudCredentials()) {
        errors.push(this.intl.t('nodeDriver.cloudCredentialError'));
      }

      if (errors.length) {
        Ember.set(this, 'errors', errors.uniq());

        return false;
      }

      return true;
    }
  });
});