define('global-admin/accounts/detail/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      var store = Ember.get(this, 'globalStore');

      return Ember.RSVP.hash({
        globalRoles: store.findAll('globalrole'),
        user: store.find('user', params.user_id)
      });
    }
  });
});