define('global-admin/security/policies/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.get('globalStore').find('podSecurityPolicyTemplate').then(function (policies) {
        var policy = policies.findBy('id', params.policy_id);

        if (!policy) {
          _this.replaceWith('security.policies.index');
        }

        return { policy: policy.clone() };
      });
    }
  });
});