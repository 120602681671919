define('shared/components/form-user-labels/component', ['exports', 'shared/mixins/manage-labels', 'shared/components/accordion-list-item/component', 'shared/components/form-user-labels/template'], function (exports, _manageLabels, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageLabels.default, {
    intl: Ember.inject.service(),

    layout: _template.default,
    classNames: ['accordion-wrapper'],

    detailKey: 'formUserLabels.detail',

    expandAll: null,

    readonlyLabels: null,

    // Inputs
    initialLabels: null,

    statusClass: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initLabels(this.get('initialLabels'), 'user', null, this.get('readonlyLabels'));
      this.labelsChanged();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },


    actions: {
      addUserLabel: function addUserLabel() {
        var _this = this;

        this._super();
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.key').last()[0].focus();
        });
      }
    },

    status: function () {
      var k = _component.STATUS.NONE;
      var count = this.get('userLabelArray').filterBy('key').get('length') || 0;

      if (count) {
        k = _component.STATUS.COUNTCONFIGURED;
      }

      this.set('statusClass', (0, _component.classForStatus)(k));

      return this.get('intl').t(_component.STATUS_INTL_KEY + '.' + k, { count: count });
    }.property('userLabelArray.@each.key'),
    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
      this.validate();
    },
    validate: function validate() {
      var errors = [];

      //    (this.get('labelArray')||[]).forEach((obj) => {
      //    });

      Ember.set(this, 'errors', errors);
    }
  });
});