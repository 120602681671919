enifed('ember-routing/lib/location/none_location', ['exports', 'ember-metal', '@ember/debug', 'ember-runtime'], function (exports, _emberMetal, _debug, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    implementation: 'none',
    path: '',

    detect: function () {
      var rootURL = this.rootURL;

      false && !(rootURL.charAt(rootURL.length - 1) === '/') && (0, _debug.assert)('rootURL must end with a trailing forward slash e.g. "/app/"', rootURL.charAt(rootURL.length - 1) === '/');
    },

    /**
      Will be pre-pended to path.
       @private
      @property rootURL
      @default '/'
    */
    rootURL: '/',

    /**
      Returns the current path without `rootURL`.
       @private
      @method getURL
      @return {String} path
    */
    getURL: function () {
      var path = (0, _emberMetal.get)(this, 'path');
      var rootURL = (0, _emberMetal.get)(this, 'rootURL');

      // remove trailing slashes if they exists
      rootURL = rootURL.replace(/\/$/, '');

      // remove rootURL from url
      return path.replace(new RegExp('^' + rootURL + '(?=/|$)'), '');
    },

    /**
      Set the path and remembers what was set. Using this method
      to change the path will not invoke the `updateURL` callback.
       @private
      @method setURL
      @param path {String}
    */
    setURL: function (path) {
      (0, _emberMetal.set)(this, 'path', path);
    },

    /**
      Register a callback to be invoked when the path changes. These
      callbacks will execute when the user presses the back or forward
      button, but not after `setURL` is invoked.
       @private
      @method onUpdateURL
      @param callback {Function}
    */
    onUpdateURL: function (callback) {
      this.updateCallback = callback;
    },

    /**
      Sets the path and calls the `updateURL` callback.
       @private
      @method handleURL
      @param url {String}
    */
    handleURL: function (url) {
      (0, _emberMetal.set)(this, 'path', url);
      this.updateCallback(url);
    },

    /**
      Given a URL, formats it to be placed into the page as part
      of an element's `href` attribute.
       This is used, for example, when using the {{action}} helper
      to generate a URL based on an event.
       @private
      @method formatURL
      @param url {String}
      @return {String} url
    */
    formatURL: function (url) {
      var rootURL = (0, _emberMetal.get)(this, 'rootURL');

      if (url !== '') {
        // remove trailing slashes if they exists
        rootURL = rootURL.replace(/\/$/, '');
      }

      return rootURL + url;
    }
  });
});