define('shared/utils/text-width', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getWidth;

  var canvas = void 0;

  function getWidth(str, fontStyle) {
    if (!canvas) {
      canvas = document.createElement('canvas');
    }

    var ctx = canvas.getContext('2d');

    ctx.font = fontStyle;

    return Math.ceil(ctx.measureText(str).width);
  }
});