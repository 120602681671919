define('shared/components/settings/danger-zone/component', ['exports', 'shared/settings/service', 'ui/utils/constants', 'shared/components/settings/danger-zone/template'], function (exports, _service, _constants, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    loading: false,
    show: false,

    actions: {
      showNode: function showNode(node) {
        node.toggleProperty('hide');
      },
      show: function show() {
        var _this = this;

        Ember.set(this, 'loading', true);
        Ember.get(this, 'settings').loadAll().then(function () {
          Ember.set(_this, 'loading', false);
          Ember.set(_this, 'show', true);
        }).catch(function () {
          Ember.set(_this, 'loading', false);
          Ember.set(_this, 'show', false);
        });
      }
    },

    allowed: Ember.computed('settings.all.@each.{name,customized}', function () {
      var out = {};

      Object.keys(_constants.default.SETTING.ALLOWED).forEach(function (key) {
        var val = Object.assign({}, _constants.default.SETTING.ALLOWED[key]);

        val.descriptionKey = 'dangerZone.description.' + key;
        out[key] = val;
      });

      return out;
    }),

    current: Ember.computed('allowed.@each.{name,customized}', function () {
      var all = Ember.get(this, 'settings.asMap');
      var allowed = Ember.get(this, 'allowed');
      var isLocalDev = window.location.host === 'localhost:8000';

      return Object.keys(allowed).filter(function (key) {
        var details = allowed[key];

        return !details['devOnly'] || isLocalDev;
      }).map(function (key) {
        var obj = all[(0, _service.normalizeName)(key)];
        var details = allowed[key];

        var out = Ember.Object.create({
          key: key,
          obj: obj
        });

        if (Ember.get(details, 'kind') === 'multiline') {
          out.set('hide', true);
        }

        if (Ember.get(details, 'kind') === 'json') {
          out.set('hide', true);
          out.set('parsedJSON', JSON.stringify(JSON.parse(out.get('obj.value')), undefined, 2));
        }

        (Object.keys(details) || []).forEach(function (key2) {
          out.set(key2, details[key2]);
        });

        return out;
      });
    })
  });
});