define('shared/mixins/new-or-edit', ['exports', '@rancher/ember-api-store/models/resource', 'ui/utils/errors'], function (exports, _resource, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    originalModel: null,
    errors: null,
    editing: true,
    primaryResource: Ember.computed.alias('model'),
    originalPrimaryResource: Ember.computed.alias('originalModel'),

    tagName: 'form', // This indirectly disables global navigation shortcut keys

    init: function init() {
      this._super();
      Ember.set(this, 'errors', null);
    },
    validate: function validate() {
      var model = Ember.get(this, 'primaryResource');
      var errors = model.validationErrors();

      if (errors.get('length')) {
        Ember.set(this, 'errors', errors);

        return false;
      }

      Ember.set(this, 'errors', null);

      return true;
    },
    submit: function submit(event) {
      event.preventDefault();
      this.send('save');
    },


    actions: {
      error: function error(err) {
        if (err) {
          var body = _errors.default.stringify(err);

          Ember.set(this, 'errors', [body]);
        } else {
          Ember.set(this, 'errors', null);
        }
      },
      save: function save(cb) {
        var _this = this;

        cb = cb || function () {};

        // Will save can return true/false or a promise
        Ember.RSVP.resolve(this.willSave()).then(function (ok) {
          if (!ok) {
            // Validation or something else said not to save
            cb(false);

            return;
          }

          _this.doSave().then(_this.didSave.bind(_this)).then(_this.doneSaving.bind(_this)).then(function () {
            cb(true);
          }).catch(function (err) {
            _this.send('error', err);
            _this.errorSaving(err);
            cb(false);
          });
        });
      }
    },

    // willSave happens before save and can stop the save from happening
    willSave: function willSave() {
      Ember.set(this, 'errors', null);
      var ok = this.validate();

      return ok;
    },
    doSave: function doSave(opt) {
      var _this2 = this;

      return Ember.get(this, 'primaryResource').save(opt).then(function (newData) {
        return _this2.mergeResult(newData);
      });
    },
    mergeResult: function mergeResult(newData) {
      var original = Ember.get(this, 'originalPrimaryResource');

      if (original) {
        if (_resource.default.detectInstance(original)) {
          original.merge(newData);

          return original;
        }
      }

      return newData;
    },


    // didSave can be used to do additional saving of dependent resources
    didSave: function didSave(neu) {
      return neu;
    },


    // doneSaving happens after didSave
    doneSaving: function doneSaving(neu) {
      return neu || Ember.get(this, 'originalPrimaryResource') || Ember.get(this, 'primaryResource');
    },


    // errorSaving can be used to do additional cleanup of dependent resources on failure
    errorSaving: function errorSaving() /* err*/{}
  });
});