define('shared/helpers/rbac-prevents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    access: Ember.inject.service(),
    scope: Ember.inject.service(),

    scopeChanged: Ember.observer('scope.currentProject.id', 'scope.currentCluster.id', function () {
      this.recompute();
    }),

    compute: function compute(params, options) {
      return !Ember.get(this, 'access').allows(options.resource, options.permission, options.scope, Ember.get(this, 'scope'));
    }
  });
});