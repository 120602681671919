define('shared/helpers/to-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params /* , options*/) {
      return JSON.stringify(params[0], undefined, 2);
    }
  });
});