define('shared/components/form-auth-cloud-credential/component', ['exports', 'shared/components/form-auth-cloud-credential/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    showAddCloudCredential: null,
    region: null, // only used a passthrough for the amazon region so the region selection can happen in cloud creds rather than have the markup on both pages
    hideSave: false,
    cloudCredentials: null,
    driverName: null,
    primaryResource: null,
    finishAndSelectCloudCredential: null,
    progressStep: null,
    cancel: null,

    createLabel: 'saveCancel.create',
    savingLabel: 'generic.loading',
    mode: 'new',

    init: function init() {
      this._super.apply(this, arguments);

      this.initSingleOrAddCredential();
    },


    actions: {
      doneSavingCloudCredential: function doneSavingCloudCredential(cred) {
        if (cred) {
          Ember.get(this, 'finishAndSelectCloudCredential')(cred);

          Ember.set(this, 'showAddCloudCredential', false);
        }
      },
      addCloudCredential: function addCloudCredential() {
        Ember.set(this, 'showAddCloudCredential', true);
      },
      cancleNewCloudCredential: function cancleNewCloudCredential() {
        Ember.set(this, 'showAddCloudCredential', false);
      }
    },

    initSingleOrAddCredential: function initSingleOrAddCredential() {
      var _this = this;

      var _cloudCredentials = this.cloudCredentials,
          cloudCredentials = _cloudCredentials === undefined ? [] : _cloudCredentials,
          primaryResource = this.primaryResource;

      var singleCloudCredentialId = Ember.get(cloudCredentials || [], 'firstObject.id') || null;

      if (Ember.isEmpty(Ember.get(this, 'cloudCredentials'))) {
        Ember.run.next(function () {
          Ember.set(_this, 'showAddCloudCredential', true);
        });
      }

      Ember.run.next(function () {
        if (primaryResource && (!primaryResource.cloudCredentialId || !cloudCredentials.find(function (c) {
          return c.id === primaryResource.cloudCredentialId;
        }))) {
          Ember.set(_this, 'primaryResource.cloudCredentialId', singleCloudCredentialId);
        }
      });
    }
  });
});