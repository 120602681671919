define('ember-model-validator/messages/pt-br', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    presenceMessage: 'não pode ficar em branco',
    absenceMessage: 'deve estar em branco',
    inclusionMessage: 'não está incluso na lista de valores válidos',
    exclusionMessage: 'está incluso na lista de valores inválidos',

    // Numericality
    numericalityMessage: 'não é um número',
    numericalityOnlyIntegerMessage: 'deve ser um número inteiro',
    numericalityGreaterThanMessage: 'deve ser maior que {count}',
    numericalityGreaterThanOrEqualToMessage: 'deve ser maior ou igual a {count}',
    numericalityEqualToMessage: 'deve ser igual a {count}',
    numericalityLessThanMessage: 'deve ser menor que {count}',
    numericalityLessThanOrEqualToMessage: 'deve ser menor ou igual a {count}',
    numericalityOddMessage: 'deve ser par',
    numericalityEvenMessage: 'dever ser ímpar',

    mailMessage: 'não é um email válido',
    formatMessage: 'é inválido',
    colorMessage: 'deve ser um código de cor hexadecimal para CSS',
    subdomainMessage: 'deve ser um subdomínio válido',
    acceptanceMessage: 'precisa ser aceito',
    zipCodeMessage: 'não é um CEP válido',
    URLMessage: 'deve ser uma URL',
    wrongLengthMessage: 'comprimento de palavra errado (deve conter {count} caracteres)',
    tooShortMessage: 'muito curto (deve conter no minimo {count} caracteres)',
    tooLongMessage: 'muito longo (no maximo {count} caracteres)',
    mustContainNumberMessage: 'deve incluir um número',
    mustContainSpecialMessage: 'deve conter um desse caracteres especiais: {characters}',
    mustContainLowerMessage: 'deve conter uma letra em minusculo',
    mustContainCapitalMessage: 'deve contar uma letar em maisculo',
    customValidationMessage: 'está inválido',
    matchMessage: 'não corresponde ao formato {match}',

    dateMessage: 'não é uma data válida',
    dateBeforeMessage: 'deve ser antes de {date}',
    dateAfterMessage: 'deve ser depois de {date}'
  };
});