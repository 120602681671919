define('shared/components/tooltip-basic/component', ['exports', 'shared/mixins/tooltip', 'shared/components/tooltip-basic/template'], function (exports, _tooltip, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tooltip.default, {
    layout: _template.default,
    needs: ['application'],
    display: null,

    model: Ember.computed.oneWay('tooltipService.tooltipOpts.model'),
    selectPartial: Ember.computed('tooltipService.tooltipOpts.template', function () {
      return this.get('tooltipService.tooltipOpts.template') || 'tooltip-basic';
    })

  });
});