enifed('ember-template-compiler/lib/system/calculate-location-display', ['exports'], function (exports) {
    'use strict';

    exports.default = function (moduleName, loc) {
        var moduleInfo = '',
            _ref,
            column,
            line;
        if (moduleName) {
            moduleInfo += '\'' + moduleName + '\' ';
        }
        if (loc) {
            _ref = loc.start || { line: undefined, column: undefined }, column = _ref.column, line = _ref.line;


            if (line !== undefined && column !== undefined) {
                if (moduleName) {
                    // only prepend @ if the moduleName was present
                    moduleInfo += '@ ';
                }
                moduleInfo += 'L' + line + ':C' + column;
            }
        }
        if (moduleInfo) {
            moduleInfo = '(' + moduleInfo + ') ';
        }
        return moduleInfo;
    };
});