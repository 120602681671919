define('shared/user-language/service', ['exports', 'shared/utils/constants', '@rancher/ember-api-store/utils/ajax-promise', 'ui/utils/load-script'], function (exports, _constants, _ajaxPromise, _loadScript) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RTL_LANGUAGES = ['fa-ir'];
  // @@TODO@@ - 10-27-17 - move to addon
  exports.default = Ember.Service.extend({
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),
    session: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    locales: Ember.computed.alias('app.locales'),
    growl: Ember.inject.service(),
    cookies: Ember.inject.service(),
    userTheme: Ember.inject.service('user-theme'),
    app: Ember.inject.service(),
    loadedLocales: null,

    bootstrap: function () {
      this.set('loadedLocales', []);
    }.on('init'),

    initUnauthed: function initUnauthed() {
      var lang = _constants.default.LANGUAGE.DEFAULT;
      var fromSession = this.get('session.' + _constants.default.SESSION.LANGUAGE);
      var fromCookie = this.get('cookies').get(_constants.default.COOKIE.LANG);

      if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      return this.sideLoadLanguage(lang);
    },
    initLanguage: function initLanguage() {
      var save = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var lang = _constants.default.LANGUAGE.DEFAULT;
      var session = this.get('session');

      var fromLogin = session.get(_constants.default.SESSION.LOGIN_LANGUAGE);
      var fromPrefs = this.get('prefs.' + _constants.default.PREFS.LANGUAGE); // get language from user prefs
      var fromSession = session.get(_constants.default.SESSION.LANGUAGE); // get local language
      var fromCookie = this.get('cookies').get(_constants.default.COOKIE.LANG); // get language from cookie


      if (fromLogin) {
        lang = fromLogin;
        if (save) {
          session.set(_constants.default.SESSION.LOGIN_LANGUAGE, undefined);
        }
      } else if (fromPrefs) {
        lang = fromPrefs;
      } else if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      lang = this.normalizeLang(lang);

      this.setLanguage(lang, save);

      return this.sideLoadLanguage(lang);
    },
    normalizeLang: function normalizeLang(lang) {
      return lang.toLowerCase();
    },
    getLocale: function getLocale() {
      return this.get('intl.locale')[0];
    },
    setLanguage: function setLanguage(lang) {
      var savePref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      // Don't save 'none', so you can't get stuck in it across reloads
      if (lang === 'none') {
        return Ember.RSVP.resolve();
      }

      var session = this.get('session');

      lang = lang || session.get(_constants.default.SESSION.LANGUAGE);

      session.set(_constants.default.SESSION.LANGUAGE, lang);
      if (savePref && this.get('access.principal')) {
        return this.set('prefs.' + _constants.default.PREFS.LANGUAGE, lang);
      } else {
        return Ember.RSVP.resolve();
      }
    },
    sideLoadLanguage: function sideLoadLanguage(language) {
      var _this = this;

      var version = this.get('settings.uiVersion');
      var loadedLocales = this.get('loadedLocales');

      if (loadedLocales.includes(language)) {
        this.get('intl').setLocale(language);
        this.setLanguage(language, false);
        this.get('userTheme').writeStyleNode();

        return Ember.RSVP.resolve();
      } else {
        return (0, _ajaxPromise.ajaxPromise)({
          url: this.get('app.baseAssets') + 'translations/' + language + '.json?' + version,
          method: 'GET',
          dataType: 'json'
        }).then(function (resp) {
          var promise = void 0;

          if (_this.get('app.needIntlPolyfill')) {
            promise = (0, _loadScript.loadScript)(_this.get('app.baseAssets') + 'assets/intl/locales/' + language.toLowerCase() + '.js?' + version);
          } else {
            promise = Ember.RSVP.resolve();
          }

          return promise.then(function () {
            loadedLocales.push(language);

            return _this.get('intl').addTranslations(language, resp.xhr.responseJSON).then(function () {
              _this.get('intl').setLocale(language);
              _this.setLanguage(language, false);
              _this.get('userTheme').writeStyleNode();
            });
          });
        }).catch(function (err) {
          _this.get('growl').fromError('Error loading language: ' + language, err);
          if (language !== _constants.default.LANGUAGE.DEFAULT) {
            return _this.sideLoadLanguage(_constants.default.LANGUAGE.DEFAULT);
          }
        });
      }
    },
    getAvailableTranslations: function getAvailableTranslations() {
      return this.get('intl').getLocalesByTranslations();
    },
    isRtl: function isRtl(lang) {
      return RTL_LANGUAGES.includes(lang.toLowerCase());
    }
  });
});