define('shared/components/form-ssl-rows/component', ['exports', 'shared/components/form-ssl-rows/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    editing: null,
    hosts: null,

    hostArray: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.initHosts();
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'editing') && Ember.get(this, 'hostArray.length') === 0) {
        this.send('addHost');
      }
    },


    actions: {
      removeHost: function removeHost(host) {
        Ember.get(this, 'hostArray').removeObject(host);
      },
      addHost: function addHost() {
        Ember.get(this, 'hostArray').pushObject({ value: '' });
      }
    },

    hostDidChange: Ember.observer('hostArray.@each.value', function () {
      var hosts = [];

      Ember.get(this, 'hostArray').filter(function (host) {
        return host.value;
      }).forEach(function (host) {
        hosts.push(host.value);
      });
      Ember.set(this, 'hosts', hosts);
    }),
    initHosts: function initHosts() {
      var hosts = Ember.get(this, 'hosts') || [];

      Ember.set(this, 'hostArray', hosts.map(function (host) {
        return { value: host };
      }));
    }
  });
});