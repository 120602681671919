define('shared/helpers/join-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params, options) {
      var separator = options.separator || ', ';

      return (params[0] || []).join(separator);
    }
  });
});