enifed('ember-views/lib/component_lookup', ['exports', '@ember/debug', 'ember-runtime'], function (exports, _debug, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    componentFor: function (name, owner, options) {
      false && !(name.indexOf('-') > -1 || true /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */) && (0, _debug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen' + /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */' or start with a capital letter' + '.', name.indexOf('-') > -1 || true);

      return owner.factoryFor('component:' + name, options);
    },
    layoutFor: function (name, owner, options) {
      false && !(name.indexOf('-') > -1 || true /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */) && (0, _debug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen.', name.indexOf('-') > -1 || true);

      return owner.lookup('template:components/' + name, options);
    }
  });
});