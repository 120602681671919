define('shared/components/multi-container-stats/component', ['exports', 'ui/utils/multi-stats', 'shared/components/multi-container-stats/template'], function (exports, _multiStats, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = ['cpuUser', 'cpuSystem', 'cpuTotal', 'networkTx', 'networkRx', 'networkTotal', 'memory', 'storageWrite', 'storageRead', 'storageTotal'];

  exports.default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    linkName: 'containerStats',
    maxPoints: 60,
    emitInterval: 1000,
    emitMaps: false,
    tagName: '',
    statsSocket: null,
    emitTimer: null,

    available: Ember.computed.alias('statsSocket.available'),
    active: Ember.computed.alias('statsSocket.active'),
    loading: Ember.computed.alias('statsSocket.loading'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('boundEmit', this.emit.bind(this));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('statsSocket')) {
        this.disconnect();
        this.tearDown();
      }

      this.connect();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.disconnect();
      this.tearDown();
    },


    onActiveChanged: function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!this.get('active')) {
        this.tearDown();
      }
    }.observes('active'),

    connect: function connect() {
      var _this = this;

      Ember.run.next(function () {
        try {
          var stats = _multiStats.default.create({
            resource: _this.get('model'),
            linkName: _this.get('linkName')
          });

          _this.set('statsSocket', stats);
          stats.on('dataPoint', function (data) {
            _this.onDataPoint(data);
          });
        } catch (e) {}
      });
    },
    disconnect: function disconnect() {
      var stats = this.get('statsSocket');

      if (stats) {
        stats.close();
      }
    },
    setUp: function setUp() {
      var _this2 = this;

      FIELDS.forEach(function (field) {
        var data = [];

        _this2.set(field, data);
        _this2.set(field + '_A', {});
        _this2.set(field + '_B', {});
      });

      if (this.get('emitMaps')) {
        var mapAry = [];

        this.set('maps', mapAry);
      }

      this.startTimer();
    },
    tearDown: function tearDown() {
      var _this3 = this;

      this.stopTimer();
      this.set('maps', null);
      FIELDS.forEach(function (field) {
        _this3.set(field, null);
        _this3.set(field + '_A', null);
        _this3.set(field + '_B', null);
      });
    },
    startTimer: function startTimer() {
      this.stopTimer();
      this.set('emitTimer', setInterval(this.get('boundEmit'), this.get('emitInterval')));
    },
    stopTimer: function stopTimer() {
      clearInterval(this.get('emitTimer'));
    },
    onDataPoint: function onDataPoint(point) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      if (!this.get('cpuSystem_A')) {
        this.setUp();
      }

      var key = point.key;

      this.get('cpuSystem_A')[key] = point.cpu_system || 0;
      this.get('cpuUser_A')[key] = point.cpu_user || 0;
      this.get('cpuTotal_A')[key] = point.cpu_total || 0;

      this.get('memory_A')[key] = point.mem_used_mb || 0;

      this.get('networkTx_A')[key] = point.net_tx_kb * 8 || 0;
      this.get('networkRx_A')[key] = point.net_rx_kb * 8 || 0;
      this.get('networkTotal_A')[key] = this.get('networkTx_A')[key] + this.get('networkRx_A')[key];

      this.get('storageWrite_A')[key] = point.disk_write_kb * 8 || 0;
      this.get('storageRead_A')[key] = point.disk_read_kb * 8 || 0;
      this.get('storageTotal_A')[key] = this.get('storageWrite_A')[key] + this.get('storageRead_A')[key];
    },
    emit: function emit() {
      var ary = void 0,
          field = void 0,
          valueMapA = void 0,
          valueMapB = void 0,
          keys = void 0,
          sum = void 0;
      var maxPoints = this.get('maxPoints');

      var map = {};
      var emitMaps = this.get('emitMaps');

      for (var i = 0; i < FIELDS.length; i++) {
        field = FIELDS[i];
        // Average out the last 2 points from each field.
        valueMapA = this.get(field + '_A');
        valueMapB = this.get(field + '_B');
        this.set(field + '_B', valueMapA);
        this.set(field + '_A', {});

        ary = this.get(field);
        sum = 0;
        field = FIELDS[i];
        keys = Object.keys(valueMapA);
        for (var j = 0; j < keys.length; j++) {
          sum += valueMapA[keys[j]];
        }

        keys = Object.keys(valueMapB);
        if (keys && keys.length) {
          for (var _j = 0; _j < keys.length; _j++) {
            sum += valueMapB[keys[_j]];
          }

          sum = Math.round(sum / 2);
        }

        while (ary.get('length') >= maxPoints) {
          ary.removeAt(0);
        }
        ary.pushObject(sum);

        if (emitMaps) {
          map[field] = sum;
        }
      }

      if (emitMaps) {
        var mapAry = this.get('maps');

        while (mapAry.get('length') >= maxPoints) {
          mapAry.removeAt(0);
        }
        mapAry.push(map);
      }
    }
  });
});