define('alert/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    this.route('index', { path: '/' });
    this.route('new', { path: '/add' });
    this.route('edit', { path: '/edit/:alert_id' });
    this.route('edit-rule', { path: '/:group_id/rule/:rule_id' });
    this.route('new-rule', { path: '/:group_id/rule/add' });
  });
});