define('shared/mixins/tooltip', ['exports', 'shared/mixins/throttled-resize'], function (exports, _throttledResize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_throttledResize.default, {
    tooltipContent: null,
    originalNode: null,
    router: Ember.inject.service(),
    currentRoute: null,
    tooltipService: Ember.inject.service('tooltip'),

    didInsertElement: function didInsertElement() {
      var $el = Ember.$(this.get('element'));
      var markup = '<div class =\'tooltip-caret\'></div>';

      Ember.$(markup).appendTo($el);
    },
    mouseEnter: function mouseEnter() {
      this.get('tooltipService').cancelTimer();
    },
    mouseLeave: function mouseLeave() {
      this.destroyTooltip();
    },


    routeObserver: Ember.on('init', Ember.observer('router.currentRouteName', function () {
      // On init
      if (!this.get('currentRoute')) {
        this.set('currentRoute', this.get('router.currentRouteName'));
      }

      // if we change routes tear down the tooltip
      if (this.get('currentRoute') !== this.get('router.currentRouteName')) {
        this.destroyTooltip();
      }
    })),

    tooltipConstructor: Ember.on('init', Ember.observer('tooltipService.tooltipOpts', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (this.get('tooltipService.tooltipOpts')) {
          this.constructTooltip();
        }
      });
    })),

    constructTooltip: function constructTooltip() {
      var tts = this.get('tooltipService');
      var node = Ember.$(this.element);
      var eventPosition = tts.get('tooltipOpts.eventPosition');
      var position = this.positionTooltip(node, Ember.$().extend({}, eventPosition));
      var css = { visibility: 'visible' };
      var classes = position.placement;

      if (tts.get('tooltipOpts.isCopyTo')) {
        css.width = position.width + 1;
      }

      if (tts.tooltipOpts.baseClass) {
        classes += ' ' + tts.tooltipOpts.baseClass;
      }

      node.offset(position).addClass(classes).css(css);
      if (position.caret) {
        node.find('.tooltip-caret').css('left', position.caret);
      }
    },
    destroyTooltip: function destroyTooltip() {
      this.get('tooltipService').startTimer();
    },
    positionTooltip: function positionTooltip(node, position) {
      var windowWidth = window.innerWidth;
      var eventNode = this.get('tooltipService.tooltipOpts.originalNode');
      var originalNodeWidth = eventNode.outerWidth();
      var originalNodeHeight = eventNode.outerHeight();
      var nodeHeight = node.outerHeight();
      var nodeWidth = node.outerWidth();
      var overridePlacement = this.get('tooltipService.tooltipOpts.placement');
      var self = this;

      if (overridePlacement) {
        position.placement = overridePlacement;
      } else if (nodeWidth >= position.left) {
        position.placement = 'left';
      } else if (nodeWidth >= windowWidth - position.left) {
        position.placement = 'right';
      } else if (nodeHeight >= position.top) {
        position.placement = 'bottom';
      } else {
        position.placement = 'top';
      }

      switch (position.placement) {
        case 'left':
          position.left = horizontalViewport(position.left + originalNodeWidth + 7, position);
          position.top = position.top + originalNodeHeight / 2 - nodeHeight / 2;
          break;
        case 'right':
          position.left = horizontalViewport(position.left - nodeWidth - 7, position);
          position.top = position.top + originalNodeHeight / 2 - nodeHeight / 2;
          break;
        case 'bottom':
          position.left = horizontalViewport(position.left + originalNodeWidth / 2 - nodeWidth / 2, position);
          position.top = position.top + originalNodeHeight + 7;
          break;
        default:
          position.left = horizontalViewport(position.left + originalNodeWidth / 2 - nodeWidth / 2, position);
          position.top = position.top - (nodeHeight + 7);
          break;
      }

      function horizontalViewport(left, position) {
        if (left < nodeWidth / 2) {
          var centerOfDot = self.get('tooltipService.tooltipOpts.originalNode').offset().left + originalNodeWidth / 2;
          var widthOfEvent = originalNodeWidth;
          var pushFromLeft = 10;

          left = pushFromLeft;
          position.caret = centerOfDot - pushFromLeft - widthOfEvent / 2;
        }

        return left;
      }

      position.width = nodeWidth;

      return position;
    }
  });
});