define('shared/components/link-to-as-attrs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.LinkComponent.extend({
    attributeBindings: ['role', 'aria-haspopup', 'aria-expanded'],

    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'params', [Ember.get(this, 'attrs.ourRoute.value')].concat(_toConsumableArray(Ember.get(this, 'attrs.ctx.value') || [])));

      if (Ember.get(this, 'attrs.qp.value')) {
        Ember.get(this, 'params').pushObject(Ember.get(this, 'attrs.qp.value'));
      }

      this._super.apply(this, arguments);
    },


    'current-when': Ember.computed('onlyCurrentWhen', function () {
      var base = Ember.get(this, 'qualifiedRouteName');

      if (Ember.get(this, 'onlyCurrentWhen.length')) {
        return Ember.get(this, 'onlyCurrentWhen').concat(base).join(' ');
      }
    }),

    queryParams: Ember.computed('attrs.qp.value', function () {
      return {
        isQueryParams: true,
        values: Ember.get(this, 'attrs.qp.value') || {}
      };
    })

  });
});