define('shared/components/input-yaml/component', ['exports', 'ui/utils/platform', 'shared/components/input-yaml/template', 'shared/mixins/throttled-resize', 'shared/utils/download-files', 'codemirror', 'js-yaml'], function (exports, _platform, _template, _throttledResize, _downloadFiles, _codemirror, _jsYaml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_throttledResize.default, {
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),

    layout: _template.default,
    mode: 'text',
    label: null,
    namePlaceholder: '',
    nameRequired: false,
    name: null,
    value: null,
    placeholder: '',
    accept: 'text/*, .yml, .yaml',
    multiple: false,
    viewportMargin: Infinity,
    autoResize: false,
    resizeFooter: 130,
    minHeight: 50,
    inputName: false,
    canChangeName: true,
    canUpload: true,
    showUploadLabel: true,
    gutters: ['CodeMirror-lint-markers'],
    tagName: ['div'],
    showUpload: true,
    showDownload: true,

    shouldChangeName: true,

    init: function init() {
      this._super.apply(this, arguments);

      // needed for code mirror???
      window.jsyaml || (window.jsyaml = _jsYaml.default);
    },


    actions: {
      click: function click() {
        this.$('INPUT[type=file]')[0].click();
      },
      wantsChange: function wantsChange() {
        Ember.set(this, 'shouldChangeName', true);
      },
      download: function download() {
        var yaml = Ember.get(this, 'value');
        var lintError = [];

        _jsYaml.default.safeLoadAll(yaml, function (y) {
          lintError.pushObjects(_codemirror.default.lint.yaml(y));
        });

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);

          return;
        }

        (0, _downloadFiles.downloadFile)(Ember.get(this, 'filename'), yaml);
      }
    },

    loadingDidChange: Ember.observer('loading', function () {
      var _this = this;

      if (!Ember.get(this, 'loading') && Ember.get(this, 'autoResize')) {
        Ember.run.next(function () {
          _this.fit();
        });
      }
    }),

    actualAccept: function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }.property('accept'),
    onResize: function onResize() {
      if (Ember.get(this, 'autoResize')) {
        this.fit();
      }
    },
    fit: function fit() {
      if (Ember.get(this, 'autoResize')) {
        var container = this.$('.codemirror-container');

        if (!container) {
          return;
        }

        var position = container.position();

        if (!position) {
          return;
        }

        var desired = this.$(window).height() - position.top - Ember.get(this, 'resizeFooter');

        container.css('max-height', Math.max(Ember.get(this, 'minHeight'), desired));
      }
    },
    change: function change(event) {
      var _this2 = this;

      var input = event.target;

      if (!input.files || !input.files.length) {
        return;
      }

      if (Ember.get(this, 'canChangeName')) {
        var firstName = input.files[0].name;

        if (Ember.get(this, 'multiple')) {
          var ext = firstName.replace(/.*\./, '');

          Ember.set(this, 'name', 'multiple.' + ext);
        } else {
          Ember.set(this, 'name', firstName);
        }

        Ember.set(this, 'shouldChangeName', false);
      }

      var promises = [];
      var file = void 0;

      for (var i = 0; i < input.files.length; i++) {
        file = input.files[i];
        promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
          var reader = new FileReader();

          reader.onload = function (res) {
            var out = res.target.result;

            resolve(out);
          };

          reader.onerror = function (err) {
            Ember.get(_this2, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
            reject(err);
          };

          reader.readAsText(file);
        }));
      }

      Ember.RSVP.all(promises).then(function (res) {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        var value = res.join('\n');

        Ember.set(_this2, 'value', value);
        if (value) {
          _this2.sendAction('fileChosen');
        }
      }).finally(function () {
        input.value = '';
      });
    }
  });
});