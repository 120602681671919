define('global-admin/security/policies/index/controller', ['exports', 'ui/mixins/filter-state'], function (exports, _filterState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var headers = [{
    translationKey: 'podSecurityPoliciesPage.index.table.name',
    name: 'name',
    sort: ['name']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    sort: ['created'],
    searchField: false,
    classNames: 'text-right pr-20',
    width: '200'
  }];

  exports.default = Ember.Controller.extend(_filterState.default, {
    sortBy: 'name',
    headers: headers,
    searchText: ''
  });
});