define('shared/components/storage-class/provisioner-vsphere-volume/component', ['exports', 'shared/components/storage-class/provisioner-vsphere-volume/template', 'shared/mixins/storage-class-provisioner'], function (exports, _template, _storageClassProvisioner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FIELDS = [{
    id: 'diskformat',
    options: [{
      value: 'thin',
      label: 'thin',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.thin'
    }, {
      value: 'zeroedthick',
      label: 'zeroedthick',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.zeroedthick'
    }, {
      value: 'eagerzeroedthick',
      label: 'eagerzeroedthick',
      translationKey: 'cruStorageClass.vsphere-volume.diskformat.eagerzeroedthick'
    }]
  }, 'storagePolicyName', 'datastore', 'hostFailuresToTolerate', 'cachereservation', 'fstype'];

  exports.default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,

    provisioner: 'vsphere-volume',
    fields: FIELDS
  });
});