define('shared/components/identity-block/component', ['exports', 'shared/components/identity-block/template', 'identicon.js'], function (exports, _template, _identicon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var missingPrincipals = [];

  exports.default = Ember.Component.extend({
    globalStore: Ember.inject.service(),

    layout: _template.default,
    // Principal or id+parsedExternalType
    principal: null,
    parsedExternalType: null,
    principalId: null,

    avatar: true,
    link: true,
    size: 35,

    loading: false,
    classNames: ['gh-block'],
    attributeBindings: ['aria-label: principal.name'],
    unknownUser: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var store = Ember.get(this, 'globalStore');
      var principalId = Ember.get(this, 'principalId');
      var missingPrincipal = missingPrincipals.indexOf(principalId);

      if (Ember.get(this, 'principal') || missingPrincipal > 0) {
        return;
      }

      if (principalId) {
        var principal = store.getById('principal', principalId);

        if (principal) {
          Ember.set(this, 'principal', principal);

          return;
        }

        Ember.set(this, 'loading', true);

        store.find('principal', principalId, { forceReload: true }).then(function (principal) {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          Ember.run.next(function () {
            Ember.set(_this, 'principal', principal);
          });
        }).catch(function () /* err*/{
          // Do something..
          missingPrincipals.pushObject(principalId);
        }).finally(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          Ember.set(_this, 'loading', false);
          Ember.set(_this, 'unknownUser', true);
        });
      }
    },


    avatarSrc: Ember.computed('principal', function () {
      return 'data:image/png;base64,' + new _identicon.default(AWS.util.crypto.md5(Ember.get(this, 'principal.id') || 'Unknown', 'hex'), 80, 0.01).toString();
    }),

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      missingPrincipals = [];
      Ember.set(this, 'unknownUser', false);
    }
  });
});