define('global-admin/security/policies/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    globalStore: Ember.inject.service(),

    model: function model() {
      var policy = this.get('globalStore').createRecord({
        type: 'podSecurityPolicyTemplate',
        name: '',
        allowPrivilegeEscalation: false,
        defaultAllowPrivilegeEscalation: false,
        hostIPC: false,
        hostNetwork: false,
        hostPID: false,
        privileged: false,
        readOnlyRootFilesystem: false
      });

      return { policy: policy };
    }
  });
});