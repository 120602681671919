enifed('ember-template-compiler/lib/plugins/transform-angle-bracket-components', ['exports'], function (exports) {
    'use strict';

    exports.default = function () /* env */{
        return {
            name: 'transform-angle-bracket-components',
            visitor: {
                ComponentNode: function (node) {
                    node.tag = '<' + node.tag + '>';
                }
            }
        };
    };
});